import { DeliveredProcedureOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { Button, FormInstance, Modal, PageHeader, Tooltip } from 'antd';
import { masterApi } from 'api/completeApi';
import {
  EntityTypesEnum,
  MdProjectDto,
  MdProjectStateEnum,
  MdProjectVariableEnum,
  MdRoleDto,
  MdRoleEnum,
  OrgUserClaimDto,
} from 'api/completeApiInterfaces';
import { AuditLogButton } from 'components/ActionButtons';
import AuditLogMasterApiEntityModal from 'components/AuditLogsComponents/AuditLogMasterApiEntityModal';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { SaveOrCancelButton } from 'components/SaveOrCancelButton/SaveOrCancelButton';
import { useBoolean, useCurrentAppUser, useIntl, useIsMounted, useSameCallback } from 'hooks';
import { Fmt } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React, { useCallback, useMemo, useState } from 'react';
import { messageError } from 'utils';
import MDSetCkPreparedSwitch from '../MdSetCkPreparedSwitch/MDSetCkPreparedSwitch';
import MDSetDivisionModal from '../MdSetDivisionModal/MDSetDivisionModal';
import MDSetGuarantorModal from '../MdSetGuarantorModal/MDSetGuarantorModal';
import MDSetProcessorModal from '../MdSetProcessorModal/MDSetProcessorModal';

type Props = {
  form: FormInstance<any>;
  mdProject: MdProjectDto;
  mdRoles: MdRoleDto[];
  onCancelChanges: () => void;
  onProjectUpdate: (project: MdProjectDto) => void;
  isDirty: boolean;
  saving: boolean;
  extendedUserClaims: OrgUserClaimDto[];
  isProposerRole: boolean;
  isO910Role: boolean;
  isProjectGuarantorRole: boolean;
};

const getStateTransitionButtonText = (state: MdProjectStateEnum): IntlMessageId => {
  switch (state) {
    case MdProjectStateEnum.entering:
      return 'MD.Projects.ProjectCardModal.button.transfer.o910';
    case MdProjectStateEnum.divisionSetting:
      return 'MD.Projects.ProjectCardModal.button.transfer.division';
    case MdProjectStateEnum.guarantorSetting:
      return 'MD.Projects.ProjectCardModal.button.transfer.guarantor';
  }
  return undefined;
};

const MDProjectCardToolbar: React.FC<Props> = ({
  form,
  mdProject,
  mdRoles,
  onCancelChanges,
  onProjectUpdate,
  isDirty,
  saving,
  extendedUserClaims,
  isProposerRole,
  isO910Role,
  isProjectGuarantorRole,
}) => {
  const currentAppUser = useCurrentAppUser();
  const intl = useIntl();
  const isMounted = useIsMounted();
  const [setDivisionModalVisible, showSetDivisionModal, hideSetDivisionModal] = useBoolean();
  const [setGuarantorModalVisible, showSetGuarantorModal, hideSetGuarantorModal] = useBoolean();
  const [setProcessorModalVisible, showSetProcessorModal, hideSetProcessorModal] = useBoolean();
  const [toShowActivityMdProject, setToShowActivityMdProject] = useState<MdProjectDto>();

  const onFormSubmit = () => {
    form.submit();
  };

  const transferButtonText = getStateTransitionButtonText(mdProject?.state);

  const isProjectSupervisorRole = useMemo(() => {
    if (!mdProject || !mdRoles) return false;
    const divisionRoles = mdRoles.filter(
      (role) => role.mdRoleType === MdRoleEnum.supervisor && role.division.id === mdProject.processorDivision?.id
    );
    return divisionRoles.some(
      (divisionRole) =>
        divisionRole.head.orgUser.appUserProfileId === currentAppUser.id ||
        divisionRole.mdUsers.some((user) => user.orgUser.appUserProfileId === currentAppUser.id)
    );
  }, [currentAppUser, mdProject, mdRoles]);

  const hasReferenceNumber = useMemo(
    () =>
      mdProject?.projectData.some(
        (data) => data.variable === MdProjectVariableEnum.referenceNumber && !!data.data?.value
      ),
    [mdProject]
  );

  const hasCkMeetingBackground = useMemo(
    () =>
      mdProject?.projectData.some(
        (data) => data.variable === MdProjectVariableEnum.ckMeetingBackground && !!data.data?.value
      ),
    [mdProject]
  );

  const canSetCkApproval = hasReferenceNumber && hasCkMeetingBackground && isProjectGuarantorRole;

  const canTransferState = useMemo(() => {
    if (!mdProject) return false;
    switch (mdProject?.state) {
      case MdProjectStateEnum.entering: {
        return isProposerRole;
      }
      case MdProjectStateEnum.divisionSetting: {
        return isO910Role && !!mdProject.processorDivision?.id;
      }
      case MdProjectStateEnum.guarantorSetting: {
        return isProjectSupervisorRole && !!mdProject.guarantor?.id;
      }
    }
    return false;
  }, [isO910Role, isProjectSupervisorRole, isProposerRole, mdProject]);

  const handleStateTransfer = useSameCallback(async (newState: MdProjectStateEnum) => {
    const [err, res] = await masterApi.projects.md.project.setstate.post({
      projectId: mdProject.id,
      requestedState: newState,
    });
    if (!isMounted.current) {
      return;
    }
    if (err) {
      messageError(err, intl);
      return;
    }
    onProjectUpdate(res.data);
  });

  const handleStateButtonClick = useCallback(() => {
    if (!canTransferState) return;

    switch (mdProject.state) {
      case MdProjectStateEnum.entering: {
        Modal.confirm({
          title: intl.formatMessage({ id: 'MD.Projects.ProjectCardModal.stateTransfer.divisionSetting.title' }),
          content: intl.formatMessage({ id: 'MD.Projects.ProjectCardModal.stateTransfer.divisionSetting.description' }),
          onOk: async () => {
            await handleStateTransfer(MdProjectStateEnum.divisionSetting);
          },
          okText: intl.formatMessage({ id: 'general.yes' }),
          cancelText: intl.formatMessage({ id: 'general.no' }),
        });
        break;
      }
      case MdProjectStateEnum.divisionSetting: {
        Modal.confirm({
          title: intl.formatMessage({ id: 'MD.Projects.ProjectCardModal.stateTransfer.guarantorSetting.title' }),
          content: intl.formatMessage({
            id: 'MD.Projects.ProjectCardModal.stateTransfer.guarantorSetting.description',
          }),
          onOk: async () => {
            await handleStateTransfer(MdProjectStateEnum.guarantorSetting);
          },
          okText: intl.formatMessage({ id: 'general.yes' }),
          cancelText: intl.formatMessage({ id: 'general.no' }),
        });
        break;
      }
      case MdProjectStateEnum.guarantorSetting: {
        Modal.confirm({
          title: intl.formatMessage({ id: 'MD.Projects.ProjectCardModal.stateTransfer.projectValidation.title' }),
          content: intl.formatMessage({
            id: 'MD.Projects.ProjectCardModal.stateTransfer.projectValidation.description',
          }),
          onOk: async () => {
            await handleStateTransfer(MdProjectStateEnum.projectValidation);
          },
          okText: intl.formatMessage({ id: 'general.yes' }),
          cancelText: intl.formatMessage({ id: 'general.no' }),
        });
        break;
      }
    }
  }, [canTransferState, handleStateTransfer, intl, mdProject]);

  const stateActionButton = useMemo(() => {
    if (!mdProject) return null;
    switch (mdProject.state) {
      case MdProjectStateEnum.divisionSetting:
        return (
          <Button
            icon={<TeamOutlined />}
            type="primary"
            key="setDivision"
            disabled={!isO910Role}
            onClick={showSetDivisionModal}
          >
            <Fmt id="MD.Projects.ProjectCardModal.button.setDivision" />
          </Button>
        );
      case MdProjectStateEnum.guarantorSetting:
        return (
          <Button
            icon={<UserOutlined />}
            type="primary"
            key="setGuarantor"
            disabled={!isProjectSupervisorRole}
            onClick={showSetGuarantorModal}
          >
            <Fmt id="MD.Projects.ProjectCardModal.button.setGuarantor" />
          </Button>
        );
      case MdProjectStateEnum.projectValidation:
        return (
          <>
            <Tooltip
              title={
                !hasReferenceNumber ? (
                  <Fmt id="MD.Projects.ProjectCkApprovalReadinesSetModal.title.prerequisites.referenceNumber" />
                ) : !hasCkMeetingBackground ? (
                  <Fmt id="MD.Projects.ProjectCkApprovalReadinesSetModal.title.prerequisites.ckBackgroundDocuments" />
                ) : (
                  ''
                )
              }
            >
              <MDSetCkPreparedSwitch
                mdProject={mdProject}
                onProjectUpdate={onProjectUpdate}
                disabled={!canSetCkApproval}
              />
            </Tooltip>

            <Button
              icon={<UserOutlined />}
              type="primary"
              key="setProcessor"
              disabled={!isProjectSupervisorRole}
              onClick={showSetProcessorModal}
            >
              <Fmt id="MD.Projects.ProjectCardModal.button.setProcessor" />
            </Button>
          </>
        );
    }
    return null;
  }, [
    isO910Role,
    isProjectGuarantorRole,
    isProjectSupervisorRole,
    mdProject,
    showSetDivisionModal,
    showSetGuarantorModal,
    showSetProcessorModal,
  ]);
  const hideAuditLog = useCallback(() => {
    setToShowActivityMdProject(undefined);
  }, []);

  if (!mdProject) return null;

  return (
    <>
      <PageHeader
        subTitle={<Fmt id="MD.Projects.ProjectCardModal.section.header.title" />}
        extra={
          <FlowLayout wrap>
            {stateActionButton}
            {transferButtonText && (
              <Button
                icon={<DeliveredProcedureOutlined />}
                type="primary"
                key="transfer"
                disabled={!canTransferState}
                onClick={handleStateButtonClick}
              >
                <Fmt id={transferButtonText} />
              </Button>
            )}
            <SaveOrCancelButton
              key="saveOrCancel"
              onSave={onFormSubmit}
              onCancel={onCancelChanges}
              disabled={!isDirty}
              loading={saving}
            />
            <AuditLogButton key="auditLogButton" onClick={() => setToShowActivityMdProject(mdProject)} />
          </FlowLayout>
        }
      />
      <MDSetDivisionModal
        open={setDivisionModalVisible}
        organizationId={mdProject.organization.id}
        mdProjectId={mdProject.id}
        mdCurrentDivision={mdProject.processorDivision}
        onClose={hideSetDivisionModal}
        onProjectUpdate={onProjectUpdate}
      />
      <MDSetGuarantorModal
        open={setGuarantorModalVisible}
        organizationId={mdProject.organization.id}
        mdProject={mdProject}
        mdRoles={mdRoles}
        onClose={hideSetGuarantorModal}
        onProjectUpdate={onProjectUpdate}
        extendedUserClaims={extendedUserClaims}
      />
      <MDSetProcessorModal
        open={setProcessorModalVisible}
        organizationId={mdProject.organization.id}
        mdProject={mdProject}
        mdRoles={mdRoles}
        onClose={hideSetProcessorModal}
        onProjectUpdate={onProjectUpdate}
      />
      {!!toShowActivityMdProject && (
        <AuditLogMasterApiEntityModal
          visible={!!toShowActivityMdProject}
          label={<Fmt id="AuditLog.mdMaterial.label" values={{ projectName: toShowActivityMdProject.name }} />}
          entityId={toShowActivityMdProject.id}
          entityType={EntityTypesEnum.mdProject}
          onOk={hideAuditLog}
          deps={[toShowActivityMdProject]}
        />
      )}
    </>
  );
};

export default MDProjectCardToolbar;
