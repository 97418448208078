import { Form, Modal, ModalProps, Select, Typography } from 'antd';
import { masterApi } from 'api/completeApi';
import { MdProjectDto, MdRoleDto, MdRoleEnum, OrgUserClaimDto } from 'api/completeApiInterfaces';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { useIntl, useSameCallback } from 'hooks';
import { Fmt } from 'locale';
import React, { useMemo, useState } from 'react';
import { messageError } from 'utils';
import { requiredRule } from 'utils/formHelpers';
import MDRoleDisplay from '../MdRoleDisplay/MDRoleDisplay';
type Props = {
  mdProject: MdProjectDto;
  mdRoles: MdRoleDto[];
  organizationId: Guid;
  extendedUserClaims: OrgUserClaimDto[];
  onClose: () => void;
  onProjectUpdate: (project: MdProjectDto) => void;
} & Omit<ModalProps, 'onOk' | 'title' | 'onCancel'>;

export type MDProjectCardGuarantorSetFormData = {
  guarantorId: Guid;
};

const MDSetGuarantorModal: React.FC<Props> = ({
  mdProject,
  organizationId,
  mdRoles,
  extendedUserClaims,
  onClose,
  onProjectUpdate,
  ...modalProps
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  const handleFormSubmit = useSameCallback(async (values: MDProjectCardGuarantorSetFormData) => {
    setLoading(true);
    const [err, res] = await masterApi.projects.md.project.setguarantor.post({
      guarantorId: values.guarantorId,
      projectId: mdProject.id,
    });
    if (err) {
      messageError(err, intl);
      setLoading(false);
      return;
    }
    onProjectUpdate(res.data);
    setLoading(false);
    onClose();
  });

  const guarantorOptions = useMemo(() => {
    return mdRoles
      ?.filter(
        (role) => role.division.id === mdProject.processorDivision?.id && role.mdRoleType === MdRoleEnum.guarantor
      )
      .map((role) => ({
        label: role.head.orgUser.appUserProfile.username,
        value: role.id,
      }));
  }, [mdProject, mdRoles]);

  return (
    <Modal
      title={<Fmt id="MD.Projects.ProjectGuarantorSetModal.title" />}
      onOk={form.submit}
      okButtonProps={{ loading: isLoading }}
      onCancel={onClose}
      {...modalProps}
    >
      <Typography.Paragraph>
        <FlowLayout growFirst alignRight>
          <Fmt id="MD.Projects.ProjectGuarantorSetModal.role.current.label" />
          {mdProject.guarantor && <MDRoleDisplay mdRole={mdProject.guarantor} extendedUserClaim={extendedUserClaims} />}
        </FlowLayout>
      </Typography.Paragraph>
      <Form form={form} onFinish={handleFormSubmit}>
        <Form.Item
          label={<Fmt id="MD.Projects.ProjectGuarantorSetModal.role.label" />}
          name="guarantorId"
          rules={[requiredRule('MD.Projects.ProjectGuarantorSetModal.role.required')]}
        >
          <Select options={guarantorOptions} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default MDSetGuarantorModal;
