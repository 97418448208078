import { ProjectMetadataDefinitionDto, ProjectMetadataDefinitionPatchDto } from 'api/completeApiInterfaces';
import { useActiveProject } from 'hooks';
import { useSameCallback } from 'hooks/useSameCallback';
import { InjectedIntl } from 'locale/Fmt';
import React, { FunctionComponent } from 'react';

import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper } from 'components/forms/FormModalWrapper/FormModalWrapper';
import { COMMON_SETTING_MODAL_WIDTH } from 'config/constants';
import { MetadataForm, MetadataFormData } from './MetadataForm';

type Props = FormModalProps<MetadataFormData> & {
  visible: boolean;
  onSubmit: (value: ProjectMetadataDefinitionPatchDto) => void;
  onClose: () => void;
  selectedMetadataItem: ProjectMetadataDefinitionDto;
  metadataDefinitions: ProjectMetadataDefinitionDto[];
  intl: InjectedIntl;
};

export const MetadataFormModal: FunctionComponent<Props> = ({
  onSubmit,
  onClose,
  visible,
  selectedMetadataItem,
  metadataDefinitions = [],
  ...restProps
}) => {
  const isInProject = useActiveProject() !== null;

  const handleSubmit = useSameCallback(async (values: MetadataFormData) => {
    if (!values) {
      return null;
    }

    await onSubmit(values);
    return null;
  });

  return (
    <>
      <FormModalWrapper
        onSubmit={handleSubmit}
        onClose={onClose}
        titleId={!!selectedMetadataItem ? 'Metadata.editProperty.title' : 'Metadata.newProperty.title'}
        width={COMMON_SETTING_MODAL_WIDTH}
        open={visible && isInProject}
        {...restProps}
      >
        {({ intl, formRef }) => (
          <MetadataForm
            intl={intl}
            wrappedComponentRef={formRef}
            selectedMetadataItem={selectedMetadataItem}
            usedItems={metadataDefinitions}
          />
        )}
      </FormModalWrapper>
    </>
  );
};
