import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Input } from 'antd';
import { apiConstraints } from 'api/completeApiConstraints';
import FilenameErrorMessage from 'components/FilenameErrorMessage';
import PathDisplay from 'components/PathDisplay';
import { FILE_AND_FOLDER_NAME_REGEX } from 'config/constants';
import { Fmt, InjectedIntlProps } from 'locale';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { maxLengthRule, requiredRule } from 'utils/formHelpersCompatibility';
import { DocumentLinkForEdit, SubmitButtonTextsEnum } from './DocumentLinkRenameFormModal';

export type DocumentsLinkRenameFormData = {
  documentLinkName: string;
};

type Props = FormComponentProps<DocumentsLinkRenameFormData> &
  InjectedIntlProps & {
    focusedDocumentLink: DocumentLinkForEdit;
    setSubmitButtonText: React.Dispatch<React.SetStateAction<SubmitButtonTextsEnum>>;
    showTargetPath?: boolean;
    showLinkLocation?: boolean;
  };

const DocumentLinkRenameForm = React.forwardRef<unknown, Props>(
  ({ intl, form, focusedDocumentLink, setSubmitButtonText, showTargetPath, showLinkLocation }, ref) => {
    // backward compatibility with class components
    useEffect(() => {
      (ref as any).current = { props: { form } };
    }, [form]);

    useEffect(() => {
      if (focusedDocumentLink.name === form.getFieldValue('documentLinkName').trim())
        setSubmitButtonText(SubmitButtonTextsEnum.ok);
      else setSubmitButtonText(SubmitButtonTextsEnum.save);
    }, [form.getFieldValue('documentLinkName')]);

    const linkedDocumentPath = useMemo(() => {
      return focusedDocumentLink.linkedDocument?.directoryPath || undefined;
    }, [focusedDocumentLink]);

    return (
      <Form layout="horizontal">
        <Form.Item label={intl.formatMessage({ id: 'DocumentLinkRenameForm.linkName' })}>
          {form.getFieldDecorator<DocumentsLinkRenameFormData>('documentLinkName', {
            initialValue: focusedDocumentLink.name,
            rules: [
              requiredRule('forms.items.rules.noEmpty'),
              maxLengthRule('general.maxNameLength', apiConstraints.documentLinkPatchDto.name.maxLength),
              {
                pattern: FILE_AND_FOLDER_NAME_REGEX,
                message: <FilenameErrorMessage />,
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item>
          <div>
            <Fmt id="DocumentLinkRenameForm.createdDate" />
          </div>
          {moment(focusedDocumentLink.createdDate)
            .locale(intl.locale)
            .format('lll')}
        </Form.Item>
        {showTargetPath && !!linkedDocumentPath && (
          <Form.Item>
            <div>
              <Fmt id="DocumentLinkRenameForm.targetPath" />
            </div>
            {<PathDisplay alignLeft path={linkedDocumentPath} />}
          </Form.Item>
        )}
        {showLinkLocation && (
          <Form.Item>
            <div>
              <Fmt id="DocumentLinkRenameForm.linkLocation" />
            </div>
            {<PathDisplay alignLeft path={focusedDocumentLink.directory.path} />}
          </Form.Item>
        )}
        <Form.Item>
          <div>
            <Fmt id="DocumentLinkRenameForm.linkAuthor" />
          </div>
          {focusedDocumentLink.createdBy.username}
        </Form.Item>
      </Form>
    );
  }
);

export default Form.create<Props>()(DocumentLinkRenameForm);
