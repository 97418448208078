import { DropIndicator } from 'components/MaskInput/DropIndicator';
import { MaskItemType } from 'components/MaskInput/MaskInput.types';
import { MaskReorderableOption } from 'components/MaskInput/MaskReorderableOption';
import React from 'react';
import {
  ListDropTargetDelegate,
  ListKeyboardDelegate,
  mergeProps,
  useDraggableCollection,
  useDroppableCollection,
  useListBox,
} from 'react-aria';
import { useDraggableCollectionState, useDroppableCollectionState, useListState } from 'react-stately';
import styles from './MaskReorderableList.module.less';

export const MaskReorderableListBox = (props: any) => {
  // See useListBox docs for more details.
  const state = useListState<MaskItemType>(props);
  const ref = React.useRef(null);
  const { listBoxProps } = useListBox(
    {
      ...props,
      shouldSelectOnPressUp: true,
    },
    state,
    ref
  );

  let dropState = useDroppableCollectionState({
    ...props,
    collection: state.collection,
    selectionManager: state.selectionManager,
  });

  let { collectionProps } = useDroppableCollection(
    {
      ...props,
      keyboardDelegate: new ListKeyboardDelegate(state.collection, state.disabledKeys, ref),
      dropTargetDelegate: new ListDropTargetDelegate(state.collection, ref),
    },
    dropState,
    ref
  );

  // Setup drag state for the collection.
  let dragState = useDraggableCollectionState({
    ...props,
    // Collection and selection manager come from list state.
    collection: state.collection,
    selectionManager: state.selectionManager,
    // Provide data for each dragged item. This function could
    // also be provided by the user of the component.
    getItems:
      props.getItems ||
      ((keys) => {
        return [...keys].map((key) => {
          let item = state.collection.getItem(key);

          return {
            'text/plain': item.textValue,
          };
        });
      }),
  });

  useDraggableCollection(props, dragState, ref);

  return (
    <div className={styles.maskReorderable} {...mergeProps(listBoxProps, collectionProps)} ref={ref}>
      <DropIndicator target={{ type: 'root' }} dropState={dropState} />
      {[...state.collection].map((item) => (
        <MaskReorderableOption key={item.key} item={item} state={state} dragState={dragState} dropState={dropState} />
      ))}
    </div>
  );
};
