import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Input, Select, Typography } from 'antd';
import { OrganizationDto } from 'api/completeApiInterfaces';
import { useSharedReportWidgets } from 'components/Reports/useSharedReportWidgets';
import { MAX_ITEM_NAME_LENGTH } from 'config/constants';
import { Fmt, InjectedIntlProps } from 'locale';
import React, { useEffect, useMemo } from 'react';
import { maxLengthRule, requiredRule, simpleSelectFilter } from 'utils/formHelpersCompatibility';

export type SharedReportWidgetCreateFormData = {
  name: string;
  organizationId: Guid;
  sharedReportId: Guid;
};

type Props = FormComponentProps<SharedReportWidgetCreateFormData> &
  InjectedIntlProps & {
    organizations: OrganizationDto[];
    defaults: SharedReportWidgetCreateFormData;
  };

const SharedReportWidgetCreateForm = React.forwardRef<unknown, Props>(
  ({ intl, form, organizations, defaults }, ref) => {
    // backward compatibility with class components
    useEffect(() => {
      (ref as any).current = { props: { form } };
    }, [form]);
    const { getFieldDecorator } = form;

    const { sharedWidgetConfigurations, loadSharedConfiguration } = useSharedReportWidgets();

    const selectedOrganizationId = useMemo(
      (): Guid => form.getFieldValue('organizationId') || (organizations?.length && organizations[0].id),
      [organizations, form]
    );

    const sharedReportsOptions = useMemo(
      () =>
        sharedWidgetConfigurations.map((configuration) => (
          <Select.Option key={configuration.id} value={configuration.id}>
            {configuration.title}
          </Select.Option>
        )),
      [sharedWidgetConfigurations]
    );

    useEffect(() => {
      loadSharedConfiguration(selectedOrganizationId);
    }, [selectedOrganizationId]);

    return (
      <Form layout="vertical">
        <Form.Item label={intl.formatMessage({ id: 'general.organizations' })}>
          {getFieldDecorator<SharedReportWidgetCreateFormData>('organizationId', {
            initialValue: defaults?.organizationId || (organizations?.length && organizations[0].id),
            rules: [requiredRule('ReportWidgetCreateFormModalForm.organization.required', true)],
          })(
            <Select showSearch allowClear={false} filterOption={simpleSelectFilter}>
              {organizations?.map((organization) => (
                <Select.Option key={organization.id} value={organization.id}>
                  {organization.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        {selectedOrganizationId && (
          <>
            <Form.Item label={intl.formatMessage({ id: 'ReportWidgetCreateFormModalForm.reportType' })}>
              {getFieldDecorator<SharedReportWidgetCreateFormData>('sharedReportId', {
                rules: [requiredRule('ReportWidgetCreateFormModalForm.reportType.required', true)],
                initialValue: defaults?.sharedReportId,
              })(
                <Select showSearch allowClear={false} filterOption={simpleSelectFilter}>
                  {sharedReportsOptions}
                </Select>
              )}
            </Form.Item>
            <Form.Item label={intl.formatMessage({ id: 'forms.items.name.label' })}>
              {getFieldDecorator<SharedReportWidgetCreateFormData>('name', {
                rules: [maxLengthRule('general.maxNameLength', MAX_ITEM_NAME_LENGTH)],
                initialValue: defaults?.name,
              })(
                <Input placeholder={intl.formatMessage({ id: 'ReportWidgetCreateFormModalForm.name.placeholder' })} />
              )}
              <Typography.Paragraph type="secondary">
                <Fmt id="ReportWidgetCreateFormModalForm.name.info" />
              </Typography.Paragraph>
            </Form.Item>
          </>
        )}
      </Form>
    );
  }
);

export default Form.create<Props>()(SharedReportWidgetCreateForm);
