import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Input, InputRef } from 'antd';
import FilenameErrorMessage from 'components/FilenameErrorMessage';
import { FILE_AND_FOLDER_NAME_REGEX } from 'config/constants';
import { InjectedIntlProps } from 'locale';
import React, { Component } from 'react';
import { duplicateNameRuleCallback, maxLengthRule, requiredRule } from 'utils/formHelpersCompatibility';

export type DirectoryLinkEditFormData = {
  name: string;
};

type Props = FormComponentProps<DirectoryLinkEditFormData> &
  InjectedIntlProps & {
    validateUniqueName: (name: string) => boolean;
    initialData: DirectoryLinkEditFormData;
    setRef: (ref: InputRef) => void;
  };

class DirectoryLinkEditForm extends Component<Props> {
  render() {
    const { intl, form, validateUniqueName, initialData, setRef } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Form layout="vertical">
        <Form.Item label={intl.formatMessage({ id: 'DirectoryLinkEditForm.form.items.name.label' })}>
          {getFieldDecorator<DirectoryLinkEditFormData>('name', {
            initialValue: initialData.name,
            rules: [
              requiredRule('DirectoryLinkEditForm.form.items.name.rules.required', true),
              maxLengthRule('general.maxNameLength'),
              {
                pattern: FILE_AND_FOLDER_NAME_REGEX,
                message: <FilenameErrorMessage />,
              },
              duplicateNameRuleCallback(validateUniqueName, 'DirectoryLinkEditForm.form.items.name.rules.nameExists'),
            ],
          })(<Input autoFocus ref={setRef} />)}
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create<Props>()(DirectoryLinkEditForm);
