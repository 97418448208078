import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { UserAddOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Button, Col, Input, InputRef, Row, Select } from 'antd';
import { GroupListDto } from 'api/completeApiInterfaces';
import { DeleteButton } from 'components/ActionButtons';
import MultipleInputModal from 'components/MultipleInputModal';
import { Fmt, InjectedIntlProps } from 'locale';
import { Dictionary, has } from 'lodash';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Dispatch, RootState } from 'store';
import { legacyMapDispatchToProps } from 'store/models/storeModelinterfaces';
import { groupOrderedListSelector } from 'store/selectors/groupSelectors';
import { includesDiacriticsRule, simpleSelectFilter } from 'utils/formHelpersCompatibility';
import uuid from 'uuid';

export type ProjectUserInviteFormData = {
  emails: Dictionary<Guid>;
  message: string;
  groups: Guid[];
};

type MailData = {
  email: string;
  id: Guid;
};

const mapStateToProps = (state: RootState) => ({
  currentUser: state.currentProjectUser.data,
  groupsList: groupOrderedListSelector(state),
  groupsMapError: state.groups.error,
  groupsLoading: state.groups.loading,
});

type PropsFromState = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadGroups: dispatch.groups.loadData,
});

type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

type Props = PropsFromState &
  PropsFromDispatch &
  FormComponentProps<ProjectUserInviteFormData> &
  InjectedIntlProps & {
    sentUsers: string[];
    initialUsers?: string[];
    ignoreGroups?: boolean;
    setRef: (ref: InputRef) => void;
  };

type State = {
  initialized: boolean;
  isMultiMail: boolean;
  emails: MailData[];
  validationVersion: number;
};

class ProjectUserInviteForm extends Component<Props, State> {
  groups: Record<Guid, GroupListDto> = null;
  validatedEmails: Dictionary<string> = {};

  constructor(props: Props, context: State) {
    super(props, context);
    this.state = {
      initialized: false,
      isMultiMail: false,
      emails: [{ id: uuid.v4(), email: '' }],
      validationVersion: 0,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.sentUsers != this.props.sentUsers) {
      const { getFieldValue } = this.props.form;
      this.setState((s) => {
        const filteredEmails = s.emails
          .map((e) => ({ ...e, email: getFieldValue('emails[' + e.id + ']') }))
          .filter((email) => !this.props.sentUsers.find((u) => u == email.email));
        return { emails: filteredEmails };
      });
    }
  }

  async componentDidMount() {
    if (!!this.props.initialUsers?.length) {
      this.setState(() => {
        const initialEmails = this.props.initialUsers.map((userName) => ({ id: uuid.v4(), email: userName }));
        return { emails: initialEmails };
      });
    } else {
      if (!this.props.ignoreGroups) {
        await this.props.loadGroups({ reload: false });
        this.groups = {};
        this.props.groupsList?.forEach((group) => {
          this.groups[group.id] = group;
        });
        if (Object.entries(this.groups).length > 0) this.setState({ initialized: true });
      }
    }
  }

  handleEmailAdd = () => {
    this.setState((s) => ({ emails: [...s.emails, { id: uuid.v4(), email: '' }] }));
  };

  handleMultiMailAdd = () => {
    this.setState({ isMultiMail: true });
  };

  handleEmailDelete = (uuid: Guid) => {
    if (this.state.emails.length < 2) return;
    if (has(this.validatedEmails, `emails[${uuid}]`)) delete this.validatedEmails[`emails[${uuid}]`];
    this.setState(
      (s) => ({ emails: s.emails.filter((mail) => mail.id !== uuid) }),
      () => this.validateAllEmails()
    );
  };

  handleEmailsAdd = (values: string[]) => {
    const emails = values.map((email) => {
      return { id: uuid.v4(), email: email };
    });
    const { getFieldValue } = this.props.form;
    this.setState(
      (s) => {
        const originalEmails = s.emails
          .map((e) => ({ ...e, email: getFieldValue('emails[' + e.id + ']') }))
          .filter((email) => email.email !== '');
        return { emails: [...originalEmails, ...emails], isMultiMail: false };
      },
      () => this.validateAllEmails()
    );
  };

  handleMultiEmailCancel = () => {
    this.setState({ isMultiMail: false });
  };

  itemsConverter = (value: string): string[] => {
    const matches: RegExpMatchArray = value.match(
      /([\w\-!#$%&'*+/=?^_`{|}~](\.?[\w\-!#$%&'*+/=?^_`{|}~])*@([\w\-!#$%&'*+/=?^_`{|}~]+\.)+[^\d\W]{2,})/gi
    );
    if (!matches || !matches.length) return [];
    const uniqueEmails: Set<string> = new Set(
      matches.filter((match) => !!match).map((match) => match.toString().toLowerCase())
    );
    return Array.from(uniqueEmails);
  };

  validateAllEmails = () => {
    const { validateFields } = this.props.form;
    const validate: string[] = [];
    this.state.emails.forEach((e) => validate.push(`emails[${e.id}]`));
    validateFields(validate, { force: true });
  };

  getEmailIdx = (field: string): number => {
    return this.state.emails.indexOf(this.state.emails.find((e) => field.includes(e.id)));
  };

  emailDuplicateValidator = (rule: any, value: any, callback: any) => {
    const { intl } = this.props;
    const tValue: string = value.toLowerCase().trim();
    const ret: string[] = [];
    const { getFieldValue } = this.props.form;
    const originalEmails = this.state.emails.map((e) => ({ ...e, email: getFieldValue('emails[' + e.id + ']') }));
    if (
      originalEmails.some(
        (e) =>
          e.email.toLowerCase().trim() === tValue &&
          this.getEmailIdx(rule.field) > this.getEmailIdx(`emails[${e.id}]`) &&
          e.email !== ''
      )
    ) {
      ret.push(intl.formatMessage({ id: 'forms.items.rules.duplicateEmail' }));
    }
    this.validatedEmails[rule.field] = tValue;
    callback(ret);
  };

  getEmailEdits = (): ReactNode[] => {
    const { intl, form } = this.props;
    const { emails } = this.state;
    const { getFieldDecorator } = form;
    return emails.map((mail, i) => {
      return (
        <Form.Item
          key={mail.id}
          label={i > 0 ? undefined : intl.formatMessage({ id: 'ProjectUserInviteFormData.form.emails' })}
          style={{ marginBottom: '4px' }}
        >
          <Row gutter={24}>
            <Col span={emails.length > 1 ? 22 : 24}>
              {getFieldDecorator(`emails[${mail.id}]`, {
                initialValue: mail.email,
                validateFirst: true,
                validateTrigger: '',
                rules: [
                  includesDiacriticsRule('forms.items.rules.email.noDiacritics'),
                  { type: 'email', message: intl.formatMessage({ id: 'forms.items.rules.email' }) },
                  {
                    validator: this.emailDuplicateValidator,
                  },
                ],
              })(
                <Input
                  placeholder={intl.formatMessage({ id: 'forms.items.email.placeholder' })}
                  onChange={() => {
                    this.setState(
                      (s) => ({ validationVersion: s.validationVersion + 1 }),
                      () => this.validateAllEmails()
                    );
                  }}
                  autoFocus
                  ref={this.props.setRef}
                />
              )}
            </Col>
            <Col span={2}>{emails.length > 1 && <DeleteButton onClick={() => this.handleEmailDelete(mail.id)} />}</Col>
          </Row>
        </Form.Item>
      );
    });
  };

  getGroups = (): ReactNode[] => {
    if (!this.state.initialized) return null;

    return Object.entries(this.groups)
      .filter(([_key, group]) => this.props.currentUser?.isAdmin || !group.isAdminGroup)
      .map(([key, group]) => <Select.Option key={key}>{group.name}</Select.Option>);
  };

  render() {
    const { intl, form } = this.props;
    const { initialized, isMultiMail } = this.state;
    const { getFieldDecorator } = form;
    return (
      <Form layout="vertical">
        {this.getEmailEdits()}
        <Form.Item>
          <Row gutter={24}>
            <Col span={12}>
              <Button onClick={this.handleMultiMailAdd} style={{ width: '100%' }}>
                <UsergroupAddOutlined /> <Fmt id="ProjectUserInviteFormData.form.addMultiMail" />
              </Button>
            </Col>
            {!isMultiMail && (
              <Col span={12}>
                <Button onClick={this.handleEmailAdd} style={{ width: '100%' }}>
                  <UserAddOutlined /> <Fmt id="ProjectUserInviteFormData.form.addEmail" />
                </Button>
              </Col>
            )}
          </Row>
        </Form.Item>
        {!this.props.initialUsers && (
          <Form.Item label={intl.formatMessage({ id: 'ProjectUserInviteFormData.form.items.message.label' })}>
            {getFieldDecorator('message', {
              initialValue: intl.formatMessage({ id: 'ProjectUserInviteFormData.form.items.message.initialValue' }),
            })(<Input.TextArea rows={3} autoSize={{ minRows: 3 }} />)}
          </Form.Item>
        )}
        {initialized && !this.props.ignoreGroups && (
          <Form.Item
            label={intl.formatMessage({ id: 'ProjectUserInviteFormData.form.items.groups.label' })}
            style={{ marginBottom: '1px' }}
          >
            {getFieldDecorator('groups')(
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder={intl.formatMessage({ id: 'general.groups' })}
                filterOption={simpleSelectFilter}
              >
                {this.getGroups()}
              </Select>
            )}
          </Form.Item>
        )}
        <MultipleInputModal
          visible={isMultiMail}
          onSubmit={this.handleEmailsAdd}
          onClose={this.handleMultiEmailCancel}
          title={intl.formatMessage({ id: 'ProjectUserInviteFormData.form.multipleInput.label' })}
          itemsFinder={this.itemsConverter}
          submitText={intl.formatMessage({ id: 'ProjectUserInviteFormData.form.multipleInput.addEmails' })}
          cancelText={intl.formatMessage({ id: 'forms.button.cancel' })}
          placeholder={intl.formatMessage({ id: 'ProjectUserInviteFormData.form.multipleInput.placeholder' })}
          errorText={intl.formatMessage({ id: 'ProjectUserInviteFormData.form.multipleInput.error' })}
          inputText={intl.formatMessage({ id: 'ProjectUserInviteFormData.form.multipleInput.inputText' })}
          resultText={intl.formatMessage({ id: 'ProjectUserInviteFormData.form.multipleInput.resultText' })}
        />
      </Form>
    );
  }
}

export default connect(
  mapStateToProps,
  legacyMapDispatchToProps(mapDispatchToProps)
)(Form.create<Props>()(ProjectUserInviteForm));
