import { Form, FormInstance, Input, Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { apiConstraints } from 'api/completeApiConstraints';
import { MdProjectDto, MdProjectPhaseEnum, MdProjectVariableEnum, OrgUserClaimDto } from 'api/completeApiInterfaces';
import classNames from 'classnames';
import { useIntl } from 'hooks';
import { Fmt } from 'locale';
import React, { useMemo } from 'react';
import { duplicateNameRule, maxLengthRule, minLengthRule, requiredRule } from 'utils/formHelpers';
import { MAP_CK_IDENTIFICATIONS_ENUM_TO_INTL_MESSAGE_ID } from '../MDProjectCardCreateForm/MDProjectCreateForm';
import styles from './MDProjectCardModal.module.less';
import MDProjectCardVariableFields from './MDProjectCardVariableFields';

type Props = {
  form: FormInstance<any>;
  extendedUserClaims: OrgUserClaimDto[];
  mdProject: MdProjectDto;
  existingProjectNames: string[];
  canEditAsO910: boolean;
  canEditAsProposer: boolean;
  canEditAsGuarantor: boolean;
};

const mappedActionCharacterItem: Record<MdProjectPhaseEnum, MdProjectVariableEnum[]> = {
  [MdProjectPhaseEnum.other]: [MdProjectVariableEnum.actionCharacter],
  [MdProjectPhaseEnum.init]: [MdProjectVariableEnum.actionCharacter],
  [MdProjectPhaseEnum.study]: [MdProjectVariableEnum.actionCharacter],
  [MdProjectPhaseEnum.intention]: [MdProjectVariableEnum.actionCharacter],
  [MdProjectPhaseEnum.update]: [MdProjectVariableEnum.actionCharacter],
};

const mappedPhaseHeaderItems: Record<MdProjectPhaseEnum, MdProjectVariableEnum[]> = {
  [MdProjectPhaseEnum.other]: [
    MdProjectVariableEnum.referenceNumber,
    MdProjectVariableEnum.documentationLink,
    MdProjectVariableEnum.mdContactPerson,
    MdProjectVariableEnum.investorContactPerson,
  ],
  [MdProjectPhaseEnum.init]: [
    MdProjectVariableEnum.referenceNumber,
    MdProjectVariableEnum.expectedRealisationTime,
    MdProjectVariableEnum.totalCost,
    MdProjectVariableEnum.documentationLink,
    MdProjectVariableEnum.mdContactPerson,
    MdProjectVariableEnum.investorContactPerson,
  ],
  [MdProjectPhaseEnum.study]: [
    MdProjectVariableEnum.referenceNumber,
    MdProjectVariableEnum.expectedRealisationTime,
    MdProjectVariableEnum.totalCost,
    MdProjectVariableEnum.documentationLink,
    MdProjectVariableEnum.mdContactPerson,
    MdProjectVariableEnum.investorContactPerson,
  ],
  [MdProjectPhaseEnum.intention]: [
    MdProjectVariableEnum.referenceNumber,
    MdProjectVariableEnum.expectedRealisationTime,
    MdProjectVariableEnum.totalCost,
    MdProjectVariableEnum.documentationLink,
    MdProjectVariableEnum.mdContactPerson,
    MdProjectVariableEnum.investorContactPerson,
  ],
  [MdProjectPhaseEnum.update]: [
    MdProjectVariableEnum.referenceNumber,
    MdProjectVariableEnum.expectedRealisationTime,
    MdProjectVariableEnum.totalCost,
    MdProjectVariableEnum.documentationLink,
    MdProjectVariableEnum.mdContactPerson,
    MdProjectVariableEnum.investorContactPerson,
  ],
};

const MDProjectCardHead: React.FC<Props> = ({
  mdProject,
  existingProjectNames,
  extendedUserClaims,
  canEditAsO910,
  canEditAsProposer,
  canEditAsGuarantor,
}) => {
  const intl = useIntl();

  const reservedProjectNames = useMemo(() => existingProjectNames.filter((name) => name !== mdProject?.name), [
    existingProjectNames,
    mdProject,
  ]);

  const ckIdentificationsOptions: DefaultOptionType[] = useMemo(
    () =>
      Object.entries(MAP_CK_IDENTIFICATIONS_ENUM_TO_INTL_MESSAGE_ID).map(([enumValue, intlMessageId]) => ({
        label: intl.formatMessage({ id: intlMessageId }),
        value: enumValue,
      })) || [],
    [intl]
  );

  if (!mdProject) return null;

  return (
    <div className={styles.mdFormSectionWrapper}>
      <div className={classNames(styles.mdFormItem)}>
        <Form.Item
          label={<Fmt id="general.name" />}
          rules={[
            requiredRule('MD.Projects.ProjectCardModal.form.name.required'),
            duplicateNameRule('MD.Projects.ProjectCardModal.form.name.duplicate', reservedProjectNames),
            maxLengthRule('general.maxNameLength', apiConstraints.mdProjectCreateDto.name.maxLength),
            minLengthRule('general.minNameLength', apiConstraints.mdProjectCreateDto.name.minLength),
          ]}
          labelAlign="left"
          name="name"
          initialValue={mdProject.name}
        >
          <Input disabled={!canEditAsProposer && !canEditAsGuarantor} />
        </Form.Item>
      </div>
      <div className={classNames(styles.mdFormItem)}>
        <Form.Item
          label={<Fmt id={'MD.Projects.ProjectCreateModal.form.ckIdentification'} />}
          labelAlign="left"
          name="ckIdentification"
          rules={[requiredRule('MD.Projects.ProjectCreateModal.form.ckIdentification.required')]}
          initialValue={mdProject.ckIdentificaion}
        >
          <Select options={ckIdentificationsOptions} disabled={!canEditAsProposer && !canEditAsGuarantor} />
        </Form.Item>
      </div>
      <MDProjectCardVariableFields
        fields={mappedActionCharacterItem}
        mdProject={mdProject}
        canEditAsO910={canEditAsO910}
        canEditAsProposer={canEditAsProposer}
        canEditAsGuarantor={canEditAsGuarantor}
        extendedUserClaims={extendedUserClaims}
      />
      <div className={classNames(styles.mdFormItem)}>
        <Form.Item
          label={<Fmt id="general.description" />}
          rules={[
            maxLengthRule('general.maxDescriptionLength', apiConstraints.mdProjectCreateDto.description.maxLength),
          ]}
          labelAlign="left"
          name="description"
          initialValue={mdProject.description}
        >
          <Input.TextArea disabled={!canEditAsProposer && !canEditAsGuarantor} rows={1} />
        </Form.Item>
      </div>
      <MDProjectCardVariableFields
        fields={mappedPhaseHeaderItems}
        mdProject={mdProject}
        canEditAsO910={canEditAsO910}
        canEditAsProposer={canEditAsProposer}
        canEditAsGuarantor={canEditAsGuarantor}
        extendedUserClaims={extendedUserClaims}
      />
    </div>
  );
};

export default MDProjectCardHead;
