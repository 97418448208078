import { Alert, Button, Typography } from 'antd';
import { api } from 'api';
import { DocumentDto, DocumentLockPurposeEnum, WorkflowStateEnum } from 'api/completeApiInterfaces';
import { ApiError } from 'api/errors';
import { baseProjectApi } from 'api/project/baseProjectApi';
import to from 'await-to-js';
import axios, { AxiosResponse } from 'axios';
import classNames from 'classnames';
import { DocumentReservationLock } from 'components/DocumentReservationLock/DocumentReservationLock';
import Modal from 'components/Modal/Modal';
import { useWindowDisabler } from 'components/WindowDisabler/WindowDisabler';
import { SIGNAL_R_DOCUMENT_CONNECTION, SIGNAL_R_WORKFLOW_CONNECTION } from 'config';
import { DEFAULT_SIGNAL_DEBOUNCE_WAIT } from 'config/constants';
import { useApiData, useBoolean, useCancelToken, useIntl, useSameCallback } from 'hooks';
import mobile from 'is-mobile';
import { Fmt } from 'locale';
import { debounce } from 'lodash';
import { FunctionComponent, default as React, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DisableWindowStoreStartPayload } from 'store/models/storeModelinterfaces';
import { messageError, processApiError } from 'utils';
import DocumentOnlineEditorRevisionCreateFormModal from '../DocumentOnlineEditorRevisionCreateForm/DocumentOnlineEditorRevisionCreateFormModal';
import LoaderForm from '../LoaderForm';
import {
  disableEditorSaveButton,
  initializeEditorMessageApi,
  sendEditorSaveInstruction,
  sendHideBusyInstruction,
  sendShowBusyInstruction,
  sendTokenRefreshInstruction,
} from '../editorMessageHelpers';
import styles from './DocumentOnlineEditorModal.module.less';

export type OnlineEditorDocument = Pick<DocumentDto, 'id' | 'currentRevision' | 'primaryFile'>;

export type Props = {
  onClose?: () => void;
  document: OnlineEditorDocument;
  frameKey: string;
  revisionId: Guid;
  documentName?: string;
  visible: boolean;
  closeOnSave?: boolean;
  isOldRevision?: boolean;
  workflowNodeId?: Guid;
  allowedStates?: WorkflowStateEnum[];
};

const TOKEN_REFRESH_INTERVAL_IN_MILLISECONDS = 1800 * 1000; // refresh token every 30 minutes

const DocumentOnlineEditorModal: FunctionComponent<Props> = ({
  onClose,
  document,
  revisionId,
  visible,
  documentName,
  frameKey,
  closeOnSave = true,
  isOldRevision,
  workflowNodeId,
  allowedStates,
}) => {
  const [wopiConfig, error, loading, startEditing] = useApiData(
    (ct) =>
      api.project.wopi.startEdit(
        { documentId: document.id, revisionId: revisionId, workflowNodeId: workflowNodeId },
        ct
      ),
    { autoload: false }
  );
  const [isDocumentChanged, setDocumentChanged] = useState<boolean>(false);
  const [isRevisionModalVisible, showRevisionModal, hideRevisionModal] = useBoolean(false);
  const refreshCancelToken = useCancelToken('Document online editor closed', []);
  const [savingCurrentRevisionId, setSavingCurrentRevisionId] = useState<Guid>(undefined);
  const [maxFileSizeLimit, setMaxFileSizeLimit] = useState<number>(undefined);
  const [oversizeWarningVisible, showOversizeWarning, hideOversizeWarning] = useBoolean(false);
  const iframe = useRef<HTMLIFrameElement>(null);
  const intl = useIntl();

  const isMobile = useMemo(() => mobile({ tablet: true }), []);

  useEffect(() => {
    if (!wopiConfig) return;

    const wopiServer = axios.create({
      baseURL: wopiConfig.wopiServerUrl,
      headers: {
        ...baseProjectApi.defaults.headers,
      },
    });

    to<AxiosResponse<number>, ApiError>(wopiServer.get<number>(`/wopi/files/requestSizeLimit`)).then(
      ([errWopi, resWopi]) => {
        if (errWopi) {
          messageError('DocumentOnlineEditor.fileLimitError', intl);
          return;
        }

        setMaxFileSizeLimit(resWopi.data);
      }
    );
  }, [wopiConfig]);

  useEffect(() => {
    if (!!maxFileSizeLimit && document?.primaryFile?.size > maxFileSizeLimit) {
      showOversizeWarning();
    }
  }, [document?.primaryFile?.size, maxFileSizeLimit]);

  const wopiClientOrigin = useMemo((): string => {
    if (!wopiConfig) return null;
    const { origin } = new URL(wopiConfig.wopiClientUrl);
    return origin;
  }, [wopiConfig]);

  const disableWindowConfig = useMemo(
    (): DisableWindowStoreStartPayload => ({
      message: intl.formatMessage({ id: 'DocumentOnlineEditor.notSavedAlert' }),
      showMask: false,
    }),
    [intl]
  );

  useWindowDisabler(isDocumentChanged, disableWindowConfig);

  const handleLock = useCallback(() => {
    visible && document?.id && revisionId && startEditing();
    setSavingCurrentRevisionId(undefined);
  }, [document?.id, revisionId, visible]);

  const refreshEditorToken = useSameCallback(async () => {
    if (!document?.id) return;
    const [err, response] = await api.project.wopi.refreshToken(document?.id, refreshCancelToken);
    if (!!err) {
      const apiError = processApiError(err);
      messageError(apiError, intl);
    } else {
      sendTokenRefreshInstruction(iframe.current, wopiClientOrigin, response.data.token);
    }
  });

  const finishSave = useCallback(() => {
    sendHideBusyInstruction(iframe.current, wopiClientOrigin);
    closeOnSave && onClose && onClose();
    setSavingCurrentRevisionId(undefined);
  }, [closeOnSave, onClose, wopiClientOrigin]);

  useEffect(() => {
    let refreshTokenTimer: NodeJS.Timeout;

    const setupTimer = () => {
      refreshTokenTimer = setTimeout(() => {
        refreshEditorToken().then(setupTimer);
      }, TOKEN_REFRESH_INTERVAL_IN_MILLISECONDS);
    };

    visible ? setupTimer() : clearTimeout(refreshTokenTimer);

    const debouncedSave = debounce(() => {
      finishSave();
    }, DEFAULT_SIGNAL_DEBOUNCE_WAIT);

    SIGNAL_R_DOCUMENT_CONNECTION.on('RevisionChanged', debouncedSave);
    SIGNAL_R_WORKFLOW_CONNECTION.on('WorkflowChanged', debouncedSave);

    return () => {
      clearTimeout(refreshTokenTimer);
      SIGNAL_R_DOCUMENT_CONNECTION.off('RevisionChanged', debouncedSave);
      SIGNAL_R_WORKFLOW_CONNECTION.off('WorkflowChanged', debouncedSave);
    };
  }, [visible]);

  useEffect(() => {
    const handleMessageEvent = (event: MessageEvent) => {
      if (event.origin === wopiClientOrigin) {
        const eventData = JSON.parse(event.data);
        if (eventData?.MessageId === 'App_LoadingStatus' && eventData?.Values?.Status === 'Document_Loaded') {
          initializeEditorMessageApi(iframe.current, wopiClientOrigin);
          disableEditorSaveButton(iframe.current, wopiClientOrigin);
        }
        if (eventData?.MessageId === 'Doc_ModifiedStatus') {
          const documentChanged = !!eventData?.Values?.Modified;
          setDocumentChanged(documentChanged);

          if (!documentChanged && !!savingCurrentRevisionId) {
            sendShowBusyInstruction(
              iframe.current,
              wopiClientOrigin,
              intl.formatMessage({ id: 'DocumentOnlineEditor.storingRevisionBusyReason' })
            );
          }
        }
      }
    };

    window.addEventListener('message', handleMessageEvent);
    return () => window.removeEventListener('message', handleMessageEvent);
  }, [wopiClientOrigin, closeOnSave, onClose, intl, savingCurrentRevisionId]);

  useEffect(() => {
    if (
      !!document &&
      !isDocumentChanged &&
      !!savingCurrentRevisionId &&
      (document.currentRevision?.id !== savingCurrentRevisionId || !document.currentRevision)
    ) {
      finishSave();
    }
  }, [isDocumentChanged, savingCurrentRevisionId, document?.currentRevision]);

  const [wopiUrl, wopiToken] = useMemo(() => {
    const wopiUrl =
      (wopiConfig &&
        wopiConfig.wopiServerUrl &&
        wopiConfig.wopiClientUrl &&
        `${wopiConfig.wopiClientUrl}WOPISrc=${wopiConfig.wopiServerUrl}/wopi/files/${document?.id}&lang=${intl.locale}`) ||
      '';
    const wopiToken = (wopiConfig && wopiConfig.token) || '';

    return [wopiUrl, wopiToken] as const;
  }, [wopiConfig, intl, document?.id]);

  const handleRevisionModalShow = useSameCallback(() => {
    showRevisionModal();
    sendShowBusyInstruction(
      iframe.current,
      wopiClientOrigin,
      intl.formatMessage({ id: 'DocumentOnlineEditor.createRevisionBusyReason' })
    );
  });

  const handleRevisionModalHide = useSameCallback(() => {
    hideRevisionModal();
    sendHideBusyInstruction(iframe.current, wopiClientOrigin);
  });

  const handleRevisionModalSubmit = useSameCallback(() => {
    sendEditorSaveInstruction(iframe.current, wopiClientOrigin);
    setSavingCurrentRevisionId(document.currentRevision?.id);
    hideRevisionModal();
  });

  const saveButton = useMemo(
    () => (
      <Button
        type="primary"
        onClick={handleRevisionModalShow}
        disabled={!isDocumentChanged || !!savingCurrentRevisionId}
        loading={!!savingCurrentRevisionId}
      >
        <Fmt id="DocumentOnlineEditor.button.saveAsRevision" />
      </Button>
    ),
    [isDocumentChanged, savingCurrentRevisionId]
  );

  if (!document?.id) return null;

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title={documentName ? documentName : <Fmt id="DocumentOnlineEditor.defaultModalTitle" />}
      additionalButtons={saveButton}
    >
      {oversizeWarningVisible && (
        <Alert
          closable
          message={<Fmt id="DocumentOnlineEditor.oversizeWarning" />}
          type="warning"
          onClose={hideOversizeWarning}
        />
      )}
      <DocumentReservationLock
        documentId={document.id}
        revisionId={revisionId}
        isLockRequested={visible}
        lockPurpose={DocumentLockPurposeEnum.editDocument}
        onLockSuccess={handleLock}
      >
        <div className={classNames(styles.modalBody, isMobile && isRevisionModalVisible && styles.almostHideContent)}>
          {visible && !error && !loading && (
            <>
              <iframe
                title="Collabora Online Viewer"
                id={`collabora-online-viewer-${frameKey}`}
                name={`collabora-online-viewer-${frameKey}`}
                ref={iframe}
              />
              <LoaderForm url={wopiUrl} token={wopiToken} frameKey={frameKey} />
            </>
          )}
          {!!error?.message && (
            <Typography.Text type="danger" className={styles.modalError}>
              {error.message}
            </Typography.Text>
          )}
        </div>
      </DocumentReservationLock>
      <DocumentOnlineEditorRevisionCreateFormModal
        documentId={document.id}
        visible={isRevisionModalVisible}
        onSubmit={handleRevisionModalSubmit}
        onClose={handleRevisionModalHide}
        isOldRevision={isOldRevision}
        allowedStates={allowedStates}
      />
    </Modal>
  );
};

export default DocumentOnlineEditorModal;
