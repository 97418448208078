import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Alert, List, Radio, Typography } from 'antd';
import { DocumentCategoryNodeRemoveStrategyEnum, ServiceError } from 'api/completeApiInterfaces';
import { ServiceErrorEnum } from 'api/errors';
import { InjectedIntlProps } from 'locale';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { requiredRule } from 'utils/formHelpersCompatibility';
import styles from './DocumentCategoryTreeNodeDeleteForm.module.less';

interface IAdditionalDataDocuments {
  directoryPath: string;
  documentId: Guid;
  documentName: string;
}

interface IAdditionalDataDirectories {
  directoryPath: string;
  directoryId: Guid;
}

interface IErrorData {
  directories: IAdditionalDataDirectories[];
  documents: IAdditionalDataDocuments[];
}

export type DocumentCategoryTreeNodeDeleteFormData = {
  removeStrategy: DocumentCategoryNodeRemoveStrategyEnum;
  removeChildren: boolean;
  nodeId: Guid;
};

type Props = FormComponentProps<DocumentCategoryTreeNodeDeleteFormData> &
  InjectedIntlProps & {
    askRemoveStrategy: boolean;
    error: ServiceError;
    removeChildren?: boolean;
    projectId: Guid;
    defaults?: DocumentCategoryTreeNodeDeleteFormData;
  };

class DocumentCategoryTreeNodeDeleteForm extends Component<Props> {
  componentDidMount() {
    this.props.defaults && this.props.form.setFieldsValue(this.props.defaults);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    if (this.props.askRemoveStrategy !== prevProps.askRemoveStrategy) {
      this.props.form.resetFields();
    }
  }

  render() {
    const { intl, form, askRemoveStrategy, error, removeChildren, projectId } = this.props;
    const { getFieldDecorator } = form;

    const errorData = error ? (error.errorData as IErrorData) : undefined;
    const errorDataDirectories = errorData ? errorData.directories : [];
    const errorDataDocuments = errorData ? errorData.documents : [];

    return (
      <Form layout="vertical">
        <Form.Item label={intl.formatMessage({ id: 'DocumentCategoryTreeNodeDeleteModal.removeChildren.label' })}>
          {getFieldDecorator<DocumentCategoryTreeNodeDeleteFormData>('removeChildren', {
            initialValue: removeChildren,
            rules: [requiredRule('forms.items.rules.required')],
          })(
            <Radio.Group buttonStyle="solid" disabled={error && askRemoveStrategy}>
              <Radio.Button value={true}>
                {intl.formatMessage({ id: 'DocumentCategoryTreeNodeDeleteModal.removeChildren.remove' })}
              </Radio.Button>
              <Radio.Button value={false}>
                {intl.formatMessage({ id: 'DocumentCategoryTreeNodeDeleteModal.removeChildren.preserve' })}
              </Radio.Button>
            </Radio.Group>
          )}
        </Form.Item>

        {error && askRemoveStrategy && (
          <>
            <Alert
              className={styles.errorMessageWrap}
              type="error"
              message={intl.formatMessage({ id: 'serviceError.DocumentCategoryNodeRemoveObstacleError' })}
            />

            <Form.Item label={intl.formatMessage({ id: `DocumentCategoryTreeNodeDeleteModal.removeStrategy.label` })}>
              {getFieldDecorator<DocumentCategoryTreeNodeDeleteFormData>('removeStrategy', {
                rules: [requiredRule('forms.items.rules.required')],
              })(
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value="replaceParent">
                    {intl.formatMessage({ id: 'DocumentCategoryTreeNodeDeleteModal.removeStrategy.replaceParent' })}
                  </Radio.Button>
                  <Radio.Button value="replaceRoot">
                    {intl.formatMessage({ id: 'DocumentCategoryTreeNodeDeleteModal.removeStrategy.replaceRoot' })}
                  </Radio.Button>
                </Radio.Group>
              )}
            </Form.Item>

            {errorDataDocuments.length > 0 &&
              error.referenceErrorCode === ServiceErrorEnum.DocumentCategoryNodeRemoveObstacleError && (
                <List<IAdditionalDataDocuments>
                  size="small"
                  header={
                    <Typography.Text strong>
                      {intl.formatMessage({ id: 'DocumentCategoryTreeNodeDeleteForm.documentsWithNode' })}
                    </Typography.Text>
                  }
                  dataSource={errorDataDocuments}
                  renderItem={(item) => (
                    <List.Item>
                      <Link
                        target="_blank"
                        to={`/projects/${projectId}/documents/${item.documentId}`}
                        title={`${item.directoryPath}/${item.documentName}`}
                      >
                        {item.directoryPath}/{item.documentName}
                      </Link>
                    </List.Item>
                  )}
                />
              )}

            {errorDataDirectories.length > 0 &&
              error.referenceErrorCode === ServiceErrorEnum.DocumentCategoryNodeRemoveObstacleError && (
                <List<IAdditionalDataDirectories>
                  size="small"
                  header={
                    <Typography.Text strong>
                      {intl.formatMessage({ id: 'DocumentCategoryTreeNodeDeleteForm.directoriesWithNode' })}
                    </Typography.Text>
                  }
                  dataSource={errorDataDirectories}
                  renderItem={(item) => (
                    <List.Item>
                      <Link
                        target="_blank"
                        to={`/projects/${projectId}/directories/${item.directoryId}`}
                        title={`${item.directoryPath}`}
                      >
                        {item.directoryPath}
                      </Link>
                    </List.Item>
                  )}
                />
              )}
          </>
        )}
      </Form>
    );
  }
}

export default Form.create<Props>()(DocumentCategoryTreeNodeDeleteForm);

export { DocumentCategoryTreeNodeDeleteForm };
