import { Button, Select, Typography } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { EstiConFirmRowDto } from 'api/completeApiInterfaces';
import { ApiError } from 'api/errors';
import { baseProjectApi } from 'api/project/baseProjectApi';
import to from 'await-to-js';
import axios, { AxiosResponse } from 'axios';
import EmptyStyled from 'components/Empty/EmptyStyled';
import { Margin } from 'components/Margin/Margin';
import KpiConfigurationContextProvider from 'components/Reports/contexts/KpiSettingsContextProvider';
import StackPanel from 'components/StackPanel';
import { API_BASE_URL } from 'config/env';
import { useCurrentAppUser, useIntl, useIsMounted, useSelectorDispatch } from 'hooks';
import { useInitialLocalStorage } from 'hooks/useDefaultLocalStorage';
import { SavedRecordTypeEnum } from 'hooks/useSavedRecordsApi';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { messageError } from 'utils';
import { combinePaths } from 'utils/urlPaths';
import { ReportDetailProps } from '../../ReportDetail';
import { ReportDetailPanelParams } from '../../ReportDetailPage';
import styles from '../../ReportDetailPage.module.less';
import FinancialOverviewByUnitReport from './FinancialOverviewByUnitReport';

const LOCAL_STORAGE_FINANCIAL_REPORT_SELECTED_UNIT_KEY = 'FinancialOverviewReportSelectedUnit';

const FinancialOverviewByUnitReportPage: FunctionComponent<ReportDetailProps> = ({
  organizationId,
  basePath,
  addBreadcrumb,
  removeBreadcrumb,
}) => {
  const [selectedUnit, initialSelectedUnit, saveSelectedUnit] = useInitialLocalStorage<string>(
    LOCAL_STORAGE_FINANCIAL_REPORT_SELECTED_UNIT_KEY
  );
  const [selectedOption, setSelectedOption] = useState<string>(initialSelectedUnit);
  const history = useHistory();
  const intl = useIntl();
  const currentAppUser = useCurrentAppUser();
  const isMounted = useIsMounted();
  const { report } = useParams<ReportDetailPanelParams>() as ReportDetailPanelParams;

  const userProjects = useSelectorDispatch(
    (dispatch) => dispatch.allProjects,
    (dispatch) => dispatch.allProjects.loadData({ reload: false })
  );

  useDirtyStoreReload(
    (state) => state.allProjects,
    (dispatch) => dispatch.allProjects
  );

  // TODO: Hack to get access to esticon firms endpoint (Require projectID which is not available on reports page)
  // BE will be updated later to remove this requirement
  const [esticonFirms, setEsticonFirms] = useState<EstiConFirmRowDto[]>();
  const [firmsLoading, setFirmsLoading] = useState<boolean>(false);
  useEffect(() => {
    if (!!userProjects?.data?.projects.length) {
      setFirmsLoading(true);
      // Any project will currently get access to available esticon firms, delete together with hack above
      const anyProjectId = userProjects?.data.projects[0].id;

      const messageProjectApi = axios.create({
        baseURL: API_BASE_URL,
        headers: {
          ...baseProjectApi.defaults.headers,
        },
      });

      to<AxiosResponse<EstiConFirmRowDto[]>, ApiError>(
        messageProjectApi.get<EstiConFirmRowDto[]>(`/projects/${anyProjectId}/Esticon/firms`)
      ).then(
        ([err, firmData]) => {
          if (!isMounted.current) return;
          setFirmsLoading(false);
          if (err) {
            messageError(err, intl);
            return;
          }

          setEsticonFirms(firmData.data);
        },
        () => {}
      );
    }
  }, [userProjects]);

  const selectUnit = useCallback(() => {
    saveSelectedUnit(selectedOption);
  }, [selectedOption]);

  const returnToUnitSelection = useCallback(() => {
    setSelectedOption(undefined);
    saveSelectedUnit('');
    history.push(basePath);
  }, []);

  useEffect(() => {
    addBreadcrumb({
      key: report,
      title: intl.formatMessage({ id: `Reporting.reportType.name.${report}` }),
      link: basePath,
      onClick: returnToUnitSelection,
    });
  }, []);

  useEffect(() => {
    if (selectedUnit) {
      const combinedPath = combinePaths([basePath, selectedUnit], '');
      addBreadcrumb({
        key: 'financialOverviewUnit',
        title:
          esticonFirms?.find((firm) => firm.id === selectedUnit)?.nazev ||
          intl.formatMessage({ id: 'general.loading' }),
        link: combinedPath,
      });

      history.replace(combinedPath);
    }
    return () => removeBreadcrumb('financialOverviewUnit');
  }, [selectedUnit, esticonFirms]);

  const availableProjectIds = useMemo(
    () => new Set<Guid>(userProjects?.data?.projects.map((project) => project.id) || []),
    [userProjects]
  );

  const availableFirmOptions = useMemo(
    () =>
      esticonFirms
        ?.filter((firmRow) =>
          currentAppUser.organizationUsers?.some(
            (orgUser) => !!orgUser.organization.esticonFirmIds?.some((firmId) => firmRow.id === firmId)
          )
        )
        ?.map((firmRow): DefaultOptionType => ({ value: firmRow.id, label: firmRow.nazev })) || [],
    [esticonFirms, currentAppUser]
  );

  if (!selectedUnit)
    return (
      <EmptyStyled description={<Fmt id="FinancialOverviewByUnitReport.noUnit.title" />}>
        <StackPanel vertical>
          <Margin bottom>
            <Select
              options={availableFirmOptions}
              onChange={setSelectedOption}
              size="middle"
              loading={firmsLoading || userProjects.loading}
              className={styles.unitSelect}
            />
          </Margin>
          <Button type="primary" onClick={selectUnit}>
            <Fmt id="general.select" />
          </Button>
        </StackPanel>
      </EmptyStyled>
    );

  return (
    <div className={styles.content}>
      <Typography.Title level={2}>
        <Fmt id="FinancialOverviewByUnitReport.title" />
      </Typography.Title>
      <KpiConfigurationContextProvider
        reportType={SavedRecordTypeEnum.KPIFinancialOverviewEsticonProjectsByUnitConfigurations}
      >
        <FinancialOverviewByUnitReport
          esticonFirmId={selectedUnit}
          organizationId={organizationId}
          availableProjectIds={availableProjectIds}
        />
      </KpiConfigurationContextProvider>
    </div>
  );
};

export default React.memo(FinancialOverviewByUnitReportPage);
