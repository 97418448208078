import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Col, DatePicker, Input, Row } from 'antd';
import { DeleteButton } from 'components/ActionButtons';
import { Margin } from 'components/Margin/Margin';
import { Fmt } from 'locale';
import moment from 'moment';
import React, { FunctionComponent, useCallback } from 'react';
import { ignoreRef } from 'utils';
import { JSONVariableTypeEnum, MDCkApprovalCondition, MDCkApprovalConditions } from '../MDProjectVariableData.types';

type Props = {
  value?: MDCkApprovalConditions;
  onChange?: (value: MDCkApprovalConditions) => void;
  canEditAsCKOrganizer: boolean;
  canEditAsGuarantor: boolean;
};

const defaultCondition: MDCkApprovalCondition = {
  Condition: '',
  ApprovalDescription: '',
  ApprovalDone: false,
  Deadline: '',
  DeadlineSetAt: undefined,
  MsgSentInfo: 0,
};

const MDVariableConditionsFormItemComponent: FunctionComponent<Props> = ({
  value,
  onChange,
  canEditAsCKOrganizer,
  canEditAsGuarantor,
}) => {
  const handleCreateNewCondition = useCallback(() => {
    onChange &&
      onChange({ type: JSONVariableTypeEnum.ckApprovalCondition, value: [...(value?.value || []), defaultCondition] });
  }, [value, onChange]);

  const rowChangeHandle = useCallback(
    (property: keyof MDCkApprovalCondition, rowIndex: number, newValue: any) => {
      const isDeadlineChanged = property === 'Deadline' && value?.value[rowIndex]?.Deadline !== newValue;
      const notificationData: Pick<MDCkApprovalCondition, 'DeadlineSetAt' | 'MsgSentInfo'> = {
        DeadlineSetAt: isDeadlineChanged
          ? moment()
              .utc()
              .toISOString()
          : value?.value[rowIndex]?.DeadlineSetAt,
        MsgSentInfo: isDeadlineChanged ? 0 : value?.value[rowIndex]?.MsgSentInfo,
      };

      onChange({
        type: JSONVariableTypeEnum.ckApprovalCondition,
        value: [
          ...(value?.value.map((condition, index) =>
            rowIndex === index ? { ...condition, [property]: newValue, ...notificationData } : condition
          ) || []),
        ],
      });
    },
    [onChange, value]
  );

  const rowDeleteHandle = useCallback(
    (rowIndex: number) => {
      onChange({
        type: JSONVariableTypeEnum.ckApprovalCondition,
        value: [...(value?.value.filter((condition, index) => index !== rowIndex) || [])],
      });
    },
    [onChange, value]
  );

  return (
    <>
      <Row gutter={8}>
        <Col span={6}>
          <Fmt id="MD.ProjectVariableCondition.condition.title" />
        </Col>
        <Col span={4}>
          <Fmt id="MD.ProjectVariableCondition.deadline.title" />
        </Col>
        <Col span={6}>
          <Fmt id="MD.ProjectVariableCondition.approvalDone.title" />
        </Col>
        <Col span={6}>
          <Fmt id="MD.ProjectVariableCondition.approvalDescription.title" />
        </Col>
        <Col span={2}></Col>
      </Row>
      {value?.value.map((condition, index) => (
        <Margin bottom key={index}>
          <Row gutter={8}>
            <Col span={6}>
              <Input
                value={condition.Condition}
                onChange={(e) => rowChangeHandle('Condition', index, e.target.value)}
                disabled={!canEditAsCKOrganizer}
              />
            </Col>
            <Col span={4}>
              <DatePicker
                value={condition.Deadline && moment(condition.Deadline)}
                onChange={(date) => rowChangeHandle('Deadline', index, date?.utc().toISOString())}
                style={{ width: '100%' }}
                disabled={!canEditAsGuarantor && !canEditAsCKOrganizer}
              />
            </Col>
            <Col span={6}>
              <Checkbox
                checked={condition.ApprovalDone}
                onChange={(e) => rowChangeHandle('ApprovalDone', index, e.target.checked)}
                disabled={!canEditAsGuarantor}
              />
            </Col>
            <Col span={6}>
              <Input
                value={condition.ApprovalDescription}
                onChange={(e) => rowChangeHandle('ApprovalDescription', index, e.target.value)}
                disabled={!canEditAsGuarantor}
              />
            </Col>
            <Col span={2}>
              <DeleteButton onClick={() => rowDeleteHandle(index)} disabled={!canEditAsCKOrganizer} />
            </Col>
          </Row>
        </Margin>
      ))}
      <Margin top>
        <Row gutter={24}>
          <Col span={14}>
            <Button onClick={handleCreateNewCondition} type="primary" block disabled={!canEditAsCKOrganizer}>
              <Fmt id="MD.ProjectVariableCondition.addNewRule.button" />
            </Button>
          </Col>
        </Row>
      </Margin>
    </>
  );
};

export const MDVariableConditionsFormItem = ignoreRef(MDVariableConditionsFormItemComponent) as FunctionComponent<
  Omit<Props, 'value' | 'onChange'>
>;
