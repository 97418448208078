import {
  AppstoreAddOutlined,
  ContainerOutlined,
  EditOutlined,
  PullRequestOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { Button, Form, Select, Typography } from 'antd';
import DatePicker from 'antd/es/date-picker';
import { DefaultOptionType } from 'antd/lib/select';
import { masterApi } from 'api/completeApi';
import { OrgExtendedPermissionEnum, OrgExtendedPermissionValueEnum } from 'api/completeApiInterfaces';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { ContentGate } from 'components/ContentGate/ContentGate';
import EmptyStyled from 'components/Empty/EmptyStyled';
import { useReportWidgetsContext } from 'components/Reports/contexts/ReportWidgetsContextProvider';
import SharedReportWidgetsModal from 'components/Reports/forms/SharedReportWidgetsModal';
import StackPanel from 'components/StackPanel';
import { Serializable } from 'components/filters/filterTypes';

import { ReportWidgetCreateFormData } from 'components/Reports/forms/ReportWidgetCreateForm';
import {
  ReportWidgetDecimalUnitEnum,
  ReportWidgetLabelAlignEnum,
} from 'components/Reports/forms/ReportWidgetCreateForm/ReportWidgetForm.utils';
import { useApiData, useBoolean, useCurrentAppUser, useIntl, useSelectorDispatch } from 'hooks';
import { useEsticonFirmsWithProjects } from 'hooks/useEsticonFirmsWithProjects';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt } from 'locale';
import { isNumber } from 'lodash';
import moment from 'moment';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { combinePaths } from 'utils/urlPaths';
import { ReportDetailProps } from '../../ReportDetail';
import { ReportDetailPanelParams } from '../../ReportDetailPage';
import ProjectsInRealisationOverviewReport from './ProjectsInRealizationOverviewReport';
import styles from './ProjectsInRealizationOverviewReport.module.less';
import {
  ReportViewConfiguration,
  ReportViewConfigurationColumn,
  getDefaultProjectInRealizationOverviewNamedReports,
} from './ProjectsInRealizationOverviewReport.reportPresets';
import ProjectsInRealisationOverviewReportDownloadButton from './ProjectsInRealizationOverviewReportDownloadButton';
import ProjectsInRealizationPairingNotesModal from './ProjectsInRealizationPairingNotesModal/ProjectsInRealizationPairingNotesModal';
import ProjectsInRealizationSavedViewsModal from './ProjectsInRealizationSavedViewsModal/ProjectsInRealizationSavedViewsModal';
import { useConfigurableReportView } from './useConfigurableReportView';
import { useProjectsInRealiationSavedViews } from './useProjectsInRealizationSavedViews';

const REPORT_FROM_START_YEAR = 2022;

const LOCAL_STORAGE_PROJECTS_IN_REALISATION_OVERVIEW_SELECTED_SETTINGS =
  'ProjectsInRealisationOverviewReportSelectedUnit';

type ReportViewSettings = {
  esticonUnit: string;
  reportDate: string;
  view?: Guid;
  startYear: number;
  widgetView?: Guid;
};

export type SavedReportViews = {
  views: ReportViewConfiguration[];
};

type ReportDetailRouterParams = {
  firmId?: Guid;
  date?: string;
  year?: string;
  widgetId?: Guid;
};

const getReportViewColumnConfiguration = (
  namedViews: ReportViewConfiguration[],
  config: ReportViewConfiguration
): ReportViewConfigurationColumn[] => {
  return namedViews.find((view) => view.id === '00000000-0000-0000-0000-000000000001')?.columns || config.columns;
};

const ProjectsInRealisationOverviewReportPage: FunctionComponent<ReportDetailProps> = ({
  organizationId,
  basePath,
  addBreadcrumb,
  removeBreadcrumb,
}) => {
  const [reportDataSettings, saveReportDataSettings] = useLocalStorage<ReportViewSettings>(
    LOCAL_STORAGE_PROJECTS_IN_REALISATION_OVERVIEW_SELECTED_SETTINGS
  );
  const { params } = useRouteMatch<ReportDetailRouterParams>();

  useEffect(() => {
    if (params.date && params.year && params.firmId && params.widgetId) {
      saveReportDataSettings({
        esticonUnit: params.firmId,
        reportDate: params.date,
        startYear: parseInt(params.year),
        widgetView: params.widgetId,
        view: undefined,
      });
    }
  }, []);

  const history = useHistory();
  const intl = useIntl();
  const currentAppUser = useCurrentAppUser();
  const [savedViewsEditVisible, showSavedViewEdit, hideSavedViewEdit] = useBoolean(false);
  const [pairingModalVisible, showPairingNotesModal, hidePairingNoteModal] = useBoolean(false);
  const [sharedWidgetsManagerModalVisible, showSharedWidgetsManagerModal, hideSharedWidgetsManagerModal] = useBoolean(
    false
  );
  const [visibleReportProjectIds, setVisibleReportProjectIds] = useState<Guid[]>([]);

  const { report } = useParams<ReportDetailPanelParams>() as ReportDetailPanelParams;

  useDirtyStoreReload(
    (state) => state.allProjects,
    (dispatch) => dispatch.allProjects
  );

  const { orderedWidgets, showReportAddModal, requestSharedWidgets, sharedWidgets } = useReportWidgetsContext();

  const [
    namedViews,
    namedViewsError,
    namedViewsLoading,
    namedViewsSaving,
    saveNamedViews,
  ] = useProjectsInRealiationSavedViews(organizationId, currentAppUser?.id);

  const [currentViewConfiguration, setCurrentViewConfiguration, handleColumnsChange] = useConfigurableReportView();

  useEffect(() => {
    if (reportDataSettings?.widgetView && !reportDataSettings?.view && orderedWidgets) {
      const widgetConfig = orderedWidgets.find((widget) => widget.id === reportDataSettings.widgetView);
      if (widgetConfig && widgetConfig.type === 'personal') {
        setCurrentViewConfiguration((config) => ({
          ...config,
          columns: getReportViewColumnConfiguration(namedViews, config),
          id: widgetConfig.id,
          filters: widgetConfig.data.filters,
        }));
        saveReportDataSettings({ ...reportDataSettings, widgetView: undefined });
        return;
      } else if (widgetConfig && widgetConfig.type === 'shared') {
        if (widgetConfig.organizationId in sharedWidgets) {
          const sharedWidgetConfig = sharedWidgets[widgetConfig.organizationId].find(
            (widget) => widget.id === widgetConfig.widgetId
          );
          if (sharedWidgetConfig && sharedWidgetConfig.type === 'personal') {
            setCurrentViewConfiguration((config) => ({
              ...config,
              columns: getReportViewColumnConfiguration(namedViews, config),
              id: widgetConfig.id,
              filters: sharedWidgetConfig.data.filters,
            }));
            saveReportDataSettings({ ...reportDataSettings, widgetView: undefined });
          }
        } else {
          void requestSharedWidgets(widgetConfig.organizationId);
        }
      }
    }
  }, [orderedWidgets, sharedWidgets]);

  const widgetCreateDefaults = useMemo(
    (): ReportWidgetCreateFormData => ({
      organizationId,
      name: namedViews?.find((view) => view.id === reportDataSettings?.view)?.name || '',
      esticonUnit: reportDataSettings?.esticonUnit,
      reportDate: reportDataSettings?.reportDate,
      reportType: OrgExtendedPermissionEnum.projectsInRealisationOverview,
      startYear: reportDataSettings?.startYear,
      filters: currentViewConfiguration?.filters || {},
      labelAlign: ReportWidgetLabelAlignEnum.Top,
      viewDecimalUnit: ReportWidgetDecimalUnitEnum.Thousands,
      columnConfigurations: [],
    }),
    [currentViewConfiguration, namedViews, organizationId, reportDataSettings]
  );

  const onShowWidgetAddModal = useCallback(() => {
    showReportAddModal(widgetCreateDefaults);
  }, [widgetCreateDefaults, showReportAddModal]);

  const handleNamedViewSave = useCallback(
    async (views: ReportViewConfiguration[], switchToViewId?: Guid) => {
      await saveNamedViews({ views });
      if (switchToViewId) {
        saveReportDataSettings((currentSettings) => ({ ...currentSettings, view: switchToViewId }));
      }
    },
    [saveNamedViews, saveReportDataSettings]
  );

  const overrideCurrentView = useCallback(async () => {
    const updatedViews = namedViews.map(
      (view): ReportViewConfiguration => (view.id === currentViewConfiguration.id ? currentViewConfiguration : view)
    );
    await saveNamedViews({ views: updatedViews });
  }, [currentViewConfiguration, namedViews, saveNamedViews]);

  useEffect(() => {
    if (reportDataSettings?.view && namedViews && reportDataSettings?.view !== currentViewConfiguration?.id) {
      const viewSetting = namedViews.find((view) => view.id === reportDataSettings?.view) || namedViews[0];
      setCurrentViewConfiguration(viewSetting);
    } else if (!reportDataSettings?.view) {
      setCurrentViewConfiguration((config) => ({
        ...config,
        columns: getReportViewColumnConfiguration(namedViews, config),
      }));
    }
  }, [namedViews, reportDataSettings?.view]);

  const handleFilterSave = useCallback((filterPresets: Record<string, Serializable>) => {
    setCurrentViewConfiguration((config) => ({ ...config, filters: filterPresets }));
  }, []);

  const reportViewOptions = useMemo(
    (): DefaultOptionType[] =>
      namedViews.map((report) => ({
        label: report.name,
        value: report.id,
      })),
    [namedViews]
  );

  const canEditCurrentView = useMemo(
    () =>
      currentViewConfiguration?.id &&
      !getDefaultProjectInRealizationOverviewNamedReports(intl).some(
        (view) => currentViewConfiguration.id === view.id
      ) &&
      namedViews?.some((view) => view.id === reportDataSettings?.view),
    [currentViewConfiguration, intl, namedViews, reportDataSettings]
  );

  const defaultViewOption = useMemo(
    (): Guid => (isNumber(reportViewOptions?.[0]?.value) ? undefined : reportViewOptions?.[0]?.value),
    [reportViewOptions]
  );

  const [reportSettings, settingsError, settingsLoading, loadReportSettings] = useApiData(
    (ct) => masterApi.projects.admin.orgSettings.id.getpohreportsettings.get(organizationId, ct),
    { autoload: false }
  );

  const orgUserReportPermissions = useMemo(
    () =>
      currentAppUser.organizationUsers
        ?.find((user) => user.organization.id === organizationId)
        ?.extendedPermissions.filter((permission) => permission.permissionType === report) || [],
    [currentAppUser, organizationId, report]
  );

  const userProjects = useSelectorDispatch(
    (dispatch) => dispatch.allProjects,
    (dispatch) => dispatch.allProjects.loadData({ reload: false })
  );

  const { esticonFirms, firmsWithProjectsLoading, firmsWithProjectsError } = useEsticonFirmsWithProjects(
    organizationId
  );

  useEffect(() => {
    loadReportSettings();
  }, [organizationId]);

  const returnToUnitSelection = useCallback(() => {
    saveReportDataSettings({
      esticonUnit: undefined,
      reportDate: undefined,
      view: reportDataSettings?.view,
      startYear: moment().year(),
      widgetView: undefined,
    });
    history.push(basePath);
  }, [basePath, history, reportDataSettings?.view, saveReportDataSettings]);

  useEffect(() => {
    addBreadcrumb({
      key: report,
      title: intl.formatMessage({ id: `Reporting.reportType.name.${report}` }),
      link: basePath,
      onClick: returnToUnitSelection,
    });

    return () => {
      removeBreadcrumb(report);
    };
  }, [returnToUnitSelection, addBreadcrumb, report, intl, basePath, removeBreadcrumb]);

  useEffect(() => {
    if (reportDataSettings?.esticonUnit && reportDataSettings?.reportDate) {
      const reportDate = moment(reportDataSettings.reportDate).format('ll');
      const combinedPath = combinePaths([basePath, reportDataSettings.esticonUnit, reportDate], '');
      const unitName = Object.values(esticonFirms).find((firm) => firm.id === reportDataSettings.esticonUnit)?.nazev;
      addBreadcrumb({
        key: 'projectsInRealisationOverviewDetail',
        title: !!unitName ? `${unitName} - ${reportDate}` : intl.formatMessage({ id: 'general.loading' }),
        link: combinedPath,
      });

      history.replace(combinedPath);
    }
    return () => removeBreadcrumb('projectsInRealisationOverviewDetail');
  }, [reportDataSettings, esticonFirms]);

  const availableProjectIds = useMemo(
    () => new Set<Guid>(userProjects?.data?.projects.map((project) => project.id) || []),
    [userProjects]
  );

  const availableFirmOptions = useMemo(
    () =>
      Object.values(esticonFirms)
        ?.filter((firmRow) =>
          currentAppUser.organizationUsers?.some(
            (orgUser) => !!orgUser.organization.esticonFirmIds?.some((firmId) => firmRow.id === firmId)
          )
        )
        ?.map((firmRow): DefaultOptionType => ({ value: firmRow.id, label: firmRow.nazev })) || [],
    [esticonFirms, currentAppUser]
  );

  const availableReportYears = useMemo(() => {
    const endYear = moment().year();
    return Array.from({ length: endYear - REPORT_FROM_START_YEAR + 1 }, (_, i) => REPORT_FROM_START_YEAR + i).map(
      (year): DefaultOptionType => ({ value: year, label: year })
    );
  }, []);

  const onFinish = (values: ReportViewSettings) => {
    saveReportDataSettings(values);
  };

  const handleViewChange = useCallback(
    (value: Guid) => {
      saveReportDataSettings({ ...reportDataSettings, view: value });
    },
    [reportDataSettings, saveReportDataSettings]
  );

  const titleData = useMemo(
    () => ({
      year: reportDataSettings?.startYear,
      date: moment(reportDataSettings?.reportDate)
        .locale(intl.locale)
        .format('ll'),
    }),
    [reportDataSettings?.reportDate, intl]
  );

  const hasNotesAdminPermission = useMemo(
    () =>
      currentAppUser.organizationUsers
        ?.find((user) => user.organization.id === organizationId)
        ?.extendedPermissions.some(
          (value) =>
            value.permission === OrgExtendedPermissionValueEnum.read &&
            value.permissionType === OrgExtendedPermissionEnum.projectsInRealisationOverviewAdmin
        ),
    [currentAppUser, organizationId]
  );

  const hasSharedWidgetEditPermission = useMemo(
    () =>
      currentAppUser.organizationUsers
        ?.find((user) => user.organization.id === organizationId)
        ?.extendedPermissions.some(
          (value) =>
            value.permission === OrgExtendedPermissionValueEnum.read &&
            value.permissionType === OrgExtendedPermissionEnum.projectsInRealisationShareWidget
        ),
    [currentAppUser, organizationId]
  );

  const displayedViewKey = useMemo(
    () =>
      namedViews?.some((view) => view.id === reportDataSettings?.view)
        ? reportDataSettings?.view
        : intl.formatMessage({ id: 'ProjectsInRealisationSavedViews.workingView.name' }),
    [intl, namedViews, reportDataSettings?.view]
  );

  const reportDate = useMemo(() => moment(reportDataSettings?.reportDate), [reportDataSettings?.reportDate]);
  const newWidgetOrganizations = useMemo(
    () =>
      currentAppUser.organizationUsers
        ?.map((userOrg) => userOrg.organization)
        .filter((org) => org.id === organizationId),
    [currentAppUser, organizationId]
  );

  if (!reportDataSettings?.esticonUnit || !reportDataSettings?.reportDate)
    return (
      <EmptyStyled description={<Fmt id="ProjectsInRealizationOverviewReport.reportSelection.title" />}>
        <ContentGate loading={settingsLoading} error={firmsWithProjectsError}>
          {!!reportSettings && (
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              initialValues={{
                reportDate: moment(reportSettings?.defaultReportDate),
              }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label={<Fmt id="ProjectsInRealizationOverviewReport.reportSelection.unit" />}
                name="esticonUnit"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'ProjectsInRealizationOverviewReport.reportSelection.required.unit',
                    }),
                  },
                ]}
              >
                <Select
                  options={availableFirmOptions}
                  size="middle"
                  loading={firmsWithProjectsLoading || userProjects.loading}
                  className={styles.formItem}
                />
              </Form.Item>

              <Form.Item
                label={<Fmt id="ProjectsInRealizationOverviewReport.reportSelection.view" />}
                name="view"
                initialValue={defaultViewOption}
              >
                <Select className={styles.formItem} options={reportViewOptions} />
              </Form.Item>

              <Form.Item
                label={<Fmt id="ProjectsInRealizationOverviewReport.reportSelection.reportingPeriod" />}
                name="startYear"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'ProjectsInRealizationOverviewReport.reportSelection.required.forYear',
                    }),
                  },
                ]}
                initialValue={moment().year()}
              >
                <Select options={availableReportYears} size="middle" className={styles.formItem} />
              </Form.Item>

              <Form.Item
                label={<Fmt id="ProjectsInRealizationOverviewReport.reportSelection.date" />}
                name="reportDate"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'ProjectsInRealizationOverviewReport.reportSelection.required.date',
                    }),
                  },
                ]}
              >
                <DatePicker className={styles.formItem} />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit" className={styles.submit}>
                  <Fmt id="general.select" />
                </Button>
              </Form.Item>
            </Form>
          )}
        </ContentGate>
      </EmptyStyled>
    );

  return (
    <div className={styles.content}>
      <StackPanel className={styles.flexTitle}>
        <Typography.Title level={2}>
          <Fmt id="ProjectsInRealizationOverviewReport.title" values={titleData} />
        </Typography.Title>

        <div className={styles.buttonToolbar}>
          {hasNotesAdminPermission && (
            <CommonHubTooltip title={<Fmt id="ProjectsInRealisationOverviewReportPage.menu.repairNotes.tooltip" />}>
              <Button
                onClick={showPairingNotesModal}
                icon={<PullRequestOutlined />}
                className={styles.saveViewButton}
              />
            </CommonHubTooltip>
          )}
          {hasSharedWidgetEditPermission && (
            <CommonHubTooltip
              title={<Fmt id="ProjectsInRealisationOverviewReportPage.menu.manageSharedWidgets.tooltip" />}
            >
              <Button
                onClick={showSharedWidgetsManagerModal}
                icon={<ContainerOutlined />}
                className={styles.saveViewButton}
              />
            </CommonHubTooltip>
          )}
          <CommonHubTooltip title={<Fmt id="ProjectsInRealisationOverviewReportPage.menu.addWidget.tooltip" />}>
            <Button onClick={onShowWidgetAddModal} icon={<AppstoreAddOutlined />} className={styles.saveViewButton} />
          </CommonHubTooltip>
          <Select
            options={reportViewOptions}
            value={displayedViewKey}
            onChange={handleViewChange}
            className={styles.viewSelect}
            loading={namedViewsLoading}
          />
          <CommonHubTooltip title={<Fmt id="ProjectsInRealisationOverviewReportPage.menu.editViews.tooltip" />}>
            <Button
              onClick={showSavedViewEdit}
              icon={<EditOutlined />}
              className={styles.editViewButton}
              loading={namedViewsLoading}
            />
          </CommonHubTooltip>
          <CommonHubTooltip title={<Fmt id="ProjectsInRealisationOverviewReportPage.menu.override.tooltip" />}>
            <Button
              onClick={overrideCurrentView}
              icon={<SaveOutlined />}
              className={styles.saveViewButton}
              disabled={!canEditCurrentView}
              loading={namedViewsSaving}
            />
          </CommonHubTooltip>
          <ProjectsInRealisationOverviewReportDownloadButton
            esticonFirmId={reportDataSettings.esticonUnit}
            organizationId={organizationId}
            reportDate={reportDate}
            reportYear={reportDataSettings.startYear}
            currentViewConfiguration={currentViewConfiguration}
            visibleProjectIds={visibleReportProjectIds}
          />
        </div>
      </StackPanel>
      <ProjectsInRealisationOverviewReport
        esticonFirmId={reportDataSettings?.esticonUnit}
        organizationId={organizationId}
        availableProjectIds={availableProjectIds}
        reportDate={reportDate}
        reportViewConfiguration={currentViewConfiguration}
        reportPermissions={orgUserReportPermissions}
        reportYear={reportDataSettings?.startYear}
        onColumnChange={handleColumnsChange}
        onFiltersChange={handleFilterSave}
        onSetVisibleReportRows={setVisibleReportProjectIds}
      />
      <ProjectsInRealizationSavedViewsModal
        open={savedViewsEditVisible}
        onClose={hideSavedViewEdit}
        onSave={handleNamedViewSave}
        existingViewsConfigurations={namedViews}
        currentViewConfiguration={currentViewConfiguration}
      />
      {sharedWidgetsManagerModalVisible && (
        <SharedReportWidgetsModal
          open={sharedWidgetsManagerModalVisible}
          onCancel={hideSharedWidgetsManagerModal}
          organizationId={organizationId}
          availableOrganizations={newWidgetOrganizations}
          defaults={widgetCreateDefaults}
        />
      )}
      {pairingModalVisible && (
        <ProjectsInRealizationPairingNotesModal
          open={pairingModalVisible}
          onClose={hidePairingNoteModal}
          organizationId={organizationId}
          esticonFirmId={reportDataSettings.esticonUnit}
        />
      )}
    </div>
  );
};

export default React.memo(ProjectsInRealisationOverviewReportPage);
