export type MDSupportedVariableType =
  | 'string'
  | 'number'
  | 'date'
  | 'boolean'
  | 'generatedLink'
  | 'directoryUrl'
  | 'documentUrl'
  | 'price'
  | 'dateRange'
  | 'ckConditions';

export type MDCkApprovalCondition = {
  Condition: string;
  Deadline?: IsoDateTime;
  ApprovalDone?: boolean;
  ApprovalDescription?: string;
  DeadlineSetAt?: IsoDateTime; // for Be evaluation of messages, set to now() when deadline changes
  MsgSentInfo?: number; // for Be evaluation of messages, reset to 0 after deadline change
};

export enum JSONVariableTypeEnum {
  number = 'number',
  string = 'string',
  date = 'date',
  link = 'link',
  boolean = 'boolean',
  interval = 'interval',
  ckApprovalCondition = 'ckApprovalConditions',
  hubLink = 'hubLink',
}

export type MDInput = { type: JSONVariableTypeEnum; value: string | number };
export type MDDate = { type: JSONVariableTypeEnum.date; value: IsoDateTime };
export type MDBoolean = { type: JSONVariableTypeEnum.boolean; value: 'true' | 'false' };
export type MDDateRange = { type: JSONVariableTypeEnum.interval; valueFrom: IsoDateTime; valueTo: IsoDateTime };
export type MDLinkData = {
  type: JSONVariableTypeEnum.hubLink | JSONVariableTypeEnum.hubLink;
  value: string;
  label: string;
};
export type MDCkApprovalConditions = { type: JSONVariableTypeEnum.ckApprovalCondition; value: MDCkApprovalCondition[] };
