export const initializeEditorMessageApi = (frameRef: HTMLIFrameElement, clientUrl: string) =>
  frameRef &&
  frameRef.contentWindow.postMessage(
    JSON.stringify({
      MessageId: 'Host_PostmessageReady',
      SendTime: Date.now(),
      Values: {},
    }),
    clientUrl
  );

export const disableEditorSaveButton = (frameRef: HTMLIFrameElement, clientUrl: string) => {
  frameRef &&
    frameRef.contentWindow.postMessage(
      JSON.stringify({
        MessageId: 'Remove_Button',
        SendTime: Date.now(),
        Values: {
          id: 'save',
        },
      }),
      clientUrl
    );
  frameRef &&
    frameRef.contentWindow.postMessage(
      JSON.stringify({
        MessageId: 'Disable_Default_UIAction',
        SendTime: Date.now(),
        Values: {
          action: 'UI_Save',
          disable: true,
        },
      }),
      clientUrl
    );
};

export const sendEditorSaveInstruction = (frameRef: HTMLIFrameElement, clientUrl: string) =>
  frameRef &&
  frameRef.contentWindow.postMessage(
    JSON.stringify({
      MessageId: 'Action_Save',
      SendTime: Date.now(),
      Values: {
        DontTerminateEdit: false,
        DontSaveIfUnmodified: true,
        Notify: true,
        ExtendedData: '',
      },
    }),
    clientUrl
  );

export const sendCloseDocumentInstruction = (frameRef: HTMLIFrameElement, clientUrl: string) =>
  frameRef &&
  frameRef.contentWindow.postMessage(
    JSON.stringify({
      MessageId: 'Action_Close',
      SendTime: Date.now(),
      Values: {},
    }),
    clientUrl
  );

export const sendTokenRefreshInstruction = (frameRef: HTMLIFrameElement, clientUrl: string, token: string) =>
  frameRef &&
  frameRef.contentWindow.postMessage(
    JSON.stringify({
      MessageId: 'Reset_Access_Token',
      SendTime: Date.now(),
      Values: { token: token },
    }),
    clientUrl
  );

export const sendShowBusyInstruction = (frameRef: HTMLIFrameElement, clientUrl: string, label: string) =>
  frameRef &&
  frameRef.contentWindow.postMessage(
    JSON.stringify({
      MessageId: 'Action_ShowBusy',
      SendTime: Date.now(),
      Values: { Label: label },
    }),
    clientUrl
  );

export const sendHideBusyInstruction = (frameRef: HTMLIFrameElement, clientUrl: string) =>
  frameRef &&
  frameRef.contentWindow.postMessage(
    JSON.stringify({
      MessageId: 'Action_HideBusy',
      SendTime: Date.now(),
      Values: {},
    }),
    clientUrl
  );
