import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Checkbox, DatePicker, Input } from 'antd';
import { ExcludedDaySetDto } from 'api/completeApiInterfaces';
import { InjectedIntlProps } from 'locale';
import moment from 'moment';
import React, { useEffect } from 'react';
import { requiredRule } from 'utils/formHelpersCompatibility';
import { momentFromExcludedDay } from '../CalendarSettingsForm/CalendarSettingsForm.utils';

export type CalendarExcludedDayFormData = { name: string; date: moment.Moment; repeating: boolean };

type Props = FormComponentProps<CalendarExcludedDayFormData> &
  InjectedIntlProps & {
    isRepeating: boolean;
    initialExcludedDay?: ExcludedDaySetDto;
  };

const getDefaultDate = (excludedDay?: ExcludedDaySetDto) => {
  return !!excludedDay ? momentFromExcludedDay(excludedDay) : moment();
};

const CalendarExcludedDayFormComponent = React.forwardRef<unknown, Props>(
  ({ intl, form, initialExcludedDay, isRepeating }, ref) => {
    // backward compatibility with class components
    useEffect(() => {
      (ref as any).current = { props: { form } };
    }, [form]);

    return (
      <Form layout="vertical">
        <Form.Item label={intl.formatMessage({ id: 'general.name' })}>
          {form.getFieldDecorator<CalendarExcludedDayFormData>('name', {
            rules: [requiredRule('forms.items.rules.required')],
            initialValue: initialExcludedDay?.name || '',
          })(<Input />)}
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'general.date' })}>
          {form.getFieldDecorator<CalendarExcludedDayFormData>('date', {
            rules: [requiredRule('forms.items.rules.required')],
            initialValue: getDefaultDate(initialExcludedDay),
          })(<DatePicker format="L" allowClear={false} />)}
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'CalendarExcludedDayForm.repeating.label' })}>
          {form.getFieldDecorator<CalendarExcludedDayFormData>('repeating', {
            initialValue: isRepeating,
            valuePropName: 'checked',
          })(<Checkbox />)}
        </Form.Item>
      </Form>
    );
  }
);

export const CalendarExcludedDayForm = Form.create<Props>()(CalendarExcludedDayFormComponent);
