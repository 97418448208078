import {
  BarsOutlined,
  ClusterOutlined,
  FileOutlined,
  FolderOutlined,
  HomeOutlined,
  SolutionOutlined,
  TagOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Alert } from 'antd';
import { ExtendedPermissionEnum, ProjectMetadataDefinitionMetadataEntityType } from 'api/completeApiInterfaces';
import {
  MasterComponent,
  MasterDetailsContainer,
  MasterDetailsViewContextProvider,
} from 'components/MasterDetailsView/MasterDetailsView';
import { routeType } from 'components/PageSubmenu/PageSubmenu';
import SideMenuActivator, { SideMenuKey } from 'components/SideMenuActivator';
import { useCurrentProjectUser, useIntl } from 'hooks';
import { Fmt } from 'locale';
import ProjectSettingsPageMenu from 'pages/ProjectSettingsPage/ProjectSettingsPageMenu';
import React, { FunctionComponent } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { ProjectRouteParams } from 'routes';
import { userHasSomePermissions } from 'utils/userHasPermission';
import Categories from './Categories';
import { ExternApps } from './ExternApps';
import Groups from './Groups';
import Labels from './Labels';
import CommonMetadata from './Metadata/CommonMetadata';
import ProjectSettingsPageGeneral from './ProjectSettingsPageGeneral/ProjectSettingsPageGeneral';
import Roles from './Roles';
import Users from './Users';

const MENU_GENERAL = 'general';
const MENU_USERS = 'users';
const MENU_ROLES = 'roles';
const MENU_GROUPS = 'groups';
const MENU_LABELS = 'labels';
const MENU_CATEGORIES = 'categories';
const MENU_EXTERN_APPS = 'applications';
const MENU_FOLDERS = 'folders';
const MENU_FILES = 'files';

type Props = RouteComponentProps<ProjectRouteParams>;

export const ADMIN_AREA_PERMISSIONS = [
  ExtendedPermissionEnum.userEditing,
  ExtendedPermissionEnum.category,
  ExtendedPermissionEnum.labels,
  ExtendedPermissionEnum.externalApplications,
];

const ITEMS: routeType[] = [
  {
    key: MENU_GENERAL,
    title: 'DirectorySettingsForm.tabs.general',
    icon: <HomeOutlined />,
    component: ProjectSettingsPageGeneral,
  },
  {
    key: MENU_USERS,
    title: 'general.users',
    icon: <UserOutlined />,
    component: Users,
    userAccess: ExtendedPermissionEnum.userEditing,
  },
  {
    key: MENU_ROLES,
    title: 'general.roles',
    icon: <SolutionOutlined />,
    component: Roles,
    userAccess: ExtendedPermissionEnum.userEditing,
  },
  {
    key: MENU_GROUPS,
    title: 'general.groups',
    icon: <UsergroupAddOutlined />,
    component: Groups,
    userAccess: ExtendedPermissionEnum.userEditing,
  },
  {
    key: MENU_LABELS,
    title: 'general.labels',
    icon: <TagOutlined />,
    component: Labels,
    userAccess: ExtendedPermissionEnum.labels,
  },
  {
    key: MENU_CATEGORIES,
    title: 'general.categories',
    icon: <BarsOutlined />,
    component: Categories,
    userAccess: ExtendedPermissionEnum.category,
  },
  {
    key: MENU_EXTERN_APPS,
    title: 'general.ExternApps',
    icon: <ClusterOutlined />,
    component: ExternApps,
    userAccess: ExtendedPermissionEnum.externalApplications,
  },
  {
    key: MENU_FOLDERS,
    title: 'general.folders',
    icon: <FolderOutlined />,
    component: () => <CommonMetadata entityType={ProjectMetadataDefinitionMetadataEntityType.directory} />,
  },
  {
    key: MENU_FILES,
    title: 'general.documents',
    icon: <FileOutlined />,
    component: () => <CommonMetadata entityType={ProjectMetadataDefinitionMetadataEntityType.document} />,
  },
];

const ProjectSettingsPage: FunctionComponent<Props> = ({ match }) => {
  const url = match.url;
  const intl = useIntl();
  const currentProjectUser = useCurrentProjectUser();

  const page = (
    <MasterDetailsViewContextProvider>
      <MasterDetailsContainer>
        <MasterComponent
          noFlex
          minWidth={200}
          maxWidth={200}
          url={url}
          title={intl.formatMessage({ id: 'general.projectSettings' })}
          children={(onSelect, selectedItemId) => (
            <ProjectSettingsPageMenu
              currentProjectUser={currentProjectUser}
              selectedItemId={selectedItemId}
              items={ITEMS}
              onSelect={onSelect}
            />
          )}
        />
        <Switch>
          {ITEMS.map((item) => (
            <Route key={item.key} path={`${match.path}/${item.path || item.key}`} component={item?.component} />
          ))}
        </Switch>
      </MasterDetailsContainer>
    </MasterDetailsViewContextProvider>
  );

  if (!currentProjectUser?.isAdmin && !userHasSomePermissions(currentProjectUser, ADMIN_AREA_PERMISSIONS))
    return (
      <Alert
        style={{ margin: 24, width: '100%' }}
        message={<Fmt id="general.error" />}
        description={<Fmt id="serviceError.ProjectAdminAccessRequiredError" />}
        type="error"
        showIcon
      />
    );

  return <SideMenuActivator menuItemKey={SideMenuKey.SETTINGS}>{page}</SideMenuActivator>;
};

export default ProjectSettingsPage;
