import { Tooltip, Typography } from 'antd';
import { OrganizationAdminReportDto } from 'api/completeApiInterfaces';
import { EditButton } from 'components/ActionButtons';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import List from 'components/List';
import { ListEmpty } from 'components/ListEmpty/ListEmpty';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import StackPanel from 'components/StackPanel';
import { useIntl } from 'hooks';
import { InjectedIntlProps } from 'locale';
import Panel from 'pages/ProjectSettingsPage/Panel/Panel';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { smartFilter } from 'utils';
import MdOverheadProjectSettingsModal from './ProjectsInRealizationPohReportSettingsModal copy/MdOverheadProjectSettingsModal';
import ProjectsInRealizationPohReportSettingsModal from './ProjectsInRealizationPohReportSettingsModal/ProjectsInRealizationPohReportSettingsModal';

type Props = InjectedIntlProps & {
  organization: OrganizationAdminReportDto;
};

export enum OrganizationSettingsType {
  'ProjectInRealizationReport' = 'projectInRealizationReport',
  'MdOverheadProject' = 'mdOverheadProject',
}

type OrganizationSettingsDefinition = {
  type: OrganizationSettingsType;
  name: string;
  description: string;
};

const OrganizationSettingsListPanel: FunctionComponent<Props> = ({ organization }) => {
  const [search, setSearch] = useState<string>('');
  const [selectedSettings, setSelectedSettings] = useState<OrganizationSettingsType>();
  const { url } = useRouteMatch();
  const intl = useIntl();

  const organizationSettingsMap: OrganizationSettingsDefinition[] = useMemo(
    () =>
      [
        {
          type: OrganizationSettingsType.ProjectInRealizationReport,
          name: intl.formatMessage({ id: 'OrganizationSettingsListPanel.report.projectsInRealizationPOH.name' }),
          description: intl.formatMessage({
            id: 'OrganizationSettingsListPanel.report.projectsInRealizationPOH.description',
          }),
        },
        organization?.hasMdModule && {
          type: OrganizationSettingsType.MdOverheadProject,
          name: intl.formatMessage({ id: 'OrganizationSettingsListPanel.report.mdOverheadProject.name' }),
          description: intl.formatMessage({ id: 'OrganizationSettingsListPanel.report.mdOverheadProject.description' }),
        },
      ].filter(Boolean),
    [intl, organization]
  );

  const handleSettingsClose = useCallback(() => {
    setSelectedSettings(undefined);
  }, []);

  const clearSearch = useCallback(() => {
    setSearch('');
  }, []);

  return (
    <>
      <MasterComponent
        url={url}
        title={intl.formatMessage({ id: 'OrganizationSettings.title' })}
        children={(onSelect, selectedItemId) => (
          <StackPanel vertical scrollable>
            <Panel noMargin panelWidth="auto" onSearch={setSearch} searchValue={search}>
              <GeneralSettingsContainer>
                <List<OrganizationSettingsDefinition>
                  data={organizationSettingsMap}
                  search={search}
                  filterItem={(item) =>
                    (item && smartFilter(item?.name, search)) || smartFilter(item?.description, search)
                  }
                  renderItem={(item) => (
                    <GeneralSettingsItem
                      key={item.type}
                      title={item.name}
                      wrap
                      description={
                        !!item.description ? (
                          <Tooltip placement="topLeft" title={item.description}>
                            <Typography.Text ellipsis>{item.description}</Typography.Text>
                          </Tooltip>
                        ) : null
                      }
                      additionalActions={<>{<EditButton onClick={() => setSelectedSettings(item.type)} />}</>}
                    />
                  )}
                  renderEmpty={(total, filtered) => (
                    <ListEmpty filtered={filtered} total={total} onClearSearch={clearSearch} />
                  )}
                />
              </GeneralSettingsContainer>
              {selectedSettings === OrganizationSettingsType.ProjectInRealizationReport && (
                <ProjectsInRealizationPohReportSettingsModal
                  visible={selectedSettings === OrganizationSettingsType.ProjectInRealizationReport}
                  onSubmit={handleSettingsClose}
                  onClose={handleSettingsClose}
                  organizationId={organization.id}
                />
              )}
              {selectedSettings === OrganizationSettingsType.MdOverheadProject && (
                <MdOverheadProjectSettingsModal
                  visible={selectedSettings === OrganizationSettingsType.MdOverheadProject}
                  onSubmit={handleSettingsClose}
                  onClose={handleSettingsClose}
                  organization={organization}
                />
              )}
            </Panel>
          </StackPanel>
        )}
      />
    </>
  );
};

export default React.memo(OrganizationSettingsListPanel);
