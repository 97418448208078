import { EditOutlined, FileOutlined, FolderOutlined } from '@ant-design/icons';
import { Alert, Button, Modal, Tag } from 'antd';
import { projectApi } from 'api/completeApi';
import { DirectoryValidationMaskPatchDto } from 'api/completeApiInterfaces';
import { DeleteButtonConfirm } from 'components/ActionButtons/DeleteButtonConfirm';
import { ContentGate } from 'components/ContentGate/ContentGate';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import { FlowLayout } from 'components/layouts/FlowLayout';
import MaskInput from 'components/MaskInput/MaskInput';
import { MaskItemType } from 'components/MaskInput/MaskInput.types';
import { MaskItemSimpleDisplay } from 'components/MaskInput/MaskItemSimpleDisplay';
import SpinBoxCenter from 'components/SpinBoxCenter';
import { useApiData, useIntl } from 'hooks';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { messageError } from 'utils';

type Props = {
  directoryId: Guid;
  canEdit: boolean;
};

export const MaskInputModal: FunctionComponent<Props> = ({ directoryId, canEdit }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  const [
    directoryValidationMask,
    validationMaskError,
    validationMaskLoading,
    loadValidationMask,
    setDirectoryValidationMask,
  ] = useApiData((ct) => projectApi.directories.primary.id.validationMask.get(directoryId, ct), { autoload: false });

  const subdirectoryMaskItems: MaskItemType[] =
    (directoryValidationMask?.subdirectoryMask?.rules as MaskItemType[]) || [];

  const documentMask: MaskItemType[] = (directoryValidationMask?.documentMask?.rules as MaskItemType[]) || [];

  useEffect(() => {
    const loadData = async () => {
      const [err, data] = await projectApi.directories.primary.id.validationMask.get(directoryId);
      if (err) {
        setLoading(false);
        messageError(err, intl);
      } else if (!!data) {
        setDirectoryValidationMask(data.data);
        setLoading(false);
      }
    };
    loadData();
  }, [directoryId]);

  const [showModal, setShowModal] = useState<false | 'documentMask' | 'subdirectoryMask'>(false);

  const handleSaveChanges = async (values: MaskItemType[], modal: false | 'documentMask' | 'subdirectoryMask') => {
    if (!modal || !canEdit) return;
    setLoading(true);

    const dataDto: DirectoryValidationMaskPatchDto = {
      documentMask:
        modal === 'documentMask' ? (!!values?.length ? { rules: values } : null) : directoryValidationMask.documentMask,
      subdirectoryMask:
        modal === 'subdirectoryMask'
          ? !!values?.length
            ? { rules: values }
            : null
          : directoryValidationMask.subdirectoryMask,
    };

    const [err, data] = await projectApi.directories.primary.id.validationMask.patch(directoryId, dataDto);

    if (err) {
      messageError(err, intl);
    } else {
      setDirectoryValidationMask(data.data);
      setShowModal(false);
    }
    setLoading(false);
  };

  return (
    <ContentGate loading={validationMaskLoading} error={validationMaskError}>
      {directoryValidationMask?.isInherited ? (
        <>
          {!!(documentMask?.length || subdirectoryMaskItems?.length) && (
            <Alert
              type="warning"
              description={intl.formatMessage({ id: 'folderMasks.inheritedMasksFromParentFolder' })}
            />
          )}
        </>
      ) : (
        <GeneralSettingsItem
          disabled={!canEdit}
          title={intl.formatMessage({ id: 'folderMasks.deleteAndInherit.title' })}
          description={intl.formatMessage({ id: 'folderMasks.deleteAndInherit.description' })}
          input={
            !directoryValidationMask?.isInherited && (
              <DeleteButtonConfirm
                disabled={!canEdit}
                type="link"
                confirmTooltip={intl.formatMessage({ id: 'folderMasks.deleteAndInherit.confirm' })}
                onConfirm={() => {
                  projectApi.directories.primary.id.validationMask.delete(directoryId).then(([err, data]) => {
                    if (err) {
                      messageError(err, intl);
                    } else {
                      loadValidationMask();
                    }
                  });
                }}
              />
            )
          }
        />
      )}
      <GeneralSettingsItem
        disabled={!canEdit}
        icon={<FileOutlined />}
        title={intl.formatMessage({ id: 'folderMasks.fileNameMask' })}
        input={
          <Button
            disabled={!canEdit}
            icon={<EditOutlined />}
            type="link"
            onClick={() => {
              setShowModal('documentMask');
            }}
          />
        }
        description={
          documentMask?.length ? (
            <FlowLayout wrap>
              {documentMask.map((item) => (
                <Tag>
                  <MaskItemSimpleDisplay item={item} />
                </Tag>
              ))}
            </FlowLayout>
          ) : (
            intl.formatMessage({ id: 'folderMasks.noMasksSet' })
          )
        }
      />
      <GeneralSettingsItem
        disabled={!canEdit}
        icon={<FolderOutlined />}
        title={intl.formatMessage({ id: 'folderMasks.subfolderNameMask' })}
        input={
          <Button
            disabled={!canEdit}
            icon={<EditOutlined />}
            type="link"
            onClick={() => {
              setShowModal('subdirectoryMask');
            }}
          />
        }
        description={
          subdirectoryMaskItems?.length ? (
            <FlowLayout wrap>
              {subdirectoryMaskItems.map((item) => (
                <Tag>
                  <MaskItemSimpleDisplay item={item} />
                </Tag>
              ))}
            </FlowLayout>
          ) : (
            intl.formatMessage({ id: 'folderMasks.noMasksSet' })
          )
        }
      />
      {canEdit && (
        <Modal
          title={intl.formatMessage({ id: 'folderMasks.directoryValidationMask' })}
          maskClosable={false}
          width={800}
          footer={null}
          open={!!showModal}
          destroyOnClose
          onCancel={() => setShowModal(false)}
        >
          <SpinBoxCenter spinning={loading}>
            <MaskInput
              onCancel={() => setShowModal(false)}
              onSubmit={(values: MaskItemType[]) => handleSaveChanges(values, showModal)}
              values={showModal === 'documentMask' ? documentMask : subdirectoryMaskItems}
            />
          </SpinBoxCenter>
        </Modal>
      )}
    </ContentGate>
  );
};
