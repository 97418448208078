import { Radio, Tag, Tooltip } from 'antd';
import { MaskItemLettersType } from 'components/MaskInput/MaskInput.types';
import { useIntl } from 'hooks';
import { IntlMessageId } from 'locale/messages/cs';
import React, { FC } from 'react';

type ItemProps = Partial<MaskItemLettersType> & {
  id: string;
};

type Props = {
  item: ItemProps;
  onChange?: (item: ItemProps) => void;
};

export const parseCount = (
  count: string
): {
  count: number;
  otherCount?: number;
} => {
  const [from, to] = count.split('-').map((x) => parseInt(x));
  return to ? { count: from, otherCount: to } : { count: from };
};

export const CASE_MAP = {
  lower: 'ab',
  upper: 'AB',
  mixed: 'aB',
} as const;

export const MaskItemLetterConfigInput: FC<Props> = ({ item, onChange }: Props) => {
  const intl = useIntl();

  return (
    <>
      <span style={{ marginRight: '0.5rem' }}>
        <Radio.Group
          size="small"
          onChange={(value) => {
            onChange?.({
              id: item.id,
              case: value.target.value,
            });
          }}
          value={item.case}
          optionType="button"
          buttonStyle="solid"
        >
          {Object.entries(CASE_MAP).map(([key, value]) => (
            <Tooltip
              key={key}
              title={intl.formatMessage({ id: `folderMasks.MaskItem.letters.case.${key}` as IntlMessageId })}
            >
              <Radio.Button key={key} value={key}>
                {value}
              </Radio.Button>
            </Tooltip>
          ))}
        </Radio.Group>
      </span>

      <Tag.CheckableTag
        onChange={() => {
          onChange?.({
            id: item.id,
            allowNumbers: !item.allowNumbers,
          });
        }}
        checked={item.allowNumbers}
      >
        {intl.formatMessage({ id: 'folderMasks.MaskItem.letters.allowNumbers' })}
      </Tag.CheckableTag>
      <Tooltip title={intl.formatMessage({ id: `folderMasks.MaskItem.letters.allowSpecialCharacters.tooltip` })}>
        <Tag.CheckableTag
          onChange={() => {
            onChange?.({
              id: item.id,
              allowSpecialCharacters: !item.allowSpecialCharacters,
            });
          }}
          checked={item.allowSpecialCharacters}
        >
          {intl.formatMessage({ id: 'folderMasks.MaskItem.letters.allowSpecialCharacters' })}
        </Tag.CheckableTag>
      </Tooltip>
      <Tag.CheckableTag
        onChange={() => {
          onChange?.({
            id: item.id,
            whitespace: !item.whitespace,
          });
        }}
        checked={item.whitespace}
      >
        {intl.formatMessage({ id: 'folderMasks.MaskItem.letters.whitespace' })}
      </Tag.CheckableTag>
    </>
  );
};
