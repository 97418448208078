import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Input, InputRef, Select } from 'antd';
import PrimaryFileInput from 'components/PrimaryFileInput';
import { InjectedIntlProps } from 'locale';
import { Component, default as React, ReactNode } from 'react';
import { maxLengthRule, requiredRule } from 'utils/formHelpersCompatibility';

export type DiscussionRelationOption<K extends string | number> = {
  key: K;
  icon: ReactNode;
};

export type DiscussionAttachmentCreateFormData = {
  name: string;
  file: File;
  discussionRelation?: string | number;
};

type Props = FormComponentProps<DiscussionAttachmentCreateFormData> &
  InjectedIntlProps & {
    initialFile?: File;
    discussionRelationOptions?: DiscussionRelationOption<string | number>[];
    setRef: (ref: InputRef) => void;
  };

type State = {
  prevFileName: string;
};

class DiscussionAttachmentCreateFormComponent extends Component<Props, State> {
  constructor(props: Props, state: State) {
    super(props, state);
    this.state = {
      prevFileName: '',
    };
  }

  handleFileChange = (file: File) => {
    if (file) {
      const name = this.props.form.getFieldValue('name') || '';
      if (
        name === '' ||
        name === this.state.prevFileName ||
        (this.props.initialFile && name === this.props.initialFile.name)
      ) {
        this.props.form.setFieldsValue({ name: file.name });
        this.setState({ prevFileName: file.name });
      }
    }
  };

  render() {
    const { intl, form, initialFile, setRef } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Form layout="vertical">
        <Form.Item>
          {getFieldDecorator('file', {
            initialValue: initialFile,
            rules: [requiredRule('forms.items.primaryFile.rules.required')],
          })(<PrimaryFileInput onChange={this.handleFileChange} />)}
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'forms.items.name.label' })}>
          {getFieldDecorator<DiscussionAttachmentCreateFormData>('name', {
            initialValue: initialFile ? initialFile.name : '',
            rules: [
              requiredRule('DocumentCreateForm.form.items.name.rules.required', true),
              maxLengthRule('general.maxNameLength'),
            ],
          })(
            <Input
              placeholder={intl.formatMessage({ id: 'DocumentCreateForm.form.items.name.placeholder' })}
              autoFocus
              ref={setRef}
            />
          )}
        </Form.Item>
        {this.props.discussionRelationOptions?.length && (
          <Form.Item label={intl.formatMessage({ id: 'AttachmentCreateFormModal.form.item.relation.label' })}>
            {getFieldDecorator<DiscussionAttachmentCreateFormData>('discussionRelation', {
              initialValue: initialFile ? initialFile.name : '',
              rules: [
                requiredRule('DocumentCreateForm.form.items.relation.rules.required', true),
                maxLengthRule('general.maxNameLength'),
              ],
            })(
              <Select dropdownMatchSelectWidth={false}>
                {this.props.discussionRelationOptions.map((option) => (
                  <Select.Option key={option.key} value={option.key}>
                    {option.icon}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        )}
      </Form>
    );
  }
}

export const DiscussionAttachmentCreateForm = Form.create<Props>()(DiscussionAttachmentCreateFormComponent);
