import { Switch, SwitchProps } from 'antd';
import { masterApi } from 'api/completeApi';
import { MdProjectDto, MdProjectVariableEnum } from 'api/completeApiInterfaces';
import { useIntl, useSameCallback } from 'hooks';
import { Fmt } from 'locale';
import React, { useState } from 'react';
import { messageError } from 'utils';
import { JSONVariableTypeEnum } from '../MDProjectDataItem/MDProjectVariableData.types';

type Props = {
  mdProject: MdProjectDto;
  onProjectUpdate: (project: MdProjectDto) => void;
} & Omit<SwitchProps, 'onChange' | 'loading'>;

const MDSetCkPreparedSwitch: React.FC<Props> = ({ mdProject, onProjectUpdate, ...switchProps }) => {
  const intl = useIntl();
  const [isLoading, setLoading] = useState(false);

  const handleChange = useSameCallback(async (checked: boolean, event: React.MouseEvent<HTMLButtonElement>) => {
    setLoading(true);
    const [err, res] = await masterApi.projects.md.project.setdata.post({
      mdProjectId: mdProject.id,
      phase: mdProject.phase,
      insertOrUpdateData: {
        [MdProjectVariableEnum.preparedForCkApproval]: {
          type: JSONVariableTypeEnum.boolean,
          value: checked ? 'true' : 'false',
        },
      },
    });
    if (err) {
      messageError(err, intl);
      setLoading(false);
      return;
    }
    onProjectUpdate(res.data);
    setLoading(false);
  });

  return (
    <Switch
      checkedChildren={<Fmt id="MD.Projects.ProjectCkApprovalReadinesSetModal.title" />}
      unCheckedChildren={<Fmt id="MD.Projects.ProjectCkApprovalReadinesSetModal.title" />}
      defaultChecked={
        mdProject.projectData.find((data) => data.variable === MdProjectVariableEnum.preparedForCkApproval)?.data
          ?.value === 'true'
      }
      onChange={handleChange}
      loading={isLoading}
      {...switchProps}
    />
  );
};

export default MDSetCkPreparedSwitch;
