import { ExportOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import {
  MdProjectDataDto,
  MdProjectDto,
  MdProjectPhaseEnum,
  MdProjectVariableEnum,
  OrgUserClaimDto,
} from 'api/completeApiInterfaces';
import classNames from 'classnames';
import { Fmt } from 'locale';
import React from 'react';
import { Link } from 'react-router-dom';
import MDProjectLinkSelectorFormItem from '../MDProjectDataItem/Items/MDProjectLinkSelectorFormItem';
import MDVariableCheckboxFormItem from '../MDProjectDataItem/Items/MDVariableCheckboxFormItem';
import { MDVariableConditionsFormItem } from '../MDProjectDataItem/Items/MDVariableConditionsFormItem';
import MDVariableDatePickerFormItem from '../MDProjectDataItem/Items/MDVariableDatePickerFormItem';
import MDVariableDateRangePickerFormItem from '../MDProjectDataItem/Items/MDVariableDateRangePickerFormItem';
import MDVariableInputFormItem from '../MDProjectDataItem/Items/MDVariableInputFormItem';
import MDVariableNumberInputFormItem from '../MDProjectDataItem/Items/MDVariableNumberInputFormItem';
import { JSONVariableTypeEnum, MDLinkData } from '../MDProjectDataItem/MDProjectVariableData.types';
import MDRoleDisplay from '../MdRoleDisplay/MDRoleDisplay';
import styles from './MDProjectCardModal.module.less';

type Props = {
  mdProject: MdProjectDto;
  fields: Record<MdProjectPhaseEnum, MdProjectVariableEnum[]>;
  canEditAsO910?: boolean;
  canEditAsProposer?: boolean;
  canEditAsGuarantor?: boolean;
  canEditAsCKOrganizer?: boolean;
  extendedUserClaims?: OrgUserClaimDto[];
};

const renderFormItem = (
  mdProject: MdProjectDto,
  variableType: MdProjectVariableEnum,
  value: MdProjectDataDto,
  canEditAsO910?: boolean,
  canEditAsProposer?: boolean,
  canEditAsGuarantor?: boolean,
  canEditAsCKOrganizer?: boolean,
  extendedUserClaims: OrgUserClaimDto[] = []
) => {
  switch (variableType) {
    case MdProjectVariableEnum.projectName: {
      return (
        <MDVariableInputFormItem
          dataType={JSONVariableTypeEnum.string}
          disabled={!canEditAsProposer && !canEditAsGuarantor}
        />
      );
    }
    case MdProjectVariableEnum.constructionRegion: {
      return (
        <MDVariableInputFormItem
          dataType={JSONVariableTypeEnum.string}
          disabled={!canEditAsProposer && !canEditAsGuarantor}
        />
      );
    }
    case MdProjectVariableEnum.stretch: {
      return (
        <MDVariableInputFormItem
          dataType={JSONVariableTypeEnum.string}
          disabled={!canEditAsProposer && !canEditAsGuarantor}
        />
      );
    }
    case MdProjectVariableEnum.documentationLink: {
      const linkData = value.data as MDLinkData;
      if (!linkData) return null;
      return (
        <Link to={linkData.value}>
          {linkData.label} <ExportOutlined />
        </Link>
      );
    }
    case MdProjectVariableEnum.totalCost: {
      return (
        <MDVariableNumberInputFormItem
          dataType={JSONVariableTypeEnum.number}
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          precision={2}
          max={999999999999999}
          decimalSeparator=","
          controls={false}
          disabled={!canEditAsProposer && !canEditAsGuarantor}
          className={styles.priceInput}
          intlId={'MD.Projects.ProjectCardModal.input.price.suffix'}
        />
      );
    }
    case MdProjectVariableEnum.referenceNumber: {
      return <MDVariableInputFormItem dataType={JSONVariableTypeEnum.string} disabled={!canEditAsGuarantor} />;
    }
    case MdProjectVariableEnum.expectedRealisationTime: {
      return <MDVariableDateRangePickerFormItem disabled={!canEditAsProposer && !canEditAsGuarantor} />;
    }
    case MdProjectVariableEnum.commentProcedure: {
      // workflow url
      const linkData = value.data as MDLinkData;
      if (!linkData) return null;
      return (
        <Link to={linkData.value}>
          {linkData.label} <ExportOutlined />
        </Link>
      );
    }
    case MdProjectVariableEnum.ckMeetingBackground: {
      // podklady pro jednání centrální komise (document url)
      return (
        mdProject.project && (
          <MDProjectLinkSelectorFormItem
            linkType="document"
            projectId={mdProject.project?.id}
            disabled={!canEditAsCKOrganizer}
          />
        )
      );
    }
    case MdProjectVariableEnum.ckMeetingMinutes: {
      // zápis z jednání centrální komise (directory url)
      return (
        mdProject.project && (
          <MDProjectLinkSelectorFormItem
            linkType="directory"
            projectId={mdProject.project?.id}
            disabled={!canEditAsCKOrganizer}
          />
        )
      );
    }
    case MdProjectVariableEnum.ckApprovalDate: {
      return <MDVariableDatePickerFormItem disabled={!canEditAsCKOrganizer} />;
    }
    case MdProjectVariableEnum.ckApprovalConditions: {
      return (
        <MDVariableConditionsFormItem
          canEditAsCKOrganizer={canEditAsCKOrganizer}
          canEditAsGuarantor={canEditAsGuarantor}
        />
      );
    }
    case MdProjectVariableEnum.ckApproved: {
      return <MDVariableCheckboxFormItem disabled={!canEditAsCKOrganizer} />;
    }
    case MdProjectVariableEnum.preparationUpdate: {
      return <MDVariableCheckboxFormItem disabled={!canEditAsProposer && !canEditAsGuarantor} />;
    } // Aktualizace Přípravy projektu
    case MdProjectVariableEnum.investorContactPerson: {
      return (
        <MDVariableInputFormItem
          dataType={JSONVariableTypeEnum.string}
          disabled={!canEditAsProposer && !canEditAsGuarantor}
        />
      );
    } // Kontaktní osoba investora
    case MdProjectVariableEnum.studyType: {
      return (
        <MDVariableInputFormItem
          dataType={JSONVariableTypeEnum.string}
          disabled={!canEditAsProposer && !canEditAsGuarantor}
        />
      );
    }
    case MdProjectVariableEnum.actionCharacter: {
      return (
        <MDVariableInputFormItem
          dataType={JSONVariableTypeEnum.string}
          disabled={!canEditAsProposer && !canEditAsGuarantor}
        />
      );
    }

    case MdProjectVariableEnum.hasOpponentsReport: {
      return <MDVariableCheckboxFormItem disabled={!canEditAsProposer && !canEditAsGuarantor} />;
    }
    case MdProjectVariableEnum.opponentsReport: {
      return (
        mdProject.project && (
          <MDProjectLinkSelectorFormItem
            linkType="document"
            projectId={mdProject.project?.id}
            disabled={!canEditAsProposer && !canEditAsGuarantor}
          />
        )
      );
    }
    case MdProjectVariableEnum.isUPDChangeRequest: {
      // Projekt vyžaduje změnu vymezení v ÚPD
      return <MDVariableCheckboxFormItem disabled={!canEditAsCKOrganizer} />;
    }
    case MdProjectVariableEnum.intentionUpdated: {
      // Aktualizace ZP
      return <MDVariableCheckboxFormItem disabled={!canEditAsProposer && !canEditAsGuarantor} />;
    }
    case MdProjectVariableEnum.mdContactPerson: {
      if (!mdProject.guarantor) return null;

      return <MDRoleDisplay mdRole={mdProject.guarantor} extendedUserClaim={extendedUserClaims} />;
    }
    case MdProjectVariableEnum.isProFond: {
      return (
        <MDVariableInputFormItem
          dataType={JSONVariableTypeEnum.number}
          disabled={!canEditAsProposer && !canEditAsGuarantor}
        />
      );
    }
  }
  return null;
};

const MDProjectCardVariableFields: React.FC<Props> = ({
  mdProject,
  fields,
  canEditAsO910,
  canEditAsProposer,
  canEditAsGuarantor,
  canEditAsCKOrganizer,
  extendedUserClaims,
}) => {
  if (!fields[mdProject.phase]) return null;

  const headerFields = fields[mdProject.phase].map(
    (field): MdProjectDataDto => ({
      phase: mdProject.phase,
      variable: field,
      data: mdProject.projectData.find((data) => data.variable === field)?.data,
    })
  );

  return (
    <div className={styles.mdFormSectionWrapper}>
      <div className={classNames(styles.mdFormItem)}>
        {headerFields.map((field) => (
          <div key={field.variable} className={classNames(styles.mdFormItem)}>
            <Form.Item
              label={<Fmt id={`MD.ProjectVariableEnum.${field.variable}.name`} />}
              labelAlign="left"
              name={field.variable}
              initialValue={field.data}
            >
              {renderFormItem(
                mdProject,
                field.variable,
                field,
                canEditAsO910,
                canEditAsProposer,
                canEditAsGuarantor,
                canEditAsCKOrganizer,
                extendedUserClaims
              )}
            </Form.Item>
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(MDProjectCardVariableFields);
