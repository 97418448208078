import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import classNames from 'classnames';
import { MaskItemType } from 'components/MaskInput/MaskInput.types';
import { MaskItemLabel } from 'components/MaskInput/MaskItemLabel';
import { MaskTag } from 'components/MaskInput/MaskTag';
import React, { FC, ReactNode } from 'react';
import { mergeProps, useButton, useDrag } from 'react-aria';
import styles from './Draggable.module.less';

type Props = {
  children?: ReactNode;
  value: Pick<MaskItemType, 'type' | 'id'>;
  onAdd: (temType: MaskItemType['type']) => void;
};

export const Draggable: FC<Props> = ({ children, value, onAdd }) => {
  const { dragProps, dragButtonProps, isDragging } = useDrag({
    getAllowedDropOperations: () => ['copy'],

    getItems() {
      return [
        {
          'text/plain': value.type,
          'my-app-custom-type': JSON.stringify(value),
        },
      ];
    },
  });

  const ref = React.useRef(null);
  const { buttonProps } = useButton({ ...dragButtonProps, elementType: 'div' }, ref);

  return (
    <MaskTag
      {...mergeProps(dragProps, buttonProps)}
      innerRef={ref}
      className={classNames(styles.draggable, isDragging && styles.dragging)}
    >
      <MaskItemLabel item={value} />
      {children}

      <div style={{ marginLeft: 'auto' }}></div>
      <Button onClick={() => onAdd(value.type)} size="small" type="link" icon={<PlusOutlined />} />
    </MaskTag>
  );
};
