import '@ant-design/compatible/assets/index.css';
import { DatePicker } from 'antd';

import { RangePickerProps } from 'antd/es/date-picker';
import { DateRangeValue } from 'components/InlineDateRangePicker/InlineDateRangePicker';
import moment from 'moment';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { JSONVariableTypeEnum, MDDateRange } from '../MDProjectVariableData.types';
import styles from './MDVariableDateRangePickerFormItem.module.less';

type Props = {
  value?: MDDateRange;
  onChange?: (value: MDDateRange) => void;
} & Omit<RangePickerProps, 'value' | 'onChange'>;

const MDVariableDateRangePickerFormItem: FunctionComponent<Props> = ({ value, onChange, ...restProps }) => {
  const handleChange = useCallback(
    (dates: DateRangeValue) => {
      onChange &&
        onChange(
          dates && {
            type: JSONVariableTypeEnum.interval,
            valueFrom: dates[0]?.startOf('month').toISOString(),
            valueTo: dates[1]?.endOf('month').toISOString(),
          }
        );
    },
    [onChange]
  );
  const rangeValue = useMemo((): DateRangeValue => value && [moment(value.valueFrom), moment(value.valueTo)], [value]);
  return (
    <DatePicker.RangePicker
      onChange={handleChange}
      value={rangeValue}
      {...restProps}
      picker="month"
      popupClassName={styles.responsiveCalendar}
    />
  );
};

export default React.memo(MDVariableDateRangePickerFormItem);
