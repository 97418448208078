import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Input } from 'antd';
import { apiConstraints } from 'api/completeApiConstraints';
import { DirectoryListDto } from 'api/completeApiInterfaces';
import { DirectoriesTreeSelectFormItem } from 'components/DirectoriesTreeSelect/DirectoriesTreeSelect';
import DocumentMultipleActionError from 'components/DocumentMultipleActionError';
import PathDisplay from 'components/PathDisplay';
import { Fmt, InjectedIntlProps } from 'locale';
import { canWriteInDirectory } from 'pages/AllDocumentsPage/AllDocumentsPage';
import React, { useEffect } from 'react';
import { maxLengthRule, requiredRule } from 'utils/formHelpersCompatibility';
import styles from './DocumentLinksCreateForm.module.less';

export type DocumentsLinksCreateFormData = {
  destinationDirectory: Guid;
  documentLinkName: string;
};

type Props = FormComponentProps<DocumentsLinksCreateFormData> &
  InjectedIntlProps & {
    selectedDocumentsCount: number;
    defaultDocumentLinkName?: string;
    selectedDirectory: DirectoryListDto;
    destinationDirectory: Guid;
    errors: JSX.Element[];
  };

const DocumentLinksCreateForm = React.forwardRef<unknown, Props>(
  (
    { intl, form, selectedDirectory, destinationDirectory, selectedDocumentsCount, defaultDocumentLinkName, errors },
    ref
  ) => {
    // backward compatibility with class components
    useEffect(() => {
      (ref as any).current = { props: { form } };
    }, [form]);

    return (
      <Form layout="horizontal" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
        <div className={styles.documentLinkWrap}>
          <div className={styles.movingDocumentsTitle}>
            {<Fmt id="DocumentLinksCreateForm.bindingsToDocuments" values={{ count: selectedDocumentsCount }} />}
          </div>
          {<PathDisplay path={selectedDirectory.path} className={styles.sourceDocumentsPath} />}
        </div>
        {selectedDocumentsCount === 1 && (
          <Form.Item label={intl.formatMessage({ id: 'DocumentLinksCreateForm.documentLinkName.label' })}>
            {form.getFieldDecorator<DocumentsLinksCreateFormData>('documentLinkName', {
              initialValue: defaultDocumentLinkName,
              rules: [
                requiredRule('forms.items.rules.noEmpty'),
                maxLengthRule('general.maxNameLength', apiConstraints.documentLinkCreateDto.name.maxLength),
              ],
            })(<Input />)}
          </Form.Item>
        )}
        <Form.Item
          className={styles.item}
          label={intl.formatMessage({ id: 'DocumentLinksCreateForm.destinationDirectoryId.label' })}
        >
          {form.getFieldDecorator<DocumentsLinksCreateFormData>('destinationDirectory', {
            initialValue: destinationDirectory,
            rules: [requiredRule('forms.items.rules.noEmpty')],
          })(
            <DirectoriesTreeSelectFormItem
              isItemDisabled={(item) => !canWriteInDirectory(item)}
              autoFocus
              dropdownStyle={{ maxHeight: 400, overflow: 'auto', maxWidth: 300 }}
              placeholder={intl.formatMessage({ id: 'DirectoryMoveFormModal.destinationDirectoryId.placeholder' })}
            />
          )}
        </Form.Item>
        <DocumentMultipleActionError errors={errors} titleId="DocumentLinksCreateForm.errors" />
      </Form>
    );
  }
);

export default Form.create<Props>()(DocumentLinksCreateForm);
