import { DownOutlined, ExportOutlined, ImportOutlined } from '@ant-design/icons';
import { Button, ButtonProps, Checkbox, Collapse, Dropdown, MenuProps, Modal, Space, Typography, message } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { projectApi } from 'api/completeApi';
import {
  DirectoryDetailDto,
  ProjectMetadataDefinitionMetadataEntityType,
  ProjectMetadataDocumentImportDto,
  ProjectMetadataImportDto,
  ProjectMetadataImportResultDto,
  ProjectMetadataValueDto,
} from 'api/completeApiInterfaces';
import { directoriesApi } from 'api/project/directories/directoriesApi';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { Margin } from 'components/Margin/Margin';
import SingleFileInput from 'components/PrimaryFileInput/SingleFileInput';
import { COMMON_SETTING_MODAL_WIDTH, DROPDOWN_TRIGGER_CLICK } from 'config/constants';
import { useApiData, useBoolean, useIntl, useIsMounted, useSameCallback } from 'hooks';
import { Fmt } from 'locale';
import { Dictionary, isEqual, trim } from 'lodash';
import moment from 'moment';
import React, { FunctionComponent, useCallback, useMemo, useRef, useState } from 'react';
import { messageError } from 'utils';
import { downloadBlob } from 'utils/downloadFile';

const { Panel } = Collapse;

type Props = {
  orderedItems: ProjectMetadataValueDto[];
  metadataLoading: boolean;
  canEdit?: boolean;
  currentDirectory: DirectoryDetailDto;
  loadDirectoryMetadata: () => void;
};

enum ImportFailReasonsEnum {
  metadataKeyNotFound = 'metadataKeyNotFound',
  ignoreExisting = 'ignoreExisting',
  documentNotFound = 'documentNotFound',
  documentHasOwner = 'documentHasOwner',
}

const hasAnyDirectoryMetadataImportFail = (notImportedDirectoryKeys?: Dictionary<string[]>) => {
  if (!notImportedDirectoryKeys) return false;
  return Object.values(notImportedDirectoryKeys).some((value) => !!value.length);
};
const hasAnyDdocumentMetadataImportFail = (notImportedDocumentKeys?: Dictionary<Dictionary<string[]>>) => {
  if (!notImportedDocumentKeys) return false;
  return Object.values(notImportedDocumentKeys).some((value) => !isEqual(value, {}));
};

export const MetadataExportImportButton: FunctionComponent<Props> = React.memo(
  ({ orderedItems, metadataLoading, loadDirectoryMetadata, canEdit, currentDirectory }) => {
    const intl = useIntl();
    const isMountedRef = useIsMounted();
    const [loading, setLoading] = useState<boolean>(false);
    const [uploadVisible, showUpload, hideUpload] = useBoolean(false);
    const [directoryMetadata, setDirectoryMetadata] = useState<Dictionary<string>>();
    const [documentsMetadata, setDocumentsMetadata] = useState<ProjectMetadataDocumentImportDto[]>();
    const [failImportResult, setFailImportResult] = useState<ProjectMetadataImportResultDto>();
    const [replaceExisting, setReplaceExisting] = useState<boolean>(false);
    const [projectMetadata] = useApiData((ct) => projectApi.metadata.definitions.get(ct), { autoload: true });

    const hasDocsMetadatas = useMemo(
      () =>
        !!projectMetadata?.definitions.find(
          (definition) => definition.type === ProjectMetadataDefinitionMetadataEntityType.document
        ),
      [projectMetadata]
    );

    const handleExport = useSameCallback(async () => {
      setLoading(true);

      const [err, resp] = await directoriesApi.exportDirectoryMetadata(currentDirectory.id);
      if (err) {
        messageError(err, intl);
        setLoading(false);
        return null;
      }
      const exportFileName = `${currentDirectory.name.replace(/[/\\:*?"<>]/g, '')}_DIRECTORY_METADATA_${moment().format(
        'YYYY-MM-DD-hh:mm:ss'
      )}.csv`;
      if (!!resp.data) {
        const blob = new Blob([resp.data], { type: '"text/csv"' });
        downloadBlob(blob, exportFileName);
      }

      setLoading(false);
      return null;
    });

    const menu = useMemo(
      (): MenuProps => ({
        items: [
          {
            label: (
              <CommonHubTooltip
                title={
                  !orderedItems.length
                    ? intl.formatMessage({ id: 'MetadataExportImportButton.menu.export.noData' })
                    : null
                }
              >
                {intl.formatMessage({ id: 'general.export' })}
              </CommonHubTooltip>
            ),
            key: 'Export',
            icon: <ExportOutlined />,
            disabled: !orderedItems.length && !hasDocsMetadatas,
            onClick: handleExport,
          },
          {
            label: (
              <CommonHubTooltip
                title={!canEdit ? intl.formatMessage({ id: 'MetadataExportImportButton.menu.import.noAccess' }) : null}
              >
                {intl.formatMessage({ id: 'general.import' })}
              </CommonHubTooltip>
            ),
            key: 'Import',
            icon: <ImportOutlined />,
            disabled: !canEdit,
            onClick: () => showUpload(),
          },
        ],
      }),
      [canEdit, handleExport, hasDocsMetadatas, intl, orderedItems.length, showUpload]
    );

    const onInvalidFileDrop = useCallback(() => {
      messageError('MetadataExportImportButton.csvFilesOnly', intl);
    }, [intl]);

    const clearStates = () => {
      setDirectoryMetadata(undefined);
      setDocumentsMetadata(undefined);
      setFailImportResult(undefined);
      setReplaceExisting(false);
      setLoading(false);
    };

    const activeFileReaderRef = useRef<FileReader>();

    const handleMetadataImport = useCallback(
      (value: File) => {
        clearStates();
        if (value) {
          const fileExt = value.name.split('.').pop();

          if (fileExt !== 'csv') {
            onInvalidFileDrop();
            return;
          }
          setLoading(true);
          activeFileReaderRef.current?.abort();
          activeFileReaderRef.current = new FileReader();
          activeFileReaderRef.current.readAsText(value);
          activeFileReaderRef.current.onload = (event) => {
            if (!isMountedRef.current) return;
            setLoading(false);
            const { result } = event.target;
            const allTextLines = (result as string).split(/\r?\n|\r|\n/g);
            const directoryMetadata: Dictionary<string> = {};
            const documentMetadata: ProjectMetadataDocumentImportDto[] = [];

            allTextLines.forEach((line) => {
              const lineArray = line.split(';').map((i) => trim(i, '"'));
              if (lineArray[0] === 'Directory') {
                directoryMetadata[lineArray[2]] = lineArray[3];
              }
              if (lineArray[0] === 'Document') {
                const existDoc = documentMetadata.find((data) => data.documentName === lineArray[1]);
                if (!!existDoc) {
                  existDoc.metadataValues[lineArray[2]] = lineArray[3];
                } else {
                  const meta = { [lineArray[2]]: lineArray[3] };
                  documentMetadata.push({ documentName: lineArray[1], metadataValues: meta });
                }
              }
            });
            setDirectoryMetadata(!!directoryMetadata ? directoryMetadata : undefined);
            setDocumentsMetadata(!!documentMetadata.length ? documentMetadata : undefined);
          };
          setLoading(false);
        }
      },
      [isMountedRef, onInvalidFileDrop]
    );

    const onImportSubmit = useCallback(async () => {
      const importData: ProjectMetadataImportDto = {
        replaceExisting,
        directoryMetadata,
        documentsMetadata,
      };
      if (!directoryMetadata && !documentsMetadata?.length) {
        onInvalidFileDrop();
        return;
      }
      setLoading(true);
      const [err, resp] = await directoriesApi.importDirectoryMetadata(currentDirectory.id, importData);
      if (err) {
        messageError(err, intl);
        clearStates();
        hideUpload();
        return null;
      }
      if (
        hasAnyDirectoryMetadataImportFail(resp.data.notImportedDirectoryKeys) ||
        hasAnyDdocumentMetadataImportFail(resp.data.notImportedDocumentKeys)
      ) {
        setFailImportResult(resp.data);
        setLoading(false);
        loadDirectoryMetadata();
        return null;
      }
      void message.success(intl.formatMessage({ id: 'MetadataExportImportButton.importSuccess' }));
      loadDirectoryMetadata();
      clearStates();
      hideUpload();
    }, [
      currentDirectory.id,
      directoryMetadata,
      documentsMetadata,
      hideUpload,
      intl,
      loadDirectoryMetadata,
      onInvalidFileDrop,
      replaceExisting,
    ]);

    const handleCheckboxChange = useSameCallback((e: CheckboxChangeEvent) => setReplaceExisting(e.target.checked));

    const failReasonText = useCallback(
      (reasonValue: ImportFailReasonsEnum) => {
        return reasonValue in ImportFailReasonsEnum
          ? intl.formatMessage({ id: `MetadataExportImportButton.importFailReason.${reasonValue}` })
          : intl.formatMessage({ id: 'MetadataExportImportButton.importFailReason.unexpectedError' });
      },
      [intl]
    );

    const directoryFailsContent = useMemo(() => {
      if (!failImportResult?.notImportedDirectoryKeys) return null;
      const content = [];
      for (const [failReason, dirProperties] of Object.entries(failImportResult?.notImportedDirectoryKeys)) {
        if (!!dirProperties.length) {
          content.push(
            <Collapse key={failReason}>
              <Panel header={failReasonText(failReason as ImportFailReasonsEnum)} key={failReason}>
                {dirProperties.map((item) => (
                  <div key={item}>{item}</div>
                ))}
              </Panel>
            </Collapse>
          );
        }
      }
      return !!content.length ? content : null;
    }, [failImportResult?.notImportedDirectoryKeys, failReasonText]);

    const documentsFailsContent = useMemo(() => {
      if (!failImportResult?.notImportedDocumentKeys) return null;
      const content = [];
      for (const [failReason, documentHashMap] of Object.entries(failImportResult?.notImportedDocumentKeys)) {
        if (!!documentHashMap) {
          const documentValues = [];
          for (const [documentName, failedPropertyKeys] of Object.entries(documentHashMap)) {
            if (!!failedPropertyKeys.length)
              documentValues.push(<div key={documentName}>{`${documentName} - ${failedPropertyKeys}`}</div>);
          }
          !!documentValues.length &&
            content.push(
              <Collapse>
                <Panel header={failReasonText(failReason as ImportFailReasonsEnum)} key={failReason}>
                  {documentValues}
                </Panel>
              </Collapse>
            );
        }
      }
      return !!content.length ? content : null;
    }, [failImportResult?.notImportedDocumentKeys, failReasonText]);

    const importModalOkProps: ButtonProps = useMemo(
      () => ({
        loading: loading,
        disabled: !!documentsFailsContent || !!directoryFailsContent,
      }),
      [directoryFailsContent, documentsFailsContent, loading]
    );

    return (
      <>
        <Dropdown menu={menu} trigger={DROPDOWN_TRIGGER_CLICK}>
          <Button shape="round" disabled={metadataLoading}>
            <Space>
              Export/Import
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
        {canEdit && (
          <Modal
            key="fileImport"
            open={uploadVisible}
            onOk={onImportSubmit}
            onCancel={() => {
              hideUpload();
              clearStates();
            }}
            okButtonProps={importModalOkProps}
            title={<Fmt id="MetadataExportImportButton.modalTitle" />}
            confirmLoading={loading}
            width={COMMON_SETTING_MODAL_WIDTH}
            destroyOnClose
          >
            <Margin bottom>
              <Typography.Text>
                <Fmt id="MetadataExportImportButton.description" />
              </Typography.Text>
            </Margin>
            <SingleFileInput
              acceptedContentType={[
                'text/csv',
                'application/csv',
                'text/comma-separated-values',
                'application/excel',
                'application/vnd.ms-excel',
                'application/vnd.msexcel',
              ]}
              onInvalidFileDrop={onInvalidFileDrop}
              onChange={handleMetadataImport}
            />
            {(!!directoryMetadata || !!documentsMetadata) && (
              <Margin top bottom>
                <Checkbox checked={replaceExisting} onChange={handleCheckboxChange}>
                  {intl.formatMessage({ id: 'MetadataExportImportButton.import.replaceExisting' })}
                </Checkbox>
              </Margin>
            )}

            {(!!documentsFailsContent || !!directoryFailsContent) && (
              <Collapse>
                {!!directoryFailsContent && (
                  <Panel
                    header={intl.formatMessage({ id: 'MetadataExportImportButton.import.directoryFails.common' })}
                    key="directoryFails"
                  >
                    {directoryFailsContent}
                  </Panel>
                )}
                {!!documentsFailsContent && (
                  <Panel
                    header={intl.formatMessage({ id: 'MetadataExportImportButton.import.documentsFails.common' })}
                    key="documentsFails"
                  >
                    {documentsFailsContent}
                  </Panel>
                )}
              </Collapse>
            )}
          </Modal>
        )}
      </>
    );
  }
);
