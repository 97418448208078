import { MdProjectVariableEnum } from 'api/completeApiInterfaces';
import { SPACE_FOR_SCROLLBAR_COLUMN, buildingsGridDateFormatter } from 'components/BudgetGrid/BudgetGrid';
import isMobile from 'is-mobile';
import { InjectedIntl } from 'locale';
import { ColumnType } from './MDApprovalProjectsReport';

//Grid columns
export const getApprovalProjectsColumns = (intl: InjectedIntl): ColumnType[] => {
  const isMobileView = isMobile();
  return [
    {
      caption: intl.formatMessage({ id: 'MDApprovedProjectsReport.actionName' }),
      width: isMobileView ? 100 : 200,
      dataField: 'actionName',
      fixed: true,
      allowHiding: false,
      dataType: 'string',
      allowHeaderFiltering: false,
    },
    {
      caption: intl.formatMessage({ id: 'MDApprovedProjectsReport.projectPhase' }),
      width: 100,
      dataField: 'projectPhase',
      fixed: true,
      dataType: 'string',
      allowFiltering: false,
      allowHeaderFiltering: true,
    },
    {
      caption: intl.formatMessage({ id: 'MD.ProjectVariableEnum.isProFond.name' }),
      width: 100,
      dataType: 'string',
      dataField: MdProjectVariableEnum.isProFond,
      allowHeaderFiltering: false,
    },
    {
      caption: intl.formatMessage({ id: 'MD.ProjectVariableEnum.actionCharacter.name' }),
      width: isMobileView ? 100 : 200,
      dataType: 'string',
      dataField: MdProjectVariableEnum.actionCharacter,
      allowHeaderFiltering: false,
    },
    {
      caption: intl.formatMessage({ id: 'MD.ProjectVariableEnum.ckIdentificaion.name' }),
      width: isMobileView ? 100 : 150,
      dataField: MdProjectVariableEnum.ckIdentificaion,
      dataType: 'string',
      allowFiltering: false,
      allowHeaderFiltering: true,
    },
    {
      caption: intl.formatMessage({ id: 'MD.ProjectVariableEnum.constructionRegion.name' }),
      width: isMobileView ? 100 : 200,
      dataField: MdProjectVariableEnum.constructionRegion,
      allowHeaderFiltering: false,
      dataType: 'string',
    },
    {
      caption: intl.formatMessage({ id: 'MD.ProjectVariableEnum.stretch.name' }),
      width: 100,
      dataField: MdProjectVariableEnum.stretch,
      allowHeaderFiltering: false,
      dataType: 'string',
    },
    {
      caption: intl.formatMessage({ id: 'MD.ProjectVariableEnum.totalCost.name' }),
      width: isMobileView ? 100 : 200,
      dataField: MdProjectVariableEnum.totalCost,
      dataType: 'number',
      cellTemplate: 'totalCost',
      allowHeaderFiltering: false,
    },
    {
      caption: intl.formatMessage({ id: 'MD.ProjectVariableEnum.expectedRealisationTime.from.name' }),
      width: 120,
      dataField: 'expectedRealisationTimeFrom',
      dataType: 'date',
      cellTemplate: 'expectedRealisationTimeFrom',
      allowHeaderFiltering: false,
    },
    {
      caption: intl.formatMessage({ id: 'MD.ProjectVariableEnum.expectedRealisationTime.to.name' }),
      width: 120,
      dataField: 'expectedRealisationTimeTo',
      dataType: 'date',
      cellTemplate: 'expectedRealisationTimeTo',
      allowHeaderFiltering: false,
    },
    {
      caption: intl.formatMessage({ id: 'MD.ProjectVariableEnum.preparedForCkApproval.name' }),
      width: isMobileView ? 100 : 200,
      dataField: MdProjectVariableEnum.preparedForCkApproval,
      dataType: 'boolean',
      cellTemplate: 'preparedForCkApproval',
      allowFiltering: false,
      allowHeaderFiltering: true,
    },

    {
      caption: intl.formatMessage({ id: 'MD.ProjectVariableEnum.ckApprovalDate.name' }),
      width: 100,
      dataField: MdProjectVariableEnum.ckApprovalDate,
      dataType: 'date',
      customizeText: buildingsGridDateFormatter,
      cellTemplate: 'ckApprovalDate',
      allowHeaderFiltering: false,
    },
    {
      caption: intl.formatMessage({ id: 'MD.ProjectVariableEnum.ckApprovalConditions.name' }),
      width: 100,
      dataField: 'ckApprovalConditionsText',
      dataType: 'string',
      cellTemplate: 'ckApprovalConditions',
      allowFiltering: false,
      allowHeaderFiltering: true,
    },
    {
      caption: intl.formatMessage({ id: 'MDApprovedProjectsReport.termExpired' }),
      width: 100,
      dataField: 'isAfterDeadline',
      dataType: 'boolean',
      cellTemplate: 'booleanToIconAfterDeadline',
      allowFiltering: false,
      allowHeaderFiltering: true,
    },
    {
      caption: intl.formatMessage({ id: 'MDApprovedProjectsReport.lastActualizationDate' }),
      width: 100,
      dataField: 'lastActualizationDate',
      dataType: 'date',
      customizeText: buildingsGridDateFormatter,
      cellTemplate: 'ckApprovalDate',
      sortOrder: 'desc',
      allowHeaderFiltering: false,
    },
    {
      caption: intl.formatMessage({ id: 'general.Current' }),
      width: 100,
      dataField: 'isUpToDate',
      dataType: 'boolean',
      allowFiltering: false,
      allowHeaderFiltering: true,
      cellTemplate: 'booleanToIconUpToDate',
    },
    SPACE_FOR_SCROLLBAR_COLUMN,
  ];
};
