import { useCallback, useEffect, useRef, useState } from 'react';
import { assert } from 'utils/assert';

export function useHeight() {
  const tableWrapRef = useRef<HTMLDivElement>(null);
  const [wrapHeight, setWrapHeight] = useState(1);
  const [wrapWidth, setWrapWidth] = useState(undefined);

  const onResize = useCallback(() => {
    if (!tableWrapRef.current) return;
    const height = tableWrapRef.current.offsetHeight;
    const width = tableWrapRef.current.offsetWidth;
    setWrapHeight(Math.floor(height));
    setWrapWidth(Math.floor(width));
  }, []);

  useEffect(() => {
    assert(!!tableWrapRef.current, 'tableWrapRef.current is null');
    onResize();

    const observer = new ResizeObserver(onResize);

    if (tableWrapRef.current) {
      observer.observe(tableWrapRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [onResize]);

  return {
    onResize,
    tableWrapRef,
    wrapHeight,
    wrapWidth,
  };
}
