import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Alert, Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { ProjectBaseListDto } from 'api/completeApiInterfaces';
import { InjectedIntlProps } from 'locale';
import React, { useEffect, useMemo } from 'react';
import { smartFilter } from 'utils';

export type MdOverheadProjectSettingsFormData = {
  projectId: Guid;
};

type Props = FormComponentProps<MdOverheadProjectSettingsFormData> &
  InjectedIntlProps & {
    defaultProjectId: Guid;
    organizationId: Guid;
    projects: ProjectBaseListDto[];
  };

const propertyFilter = (input: string, option: DefaultOptionType) => {
  return smartFilter(option.label?.toString() || option.children?.toString(), input);
};

const MdOverheadProjectSettingsFormComponent = React.forwardRef<unknown, Props>(
  ({ intl, form, defaultProjectId, projects }, ref) => {
    // backward compatibility with class components
    useEffect(() => {
      (ref as any).current = { props: { form } };
    }, [form]);

    const projectOptions = useMemo((): DefaultOptionType[] => {
      return projects.map((project) => ({
        label: project.name,
        value: project.id,
      }));
    }, [projects]);

    return (
      <Form layout="vertical">
        <Form.Item label={intl.formatMessage({ id: 'MD.OverheadProjectSettingsForm.overheadProject.label' })}>
          {form.getFieldDecorator<MdOverheadProjectSettingsFormData>('projectId', {
            initialValue: defaultProjectId,
            rules: [
              {
                required: true,
                message: intl.formatMessage({ id: 'MD.OverheadProjectSettingsForm.overheadProject.label.required' }),
              },
            ],
          })(<Select options={projectOptions} showSearch filterOption={propertyFilter} />)}
        </Form.Item>
        <Alert
          message={intl.formatMessage({ id: 'MD.OverheadProjectSettingsForm.overheadProject.alert' })}
          type="warning"
          showIcon
        />
      </Form>
    );
  }
);

export const MdOverheadProjectSettingsForm = React.memo(Form.create<Props>()(MdOverheadProjectSettingsFormComponent));
