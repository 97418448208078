import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Input } from 'antd';
import { apiConstraints } from 'api/completeApiConstraints';
import { ProjectMetadataDefinitionDto } from 'api/completeApiInterfaces';
import { InjectedIntlProps } from 'locale';
import React, { useEffect, useMemo } from 'react';
import { duplicateNameRule, maxLengthRule, requiredRule } from 'utils/formHelpersCompatibility';

export type MetadataFormData = {
  name: string;
};

type Props = FormComponentProps<MetadataFormData> &
  InjectedIntlProps & {
    selectedMetadataItem?: ProjectMetadataDefinitionDto;
    usedItems: ProjectMetadataDefinitionDto[];
  };

const MetadataFormComponent = React.forwardRef<unknown, Props>(
  ({ intl, form, selectedMetadataItem: metadataItem, usedItems = [] }, ref) => {
    // backward compatibility with class components
    useEffect(() => {
      (ref as any).current = { props: { form } };
    }, [form]);

    const namesToCompare = useMemo(() => {
      return usedItems.filter((used) => used.name !== metadataItem?.name).map((item) => item.name);
    }, [usedItems]);

    // render
    return (
      <Form layout="vertical">
        <Form.Item label={intl.formatMessage({ id: 'general.name' })}>
          {form.getFieldDecorator<MetadataFormData>('name', {
            rules: [
              requiredRule('forms.items.rules.required', true),
              maxLengthRule('general.maxNameLength', apiConstraints.projectMetadataDefinitionCreateDto.name.maxLength),
              duplicateNameRule('forms.items.name.rules.nameExists', namesToCompare, true),
            ],
            initialValue: metadataItem?.name,
          })(<Input maxLength={apiConstraints.assignmentCreateDto.name.maxLength} />)}
        </Form.Item>
      </Form>
    );
  }
);

export const MetadataForm = Form.create<Props>()(MetadataFormComponent);
