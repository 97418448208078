import { api } from 'api';
import {
  WorkflowApprovalDocumentDto,
  WorkflowDto,
  WorkflowNodeAddRevisionDto,
  WorkflowStateEnum,
} from 'api/completeApiInterfaces';
import { injectDocumentIdsToCreateDto } from 'api/project/upload/uploadHelpers';
import { RevisionCreateFormModal } from 'components/forms/RevisionCreateForm';
import {
  CreateRevisionUploadData,
  revisionCreateFormDataToCreateDto,
} from 'components/forms/RevisionCreateForm/RevisionCreateFormModal';
import React, { FunctionComponent } from 'react';

type Props = {
  visible: boolean;
  onSubmit: (revision: WorkflowDto) => void;
  onClose: () => void;
  workflowId: Guid;
  workflowNodeId: Guid;
  approvalDocument: WorkflowApprovalDocumentDto;
  allowedStates?: WorkflowStateEnum[];
  canAddSignedDocument: boolean;
};

export const UploadApprovalRevisionModal: FunctionComponent<Props> = ({
  visible,
  onSubmit,
  onClose,
  workflowId,
  workflowNodeId,
  approvalDocument,
  allowedStates,
  canAddSignedDocument,
}) => {
  const createRevisionData: CreateRevisionUploadData<WorkflowDto> = (data) => {
    const createDto: WorkflowNodeAddRevisionDto = {
      ...revisionCreateFormDataToCreateDto(data),
      workflowId: workflowId,
      workflowNodeId: workflowNodeId,
      documentId: approvalDocument.document.id,
    };
    return {
      createSaveRequest: (data) =>
        api.project.workflow.workflowNode.addRevision(
          injectDocumentIdsToCreateDto(createDto, data),
          data.ctSource.token
        ),
    };
  };

  return (
    <RevisionCreateFormModal
      availableStates={allowedStates}
      visible={visible}
      currentRevisionFileName={approvalDocument?.document.name}
      onSubmit={(values, result) => onSubmit(result)}
      onClose={onClose}
      createUploadData={createRevisionData}
      allowSignedDocumentAttachment={canAddSignedDocument}
    />
  );
};
