import '@ant-design/compatible/assets/index.css';
import { Modal, ModalProps } from 'antd';

import { DirectoryListsExDto } from 'api/completeApiInterfaces';
import { DirectoryList } from 'components/DocumentSelect/DirectoryList';
import StackPanel from 'components/StackPanel';
import { useSameCallback } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { directoryListToLinkedTree } from 'store/selectors';
import { DirectoryNodeKey } from 'utils/typeMappings/directories/directoryTreeIds';
import { DirectoryNodeType, directoryNodeHelpers } from 'utils/typeMappings/directories/directoryTypes';
import styles from './MDProjectDirectorySelectModal.module.less';

type Props = {
  directories: DirectoryListsExDto;
  onDirectorySelect: (directoryId: Guid) => void;
} & ModalProps;

const MDProjectDirectorySelectModal: FunctionComponent<Props> = ({ directories, onDirectorySelect, ...modalProps }) => {
  const [selectedKeys, setSelectedKeys] = useState<DirectoryNodeKey[]>([]);

  const directoryTree = useMemo(() => directoryListToLinkedTree(directories.directories, []), [directories]);
  const handleDirectoryConfirm = useSameCallback(() => {
    if (!selectedKeys.length) return;
    const directoryKey = directoryNodeHelpers.directoryNodeKeyToId(selectedKeys[0]);
    onDirectorySelect(
      directoryKey.type === DirectoryNodeType.Directory ? directoryKey.directoryId : directoryKey.directoryLinkId
    );
  });

  return (
    <Modal
      {...modalProps}
      title={<Fmt id="MD.ProjectVariableLinkModal.directory.title" />}
      okButtonProps={{ disabled: !selectedKeys.length }}
      onOk={handleDirectoryConfirm}
    >
      <StackPanel vertical scrollable className={styles.directoryListWrapper}>
        <DirectoryList
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          directoryRoot={directoryTree.rootDir}
          selectedDocuments={[]}
          directoriesById={directoryTree.dirsById}
        />
      </StackPanel>
    </Modal>
  );
};

export default MDProjectDirectorySelectModal;
