import { Spin } from 'antd';
import { api } from 'api';
import { masterApi } from 'api/completeApi';
import { AppUserDto, OrganizationAdminReportDto, ProjectUserProfileListDto } from 'api/completeApiInterfaces';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import ServiceErrorBox from 'components/ServiceErrorBox';
import SpinBox from 'components/SpinBox';
import StackPanel from 'components/StackPanel';
import { useApiData, useIntl } from 'hooks';
import { InjectedIntlProps } from 'locale';
import { JobPanel } from 'pages/OrganizationsSettingPage/JobPositions/JobPabel';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { textComparer } from 'utils/comparators';
import MDDivisionListPanel from './MDDivisions/MDDivisionListPanel';
import MDRolesListPanel from './MDRoles/MDRolesListPanel';
import OrganizationGeneralTab from './OrganizationGeneralTab';
import OrganizationLabelsListPanel from './OrganizationLabels/OrganizationLabelsListPanel';
import OrganizationSettingsListPanel from './OrganizationSettings/OrganizationSettingsListPanel';
import ProjectsTemplateListPanel from './ProjectTemplate/ProjectsTemplateListPanel';
import ProjectsListPanel from './Projects/ProjectsListPanel';
import UserPermissionProfileListPanel from './UserPermissionProfiles/UserPermissionProfileListPanel';
import OrganizationUsersListPanel from './Users/OrganizationUsersListPanel';
import WorkflowTemplateListPanel from './WorkflowTemplates/WorkflowTemplateListPanel';

export type ProjectUserProfileListExtended = ProjectUserProfileListDto & { appUserId?: Guid };

type Props = InjectedIntlProps & {
  organizations: OrganizationAdminReportDto[];
  loadOrganizationsAdminReport: () => void;
  appUsers: AppUserDto[];
};

export type OrganizationDetailPanelParams = {
  organizationId: string;
};

const OrganizationsDetailPanel: FunctionComponent<Props> = ({
  organizations,
  loadOrganizationsAdminReport,
  appUsers,
}) => {
  const { organizationId } = useParams<OrganizationDetailPanelParams>();
  const { url } = useRouteMatch();
  const intl = useIntl();

  const [organizationUsers, organizationUsersError, organizationUsersLoading, loadOrganizationUsers] = useApiData(
    (ct) => api.master.organization.getorgusers(selectedOrganization?.id, ct),
    {
      autoload: false,
    }
  );

  const [permissionProfiles, permissionProfilesError, permissionProfilesLoading, loadPermissionProfiles] = useApiData(
    (ct) => masterApi.projects.tempates.extendedpermissiontemplate.id.get(organizationId, ct),
    {
      autoload: false,
    }
  );

  useEffect(() => {
    if (!!organizationId) loadPermissionProfiles();
  }, [organizationId]);

  const selectedOrganization = useMemo(
    () => organizations?.find((organization) => organization.id === organizationId),
    [organizations, organizationId]
  );

  const userList: ProjectUserProfileListExtended[] = useMemo(() => {
    return (
      organizationUsers
        ?.map((user) => ({
          id: user.id,
          appUserId: user.appUserProfile.id,
          username: user.appUserProfile.username,
          firstname: user.namesFromOrganization ? user.firstname : user.appUserProfile.firstname,
          lastname: user.namesFromOrganization ? user.lastname : user.appUserProfile.lastname,
          isAdmin: user.isAdmin,
          status: undefined,
          isConfirmed: !!user.appUserProfile.lastAccessDate,
        }))
        .sort(textComparer.map((user) => user.username)) || []
    );
  }, [organizationUsers]);

  useEffect(() => {
    if (!!selectedOrganization) loadOrganizationUsers();
  }, [selectedOrganization]);

  const renderUserTabContent = () => {
    if (organizationUsersError) return <ServiceErrorBox error={organizationUsersError} />;
    if (organizationUsersLoading && !organizationUsers) return <SpinBox />;
    if (organizationUsers === null) return null;
    return (
      <OrganizationUsersListPanel
        loading={organizationUsersLoading}
        error={organizationUsersError}
        reloadUsers={loadOrganizationUsers}
        intl={intl}
        organizationUsers={organizationUsers}
        userList={userList}
        appUsers={appUsers}
        selectedOrganization={selectedOrganization}
      />
    );
  };

  return (
    <>
      <MasterComponent
        url={url}
        title={selectedOrganization?.name}
        children={(onSelect, selectedItemId) => (
          <StackPanel vertical scrollable>
            <Spin delay={0} spinning={organizationUsersLoading}>
              <OrganizationGeneralTab organization={selectedOrganization} url={url} selectedItemId={selectedItemId} />
            </Spin>
          </StackPanel>
        )}
      />
      <Switch>
        <Route path={`${url}/users`} render={(props) => <>{renderUserTabContent()}</>} />
        <Route
          path={`${url}/job`}
          component={() => (
            <JobPanel
              selectedOrganizationId={selectedOrganization?.id}
              organizationUsers={organizationUsers}
              reloadOrganizationUsers={loadOrganizationUsers}
            />
          )}
        />
        <Route
          path={`${url}/projects`}
          render={() => (
            <ProjectsListPanel
              selectedOrganization={selectedOrganization}
              organizationUsers={organizationUsers}
              reloadOrganizationUsers={loadOrganizationUsers}
              loadOrganizationsAdminReport={loadOrganizationsAdminReport}
              intl={intl}
            />
          )}
        />
        <Route
          path={`${url}/projectTemplates`}
          render={() => (
            <ProjectsTemplateListPanel
              organizationId={selectedOrganization?.id}
              organizationUsers={organizationUsers}
              reloadOrganizationUsers={loadOrganizationUsers}
              organizationPermissionProfiles={permissionProfiles}
              organizationTimezoneId={selectedOrganization.defaultTimeZoneId}
              intl={intl}
            />
          )}
        />
        <Route
          path={`${url}/workflowTemplates`}
          render={() => <WorkflowTemplateListPanel organizationId={selectedOrganization?.id} intl={intl} />}
        />
        <Route
          path={`${url}/organizationSettings`}
          render={() => <OrganizationSettingsListPanel organization={selectedOrganization} intl={intl} />}
        />
        <Route
          path={`${url}/organizationLabels`}
          render={() => <OrganizationLabelsListPanel organizationId={selectedOrganization?.id} intl={intl} />}
        />
        <Route
          path={`${url}/userPermissionProfiles`}
          render={() => (
            <UserPermissionProfileListPanel
              organizationId={selectedOrganization?.id}
              permissionProfileList={permissionProfiles}
              reloadOrganizationPermissionProfiles={loadPermissionProfiles}
              permissionProfileLoading={permissionProfilesLoading}
              intl={intl}
            />
          )}
        />
        <Route
          path={`${url}/mdDivisions`}
          render={() => (
            <MDDivisionListPanel
              organizationId={selectedOrganization?.id}
              organizationUsers={organizationUsers}
              intl={intl}
            />
          )}
        />
        <Route
          path={`${url}/mdRoles`}
          render={() => <MDRolesListPanel organizationId={selectedOrganization?.id} intl={intl} />}
        />
      </Switch>
    </>
  );
};

export default OrganizationsDetailPanel;
