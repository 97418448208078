import StopPropagationWrapper from 'components/StopPropagationWrapper/StopPropagationWrapper';
import React, { FunctionComponent } from 'react';
import ActionButton, { ActionButtonProps } from './ActionButton';
import { EditIcon } from 'components/Icons/HubActionsIcons';

export const EditButton: FunctionComponent<ActionButtonProps> = (props) => (
  <StopPropagationWrapper>
    <ActionButton icon={<EditIcon />} messageId="general.edit" {...props} />
  </StopPropagationWrapper>
);
