import { Form, Modal, ModalProps, Select, Typography } from 'antd';
import { masterApi } from 'api/completeApi';
import { MdDivisionDto, MdProjectDto } from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { useApiData, useIntl, useSameCallback } from 'hooks';
import { Fmt } from 'locale';
import React, { useMemo, useState } from 'react';
import { messageError } from 'utils';
import { requiredRule } from 'utils/formHelpers';

type Props = {
  mdProjectId: Guid;
  organizationId: Guid;
  mdCurrentDivision: MdDivisionDto;
  onClose: () => void;
  onProjectUpdate: (project: MdProjectDto) => void;
} & Omit<ModalProps, 'onOk' | 'title' | 'onCancel'>;

export type MDProjectCardDivisionSetFormData = {
  divisionId: Guid;
};

const MDSetDivisionModal: React.FC<Props> = ({
  mdProjectId,
  organizationId,
  onClose,
  onProjectUpdate,
  mdCurrentDivision,
  ...modalProps
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  const [mdDivisions, mdDivisionsError, mdDivisionsLoading] = useApiData(
    (ct) => masterApi.projects.md.divisions.id.get(organizationId, ct),
    { autoload: true }
  );

  const handleFormSubmit = useSameCallback(async (values: MDProjectCardDivisionSetFormData) => {
    setLoading(true);
    const [err, res] = await masterApi.projects.md.project.setdivision.post({
      divisionId: values.divisionId,
      projectId: mdProjectId,
    });
    if (err) {
      messageError(err, intl);
      setLoading(false);
      return;
    }
    onProjectUpdate(res.data);
    setLoading(false);
    onClose();
  });

  const divisionOptions = useMemo(() => {
    return mdDivisions?.map((division) => ({
      label: division.name,
      value: division.id,
    }));
  }, [mdDivisions]);

  return (
    <Modal
      title={<Fmt id="MD.Projects.ProjectDivisionSetModal.title" />}
      onOk={form.submit}
      okButtonProps={{ loading: isLoading }}
      onCancel={onClose}
      {...modalProps}
    >
      <ContentGate loading={mdDivisionsLoading} error={mdDivisionsError}>
        <Form form={form} onFinish={handleFormSubmit}>
          {mdCurrentDivision && (
            <Typography.Paragraph>
              <Fmt
                id="MD.Projects.ProjectDivisionSetModal.division.current.label"
                values={{ division: mdCurrentDivision.name }}
              />
            </Typography.Paragraph>
          )}
          <Form.Item
            label={<Fmt id="MD.Projects.ProjectDivisionSetModal.division.label" />}
            name="divisionId"
            rules={[requiredRule('MD.Projects.ProjectDivisionSetModal.division.required')]}
          >
            <Select options={divisionOptions} loading={mdDivisionsLoading} />
          </Form.Item>
        </Form>
      </ContentGate>
    </Modal>
  );
};

export default MDSetDivisionModal;
