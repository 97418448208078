import { EsticonDocumentDto, LanguageEnum } from 'api/completeApiInterfaces';
import classNames from 'classnames';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import DisplayName from 'components/DisplayName';
import RevisionNumberTag from 'components/RevisionNumberTag';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { CheckBox, Template } from 'devextreme-react';
import { ITemplateProps } from 'devextreme-react/core/template';
import { TreeList } from 'devextreme-react/tree-list';
import { dxElement } from 'devextreme/core/element';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { locale as devExtremeLocale, loadMessages } from 'devextreme/localization';
import { GridBase, GridBaseOptions } from 'devextreme/ui/data_grid';
import { dxToolbarItem } from 'devextreme/ui/toolbar';
import dxTreeList, {
  dxTreeListColumn,
  dxTreeListOptions,
  dxTreeListRowObject,
  dxTreeListScrolling,
  dxTreeListSelection,
} from 'devextreme/ui/tree_list';
import { useHeight } from 'hooks/useHeight';
import { Fmt, InjectedIntlProps, memoizeWithIntl } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import moment from 'moment';
import { addContextMenuItemsSetExpandKeys } from 'pages/Building/contextMenuExpandCollapseUtils';
import { BuildingItemQualificationEnum } from 'pages/BuildingBudgetPage/BuildingBudgetTypes';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './BudgetGrid.module.less';

export interface WithId {
  id: Guid;
}

export type OnContextMenuPreparingType = {
  component?: dxTreeList;
  element?: dxElement;
  model?: any;
  items?: any[];
  target?: string;
  targetElement?: dxElement;
  columnIndex?: number;
  column?: dxTreeListColumn;
  rowIndex?: number;
  row?: dxTreeListRowObject;
};

export const buildingsGridDateFormatter = (cellInfo: { value?: string }) => {
  const gridDateFormat = GRID_DATE_FORMAT();
  return !!cellInfo.value && moment(cellInfo.value).format(gridDateFormat);
};

type Props<T extends WithId> = InjectedIntlProps & {
  columns: dxTreeListColumn[];
  onRowDblClick?: (e: any) => void;
  onRowClick?: (e: any) => void;
  selectedRowKeys?: Guid[];
  data: T[];
  height?: number;
  getRowClass?: (record: T) => string;
  onExpandedKeysChanged?: (keys: Guid[]) => void;
  onContextMenuPreparing?: (e: OnContextMenuPreparingType) => void;
  onRowExpanded?: (e: any) => void;
  focusedRowKey?: Guid;
  onFocusedRowChanged?: (data: T) => void;
  onSelectionChanged?: (rowsIds: Guid[]) => void;
  hideSearch?: boolean;
  clearFocusOnSearchChange?: boolean;
  onSearchValueChanged?: (searchValue: string) => void;
  children?: React.ReactElement<ITemplateProps> | React.ReactElement<ITemplateProps>[];
  onColumnOptionsChange?: (optionType: string, previousValue: any, newValue: any) => void;
  onToolbarPreparing?: (e: { toolbarOptions: { items: Array<string | dxToolbarItem | any> } }) => void;
};

export const SPACE_FOR_SCROLLBAR_COLUMN: dxTreeListColumn & { dataField: any } = {
  width: '100%',
  minWidth: 10,
  allowHiding: false,
  allowReordering: false,
  allowSearch: false,
  allowSorting: false,
  showInColumnChooser: false,
  dataField: undefined, // this makes it work with "strongly" typed ColumnType
};

const SELECTION_CONFIG: dxTreeListSelection = {
  recursive: true,
  mode: 'multiple',
  allowSelectAll: true,
};

type OptionChangeParams = {
  name: string;
  fullName: string;
  value: any;
  previousValue: any;
};

const SEARCH_PANEL_OPTION_TARGET = 'searchPanel';
const COLUMN_CHANGE_EVENT_NAME = 'columns';

const SELECTION_CONFIG_NONE: dxTreeListSelection = { mode: 'none' };

export const GRID_DATE_FORMAT = () =>
  moment.locale() === LanguageEnum.en ? 'YYYY-MM-DD' : moment.locale() === LanguageEnum.cs ? 'DD.MM.YYYY' : 'LL';

export const renderPercent = (e: any) => (
  <div className={styles.costColumn}>
    {e.value != null && (
      <>
        <div className={styles.percentProgressWrap}>
          <div className={styles.percentProgress} style={{ width: `${Math.round(e.value)}%` }} />
        </div>
        <div className={styles.percentValue}>{e.text} %</div>
      </>
    )}
  </div>
);

export const renderDocumentWithRevision = (e: any) => {
  const doc: EsticonDocumentDto = e.data.document;
  return (
    <>
      {doc && (
        <FlowLayout>
          <RevisionNumberTag
            no={doc.currentRevision?.number}
            state={doc.currentRevision?.revisionState}
            className={styles.revisionTag}
          />
          <DisplayName text={doc.name} title={doc.name} />
        </FlowLayout>
      )}
    </>
  );
};
export const renderQualificationIcon = (cellInfo: { value?: string }) => {
  if (!cellInfo.value) return null;
  const cellValue = cellInfo.value === BuildingItemQualificationEnum.isQualified;
  return (
    <CommonHubTooltip title={<Fmt id={cellValue ? 'Building.general.qualified' : 'Building.general.notQualified'} />}>
      {/* span is needful for the tooltip */}
      <span>
        <CheckBox disabled value={cellValue} />
      </span>
    </CommonHubTooltip>
  );
};

const SCROLLING_SETTINGS: dxTreeListScrolling = { mode: 'virtual' };

const BudgetGridComponent = <T extends WithId>({
  intl,
  columns,
  onRowDblClick,
  onRowClick,
  selectedRowKeys,
  data,
  height,
  getRowClass,
  onExpandedKeysChanged,
  onRowExpanded,
  onContextMenuPreparing,
  onFocusedRowChanged,
  onSelectionChanged,
  focusedRowKey,
  hideSearch,
  clearFocusOnSearchChange,
  onSearchValueChanged,
  onColumnOptionsChange,
  onToolbarPreparing,
  children,
}: Props<T>) => {
  const onRowPrepared = useCallback(
    (e) => {
      const rowClass = getRowClass && getRowClass(e.data);
      if (e.data) e.rowElement.className = classNames(e.rowElement.className, rowClass);
      return e;
    },
    [getRowClass]
  );

  const [expandedKeys, setExpandedKeys] = useState<Guid[]>([]);
  const [extremeLocale, setExtremeLocale] = useState<string>(intl.locale);
  const [messagesLoaded, setMessagesLoaded] = useState<Record<string, boolean>>({});
  const [contextMenuExpandKeys, setContextMenuExpandKeys] = useState<Guid[]>([]);

  const [localFocusedRowKey, setLocalFocusedRowKey] = useState(focusedRowKey);
  useEffect(() => {
    onFocusedRowChanged && setLocalFocusedRowKey(focusedRowKey);
  }, [focusedRowKey, onFocusedRowChanged]);

  const addTooltip = useCallback(
    (data: any) => {
      const intlMessageId = `BuildingBudget.columnHeaderTooltip.${data.column.name}` as IntlMessageId;

      return (
        <CommonHubTooltip title={intl.formatMessage({ id: intlMessageId })}>
          <span>{data.column.caption}</span>
        </CommonHubTooltip>
      );
    },
    [intl]
  );

  const quantityTypeFormatter = useCallback(
    (cellInfo: { value?: string }) => {
      return !!cellInfo.value && cellInfo.value === 'SoD'
        ? intl.formatMessage({ id: `BuildingRealization.${cellInfo.value}` })
        : cellInfo?.value;
    },
    [intl]
  );

  useEffect(() => {
    let cancelled = false;
    (async () => {
      if (!messagesLoaded[intl.locale]) {
        const devextremeMessages = await import(`devextreme/localization/messages/${intl.locale}.json`);
        const localeMessages = { [intl.locale]: devextremeMessages[intl.locale] };
        if (cancelled) return;
        loadMessages(localeMessages);
        setMessagesLoaded((s) => ({ ...s, [intl.locale]: true }));
      }
      devExtremeLocale(intl.locale);
      setExtremeLocale(intl.locale);
    })();
    return () => (cancelled = true);
  }, [intl, messagesLoaded]);

  useEffect(() => {
    const id = data && data[0]?.id;
    if (id) {
      setExpandedKeys([id]);
    }
  }, [data]);

  useEffect(() => {
    if (contextMenuExpandKeys && contextMenuExpandKeys.length) {
      setExpandedKeys((prevstate) => [...prevstate, ...contextMenuExpandKeys]);
    }
  }, [contextMenuExpandKeys]);

  const onRowCollapsing = useCallback((e) => {
    setExpandedKeys((prevState) => prevState.filter((k) => k !== e.key));
    return e;
  }, []);

  const onRowExpanding = useCallback((e) => {
    setExpandedKeys((prevState) => [...prevState, e.key]);
    return e;
  }, []);

  useEffect(() => {
    onExpandedKeysChanged && onExpandedKeysChanged(expandedKeys);
  }, [expandedKeys]);

  const searchPanel = useMemo(
    (): GridBaseOptions<GridBase>['searchPanel'] => ({
      visible: !hideSearch,
      placeholder: intl.formatMessage({ id: 'BuildingBudget.searchPlaceholder' }),
    }),
    [intl, hideSearch]
  );

  const columnChooser = useMemo(
    (): GridBaseOptions<GridBase>['columnChooser'] => ({
      enabled: true,
      mode: 'select',
      title: intl.formatMessage({ id: 'BuildingBudget.showColumns' }),
    }),
    [intl]
  );

  const handleFocusedRowChanged = useCallback<dxTreeListOptions['onFocusedRowChanged']>(
    (event) => {
      if (event.row) {
        setLocalFocusedRowKey(event.row.node.key);
        onFocusedRowChanged?.((event.row as any)?.data || undefined);
      }
    },
    [onFocusedRowChanged]
  );

  const selectionConfig = onSelectionChanged ? SELECTION_CONFIG : SELECTION_CONFIG_NONE;

  const handleSelectionChanged = useCallback(
    (e) => {
      onSelectionChanged && onSelectionChanged(e.selectedRowKeys); // , e.selectedRowsData[0]
    },
    [onSelectionChanged]
  );

  const handleContextMenuPreparing = useCallback(
    (event: OnContextMenuPreparingType) => {
      onContextMenuPreparing && onContextMenuPreparing(event);
      addContextMenuItemsSetExpandKeys(event, setContextMenuExpandKeys, expandedKeys, intl);
    },
    [onContextMenuPreparing, expandedKeys, intl]
  );

  // Make clicking the "column chooser" row activate the checkbox
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      const path: HTMLElement[] = (event as any).path || [];
      const isColumnCheckbox = path.some((element: any) => element.classList?.contains('dx-checkbox'));
      const columnRow = path.find((element: any) => element.classList?.contains('dx-treeview-item-with-checkbox'));
      if (!!columnRow && !isColumnCheckbox) {
        (columnRow.querySelector('.dx-checkbox') as any).click();
      }
    };
    document.body.addEventListener('click', listener);
    return () => document.body.removeEventListener('click', listener);
  }, []);

  const handleOptionChange = useCallback(
    (option: OptionChangeParams) => {
      if (option.name === SEARCH_PANEL_OPTION_TARGET) {
        if (clearFocusOnSearchChange) {
          onFocusedRowChanged?.(null);
        }
        onSearchValueChanged && onSearchValueChanged(option.value);
      } else if (option.name === COLUMN_CHANGE_EVENT_NAME) {
        onColumnOptionsChange && onColumnOptionsChange(option.fullName, option.previousValue, option.value);
      }
    },
    [onSearchValueChanged, onFocusedRowChanged, onColumnOptionsChange, clearFocusOnSearchChange]
  );

  //gridData rules - data: T[]
  //data = undefined  - something is wrong
  //data = null  - preparing
  //data = []  - noData

  const noDataTextValue = useMemo(() => {
    if (!!data?.length) {
      return intl.formatMessage({ id: 'BudgetGrid.noSearchDataText' });
    }
    return data !== null && !data?.length
      ? intl.formatMessage({ id: 'BudgetGrid.noDataText' })
      : intl.formatMessage({ id: 'BudgetGrid.preparing' });
  }, [data, intl]);

  const { tableWrapRef, wrapHeight } = useHeight();

  return (
    <div className={styles.tableWrap} ref={tableWrapRef}>
      {data !== undefined && messagesLoaded[extremeLocale] && (
        <TreeList
          key={extremeLocale}
          allowColumnReordering
          allowColumnResizing
          focusedRowEnabled
          focusStateEnabled //Specifies whether the UI component can be focused using keyboard navigation.
          focusedRowKey={localFocusedRowKey} // Specifies initially or currently focused grid row's key.
          onFocusedRowChanged={handleFocusedRowChanged} // A function that executed when the focused row changes. Applies only to data rows. focusedRowEnabled should be true.
          itemsExpr="children"
          dataStructure="tree"
          width="auto"
          columnResizingMode="widget"
          scrolling={SCROLLING_SETTINGS}
          columnMinWidth={50}
          className={styles.table}
          height={height != null ? height : wrapHeight}
          searchPanel={searchPanel}
          columnChooser={columnChooser}
          onRowPrepared={onRowPrepared}
          onRowDblClick={onRowDblClick}
          onRowClick={onRowClick}
          selectedRowKeys={selectedRowKeys}
          dataSource={data}
          columns={columns}
          expandedRowKeys={expandedKeys}
          onRowExpanding={onRowExpanding}
          onRowCollapsing={onRowCollapsing}
          noDataText={noDataTextValue}
          expandNodesOnFiltering={false}
          onRowExpanded={onRowExpanded}
          repaintChangesOnly={false}
          keyExpr="key"
          onContextMenuPreparing={handleContextMenuPreparing}
          onSelectionChanged={handleSelectionChanged}
          selection={selectionConfig}
          filterSyncEnabled
          onOptionChanged={handleOptionChange}
          onToolbarPreparing={onToolbarPreparing}
        >
          {children}
          <Template name="spentPercentTemplate" render={renderPercent} />
          <Template name="documentWithRevision" render={renderDocumentWithRevision} />
          <Template name="addTooltip" render={addTooltip} />
          <Template name="originTypeTranslation" render={quantityTypeFormatter} />
          <Template name="qualificationToIcon" render={renderQualificationIcon} />
        </TreeList>
      )}
    </div>
  );
};

export const BudgetGrid = memoizeWithIntl(BudgetGridComponent);
