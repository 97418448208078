import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps, ValidationRule } from '@ant-design/compatible/lib/form';
import { Input, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { apiConstraints } from 'api/completeApiConstraints';
import { AssignmentTypeEnum, ProjectUserProfileStatusEnum } from 'api/completeApiInterfaces';
import { DatePickerWithHolidays } from 'components/CalendarWithHolidays/DatePickerWithHolidays';
import { DocumentSelectDocumentState } from 'components/DocumentSelect/DocumentSelect';
import { DocumentsSelectMultipleFormItem } from 'components/DocumentSelect/FormItem/DocumentSelectMultipleFormItem';
import { UserTransferFormItem, groupSelectUserFooter } from 'components/UserTransfer/UserTransfer';
import { useCurrentProjectUser, useDispatchEffect, useStoreSelector } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt, InjectedIntlProps } from 'locale';
import { uniq } from 'lodash';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useMemo } from 'react';
import { groupListSelector } from 'store/selectors/groupSelectors';
import { projectUsersListSelector } from 'store/selectors/projectUsersSelectors';
import { maxLengthRule, requiredRule, simpleSelectFilter } from 'utils/formHelpersCompatibility';

export type AssignmentFormData = {
  name: string;
  content: string;
  type: AssignmentTypeEnum;
  deadlineDate: IsoDateTime;
  assignmentDocuments: DocumentSelectDocumentState[];
  solverIds: Guid[];
  taskGiverId: Guid;
  associateIds: Guid[];
};

export type AssignmentAddFormConfiguration = {
  initialDocuments?: DocumentSelectDocumentState[];
};

export const isDateInThePast = (current: Moment) => current < moment();

const AVAILABLE_ASSIGNMENT_TYPES: AssignmentTypeEnum[] = [
  AssignmentTypeEnum.userTask,
  AssignmentTypeEnum.userDirectoryPermission,
  AssignmentTypeEnum.uploadDocuments,
  AssignmentTypeEnum.defectSolving,
];

type Props = FormComponentProps<AssignmentFormData> & InjectedIntlProps & AssignmentAddFormConfiguration;

const AssignmentFormComponent = React.forwardRef<unknown, Props>(({ intl, form, initialDocuments }, ref) => {
  // backward compatibility with class components
  useEffect(() => {
    (ref as any).current = { props: { form } };
  }, [form]);

  useDispatchEffect((dispatch) => {
    dispatch.projectUsers.loadData({ reload: false });
    dispatch.groups.loadData({ reload: false });
  }, []);

  useDirtyStoreReload(
    (store) => store.projectUsers,
    (dispatch) => dispatch.projectUsers
  );

  useDirtyStoreReload(
    (store) => store.groups,
    (dispatch) => dispatch.groups
  );

  const currentUser = useCurrentProjectUser();
  const projectUsers = useStoreSelector(projectUsersListSelector);
  const projectUsersActiveOnly = projectUsers?.filter((user) => user.status === ProjectUserProfileStatusEnum.active);

  const deadlineInitialValue = useMemo(() => moment().add(2, 'days'), []);

  const groups = useStoreSelector(groupListSelector);
  const createUserTransferFooter = useMemo(() => groupSelectUserFooter(groups, projectUsersActiveOnly), [
    groups,
    projectUsersActiveOnly,
  ]);

  useEffect(() => {
    const taskGiverId: Guid = form.getFieldValue('taskGiverId');
    const formFields = form.getFieldsValue();
    const associatedIds: Guid[] = formFields.associateIds || [];

    if (taskGiverId === currentUser.id) {
      form.setFieldsValue({ ...formFields, associateIds: [...associatedIds.filter((id) => id !== currentUser.id)] });
    } else {
      form.setFieldsValue({ ...formFields, associateIds: uniq([...associatedIds, currentUser.id]) });
    }
  }, [form.getFieldValue('taskGiverId')]);

  const userOptions = useMemo(() => {
    return (
      projectUsers &&
      Object.entries(projectUsers).map(([key, user]) => <Select.Option key={user.id}>{user.username}</Select.Option>)
    );
  }, [projectUsers]);

  useEffect(() => {}, [form]);

  const associatedUserRule = useCallback(
    (): ValidationRule => ({
      required: form.getFieldValue('taskGiverId') !== currentUser.id,
      validator: (rule, value: Guid[], callback) => {
        const taskGiverId = form.getFieldValue('taskGiverId');
        const currentUserIsTaskGiver = taskGiverId === currentUser.id;

        if (!currentUserIsTaskGiver && !value) {
          callback(React.createElement(Fmt, { id: 'forms.items.rules.required' }));
        } else if (!!value && value.some((id) => id === taskGiverId)) {
          callback(React.createElement(Fmt, { id: 'AssignmentForm.error.userDuplicateInAssociates' }));
        } else if (!currentUserIsTaskGiver && (!value || !value.some((id) => id === currentUser.id))) {
          callback(React.createElement(Fmt, { id: 'AssignmentForm.error.currentUserRequiredInAssociates' }));
        } else {
          callback();
        }
      },
    }),
    [currentUser, form]
  );

  // render
  return (
    <Form layout="vertical">
      <Form.Item label={intl.formatMessage({ id: 'AssignmentForm.type' })}>
        {form.getFieldDecorator<AssignmentFormData>('type', {
          rules: [requiredRule('forms.items.rules.required')],
          initialValue: AssignmentTypeEnum.userTask,
        })(
          <Select>
            {AVAILABLE_ASSIGNMENT_TYPES.map((assignmentType) => (
              <Select.Option key={assignmentType} value={assignmentType}>
                <Fmt id={`AssignmentTypeEnum.${assignmentType}`} />
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>

      <Form.Item label={intl.formatMessage({ id: 'AssignmentForm.deadlineDate' })}>
        {form.getFieldDecorator<AssignmentFormData>('deadlineDate', {
          rules: [requiredRule('forms.items.rules.required')],
          initialValue: deadlineInitialValue,
        })(<DatePickerWithHolidays showToday disabledDate={isDateInThePast} />)}
      </Form.Item>

      <Form.Item label={intl.formatMessage({ id: 'AssignmentForm.name' })}>
        {form.getFieldDecorator<AssignmentFormData>('name', {
          rules: [
            requiredRule('AssignmentForm.name.required', true),
            maxLengthRule('general.maxNameLength', apiConstraints.assignmentCreateDto.name.maxLength),
          ],
        })(<Input maxLength={apiConstraints.assignmentCreateDto.name.maxLength} />)}
      </Form.Item>

      <Form.Item label={intl.formatMessage({ id: 'AssignmentForm.content' })}>
        {form.getFieldDecorator<AssignmentFormData>('content', {
          rules: [maxLengthRule('general.maxNameLength', apiConstraints.assignmentCreateDto.content.maxLength)],
        })(<TextArea rows={3} maxLength={apiConstraints.assignmentCreateDto.content.maxLength} />)}
      </Form.Item>

      <Form.Item label={intl.formatMessage({ id: 'AssignmentForm.taskGiver' })}>
        {form.getFieldDecorator<AssignmentFormData>('taskGiverId', {
          rules: [requiredRule('forms.items.rules.required')],
          initialValue: currentUser.id,
        })(
          <Select allowClear showSearch filterOption={simpleSelectFilter}>
            {userOptions}
          </Select>
        )}
      </Form.Item>

      <Form.Item label={intl.formatMessage({ id: 'AssignmentForm.associateIds' })}>
        {form.getFieldDecorator<AssignmentFormData>('associateIds', {
          rules: [associatedUserRule()],
        })(<UserTransferFormItem users={projectUsersActiveOnly} createFooter={createUserTransferFooter} />)}
      </Form.Item>

      <Form.Item label={intl.formatMessage({ id: 'AssignmentForm.solverIds' })}>
        {form.getFieldDecorator<AssignmentFormData>('solverIds', {
          rules: [requiredRule('forms.items.rules.required')],
        })(<UserTransferFormItem users={projectUsersActiveOnly} createFooter={createUserTransferFooter} />)}
      </Form.Item>

      <Form.Item label={intl.formatMessage({ id: 'AssignmentForm.assignmentDocuments' })}>
        {form.getFieldDecorator<AssignmentFormData>('assignmentDocuments', {
          initialValue: initialDocuments,
        })(<DocumentsSelectMultipleFormItem allowUploadNew />)}
      </Form.Item>
    </Form>
  );
});

export const AssignmentForm = Form.create<Props>()(AssignmentFormComponent);
