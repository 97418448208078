import { useCallback, useEffect, useRef, useState } from 'react';

const LANDSCAPE_CAMERA_SETTINGS = {
  video: {
    facingMode: { ideal: 'environment' },
    width: { ideal: 1920 },
    height: { ideal: 1080 },
  },
  audio: true,
};

const PORTRAIT_CAMERA_SETTINGS = {
  video: {
    facingMode: { ideal: 'environment' },
    width: { ideal: 1080 },
    height: { ideal: 1920 },
  },
  audio: true,
};

export const useCapture = () => {
  const previewRef = useRef<HTMLVideoElement>(null);
  const recordingRef = useRef<HTMLVideoElement>(null);
  const downloadButtonRef = useRef<HTMLAnchorElement>(null);

  const [capturedData, setCapturedData] = useState<Blob | null>(null);

  const [cameraInit, setCameraInit] = useState(false);
  const [recording, setRecording] = useState(false);

  const [selectedDevice, setSelectedDevice] = useState<string | null>(null);

  const [availableDevices, setAvailableDevices] = useState<MediaDeviceInfo[]>([]);
  const streamRef = useRef<MediaStream | null>(null);

  const [cameraSettings, _setCameraSettings] = useState<'LANDSCAPE' | 'PORTRAIT'>('LANDSCAPE');

  const setCameraSettings = useCallback((setting: 'LANDSCAPE' | 'PORTRAIT') => {
    _setCameraSettings(setting);
  }, []);

  const initCamera = useCallback(
    (cameraSettings: MediaStreamConstraints) => {
      if (streamRef.current) {
        // Stop all tracks
        streamRef.current.getTracks().forEach((track) => {
          track.stop();
        });
      }
      setRecording(false);

      setCameraInit(false);
      navigator.mediaDevices
        .getUserMedia(cameraSettings)
        .then((stream) => {
          streamRef.current = stream;
          previewRef.current.srcObject = stream;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          previewRef.current.captureStream = previewRef.current.captureStream || previewRef.current.mozCaptureStream;

          console.log({ videTracks: stream.getVideoTracks(), audioTracks: stream.getAudioTracks() });
          setSelectedDevice(stream.getVideoTracks()[0].label);

          navigator.mediaDevices
            .enumerateDevices()
            .then((devices) => {
              setAvailableDevices(devices);
            })
            .catch(function(e) {
              console.log(e.name + ': ' + e.message);
            });

          return new Promise((resolve) => (previewRef.current.onplaying = resolve));
        })
        .then(() => {
          setCameraInit(true);
        });
    },
    [cameraSettings]
  );

  const stopCapture = () => {
    const recorder = recorderRef.current;
    if (!cameraInit || !recorder) {
      return;
    }

    const stopped = new Promise((resolve, reject) => {
      recorder.onstop = resolve;
      recorder.onerror = (event) => reject(event);
    });

    if (recorder.state === 'recording') {
      recorder.stop();
    }

    stopped
      .then(() => dataRef.current)
      .then((recordedChunks) => {
        let recordedBlob = new Blob(recordedChunks, { type: 'video/webm' });
        recordingRef.current.src = URL.createObjectURL(recordedBlob);
        setCapturedData(recordedBlob);
        downloadButtonRef.current.href = recordingRef.current.src;
        downloadButtonRef.current.download = 'RecordedVideo.webm';

        console.log(`Successfully recorded ${recordedBlob.size} bytes of ${recordedBlob.type} media.`);
        setRecording(false);
      })
      .catch((error) => {
        if (error.name === 'NotFoundError') {
          console.log("Camera or microphone not found. Can't record.");
        } else {
          console.log(error);
        }
        setRecording(false);
      });
  };

  const dataRef = useRef<Blob[]>([]);
  const recorderRef = useRef<MediaRecorder | null>(null);

  const clearData = useCallback(() => {
    setCapturedData(null);
  }, []);

  const startCapture = () => {
    if (!cameraInit) {
      return;
    }
    setCapturedData(null);
    dataRef.current = [];

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const captureStream = previewRef.current.captureStream();
    const recorder = new MediaRecorder(captureStream);
    recorderRef.current = recorder;

    recorder.ondataavailable = (event) => dataRef.current.push(event.data);
    recorder.start();
    setRecording(true);
  };

  useEffect(() => {
    const constraints = cameraSettings === 'LANDSCAPE' ? LANDSCAPE_CAMERA_SETTINGS : PORTRAIT_CAMERA_SETTINGS;
    initCamera(constraints);
  }, [cameraSettings]);

  return {
    startCapture,
    stopCapture,
    recording,
    capturedData,
    setCameraSettings,
    previewRef,
    recordingRef,
    downloadButtonRef,
    availableDevices,
    selectedDevice,
    cameraInit,
    cameraSettings,
    clearData,
  } as const;
};
