import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Checkbox, Input, InputRef, Radio, Select } from 'antd';
import { AppUserDto, OrganizationAdminReportDto, ProjectTimezoneListDto } from 'api/completeApiInterfaces';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { DEFAULT_LOCALE, MAX_PROJECT_DESCRIPTION_LENGTH, MAX_PROJECT_NAME_LENGTH } from 'config/constants';
import { Fmt, InjectedIntlProps } from 'locale';
import { LanguageEnum } from 'locale/messages/interfaces';
import React, { useEffect, useMemo } from 'react';
import { duplicateNameRule, maxLengthRule, requiredRule, simpleSelectFilter } from 'utils/formHelpersCompatibility';
import { AppUsersFormItem, SelectableUsersSourceEnum, UserAttributes } from '../AppUsersAddFormModal/AppUsersFormItem';

export type OrganizationAddFormData = {
  name: string;
  description: string;
  allowableStorageAreas: string[];
  defaultLanguage: LanguageEnum;
  defaultTimeZoneId: string;
  projectAdminCanInviteNewUser: boolean;
  usersToAddWithAttributes: Record<string, UserAttributes>;
};

type Props = FormComponentProps<OrganizationAddFormData> &
  InjectedIntlProps & {
    currentOrganization?: OrganizationAdminReportDto;
    appUsers: AppUserDto[];
    timeZones: ProjectTimezoneListDto;
    organizations: OrganizationAdminReportDto[];
    setRef: (ref: InputRef) => void;
    configStorageAreas?: string[];
    userCanAddOrganization: boolean;
  };

export const noAdminInAppUsersItem = (value: Record<string, UserAttributes>) => {
  if (!!!value) return true;
  const atLeastOneAdmin = Object.values(value).some((value) => value.isAdmin);
  if (!atLeastOneAdmin) return true;
  return false;
};

const OrganizationAddForm = React.forwardRef<unknown, Props>(
  (
    {
      intl,
      form,
      currentOrganization,
      appUsers,
      timeZones,
      organizations,
      configStorageAreas,
      userCanAddOrganization,
      setRef,
    },
    ref
  ) => {
    // backward compatibility with class components
    useEffect(() => {
      (ref as any).current = { props: { form } };
    }, [form]);

    const { getFieldDecorator } = form;

    const usedNames = useMemo(() => {
      return organizations
        ?.map((organization) => organization.name)
        .filter((organizationName) => organizationName !== currentOrganization?.name);
    }, [currentOrganization?.name, organizations]);

    const isUserSettingPossible = !currentOrganization;

    return (
      <Form layout="horizontal" labelCol={{ span: 4 }} wrapperCol={{ span: 18 }} autoComplete="off">
        <Form.Item label={intl.formatMessage({ id: 'OrganizationAddForm.title' })}>
          {getFieldDecorator<OrganizationAddFormData>('name', {
            initialValue: currentOrganization?.name,
            rules: [
              requiredRule('forms.items.rules.required', true),
              maxLengthRule('general.maxNameLength', MAX_PROJECT_NAME_LENGTH),
              !!currentOrganization && duplicateNameRule('forms.items.name.rules.nameExists', usedNames, true),
            ],
          })(
            <Input
              placeholder={intl.formatMessage({ id: 'OrganizationAddForm.title.placeholder' })}
              autoFocus
              ref={setRef}
            />
          )}
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'OrganizationAddForm.description' })}>
          {getFieldDecorator<OrganizationAddFormData>('description', {
            initialValue: currentOrganization?.description,
            rules: [
              {
                max: MAX_PROJECT_DESCRIPTION_LENGTH,
                message: intl.formatMessage(
                  { id: 'general.maxDescriptionLength' },
                  { max: MAX_PROJECT_DESCRIPTION_LENGTH }
                ),
              },
            ],
          })(
            <Input.TextArea
              rows={3}
              autoSize={{ minRows: 3 }}
              placeholder={intl.formatMessage({ id: 'OrganizationAddForm.description.placeholder' })}
            />
          )}
        </Form.Item>
        {!!configStorageAreas?.length && userCanAddOrganization && (
          <Form.Item label={intl.formatMessage({ id: 'general.storageareas' })}>
            {getFieldDecorator<OrganizationAddFormData>('allowableStorageAreas', {
              initialValue: currentOrganization?.defaultStorageArea,
              rules: [{ required: true, message: intl.formatMessage({ id: 'forms.items.rules.required' }) }],
            })(
              <Select mode="multiple" allowClear showArrow>
                {configStorageAreas.map((area) => (
                  <Select.Option key={area}>{area}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        )}
        <Form.Item
          label={
            <span>
              <Fmt id="OrganizationAddForm.language" />
              <CommonHubTooltip
                title={intl.formatMessage({ id: 'OrganizationAddForm.language.tooltip' })}
                placement="right"
              >
                <QuestionCircleOutlined style={{ paddingLeft: '8px' }} />
              </CommonHubTooltip>
            </span>
          }
        >
          {getFieldDecorator<OrganizationAddFormData>('defaultLanguage', {
            initialValue: currentOrganization?.defaultLanguage || LanguageEnum[DEFAULT_LOCALE] || LanguageEnum.cs,
          })(
            <Radio.Group>
              <Radio.Button value={LanguageEnum.cs}>
                {intl.formatMessage({ id: 'ProjectCreateForm.form.items.language.cs' })}
              </Radio.Button>
              <Radio.Button value={LanguageEnum.en}>
                {intl.formatMessage({ id: 'ProjectCreateForm.form.items.language.en' })}
              </Radio.Button>
            </Radio.Group>
          )}
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'ProjectCreateForm.form.timeZone' })}>
          {form.getFieldDecorator<OrganizationAddFormData>('defaultTimeZoneId', {
            rules: [requiredRule('forms.items.rules.required')],
            initialValue: currentOrganization?.defaultTimeZoneId || timeZones.defaultTimeZoneId,
          })(
            <Select showSearch allowClear filterOption={simpleSelectFilter}>
              {timeZones?.timeZones.map((tz) => (
                <Select.Option key={tz.id} value={tz.id}>
                  {tz.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 4, span: 18 }}>
          {getFieldDecorator<OrganizationAddFormData>('projectAdminCanInviteNewUser', {
            initialValue: (!!currentOrganization && currentOrganization?.projectAdminCanInviteNewUser) || undefined,
            valuePropName: 'checked',
          })(
            <Checkbox>
              <Fmt id="OrganizationAddForm.addUsersAlsoWithInvitation" />
            </Checkbox>
          )}
        </Form.Item>
        {isUserSettingPossible && (
          <Form.Item label={intl.formatMessage({ id: 'general.users' })}>
            {form.getFieldDecorator('usersToAddWithAttributes', {
              rules: [
                requiredRule('OrganizationAddForm.required'),
                {
                  validator(rule, value, callback, source, options) {
                    const errors: string[] = [];
                    if (noAdminInAppUsersItem(value)) {
                      errors.push(intl.formatMessage({ id: 'OrganizationAddForm.required' }));
                    }
                    callback(errors);
                  },
                },
              ],
            })(
              <AppUsersFormItem
                selectableAppUsers={appUsers}
                intl={intl}
                selectableUsersToAddSource={SelectableUsersSourceEnum.aspeHub}
              />
            )}
          </Form.Item>
        )}
      </Form>
    );
  }
);

export default Form.create<Props>()(OrganizationAddForm);
