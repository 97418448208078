import { Button, Popconfirm, message } from 'antd';
import { api } from 'api';
import { AssignmentDocumentsPatchDto, AssignmentDto, DirectoryContentDto } from 'api/completeApiInterfaces';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { CommonDocument, DocumentCompleteList } from 'components/DocumentCompleteList/DocumentCompleteList';
import { DocumentSelectDocumentState } from 'components/DocumentSelect/DocumentSelect';
import {
  DocumentSelectToolbar,
  DocumentToolbarSelectedDocuments,
} from 'components/DocumentSelectToolbar/DocumentSelectToolbar';
import { DeleteIcon } from 'components/Icons/HubActionsIcons';
import StackPanel from 'components/StackPanel';
import { useIntl } from 'hooks';
import { uniq } from 'lodash';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { messageError } from 'utils';
import { DisabledWithReason } from 'utils/types';

type Props = {
  canEdit: boolean;
  assignment: AssignmentDto;
  updateAssignment: (assignment: AssignmentDto | null) => void;
};

export const AssignmentDocumentsField: FunctionComponent<Props> = ({ canEdit, assignment, updateAssignment }) => {
  const [selectedDocuments, setSelectedDocuments] = useState<DocumentSelectDocumentState[]>([]);
  const intl = useIntl();

  const existingDocumentsDisabled = useCallback(
    (document: DirectoryContentDto): DisabledWithReason => {
      if (assignment.documents?.some((assignmentDocument) => assignmentDocument.document.id === document.id)) {
        return intl.formatMessage({ id: 'AssignmentDocumentsField.documentIsAlreadySelected' });
      }
      return false;
    },
    [intl, assignment.documents]
  );

  const handleDocumentSelection = useCallback(
    async (selectedDocuments: DocumentToolbarSelectedDocuments) => {
      const data: AssignmentDocumentsPatchDto = {
        assignmentDocumentIds: uniq([
          ...assignment.documents.map((document) => document.document.id),
          ...(selectedDocuments.uploaded?.map((document) => document.id) || []),
          ...(selectedDocuments.existing?.map((document) => document.id) || []),
        ]),
      };

      const [err, resp] = await api.project.assignments.updateDocuments(assignment.id, data);
      if (err) {
        messageError(err, intl);

        if (selectedDocuments.uploaded?.length) {
          // open "add existing" form instead
          setSelectedDocuments([...(selectedDocuments.existing || []), ...(selectedDocuments.uploaded || [])]);
          void message.warning({
            content: intl.formatMessage({ id: 'AssignmentDocumentsField.unableToAddDocument.content' }),
          });
        }
      } else {
        updateAssignment(resp.data);
        setSelectedDocuments([]);
      }
    },
    [assignment, intl, updateAssignment]
  );

  const commonDocuments = useMemo(
    (): CommonDocument[] =>
      assignment.documents?.map((document) => ({ ...document.document, revision: document.currentRevision })),
    [assignment.documents]
  );

  const removeDocument = useCallback(
    async (documentId: Guid) => {
      const data: AssignmentDocumentsPatchDto = {
        assignmentDocumentIds: [
          ...assignment.documents.map((document) => document.document.id).filter((id) => id !== documentId),
        ],
      };

      const [err, resp] = await api.project.assignments.updateDocuments(assignment.id, data);

      if (err) {
        messageError(err, intl);
      } else {
        updateAssignment(resp.data);
      }
    },
    [assignment, intl, updateAssignment]
  );

  const additionalButtons = useCallback(
    (document: CommonDocument) => (
      <Popconfirm
        placement="right"
        title={intl.formatMessage({ id: 'Popconfirm.deleteItem' })}
        okText={intl.formatMessage({ id: 'general.yes' })}
        cancelText={intl.formatMessage({ id: 'general.no' })}
        onConfirm={() => removeDocument(document.id)}
      >
        <CommonHubTooltip title={intl.formatMessage({ id: 'AssignmentDocumentsField.removeDocument' })}>
          <Button size="small" type="default" danger shape="circle" disabled={!canEdit}>
            <DeleteIcon />
          </Button>
        </CommonHubTooltip>
      </Popconfirm>
    ),
    [intl, removeDocument, canEdit]
  );

  return (
    <StackPanel vertical>
      {canEdit && (
        <DocumentSelectToolbar
          canSelectExisting
          canUpload
          multiple
          disableDocumentForSelection={existingDocumentsDisabled}
          onDocumentSelect={handleDocumentSelection}
          selectedExistingDocuments={selectedDocuments}
          openSelectionWithDocumentsChange
        />
      )}
      <DocumentCompleteList
        hideToolbar
        documents={commonDocuments}
        reloadDocuments={() => updateAssignment(null)}
        flexGrow
        hideSelect
        additionalDocumentButtons={additionalButtons}
      />
    </StackPanel>
  );
};
