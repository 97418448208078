import { HolderOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { TagProps } from 'antd/lib/tag';
import classNames from 'classnames';
import React, { FC, MutableRefObject } from 'react';
import styles from './MaskTag.module.less';

export type MaskTagItemProps = TagProps & {
  innerRef?: MutableRefObject<any>;
};

export const MaskTag: FC<MaskTagItemProps> = ({ children, innerRef, className, ...props }) => {
  return (
    <Tag className={classNames(className, styles.tag)} ref={innerRef} {...props}>
      <span>
        <HolderOutlined />
      </span>
      {children}
    </Tag>
  );
};
