import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Input, Select } from 'antd';
import { apiConstraints } from 'api/completeApiConstraints';
import { ProjectTemplateRoleDto, ProjectTemplateUserDto } from 'api/completeApiInterfaces';
import { InjectedIntlProps } from 'locale';
import React, { ReactNode, useCallback, useEffect } from 'react';
import {
  duplicateNameRuleCallback,
  maxLengthRule,
  requiredRule,
  simpleSelectFilter,
  whitespaceRule,
} from 'utils/formHelpersCompatibility';

export type ProjectTemplateRoleFormData = {
  name: string;
  description: string;
  userId: string;
};

type Props = FormComponentProps<ProjectTemplateRoleFormData> &
  InjectedIntlProps & {
    validateUniqueName: (name: string) => boolean;
    defaults?: ProjectTemplateRoleDto;
    templateUsers: ProjectTemplateUserDto[];
  };

const ProjectTemplateRoleForm = React.forwardRef<unknown, Props>(
  ({ intl, form, validateUniqueName, defaults, templateUsers }, ref) => {
    // backward compatibility with class components
    useEffect(() => {
      (ref as any).current = { props: { form } };
    }, [form]);

    const getUsers = useCallback((): ReactNode[] => {
      return templateUsers.map((user) => (
        <Select.Option key={user.id}>{user.appUserOrganization.appUserProfile.username}</Select.Option>
      ));
    }, [templateUsers]);

    const { getFieldDecorator } = form;
    return (
      <Form layout="vertical">
        <Form.Item label={intl.formatMessage({ id: 'forms.items.name.label' })}>
          {getFieldDecorator<ProjectTemplateRoleFormData>('name', {
            rules: [
              requiredRule('forms.items.name.rules.required', true),
              maxLengthRule('general.maxNameLength'),
              duplicateNameRuleCallback(validateUniqueName),
            ],
            initialValue: defaults?.name,
          })(<Input placeholder={intl.formatMessage({ id: 'RoleForm.items.name.placeholder' })} autoFocus />)}
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'forms.items.description.label' })}>
          {getFieldDecorator<ProjectTemplateRoleFormData>('description', {
            rules: [
              whitespaceRule('forms.items.name.rules.empty'),
              {
                max: apiConstraints.projectTemplateRoleDto.description.maxLength,
                message: intl.formatMessage(
                  { id: 'general.maxDescriptionLength' },
                  { max: apiConstraints.projectTemplateRoleDto.description.maxLength }
                ),
              },
            ],
            initialValue: defaults?.description,
          })(<Input.TextArea rows={2} autoSize={{ minRows: 2 }} />)}
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'forms.items.userId.label' })}>
          {getFieldDecorator<ProjectTemplateRoleFormData>('userId', { initialValue: defaults?.templateUserId })(
            <Select allowClear showSearch filterOption={simpleSelectFilter}>
              {getUsers()}
            </Select>
          )}
        </Form.Item>
      </Form>
    );
  }
);

export default Form.create<Props>()(ProjectTemplateRoleForm);
