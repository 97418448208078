import { Collapse } from 'antd';
import { AuditLogDto } from 'api/completeApiInterfaces';
import { useCurrentProjectUser, useVisibleState } from 'hooks';
import React, { FunctionComponent } from 'react';
import AuditLogDetailModal from './AuditLogDetailModal';
import AuditLogRow from './AuditLogRow';
import { AuditLogRowDisplay } from './AuditLogRowDisplay';

type Props = {
  logs: AuditLogDto[];
  isAdminOutsideProject?: boolean;
};

export const AuditLogGrid: FunctionComponent<Props> = ({ logs, isAdminOutsideProject = false }) => {
  const [auditLogId, auditLogVisible, setAuditLogId, hideAuditLog] = useVisibleState<number>();
  const currentUser = useCurrentProjectUser();
  return (
    <>
      <Collapse accordion>
        {logs
          .filter((log) => !!log.entityData || !!log.eventData)
          .map((log, index) => (
            <Collapse.Panel
              key={index} // Audit log is stateless, and log.id can be 0 multiple times
              header={
                <AuditLogRow
                  log={log}
                  displayEntityType
                  isAdmin={!!currentUser || isAdminOutsideProject}
                  onDetailClick={setAuditLogId}
                />
              }
            >
              <AuditLogRowDisplay log={log} />
            </Collapse.Panel>
          ))}
      </Collapse>
      <AuditLogDetailModal auditLogId={auditLogId} visible={auditLogVisible} onOk={hideAuditLog} />
    </>
  );
};
