import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Col, Input, Row } from 'antd';
import { apiConstraints } from 'api/completeApiConstraints';
import {
  AccessLevelEnum,
  CommentProcedurePhaseEnum,
  DirectoryContentDto,
  ProjectUserProfileDto,
} from 'api/completeApiInterfaces';
import { DateSelectFormItem, DateSelectOptionType, DateSelectValue } from 'components/DateSelect/DateSelect';
import { DocumentSelectDocumentState } from 'components/DocumentSelect/DocumentSelect';
import { DocumentsSelectMultipleFormItem } from 'components/DocumentSelect/FormItem/DocumentSelectMultipleFormItem';
import { LabelsInput } from 'components/LabelsInput/LabelsInput';
import { MultipleRolesListSelectFormItem } from 'components/MultipleRolesListSelect/MultipleRolesListSelect';
import { useCurrentProjectUser } from 'hooks';
import { Fmt, InjectedIntl, InjectedIntlProps } from 'locale';
import React, { useCallback, useEffect, useMemo } from 'react';
import { duplicateNameRule, maxLengthRule, requiredRule } from 'utils/formHelpersCompatibility';
import { ConnectedDirectory } from 'utils/typeMappings/directories/directoryTypes';
import { DisabledWithReason } from 'utils/types';

export const commentProcedureCalendarPresetDeadlines: number[] = [5, 7, 10];

export const commentProcedureDefaultDeadline: DateSelectValue = {
  type: DateSelectOptionType.CalendarDays,
  calendarDays: 7,
  workingDays: 7,
};

export type CommentProcedureAddFormData = {
  name: string;
  description: string;
  processorRoleIds: Guid[];
  submitterRoleIds: Guid[];
  processorUserIds: Guid[];
  submitterUserIds: Guid[];
  labels: Guid[];
  commentProcedureDocuments: DocumentSelectDocumentState[];
  deadline: DateSelectValue;
};

export type CommentProcedureAddFormConfiguration = {
  startDirectoryId?: Guid; // start file selection in this directory
  initialDocuments?: DocumentSelectDocumentState[]; // pre-selected files when creating from selected documents;
};

type Props = FormComponentProps<CommentProcedureAddFormData> &
  InjectedIntlProps &
  CommentProcedureAddFormConfiguration & {
    existingCommentProcedureNames?: string[];
  };

// Common for comment procedure and workflow
export const isCommentWorkflowDocumentDisabled = (
  document: DirectoryContentDto,
  directory: ConnectedDirectory,
  currentUser: ProjectUserProfileDto,
  intl: InjectedIntl
): DisabledWithReason => {
  const currentAccessLevel = directory.currentAccessLevel;
  if (currentAccessLevel !== AccessLevelEnum.admin && currentAccessLevel !== AccessLevelEnum.write) {
    return intl.formatMessage({ id: 'DocumentSelect.disabledMessage.noWriteAccess' });
  }
  if (document.reservedBy && document.reservedBy.id !== currentUser.id) {
    return intl.formatMessage({ id: 'DocumentSelect.disabledMessage.notUnlocked' });
  }
  if (document.ownedBy && document.ownedBy.user?.id !== currentUser.id) {
    return intl.formatMessage({ id: 'DocumentSelect.disabledMessage.notOwner' });
  }
  if (document.workflowId) {
    return intl.formatMessage({ id: 'DocumentSelect.disabledMessage.documentInWorkflow' });
  }
  if (document.commentProcedureId) {
    return intl.formatMessage({ id: 'DocumentSelect.disabledMessage.documentInProcedure' });
  }
  if (document.esticonObjectLink?.isDilci) {
    return intl.formatMessage({ id: 'DocumentSelect.disabledMessage.partialEsticonDocument' });
  }
  return false;
};

export const isCommentedDocumentDisabled = (
  document: DirectoryContentDto,
  directory: ConnectedDirectory,
  currentUser: ProjectUserProfileDto,
  intl: InjectedIntl,
  commentProcedureId?: Guid
): DisabledWithReason => {
  if (commentProcedureId && document.commentProcedureId === commentProcedureId) {
    return intl.formatMessage({ id: 'DocumentSelect.disabledMessage.documentInThisProcedure' });
  }
  return isCommentWorkflowDocumentDisabled(document, directory, currentUser, intl);
};

const CommentProcedureAddForm = React.forwardRef<unknown, Props>(
  ({ intl, form, startDirectoryId, initialDocuments, existingCommentProcedureNames }, ref) => {
    // backward compatibility with class components
    useEffect(() => {
      (ref as any).current = { props: { form } };
    }, [form]);

    const currentUser = useCurrentProjectUser();

    const disabledDocuments = useCallback(
      (file: DirectoryContentDto, directory: ConnectedDirectory) =>
        isCommentedDocumentDisabled(file, directory, currentUser, intl),
      [currentUser, intl]
    );

    const rolesRules = useMemo(() => [requiredRule('CommentProcedureAddForm.role.required')], []);

    return (
      <Form layout="vertical">
        <Form.Item label={intl.formatMessage({ id: 'CommentProcedureAddForm.name' })}>
          {form.getFieldDecorator<CommentProcedureAddFormData>('name', {
            rules: [
              requiredRule('CommentProcedureAddForm.name.required', true),
              maxLengthRule('general.maxNameLength', apiConstraints.commentProcedureCreateDto.name.maxLength),
              duplicateNameRule('forms.items.name.rules.nameExists', existingCommentProcedureNames, true),
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'CommentProcedureAddForm.description' })}>
          {form.getFieldDecorator<CommentProcedureAddFormData>('description', {
            rules: [
              maxLengthRule(
                'general.maxDescriptionLength',
                apiConstraints.commentProcedureCreateDto.description.maxLength
              ),
            ],
          })(<Input.TextArea rows={3} />)}
        </Form.Item>

        <Row gutter={20}>
          <Col span={12}>
            <Form.Item label={intl.formatMessage({ id: 'CommentProcedureAddForm.headOfSubmitters' })}>
              {form.getFieldDecorator<CommentProcedureAddFormData>('submitterRoleIds', {
                rules: rolesRules,
                initialValue: [],
              })(<MultipleRolesListSelectFormItem />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={intl.formatMessage({ id: 'CommentProcedureAddForm.headOfProcessors' })}>
              {form.getFieldDecorator<CommentProcedureAddFormData>('processorRoleIds', {
                rules: rolesRules,
                initialValue: [],
              })(<MultipleRolesListSelectFormItem />)}
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label={intl.formatMessage({ id: 'forms.items.labels.label' })}>
          {form.getFieldDecorator<CommentProcedureAddFormData>('labels', { initialValue: [] })(<LabelsInput />)}
        </Form.Item>

        <Form.Item label={intl.formatMessage({ id: 'CommentProcedureAddForm.commentedDocuments' })}>
          {form.getFieldDecorator<CommentProcedureAddFormData>('commentProcedureDocuments', {
            initialValue: initialDocuments || [],
          })(
            <DocumentsSelectMultipleFormItem
              disabledDocuments={disabledDocuments}
              startDirectoryId={startDirectoryId}
              titleId="CommentProcedureAddForm.commentedDocuments.modalTitle"
            />
          )}
        </Form.Item>

        <Form.Item label={<Fmt id={`CommentProcedureDeadline.for.${CommentProcedurePhaseEnum.Commenting}`} />}>
          {form.getFieldDecorator<CommentProcedureAddFormData>('deadline', {
            initialValue: commentProcedureDefaultDeadline,
            rules: [requiredRule('forms.items.rules.required')],
          })(<DateSelectFormItem calendarDaysPresets={commentProcedureCalendarPresetDeadlines} enableUnlimited />)}
        </Form.Item>
      </Form>
    );
  }
);

export default Form.create<Props>()(CommentProcedureAddForm);
