import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Input } from 'antd';
import { apiConstraints } from 'api/completeApiConstraints';
import { MdDivisionDto, OrgUserDto, ProjectUserProfileListDto } from 'api/completeApiInterfaces';
import { UserTransferFormItem } from 'components/UserTransfer/UserTransfer';
import { InjectedIntlProps } from 'locale';
import { AutoSizeType } from 'rc-textarea/lib/ResizableTextArea';
import React, { useEffect, useMemo } from 'react';
import { duplicateNameRuleCallback, maxLengthRule, minLengthRule, requiredRule } from 'utils/formHelpersCompatibility';

export type MDDivisionFormData = {
  name: string;
  description: string;
  organizationName?: string;
  headsOrganizationUserIds: Guid[];
  users: Guid[];
};

const DESCRIPTION_AUTOSIZE: AutoSizeType = { minRows: 2 };

type Props = FormComponentProps<MDDivisionFormData> &
  InjectedIntlProps & {
    validateUniqueName: (name: string) => boolean;
    defaults?: MdDivisionDto;
    organizationUsers: OrgUserDto[];
    showUserTransfer: boolean;
  };

const mapUserToTransfer = (user: OrgUserDto): ProjectUserProfileListDto => ({
  id: user.id,
  username: user.appUserProfile.username,
  firstname: user.firstname,
  lastname: user.lastname,
  isAdmin: user.isAdmin,
  status: undefined,
});

const MDDivisionFormComponent = React.forwardRef<unknown, Props>(
  ({ intl, form, validateUniqueName, organizationUsers, showUserTransfer, defaults }, ref) => {
    // backward compatibility with class components
    useEffect(() => {
      (ref as any).current = { props: { form } };
    }, [form]);

    const divisionUsers = form.getFieldValue('users');
    const divisionHeadUsers = form.getFieldValue('headsOrganizationUserIds');

    const defaultLeaders = useMemo(
      () => defaults?.mdUsers.filter((user) => user.isHead)?.map((user) => user.orgUser.id),
      [defaults]
    );

    const availableHeadList = useMemo(() => {
      const memberUsers: Guid[] = divisionUsers || [];
      return (
        organizationUsers
          .filter((user) => !memberUsers.some((memberId) => user.id === memberId))
          .map(mapUserToTransfer) || []
      );
    }, [divisionUsers, organizationUsers]);

    const availableUserList = useMemo(() => {
      const heads: Guid[] = divisionHeadUsers || [];
      return (
        organizationUsers.filter((user) => !heads.some((headId) => headId === user.id)).map(mapUserToTransfer) || []
      );
    }, [divisionHeadUsers, organizationUsers]);

    return (
      <Form layout="vertical" form={form}>
        <Form.Item label={intl.formatMessage({ id: 'MD.Organization.divisions.modal.input.name' })}>
          {form.getFieldDecorator<MDDivisionFormData>('name', {
            initialValue: defaults?.name,
            rules: [
              requiredRule('forms.items.name.rules.required', true),
              maxLengthRule('general.maxNameLength', apiConstraints.mdDivisionCreateDto.name.maxLength),
              minLengthRule('general.minNameLength', apiConstraints.mdDivisionCreateDto.name.minLength),
              duplicateNameRuleCallback(validateUniqueName),
            ],
          })(<Input autoFocus />)}
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'MD.Organization.divisions.modal.input.description' })}>
          {form.getFieldDecorator<MDDivisionFormData>('description', {
            initialValue: defaults?.description,
            rules: [
              maxLengthRule('general.maxDescriptionLength', apiConstraints.mdDivisionCreateDto.description.maxLength),
            ],
          })(<Input.TextArea rows={2} autoSize={DESCRIPTION_AUTOSIZE} />)}
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'MD.Organization.divisions.modal.input.organization' })}>
          {form.getFieldDecorator<MDDivisionFormData>('organizationName', {
            initialValue: defaults?.organizationName,
            rules: [
              {
                whitespace: true,
                message: intl.formatMessage({ id: 'forms.items.name.rules.empty' }),
              },
              maxLengthRule('general.maxNameLength', apiConstraints.mdDivisionCreateDto.mdOrganizationName.maxLength),
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'MD.Organization.divisions.modal.input.leader' })}>
          {form.getFieldDecorator<MDDivisionFormData>('headsOrganizationUserIds', {
            initialValue: defaultLeaders,
            rules: [requiredRule('MD.Organization.divisions.modal.input.leader.required', false)],
          })(<UserTransferFormItem users={availableHeadList} />)}
        </Form.Item>
        {showUserTransfer && (
          <Form.Item label={intl.formatMessage({ id: 'general.users' })}>
            {form.getFieldDecorator<MDDivisionFormData>('users', {
              initialValue: defaults?.mdUsers.map((user) => user.orgUser.id) || [],
            })(<UserTransferFormItem users={availableUserList} />)}
          </Form.Item>
        )}
      </Form>
    );
  }
);

export const MDDivisionForm = React.memo(Form.create<Props>()(MDDivisionFormComponent));
