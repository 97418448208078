import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Input, InputRef } from 'antd';

import Select, { DefaultOptionType } from 'antd/lib/select';
import { apiConstraints } from 'api/completeApiConstraints';
import { MdCkIdentificationEnum, MdProjectPhaseEnum, ProjectTemplateListDto } from 'api/completeApiInterfaces';
import { Fmt, InjectedIntlProps } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import { compact } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { maxLengthRule, minLengthRule, requiredRule } from 'utils/formHelpersCompatibility';

export type MDProjectCreateFormData = {
  name: string;
  description?: string;
  requestedPhase: MdProjectPhaseEnum;
  templateId: Guid;
  ckIdentification: MdCkIdentificationEnum;
};

export const MAP_CK_IDENTIFICATIONS_ENUM_TO_INTL_MESSAGE_ID: Record<MdCkIdentificationEnum, IntlMessageId> = {
  [MdCkIdentificationEnum.other]: 'MD.MDcreateProject.ckIdentification.other',
  [MdCkIdentificationEnum.railway]: 'MD.MDcreateProject.ckIdentification.railway',
  [MdCkIdentificationEnum.road]: 'MD.MDcreateProject.ckIdentification.road',
  [MdCkIdentificationEnum.water]: 'MD.MDcreateProject.ckIdentification.water',
};

const DESCRIPTION_ROWS = 3;

type Props = FormComponentProps<MDProjectCreateFormData> &
  InjectedIntlProps & {
    setRef: (ref: InputRef) => void;
    projectTemplates: ProjectTemplateListDto[];
    initialPhaseValue?: MdProjectPhaseEnum;
  };

const MDProjectCreateForm = React.forwardRef<unknown, Props>(
  ({ intl, form, projectTemplates, initialPhaseValue, setRef }, ref) => {
    // backward compatibility with class components
    useEffect(() => {
      (ref as any).current = { props: { form } };
    }, [form]);
    const { getFieldDecorator } = form;

    const phaseOptions = useMemo(
      (): DefaultOptionType[] =>
        compact([
          { label: intl.formatMessage({ id: 'MD.ProjectPhase.other' }), value: MdProjectPhaseEnum.other },
          { label: intl.formatMessage({ id: 'MD.ProjectPhase.init' }), value: MdProjectPhaseEnum.init },
          { label: intl.formatMessage({ id: 'MD.ProjectPhase.study' }), value: MdProjectPhaseEnum.study },
          { label: intl.formatMessage({ id: 'MD.ProjectPhase.intention' }), value: MdProjectPhaseEnum.intention },
          !!initialPhaseValue
            ? { label: intl.formatMessage({ id: 'MD.ProjectPhase.update' }), value: MdProjectPhaseEnum.update }
            : null,
        ]),
      [intl, initialPhaseValue]
    );

    const templateOptions: DefaultOptionType[] = useMemo(
      () =>
        projectTemplates?.map((template) => ({
          label: template.name,
          value: template.id,
        })) || [],
      [projectTemplates]
    );

    const ckIdentificationsOptions: DefaultOptionType[] = useMemo(
      () =>
        Object.entries(MAP_CK_IDENTIFICATIONS_ENUM_TO_INTL_MESSAGE_ID).map(([enumValue, intlMessageId]) => ({
          label: intl.formatMessage({ id: intlMessageId }),
          value: enumValue,
        })) || [],
      [intl]
    );

    return (
      <Form form={form}>
        <Form.Item label={<Fmt id="general.name" />}>
          {getFieldDecorator<MDProjectCreateFormData>('name', {
            rules: [
              requiredRule('MD.Projects.ProjectCreateModal.form.name.required', true),
              maxLengthRule('general.maxNameLength', apiConstraints.mdProjectListDto.name.maxLength),
              minLengthRule('general.minNameLength', apiConstraints.mdProjectListDto.name.minLength),
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label={<Fmt id="general.description" />}>
          {getFieldDecorator<MDProjectCreateFormData>('description', {
            rules: [
              maxLengthRule('general.maxDescriptionLength', apiConstraints.mdProjectListDto.description.maxLength),
            ],
          })(<Input.TextArea rows={DESCRIPTION_ROWS} />)}
        </Form.Item>
        <Form.Item label={<Fmt id="MD.Projects.ProjectCreateModal.form.phase" />}>
          {getFieldDecorator<MDProjectCreateFormData>('requestedPhase', {
            rules: [requiredRule('MD.Projects.ProjectCreateModal.form.phase.required', true)],
            initialValue: initialPhaseValue,
          })(<Select options={phaseOptions} disabled={!!initialPhaseValue} />)}
        </Form.Item>

        <Form.Item label={<Fmt id="MD.Projects.ProjectCreateModal.form.template" />}>
          {getFieldDecorator<MDProjectCreateFormData>('templateId', {
            rules: [requiredRule('MD.Projects.ProjectCreateModal.form.template.required', true)],
            initialValue: templateOptions?.length === 1 ? templateOptions[0].value : null,
          })(<Select options={templateOptions} />)}
        </Form.Item>
        <Form.Item label={<Fmt id="MD.Projects.ProjectCreateModal.form.ckIdentification" />}>
          {getFieldDecorator<MDProjectCreateFormData>('ckIdentification', {
            rules: [requiredRule('MD.Projects.ProjectCreateModal.form.ckIdentification.required', true)],
          })(<Select options={ckIdentificationsOptions} allowClear />)}
        </Form.Item>
      </Form>
    );
  }
);

export default Form.create<Props>()(MDProjectCreateForm);
