import { CheckCircleOutlined, ExclamationCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Modal, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { breakTableValue } from 'components/AuditLogsComponents/ParamsTable';
import { MDCkApprovalCondition } from 'components/Reports/MDProjectCard/MDProjectDataItem/MDProjectVariableData.types';
import { COMMON_SETTING_MODAL_WIDTH } from 'config/constants';
import { useIntl } from 'hooks';
import moment from 'moment';
import React, { useMemo } from 'react';
import uuid from 'uuid';

type Props = {
  ckApprovalConditions: MDCkApprovalCondition[];
  onOK: () => void;
};

interface DataType {
  key: Guid;
  Condition: string;
  Deadline?: IsoDateTime;
  ApprovalDone?: boolean;
  ApprovalDescription?: string;
}

const MDCkConditionModal: React.FC<Props> = ({ ckApprovalConditions, onOK }) => {
  const intl = useIntl();

  const columns: ColumnsType<DataType> = [
    {
      title: intl.formatMessage({ id: 'MDCkConditionModal.Condition' }),
      dataIndex: 'Condition',
      key: 'Condition',
      width: 200,
      render: (value: string) => breakTableValue(value),
    },
    {
      title: intl.formatMessage({ id: 'MDCkConditionModal.Deadline' }),
      dataIndex: 'Deadline',
      key: 'Deadline',
      render: (value) =>
        !!value
          ? moment(value as string)
              .locale(intl.locale)
              .format('L')
          : '',
    },
    {
      title: intl.formatMessage({ id: 'MDCkConditionModal.ApprovalDone' }),
      dataIndex: 'ApprovalDone',
      key: 'ApprovalDone',
      render: (value, record) =>
        !!value ? (
          <CheckCircleOutlined style={{ color: 'green' }} />
        ) : moment(record.Deadline).isValid && moment(record.Deadline) < moment() ? (
          <ExclamationCircleOutlined style={{ color: 'red' }} />
        ) : (
          <WarningOutlined />
        ),
    },
    {
      title: intl.formatMessage({ id: 'MDCkConditionModal.ApprovalDescription' }),
      dataIndex: 'ApprovalDescription',
      key: 'ApprovalDescription',
      render: (value: string) => breakTableValue(value),
    },
  ];

  const dataSource = useMemo(() => {
    const data: DataType[] = [];
    ckApprovalConditions.forEach((condition) => {
      data.push({
        key: uuid(),
        Condition: condition.Condition,
        Deadline: condition.Deadline,
        ApprovalDone: condition.ApprovalDone,
        ApprovalDescription: condition.ApprovalDescription,
      });
    });
    return data;
  }, [ckApprovalConditions]);

  return (
    <Modal
      title={intl.formatMessage({ id: 'MDCkConditionModal.title' })}
      open={!!ckApprovalConditions && !!ckApprovalConditions?.length}
      onOk={onOK}
      onCancel={onOK}
      width={COMMON_SETTING_MODAL_WIDTH}
    >
      <Table dataSource={dataSource} columns={columns} pagination={false} />
    </Modal>
  );
};
export default MDCkConditionModal;
