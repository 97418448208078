import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Input, InputRef } from 'antd';

import StackPanel from 'components/StackPanel';
import { MAX_ITEM_NAME_LENGTH } from 'config/constants';
import { Fmt, InjectedIntlProps } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React, { useEffect } from 'react';
import { maxLengthRule, requiredRule } from 'utils/formHelpersCompatibility';
import { KPIConfiguration, KpiRule } from './KpiConfigurationForm.utils';
import { KpiRuleConfigurationFormItem } from './KpiRuleConfigurationFormItem';

export type KpiConfigurationFormData = {
  title: string;
  rules: Record<string, KpiRule>;
};

type Props = FormComponentProps<KpiConfigurationFormData> &
  InjectedIntlProps & {
    defaultConfiguration?: KPIConfiguration;
    availableColumns: Record<string, IntlMessageId>;
    setRef: (ref: InputRef) => void;
  };

const KpiConfigurationForm = React.forwardRef<unknown, Props>(
  ({ intl, form, availableColumns, defaultConfiguration, setRef }, ref) => {
    // backward compatibility with class components
    useEffect(() => {
      (ref as any).current = { props: { form } };
    }, [form]);
    const { getFieldDecorator } = form;

    return (
      <Form layout="vertical">
        <StackPanel vertical>
          <Form.Item label={intl.formatMessage({ id: 'KpiConfigurationForm.title' })}>
            {getFieldDecorator<KpiConfigurationFormData>('title', {
              rules: [
                requiredRule('KpiConfigurationForm.title.required', true),
                maxLengthRule('general.maxNameLength', MAX_ITEM_NAME_LENGTH),
              ],
              initialValue: defaultConfiguration?.title,
            })(<Input autoFocus ref={setRef} />)}
          </Form.Item>
        </StackPanel>
        <Form.Item label={<Fmt id="KpiConfigurationForm.columnRules" />}>
          {form.getFieldDecorator<KpiConfigurationFormData>('rules', {
            initialValue: defaultConfiguration?.rules || {},
          })(<KpiRuleConfigurationFormItem intl={intl} availableColumns={availableColumns} />)}
        </Form.Item>
      </Form>
    );
  }
);

export default Form.create<Props>()(KpiConfigurationForm);
