import '@ant-design/compatible/assets/index.css';
import { TeamOutlined } from '@ant-design/icons';
import { Avatar, Button, Popover } from 'antd';

import { MdRoleDto, MdUserDto, OrgUserClaimDto } from 'api/completeApiInterfaces';
import StackPanel from 'components/StackPanel';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { Fmt } from 'locale';
import React, { FunctionComponent, useMemo } from 'react';
import styles from './MDRoleDisplay.module.less';

type Props = {
  mdRole: MdRoleDto;
  extendedUserClaim: OrgUserClaimDto[];
};

const renderUserClaim = (claim: OrgUserClaimDto) => {
  const claimType = claim.claimType.toLocaleLowerCase();
  if (claimType === 'phonenumber') {
    return (
      <div className={styles.infoRow} key={claimType}>
        <Fmt id={`OrganizationUserClaim.claimType.${claimType}`} />
        {claim.claimValue}
      </div>
    );
  }
  return <></>;
};

const renderMdUser = (mdUser: MdUserDto, extendedUserClaim: OrgUserClaimDto[]) => {
  const userInfo = [
    ((mdUser.orgUser.firstname || '') + ' ' + (mdUser.orgUser.lastname || '')).trim(),
    mdUser.orgUser.appUserProfile.username,
  ]
    .filter(Boolean)
    .map((info, infoOrder) => (
      <div className={styles.infoRow} key={infoOrder}>
        {info}
      </div>
    ));
  return (
    <FlowLayout key={mdUser.id}>
      <StackPanel vertical className={styles.roleUser}>
        {userInfo}
        {extendedUserClaim?.filter((claim) => claim.userId === mdUser.orgUser.id).map(renderUserClaim)}
      </StackPanel>
    </FlowLayout>
  );
};

const MDRoleDisplay: FunctionComponent<Props> = ({ mdRole, extendedUserClaim }) => {
  const roleUsersContent = useMemo(
    () => <StackPanel vertical>{mdRole?.mdUsers.map((mdUser) => renderMdUser(mdUser, extendedUserClaim))}</StackPanel>,
    [extendedUserClaim, mdRole.mdUsers]
  );
  return (
    <FlowLayout className={styles.wrapper}>
      {renderMdUser(mdRole?.head, extendedUserClaim)}
      <Popover content={roleUsersContent} title={<Fmt id="MD.Organization.roles.modal.input.users" />} trigger="hover">
        <Button type="link">
          <Avatar icon={<TeamOutlined />} />
        </Button>
      </Popover>
    </FlowLayout>
  );
};

export default React.memo(MDRoleDisplay);
