import { message } from 'antd';
import { masterApi } from 'api/completeApi';
import {
  OrgUserDto,
  OrgWorkingPositionDto,
  ServiceError,
  WorkingPositionRemoveStrategyEnum,
} from 'api/completeApiInterfaces';
import { EditButton } from 'components/ActionButtons';
import { DeleteButton } from 'components/DeleteButton/DeleteButton';
import { NewFormModalWrapper } from 'components/forms/NewFormModalWrapper/NewFormModalWrapper';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import List from 'components/List';
import { ListEmpty } from 'components/ListEmpty/ListEmpty';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import ServiceErrorBox from 'components/ServiceErrorBox';
import StackPanel from 'components/StackPanel';
import { useBoolean, useDeleteItems, useIntl } from 'hooks';
import { IntlMessageId } from 'locale/messages/cs';
import JobPositionAddForm, {
  JobPositionAddFormData,
} from 'pages/OrganizationsSettingPage/JobPositions/JobPositionAddForm';
import { useJobPositions } from 'pages/OrganizationsSettingPage/JobPositions/useJobPositions';
import Panel from 'pages/ProjectSettingsPage/Panel/Panel';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { smartFilter } from 'utils';

type Props = {
  loading: boolean;
  error: ServiceError;
  titleId?: IntlMessageId;
  panelWidth?: number | string;
  organizationUsers: OrgUserDto[];
  selectedOrganizationId: Guid;
  reloadOrganizationUsers: () => void;
};

const JobPositionsListPanel: FunctionComponent<Props> = ({
  loading,
  error,
  selectedOrganizationId,
  titleId,
  organizationUsers,
  reloadOrganizationUsers,
}) => {
  const [search, setSearch] = useState<string>('');
  const { url } = useRouteMatch();
  const intl = useIntl();
  const [jobPositionFormModalVisible, showJobPositionFormModal, hideJobPositionFormModal] = useBoolean(false);

  const [jobPositions, setJobPositions] = useJobPositions(selectedOrganizationId);

  const addJobPosition = useCallback(
    async (values: JobPositionAddFormData) => {
      if (values.id != null) {
        const [err, data] = await masterApi.projects.reports.workingpositions.id.id.patch(
          selectedOrganizationId,
          values.id,
          { name: values.name }
        );
        if (err) return err;
        hideJobPositionFormModal();
        if (!data?.data?.orgWorkingPositions) return null;
        setJobPositions(data.data.orgWorkingPositions);
        return null;
      }

      const [err, data] = await masterApi.projects.reports.workingpositions.id.post(selectedOrganizationId, values);
      if (err) return err;
      hideJobPositionFormModal();
      if (!data?.data?.orgWorkingPositions) return null;
      setJobPositions(data.data.orgWorkingPositions);
      return null;
    },

    [selectedOrganizationId]
  );

  const [editingJobPosition, setEditingJobPosition] = useState<OrgWorkingPositionDto | null>(null);

  const onEdit = useCallback(
    async (jobPositionId: Guid) => {
      const jobPosition = jobPositions.find((item) => item.id === jobPositionId) || null;
      setEditingJobPosition(jobPosition);

      showJobPositionFormModal();
    },
    [jobPositions]
  );

  const clearSearch = useCallback(() => {
    setSearch('');
  }, []);

  const handleDeleteLabel = (id: Guid) => {
    setJobPositions((data) => data.filter((item) => item.id !== id));
    void message.success(intl.formatMessage({ id: 'JobPositions.delete.confirmed' }));
    reloadOrganizationUsers();
  };

  const [deletingLinks, deleteLink] = useDeleteItems(
    intl,
    (id) =>
      masterApi.projects.reports.workingpositions.id.id.delete(
        selectedOrganizationId,
        id,
        WorkingPositionRemoveStrategyEnum.remove
      ),
    handleDeleteLabel,
    true,
    'JobPositions.delete.confirmTitle',
    (id) =>
      intl.formatMessage(
        { id: 'JobPositions.delete.confirmDeleteFromUsers' },
        { count: organizationUsers.filter((user) => user.workingPosition?.id === id).length }
      )
  );

  const renderContent = (onSelect: (itemId: Guid) => void, selectedId: Guid) => {
    if (error) return <ServiceErrorBox error={error} />;
    if (loading) return null;

    return (
      <GeneralSettingsContainer>
        <List<OrgWorkingPositionDto>
          data={jobPositions}
          search={search}
          renderEmpty={(total, filtered) => <ListEmpty filtered={filtered} total={total} onClearSearch={clearSearch} />}
          filterItem={(item) => item && smartFilter(item?.name, search)}
          renderItem={(item) => (
            <GeneralSettingsItem
              title={item.name}
              key={item.id}
              loading={!!deletingLinks.has(item.id)}
              additionalActions={
                <>
                  <EditButton onClick={() => onEdit(item.id)} />
                  <DeleteButton type="link" onDelete={() => deleteLink(item.id)} />
                </>
              }
            />
          )}
        />
      </GeneralSettingsContainer>
    );
  };

  return (
    <>
      <MasterComponent
        maxWidth={600}
        url={url}
        title={intl.formatMessage({ id: 'JobPositions.title' })}
        children={(onSelect, selectedItemId) => (
          <StackPanel>
            <Panel
              noMargin
              onSearch={setSearch}
              searchValue={search}
              addButtonText={intl.formatMessage({ id: 'JobPositions.addPosition' })}
              addButtonOnClick={() => {
                setEditingJobPosition(null);
                showJobPositionFormModal();
              }}
            >
              {!!titleId && intl.formatMessage({ id: titleId })}
              {renderContent(onSelect, selectedItemId)}
            </Panel>
          </StackPanel>
        )}
      />
      {jobPositionFormModalVisible && (
        <NewFormModalWrapper
          title={intl.formatMessage({ id: 'JobPositions.addPosition' })}
          open={jobPositionFormModalVisible}
          onClose={hideJobPositionFormModal}
          onSubmit={addJobPosition}
        >
          <JobPositionAddForm editingItem={editingJobPosition} />
        </NewFormModalWrapper>
      )}
    </>
  );
};

JobPositionsListPanel.displayName = 'JobPositionsListPanel';

export default JobPositionsListPanel;
