import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { InboxOutlined } from '@ant-design/icons';
import { Col, Collapse, Input, InputRef, Row, Select, Typography, Upload } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { projectApi } from 'api/completeApi';
import { WorkflowStateEnum } from 'api/completeApiInterfaces';
import { PreferredDirectory } from 'components/DirectoriesTreeSelect/DirectoriesPreferredSelect';
import { DirectoriesTreeSelectFormItem } from 'components/DirectoriesTreeSelect/DirectoriesTreeSelect';
import FilenameErrorMessage from 'components/FilenameErrorMessage';
import { FileAddIcon } from 'components/Icons/HubActionsIcons';
import { LabelsInput } from 'components/LabelsInput/LabelsInput';
import PrimaryFileInput from 'components/PrimaryFileInput';
import SingleFileInput from 'components/PrimaryFileInput/SingleFileInput';
import RevisionNumberTag from 'components/RevisionNumberTag';
import { RoleSelect } from 'components/RoleSelect/RoleSelect';
import {
  FILE_AND_FOLDER_NAME_REGEX,
  MAX_ITEM_DESCRIPTION_LENGTH,
  SIGNED_DOCUMENT_ACCEPTED_CONTENT_TYPES,
} from 'config/constants';
import { useApiData } from 'hooks';
import { Fmt, InjectedIntlProps } from 'locale';
import { Dictionary } from 'lodash';
import { canWriteInDirectory } from 'pages/AllDocumentsPage/AllDocumentsPage';
import { default as React, useEffect, useState } from 'react';
import { duplicateNameRuleCallback, maxLengthRule, requiredRule } from 'utils/formHelpersCompatibility';
import { DocumentCategoryFormFc } from '../DocumentCategoryForm/DocumentCategoryFormFc';
import { getFormValue } from '../FormModalWrapper';
import styles from './DocumentCreateForm.module.less';

const { Option } = Select;

export const setFormValue = <T, F extends string & keyof T>(form: WrappedFormUtils<T>, field: F, value: T[F]) =>
  form.setFields({
    [field]: {
      value,
    },
  });

export const useRequiredCategories = (directoryId: Guid | undefined) => {
  const [detail, _error, _loading, loadDetail] = useApiData((ct) =>
    projectApi.directories.primary.id.get(directoryId, ct)
  );

  useEffect(() => {
    if (directoryId) {
      loadDetail();
    }
  }, [directoryId]);

  return detail?.requiredCategoryTrees || {};
};

export type DocumentCreateFormData = {
  name: string;
  description: string;
  labels: Guid[];
  primaryFile: File;
  signedDocument?: File;
  secondaryFiles: UploadChangeParam;
  categories: Dictionary<Guid>;
  destinationDirectory: Guid;
  ownedById: Guid;
  state: WorkflowStateEnum;
};

type Props = FormComponentProps<DocumentCreateFormData> &
  InjectedIntlProps & {
    initialPrimaryFile?: File;
    validateUniqueName: (name: string) => boolean;
    destinationDirectory?: Guid;
    preferredDirectories: PreferredDirectory[];
    showDestinationInput?: boolean;
    allowedStates?: WorkflowStateEnum[];
    setRef: (ref: InputRef) => void;
    usefulNewDocumentStatuses: WorkflowStateEnum[];
    allowSignedDocumentAttachment: boolean;
  };

const DocumentCreateForm = React.forwardRef<unknown, Props>((props, ref) => {
  const {
    intl,
    form,
    validateUniqueName,
    initialPrimaryFile,
    destinationDirectory,
    preferredDirectories,
    showDestinationInput,
    allowedStates,
    setRef,
    usefulNewDocumentStatuses,
    allowSignedDocumentAttachment,
  } = props;

  // backward compatibility with class components
  useEffect(() => {
    (ref as any).current = { props: { form } };
  }, [form]);

  const { getFieldDecorator } = form;

  const [lastFileName, setLastFileName] = useState(initialPrimaryFile?.name || '');

  const handleFileChange = (file: File) => {
    if (file) {
      const currentName = getFormValue(form, 'name');
      const newName = file.name;
      if (currentName === '' || currentName === lastFileName) {
        setFormValue(form, 'name', newName);
      }
      setLastFileName(newName);
    }
  };

  const currentDirectoryId = getFormValue(form, 'destinationDirectory');
  const requiredCategories = useRequiredCategories(currentDirectoryId);

  return (
    <Form layout="vertical">
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item>
            {getFieldDecorator('primaryFile', {
              initialValue: initialPrimaryFile,
              rules: [requiredRule('forms.items.primaryFile.rules.required')],
            })(<PrimaryFileInput onChange={handleFileChange} />)}
          </Form.Item>
          <Form.Item label={intl.formatMessage({ id: 'DocumentCreateForm.form.items.destinationDirectory.label' })}>
            {getFieldDecorator<DocumentCreateFormData>('destinationDirectory', {
              initialValue: destinationDirectory,
              rules: [requiredRule('DocumentCreateForm.form.items.destinationDirectory.rules.required')],
            })(
              <DirectoriesTreeSelectFormItem
                disabled={!showDestinationInput}
                isItemDisabled={(item) => !canWriteInDirectory(item)}
                showSearch
                preferredDirectories={preferredDirectories}
                placeholder={intl.formatMessage({
                  id: 'DocumentCreateForm.form.items.destinationDirectory.placeholder',
                })}
              />
            )}
          </Form.Item>
          <Form.Item label={intl.formatMessage({ id: 'forms.items.name.label' })}>
            {getFieldDecorator<DocumentCreateFormData>('name', {
              initialValue: initialPrimaryFile ? initialPrimaryFile.name : '',
              rules: [
                requiredRule('DocumentCreateForm.form.items.name.rules.required', true),
                maxLengthRule('general.maxNameLength'),
                {
                  pattern: FILE_AND_FOLDER_NAME_REGEX,
                  message: <FilenameErrorMessage />,
                },
                duplicateNameRuleCallback(validateUniqueName, 'DocumentCreateForm.form.items.name.rules.nameExists'),
              ],
            })(
              <Input
                placeholder={intl.formatMessage({ id: 'DocumentCreateForm.form.items.name.placeholder' })}
                autoFocus
                ref={setRef}
              />
            )}
          </Form.Item>
          <Form.Item label={intl.formatMessage({ id: 'forms.items.description.label' })}>
            {getFieldDecorator<DocumentCreateFormData>('description', {
              rules: [
                {
                  whitespace: true,
                  message: intl.formatMessage({ id: 'forms.items.name.rules.empty' }),
                },
                {
                  max: MAX_ITEM_DESCRIPTION_LENGTH,
                  message: intl.formatMessage(
                    { id: 'general.maxDescriptionLength' },
                    { max: MAX_ITEM_DESCRIPTION_LENGTH }
                  ),
                },
              ],
            })(<Input.TextArea rows={2} autoSize={{ minRows: 2 }} />)}
          </Form.Item>
          <Collapse>
            {allowSignedDocumentAttachment && (
              <Collapse.Panel
                header={
                  <>
                    <FileAddIcon className={styles.secondaryFileIcon} />
                    <span>{intl.formatMessage({ id: 'SignedDocumentInput.collapse.header' })}</span>
                  </>
                }
                key="signedDocument"
              >
                {getFieldDecorator('signedDocument')(
                  <SingleFileInput
                    inputText="SignedDocumentInput.collapse.text"
                    inputHint="SignedDocumentInput.collapse.hint"
                    acceptedContentType={SIGNED_DOCUMENT_ACCEPTED_CONTENT_TYPES}
                  />
                )}
              </Collapse.Panel>
            )}
            <Collapse.Panel
              header={
                <>
                  <FileAddIcon className={styles.secondaryFileIcon} />
                  <span>{intl.formatMessage({ id: 'SecondaryFilesInput.collapse.header' })}</span>
                </>
              }
              key="attachments"
            >
              {getFieldDecorator('secondaryFiles')(
                <Upload.Dragger multiple={true} beforeUpload={() => false} customRequest={() => {}}>
                  <p className="ant-upload-text">{intl.formatMessage({ id: 'SecondaryFilesInput.collapse.text' })}</p>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                </Upload.Dragger>
              )}
            </Collapse.Panel>
          </Collapse>
        </Col>
        <Col span={12}>
          <Form.Item label={intl.formatMessage({ id: 'DocumentCreateForm.form.items.state.label' })}>
            {getFieldDecorator<DocumentCreateFormData>('state', {
              initialValue: allowedStates?.length === 1 ? allowedStates[0] : null,
              rules: [requiredRule('DocumentCreateForm.form.items.state.rules.required')],
            })(
              <Select>
                {(allowedStates || usefulNewDocumentStatuses).map((state) => (
                  <Option key={state}>
                    <div className={styles.stateOption}>
                      <RevisionNumberTag state={state} showTitle />
                    </div>
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item label={<Fmt id="general.manager" />}>
            {getFieldDecorator<DocumentCreateFormData>('ownedById')(<RoleSelect allowClear />)}
          </Form.Item>

          <Form.Item label={intl.formatMessage({ id: 'forms.items.labels.label' })}>
            {getFieldDecorator<DocumentCreateFormData>('labels', { initialValue: [] })(<LabelsInput />)}
          </Form.Item>

          <Typography.Title level={4}>
            <Fmt id="DocumentCreateForm.section.categories" />
          </Typography.Title>

          <DocumentCategoryFormFc
            layout="horizontal"
            form={form}
            requiredCategories={requiredCategories}
            autoSelectDefault={true}
          />
        </Col>
      </Row>
    </Form>
  );
});

export default Form.create<Props>()(DocumentCreateForm);
