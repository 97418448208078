import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Alert, Input, InputRef } from 'antd';
import { Margin } from 'components/Margin/Margin';
import { MAX_ITEM_NAME_LENGTH } from 'config/constants';
import { Fmt, InjectedIntlProps } from 'locale';
import React, { useEffect } from 'react';
import { maxLengthRule, requiredRule } from 'utils/formHelpersCompatibility';
import styles from './DiscussionInputAttachmentNameForm.module.less';

export type DiscussionInputAttachmentNameFormData = {
  name: string;
};

type Props = FormComponentProps<DiscussionInputAttachmentNameFormData> &
  InjectedIntlProps & { imageFile: File; disabled: boolean; setRef: (ref: InputRef) => void };

const DiscussionInputAttachmentNameFormComponent = React.forwardRef<unknown, Props>(
  ({ intl, form, imageFile, disabled, setRef }, ref) => {
    // backward compatibility with class components
    useEffect(() => {
      (ref as any).current = { props: { form } };
    }, [form]);

    // render
    return (
      <Form layout="vertical">
        <img src={imageFile && URL.createObjectURL(imageFile)} className={styles.imagePreview} />
        {disabled ? (
          <Margin bottom>
            <Alert message={<Fmt id="DiscussionLinkAttachmentForm.disabled" />} type="error" />
          </Margin>
        ) : (
          <Form.Item label={intl.formatMessage({ id: 'DiscussionLinkAttachmentForm.name' })}>
            {form.getFieldDecorator<DiscussionInputAttachmentNameFormData>('name', {
              rules: [
                requiredRule('DiscussionLinkAttachmentForm.name.required', true),
                maxLengthRule('general.maxNameLength', MAX_ITEM_NAME_LENGTH),
              ],
            })(<Input autoFocus ref={setRef} />)}
          </Form.Item>
        )}
      </Form>
    );
  }
);

export const DiscussionInputAttachmentNameForm = Form.create<Props>()(DiscussionInputAttachmentNameFormComponent);
