import {
  CaretRightOutlined,
  CheckOutlined,
  DeleteOutlined,
  DownloadOutlined,
  LoadingOutlined,
  PauseOutlined,
  PictureOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import ButtonGroup from 'antd/es/button/button-group';
import classNames from 'classnames';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { useCapture } from 'hooks/useCapture';
import { Fmt } from 'locale';
import React, { FC } from 'react';
import styles from './Capture.module.less';

type Props = {
  onCaptured: (data: { blob: Blob }) => void;
  onCancel: () => void;
};

export const Capture: FC<Props> = ({ onCaptured, onCancel }) => {
  const {
    startCapture,
    stopCapture,
    recording,
    capturedData,
    setCameraSettings,
    previewRef,
    recordingRef,
    downloadButtonRef,
    cameraInit,
    cameraSettings,
    clearData,
  } = useCapture();

  const [playingState, setPlayingState] = React.useState(true);

  return (
    <div className={styles.container}>
      <>
        <div className={classNames(styles.recordButtons)}>
          <div className={styles.left}>
            {capturedData ? (
              <a href="#" ref={downloadButtonRef} className={classNames(!capturedData && styles.hidden)}>
                <Button icon={<DownloadOutlined />}>
                  <Fmt id="general.download" />
                </Button>
              </a>
            ) : (
              <ButtonGroup>
                <Button
                  icon={<PictureOutlined />}
                  type={cameraSettings === 'LANDSCAPE' ? 'primary' : 'default'}
                  onClick={() => {
                    setCameraSettings('LANDSCAPE');
                  }}
                />
                <Button
                  icon={<UserOutlined />}
                  type={cameraSettings === 'PORTRAIT' ? 'primary' : 'default'}
                  onClick={() => {
                    setCameraSettings('PORTRAIT');
                  }}
                />
              </ButtonGroup>
            )}
          </div>
          <div className={styles.recordButtonWrap}>
            {!!capturedData ? (
              <>
                <Button
                  type="primary"
                  shape="circle"
                  size="large"
                  icon={!playingState ? <CaretRightOutlined /> : <PauseOutlined />}
                  onClick={() => {
                    if (recordingRef.current?.paused) {
                      recordingRef.current?.play().catch(console.error);
                      setPlayingState(true);
                    } else {
                      recordingRef.current?.pause();
                      setPlayingState(false);
                    }
                  }}
                />
              </>
            ) : (
              <>
                {cameraInit ? (
                  <button
                    className={classNames(styles.recordButton, recording && styles.recording)}
                    onClick={() => {
                      if (!recording) {
                        startCapture();
                      } else {
                        stopCapture();
                      }
                    }}
                  />
                ) : (
                  <LoadingOutlined />
                )}
              </>
            )}
          </div>
          <div className={styles.right}>
            {!!capturedData && (
              <FlowLayout>
                <Button
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    onCancel();
                    clearData();
                  }}
                  type="primary"
                >
                  <Fmt id="Capture.record.delete" />
                </Button>
                <Button
                  icon={<CheckOutlined />}
                  onClick={() => {
                    onCaptured({ blob: capturedData });
                    clearData();
                  }}
                  type="primary"
                >
                  <Fmt id="Capture.record.confirm" />
                </Button>
              </FlowLayout>
            )}
          </div>
        </div>
        <video
          className={classNames(styles.preview, !!capturedData && styles.hidden)}
          ref={previewRef}
          autoPlay
          playsInline
          muted
        />
        <video
          autoPlay
          loop
          className={classNames(styles.recordedVideoFull, !capturedData && styles.hidden)}
          ref={recordingRef}
        />
      </>
    </div>
  );
};
