import { masterApi } from 'api/completeApi';
import { MdDivisionDto, OrgUserDto } from 'api/completeApiInterfaces';
import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from 'components/forms/FormModalWrapper';
import { useIntl, useSameCallback } from 'hooks';
import { uniq } from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { messageError } from 'utils';
import { MDDivisionAddUserForm, MDDivisionAddUsersFormData } from './MDDivisionAddUserForm';

type Props = FormModalProps<MdDivisionDto[]> & {
  selectedDivision: MdDivisionDto;
  organizationUsers: OrgUserDto[];
  assignedUsersIds: Guid[];
};

const MDDivisionUserAddFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, selectedDivision, organizationUsers, assignedUsersIds, ...restProps } = props;
  const [saving, setSaving] = useState<boolean>();
  const intl = useIntl();

  const handleSubmit: FormSubmitHandler<MDDivisionAddUsersFormData> = useSameCallback(async (values) => {
    setSaving(true);
    const [err, res] = await masterApi.projects.md.division.patch.patch({
      ...selectedDivision,
      users: {
        headsOrganizationUserIds: selectedDivision.mdUsers.filter((user) => user.isHead).map((user) => user.orgUser.id),
        membersOrganizationUserIds: uniq([
          ...selectedDivision.mdUsers.filter((user) => !user.isHead).map((user) => user.orgUser.id),
          ...values.users,
        ]),
      },
      deleteMembersFromRoles: false,
    });
    if (err) {
      messageError(err, intl);
    } else {
      onSubmit(res.data);
    }

    setSaving(false);
    return null;
  });

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      titleId={'MD.Organization.divisionsUsers.modal.title.add'}
      submitTextId={'general.add'}
      forceLoading={saving}
      width={700}
      {...restProps}
    >
      {({ intl, formRef }) => (
        <MDDivisionAddUserForm
          intl={intl}
          wrappedComponentRef={formRef}
          organizationUsers={organizationUsers}
          assignedUsersIds={assignedUsersIds}
        />
      )}
    </FormModalWrapper>
  );
};

export default MDDivisionUserAddFormModal;
