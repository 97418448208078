import { Modal } from 'antd';
import { masterApi } from 'api/completeApi';
import { EntityTypesEnum, MdDivisionDto, OrgUserDto } from 'api/completeApiInterfaces';
import { MdDivisionObstacleErrorData, ServiceErrorEnum } from 'api/errors';
import { AuditLogButton, EditButton } from 'components/ActionButtons';
import { DeleteButtonConfirm } from 'components/ActionButtons/DeleteButtonConfirm';
import AuditLogMasterApiEntityModal from 'components/AuditLogsComponents/AuditLogMasterApiEntityModal';
import CommonHubEllipsisText from 'components/CommonHubEllipsisText/CommonHubEllipsisText';
import { ContentGate } from 'components/ContentGate/ContentGate';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import List from 'components/List';
import { ListEmpty } from 'components/ListEmpty/ListEmpty';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import StackPanel from 'components/StackPanel';
import { useApiData, useBoolean, useIntl } from 'hooks';
import { Fmt, InjectedIntlProps } from 'locale';
import Panel from 'pages/ProjectSettingsPage/Panel/Panel';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { messageError, processApiError, smartFilter, strCompareCI } from 'utils';
import MDDivisionDetailPanel from './MDDivisionDetailPanel';
import MDDivisionFormModal from './MDDivisionFormModal';

type Props = InjectedIntlProps & {
  organizationId: Guid;
  organizationUsers: OrgUserDto[];
};

export const RESERVED_DIVISION_NAME_O910 = 'O910';

const MDDivisionListPanel: FunctionComponent<Props> = ({ organizationId, organizationUsers }) => {
  const [search, setSearch] = useState<string>('');
  const [divisionModalVisible, showDivisionModal, hideDivisionModal] = useBoolean(false);
  const [editedDivisionId, setEditedDivisionId] = useState<Guid>(undefined);
  const [toShowActivityMdDivision, setToShowActivityMdDivision] = useState<MdDivisionDto>();

  const { url } = useRouteMatch();
  const intl = useIntl();

  const [divisions, divisionsError, divisionsLoading, loadDivisions, setDivisions] = useApiData(
    (ct) => masterApi.projects.md.divisions.id.get(organizationId, ct),
    { autoload: true }
  );

  const editedDivision = useMemo(() => divisions?.find((division) => division.id === editedDivisionId), [
    divisions,
    editedDivisionId,
  ]);

  const handleDivisionEditShow = useCallback(
    (editedId: Guid) => {
      setEditedDivisionId(editedId);
      showDivisionModal();
    },
    [showDivisionModal]
  );

  const handleDivisionSubmit = useCallback(
    (updatedDivisions: MdDivisionDto[]) => {
      setEditedDivisionId(undefined);
      setDivisions(updatedDivisions);
      hideDivisionModal();
    },
    [hideDivisionModal, setDivisions]
  );

  const handleDivisionClose = useCallback(() => {
    setEditedDivisionId(undefined);
    hideDivisionModal();
  }, [hideDivisionModal]);

  const handleDivisionDelete = useCallback(
    async (divisionId: Guid) => {
      const [err] = await masterApi.projects.md.division.delete.id.delete(divisionId);
      if (err) {
        const error = processApiError(err);
        if (error && error.referenceErrorCode === ServiceErrorEnum.MdDivisionObstacleError) {
          const errData = error.errorData as MdDivisionObstacleErrorData;
          Modal.confirm({
            title: intl.formatMessage({ id: 'MD.Organization.divisionsUsers.delete.division.title' }),
            content: intl.formatMessage(
              { id: 'MD.Organization.divisionsUsers.delete.division.description' },
              {
                roles: errData.mdRoles
                  .map((role) => intl.formatMessage({ id: `MD.Roles.name.${role.roleType}` }))
                  .join(', '),
              }
            ),
            onOk: async () => {
              const [err] = await masterApi.projects.md.division.delete.id.delete(divisionId, true);
              if (err) {
                messageError(err, intl);
                return;
              }
              loadDivisions();
            },
            okText: intl.formatMessage({ id: 'general.yes' }),
            cancelText: intl.formatMessage({ id: 'general.no' }),
          });
        } else {
          messageError(err, intl);
        }
      } else {
        loadDivisions();
      }
    },
    [intl, loadDivisions]
  );

  const validateUniqueName = useCallback(
    (name: string): boolean =>
      name
        ? !divisions?.some(
            (division) =>
              strCompareCI(division.name, name) === 0 && (!!editedDivisionId ? division.id !== editedDivisionId : true)
          )
        : true,
    [editedDivisionId, divisions]
  );

  const assignedUsersIds = useMemo(
    () => divisions?.flatMap((division) => division.mdUsers.map((user) => user.orgUser.id)),
    [divisions]
  );

  const clearSearch = useCallback(() => {
    setSearch('');
  }, []);

  const hideAuditLog = useCallback(() => {
    setToShowActivityMdDivision(undefined);
  }, []);

  return (
    <>
      <MasterComponent
        url={url}
        title={intl.formatMessage({ id: 'MD.Organization.divisions.title' })}
        children={(onSelect, selectedItemId) => (
          <StackPanel>
            <Panel
              noMargin
              addButtonOnClick={showDivisionModal}
              addButtonText={<Fmt id="MD.Organization.divisions.panel.add" />}
              onSearch={setSearch}
              searchValue={search}
            >
              <ContentGate loading={divisionsLoading} empty={!divisions?.length} error={divisionsError}>
                <GeneralSettingsContainer>
                  <List<MdDivisionDto>
                    data={divisions}
                    search={search}
                    filterItem={(item) =>
                      (item && smartFilter(item?.name, search)) || smartFilter(item?.description, search)
                    }
                    renderItem={(item) => (
                      <GeneralSettingsItem
                        key={item.id}
                        title={item.name}
                        wrap
                        selected={item.id === selectedItemId}
                        selectable
                        onClick={onSelect ? () => onSelect(item.id) : null}
                        description={
                          !!item.description ? (
                            <CommonHubEllipsisText placement="topLeft" title={item.description}>
                              {item.description}
                            </CommonHubEllipsisText>
                          ) : null
                        }
                        additionalActions={
                          <>
                            <EditButton onClick={() => handleDivisionEditShow(item.id)} />
                            <DeleteButtonConfirm
                              onConfirm={() => handleDivisionDelete(item.id)}
                              disabled={item.name === RESERVED_DIVISION_NAME_O910}
                              confirmTooltip={<Fmt id="MD.Organization.divisions.panel.delete.confirm.tooltip" />}
                            />
                            <AuditLogButton key="auditLogButton" onClick={() => setToShowActivityMdDivision(item)} />
                          </>
                        }
                      />
                    )}
                    renderEmpty={(total, filtered) => (
                      <ListEmpty filtered={filtered} total={total} onClearSearch={clearSearch} />
                    )}
                  />
                </GeneralSettingsContainer>
              </ContentGate>
            </Panel>
          </StackPanel>
        )}
      />
      <Switch>
        <Route
          path={`${url}/:divisionId`}
          render={() => (
            <MDDivisionDetailPanel
              divisions={divisions}
              organizationUsers={organizationUsers}
              intl={intl}
              setDivisions={setDivisions}
              assignedUsersIds={assignedUsersIds}
            />
          )}
        />
      </Switch>
      <MDDivisionFormModal
        organizationId={organizationId}
        editedDivision={editedDivision}
        validateUniqueName={validateUniqueName}
        onSubmit={handleDivisionSubmit}
        onClose={handleDivisionClose}
        visible={divisionModalVisible}
        organizationUsers={organizationUsers}
        assignedUsersIds={assignedUsersIds}
      />
      {!!toShowActivityMdDivision && (
        <AuditLogMasterApiEntityModal
          visible={!!toShowActivityMdDivision}
          label={<Fmt id="AuditLog.division.label" values={{ divisionName: toShowActivityMdDivision.name }} />}
          entityId={toShowActivityMdDivision.id}
          entityType={EntityTypesEnum.mdDivision}
          onOk={hideAuditLog}
          deps={[toShowActivityMdDivision]}
        />
      )}
    </>
  );
};

export default React.memo(MDDivisionListPanel);
