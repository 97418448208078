import '@ant-design/compatible/assets/index.css';
import { Checkbox } from 'antd';

import { CheckboxChangeEvent, CheckboxProps } from 'antd/lib/checkbox';
import React, { FunctionComponent, useCallback } from 'react';
import { JSONVariableTypeEnum, MDBoolean } from '../MDProjectVariableData.types';

type Props = {
  value?: MDBoolean;
  onChange?: (value: MDBoolean) => void;
} & Omit<CheckboxProps, 'value' | 'onChange'>;

const MDVariableCheckboxFormItem: FunctionComponent<Props> = ({ value, onChange, ...restProps }) => {
  const handleChange = useCallback(
    (e: CheckboxChangeEvent) => {
      onChange && onChange({ type: JSONVariableTypeEnum.boolean, value: e.target.checked ? 'true' : 'false' });
    },
    [onChange]
  );
  return <Checkbox onChange={handleChange} checked={value?.value === 'true'} {...restProps} />;
};

export default React.memo(MDVariableCheckboxFormItem);
