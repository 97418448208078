import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { MdDivisionDto, MdRoleDto, MdUserDto, ProjectUserProfileListDto } from 'api/completeApiInterfaces';
import { UserTransferFormItem } from 'components/UserTransfer/UserTransfer';
import { InjectedIntlProps } from 'locale';
import React, { useEffect, useMemo, useState } from 'react';
import { smartFilter } from 'utils';
import { requiredRule } from 'utils/formHelpersCompatibility';

export type MDRoleFormData = {
  headMdUserId: Guid;
  mdUserIds?: Guid[];
};

type Props = FormComponentProps<MDRoleFormData> &
  InjectedIntlProps & {
    defaults?: MdRoleDto;
    divisions: MdDivisionDto[];
    usedRoleUsers: MdUserDto[];
    existingRoleLeaderIds: Guid[];
    disableHeadUserEdit?: boolean;
  };

const headUserSelectFilter = (input: string, option?: DefaultOptionType) => {
  return smartFilter(option.label.toString(), input);
};

const MDRoleFormComponent = React.forwardRef<unknown, Props>(
  ({ intl, form, divisions, existingRoleLeaderIds, usedRoleUsers, disableHeadUserEdit, defaults }, ref) => {
    // backward compatibility with class components
    useEffect(() => {
      (ref as any).current = { props: { form } };
    }, [form]);

    const [selectedHeadUserId, setSelectedHeadUserId] = useState<Guid>(defaults?.head.id);
    const [selectedDivision, setSelectedDivision] = useState<MdDivisionDto>(defaults?.division);

    useEffect(() => {
      form.setFieldsValue({ headMdUserId: defaults?.head.id, mdUserIds: defaults?.mdUsers.map((user) => user.id) });
    }, [defaults]);

    const selectedLeaderDivision = useMemo(() => {
      return divisions.find((division) => division.mdUsers.some((user) => user.id === selectedHeadUserId));
    }, [divisions, selectedHeadUserId]);

    useEffect(() => {
      const headUserId = form.getFieldValue('headMdUserId');
      setSelectedHeadUserId(headUserId);
      const headUserDivision = divisions.find((division) => division.mdUsers.some((user) => user.id === headUserId));
      if (headUserDivision?.id !== selectedDivision?.id || !headUserId) {
        setSelectedDivision(headUserDivision);
        form.setFieldsValue({ mdUserIds: [] });
      }
    }, [form.getFieldValue('headMdUserId')]);

    const availableUserList = useMemo(
      () =>
        divisions
          .filter((division) => division.id === selectedLeaderDivision?.id)
          .flatMap((division) => division.mdUsers)
          .filter((mdUser) => mdUser.id !== form.getFieldValue('headMdUserId'))
          .filter((mdUser) => !usedRoleUsers.some((usedUser) => usedUser.id === mdUser.id))
          .map(
            (mdUser): ProjectUserProfileListDto => ({
              id: mdUser.id,
              username: mdUser.orgUser.appUserProfile.username,
              firstname: mdUser.orgUser.firstname,
              lastname: mdUser.orgUser.lastname,
              isAdmin: mdUser.orgUser.isAdmin,
              status: undefined,
            })
          ),
      [divisions, selectedLeaderDivision, usedRoleUsers, form.getFieldValue('headMdUserId')]
    );

    const leaderOptions = useMemo((): DefaultOptionType[] => {
      return divisions
        .filter((division) => !selectedLeaderDivision || division.id === selectedLeaderDivision?.id)
        .flatMap((division) => division.mdUsers)
        .filter((mdUser) => !existingRoleLeaderIds.includes(mdUser.id) || mdUser.id === defaults?.head.id)
        .map((mdUser) => ({
          label: mdUser.orgUser.appUserProfile.username,
          value: mdUser.id,
        }));
    }, [defaults?.head.id, divisions, existingRoleLeaderIds, selectedLeaderDivision]);

    return (
      <Form layout="vertical">
        <Form.Item label={intl.formatMessage({ id: 'MD.Organization.roles.modal.selectedDivision' })}>
          {selectedLeaderDivision?.name ||
            intl.formatMessage({ id: 'MD.Organization.roles.modal.selectedDivision.notSelected' })}
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'MD.Organization.roles.modal.input.leader' })}>
          {form.getFieldDecorator<MDRoleFormData>('headMdUserId', {
            initialValue: defaults?.head.id,
            rules: [requiredRule('MD.Organization.roles.modal.input.leader.required', true)],
          })(
            <Select
              options={leaderOptions}
              showSearch
              filterOption={headUserSelectFilter}
              allowClear
              disabled={disableHeadUserEdit}
            />
          )}
        </Form.Item>
        {divisions && (
          <Form.Item label={intl.formatMessage({ id: 'MD.Organization.roles.modal.input.users' })}>
            {form.getFieldDecorator<MDRoleFormData>('mdUserIds', {
              initialValue: defaults?.mdUsers.map((user) => user.id),
            })(<UserTransferFormItem users={availableUserList} />)}
          </Form.Item>
        )}
      </Form>
    );
  }
);

export const MDRoleForm = React.memo(Form.create<Props>()(MDRoleFormComponent));
