import { EditOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal } from 'antd';
import { MaskItemTextType } from 'components/MaskInput/MaskInput.types';
import { useBoolean, useIntl } from 'hooks';
import React, { FC } from 'react';

type ItemProps = MaskItemTextType & {
  id: string;
};

type Props = {
  item: ItemProps;
  onChange?: (item: ItemProps) => void;
};

export const MaskItemTextInput: FC<Props> = ({ item, onChange }: Props) => {
  const intl = useIntl();
  const [form] = Form.useForm<MaskItemTextType>();
  const [open, show, hide] = useBoolean(false);

  return (
    <>
      <Button size="small" onClick={show} icon={<EditOutlined />}>
        {`"${item.value}"`}
      </Button>
      <Modal
        title={intl.formatMessage({ id: 'folderMasks.MaskItem.text.title' })}
        open={open}
        onCancel={hide}
        onOk={() => {
          form.validateFields().then((values) => {
            onChange?.({
              id: item.id,
              value: values.value,
            });
            hide();
          });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          <Form.Item
            name="value"
            label={intl.formatMessage({ id: 'folderMasks.MaskItem.text.label' })}
            initialValue={item.value}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'folderMasks.MaskItem.text.rules.required' }),
              },
              ({ getFieldValue }) => ({
                // https://docs.microsoft.com/en-us/windows/win32/fileio/naming-a-file?redirectedfrom=MSDN
                validator(_, value) {
                  if (!value) return Promise.resolve();
                  const regex = /^[^\\/:*?"<>|]+$/;
                  if (!regex.test(value) || value === '0') {
                    return Promise.reject(
                      new Error(intl.formatMessage({ id: 'folderMasks.MaskItem.text.rules.invalidCharacters' }))
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
