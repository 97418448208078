import React, { createContext } from 'react';
import { FormInstance } from 'antd';

type ValueType = {
  form: FormInstance<any>;
};

export const FormModalWrapperContext = createContext<ValueType>(undefined);

export const useFormModalWrapperContext = () => {
  const context = React.useContext(FormModalWrapperContext);

  if (!context) {
    throw new Error('FormModalWrapper compound components cannot be rendered outside the FormModalWrapper component');
  }

  return context;
};
