import { OrgExtendedPermissionEnum } from 'api/completeApiInterfaces';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import SideMenuActivator, { SideMenuKey } from 'components/SideMenuActivator';
import { useIntl } from 'hooks';
import PageContent from 'pages/ProjectSettingsPage/PageContent';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import ReportDetail from './ReportDetail';
import styles from './ReportDetailPage.module.less';

type Props = RouteComponentProps;

export type ReportDetailPanelParams = {
  organizationId: Guid;
  report: OrgExtendedPermissionEnum;
};

export type ReportTitlePart = {
  key: string;
  link: string;
  title: string;
  onClick?: () => void;
};

const ReportDetailPageComponent: FunctionComponent<Props> = () => {
  const [breadcrumbParts, setBreadcrumb] = useState<ReportTitlePart[]>([]);
  const intl = useIntl();

  const addBreadcrumb = useCallback(
    (part: ReportTitlePart) => setBreadcrumb((parts) => [...parts, part].sort((a, b) => a.link.length - b.link.length)),
    []
  );

  const removeBreadcrumb = useCallback(
    (key: string) => setBreadcrumb((parts) => [...parts.filter((part) => part.key !== key)]),
    []
  );

  useEffect(() => {
    addBreadcrumb({ key: 'reports', title: intl.formatMessage({ id: 'Reporting.list.title' }), link: '/reports' });
  }, []);

  return (
    <ErrorBoundary>
      <SideMenuActivator menuItemKey={SideMenuKey.REPORTING}>
        <div className={styles.content}>
          <PageContent
            title={
              <>
                {breadcrumbParts
                  .map<React.ReactNode>((part) =>
                    part.onClick ? (
                      <span key={part.key} onClick={part.onClick} className={styles.breadcrumbLink}>
                        {part.title}
                      </span>
                    ) : (
                      <Link key={part.key} to={part.link}>
                        {part.title}
                      </Link>
                    )
                  )
                  .reduce((prev, curr) => (!!prev ? [prev, ' / ', curr] : curr), undefined)}
              </>
            }
            titleTooltip={breadcrumbParts.map((part) => part.title).join(' / ')}
          >
            <ReportDetail addBreadcrumb={addBreadcrumb} removeBreadcrumb={removeBreadcrumb} />
          </PageContent>
        </div>
      </SideMenuActivator>
    </ErrorBoundary>
  );
};

export default React.memo(ReportDetailPageComponent);
