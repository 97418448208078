import { MdProjectDto, MdProjectPhaseEnum, MdProjectVariableEnum } from 'api/completeApiInterfaces';
import React from 'react';
import MDProjectCardVariableFields from './MDProjectCardVariableFields';

type Props = {
  mdProject: MdProjectDto;
  canEditAsProposer: boolean;
  canEditAsGuarantor: boolean;
  canEditAsCKOrganizer: boolean;
};

const mappedPhaseProjectDataItems: Record<MdProjectPhaseEnum, MdProjectVariableEnum[]> = {
  [MdProjectPhaseEnum.other]: [],
  [MdProjectPhaseEnum.init]: [
    MdProjectVariableEnum.constructionRegion,
    MdProjectVariableEnum.stretch,
    MdProjectVariableEnum.commentProcedure,
    MdProjectVariableEnum.preparationUpdate,
  ],
  [MdProjectPhaseEnum.study]: [
    MdProjectVariableEnum.studyType,
    MdProjectVariableEnum.actionCharacter,
    MdProjectVariableEnum.constructionRegion,
    MdProjectVariableEnum.stretch,
    MdProjectVariableEnum.commentProcedure,
  ],
  [MdProjectPhaseEnum.intention]: [
    MdProjectVariableEnum.actionCharacter,
    MdProjectVariableEnum.constructionRegion,
    MdProjectVariableEnum.stretch,
    MdProjectVariableEnum.intentionUpdated,
  ],
  [MdProjectPhaseEnum.update]: [
    MdProjectVariableEnum.actionCharacter,
    MdProjectVariableEnum.constructionRegion,
    MdProjectVariableEnum.stretch,
    MdProjectVariableEnum.intentionUpdated,
  ],
};

const MDProjectCardData: React.FC<Props> = ({
  mdProject,
  canEditAsProposer,
  canEditAsGuarantor,
  canEditAsCKOrganizer,
}) => {
  if (!mdProject) return null;

  return (
    <MDProjectCardVariableFields
      fields={mappedPhaseProjectDataItems}
      mdProject={mdProject}
      canEditAsProposer={canEditAsProposer}
      canEditAsGuarantor={canEditAsGuarantor}
      canEditAsCKOrganizer={canEditAsCKOrganizer}
    />
  );
};

export default MDProjectCardData;
