import { VideoCameraOutlined } from '@ant-design/icons';
import { Alert } from 'antd';
import classNames from 'classnames';
import { FileViewerComponent, FileViewerProperties } from 'components/FileViewer/FileViewerInterfaces';
import { Fmt } from 'locale';
import { Dictionary } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import ReactPlayer from 'react-player';
import styles from './Viewer.module.less';

const VIDEO_PLAYER_WIDTH = '100%';
const VIDEO_PLAYER_HEIGHT = '100%';

const supportedTypesMap: Dictionary<string> = {
  'video/mp4': 'video/mp4',
  'application/mp4': 'video/mp4',
  'video/ogg': 'video/ogg',
  'application/ogg': 'video/ogg',
  'video/webm': 'video/webm',
  'video/quicktime': 'video/quicktime',
  'video/3gpp': 'video/3gpp',
};

export const VideoViewer: FileViewerComponent = ({ style, className, url, onLoad, contentType, viewerVisible }) => {
  const sourceType = supportedTypesMap[contentType.toLowerCase()];
  const [playState, setPlayingState] = useState<boolean>(false);

  const isPlaying = useMemo(() => playState && viewerVisible, [playState, viewerVisible]);

  if (!sourceType) return <Alert type="error" message={<Fmt id="FileViewer.UnsupportedMediaType" />} />;

  useEffect(() => onLoad && onLoad(), []);

  return (
    <div style={style} className={classNames(styles.viewer, className)}>
      <ReactPlayer
        controls={true}
        url={url}
        light={false}
        width={VIDEO_PLAYER_WIDTH}
        height={VIDEO_PLAYER_HEIGHT}
        playing={isPlaying}
        onPause={() => setPlayingState(false)}
        onPlay={() => setPlayingState(true)}
      />
    </div>
  );
};

export const VideoViewerData: FileViewerProperties = {
  supportedTypes: Object.keys(supportedTypesMap),
  icon: <VideoCameraOutlined />,
  component: 'VideoViewer',
  titleTranslationId: 'VideoViewer.title',
};
