import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Input } from 'antd';
import { DirectoryLinkMoveDto, DirectoryListDto } from 'api/completeApiInterfaces';
import { DirectoriesTreeSelectFormItem } from 'components/DirectoriesTreeSelect/DirectoriesTreeSelect';
import DocumentMultipleActionError from 'components/DocumentMultipleActionError';
import FilenameErrorMessage from 'components/FilenameErrorMessage';
import PathDisplay from 'components/PathDisplay';
import { FILE_AND_FOLDER_NAME_REGEX } from 'config/constants';
import { Fmt, InjectedIntlProps } from 'locale';
import { canWriteInDirectory, directoryAncestryIds } from 'pages/AllDocumentsPage/AllDocumentsPage';
import React, { Component } from 'react';

import { maxLengthRule, requiredRule } from 'utils/formHelpersCompatibility';
import { ConnectedDirectory } from 'utils/typeMappings/directories/directoryTypes';
import styles from './DirectoryLinkMoveForm.module.less';

type Props = FormComponentProps<DirectoryLinkMoveDto> &
  InjectedIntlProps & {
    selectedDirectory: DirectoryListDto;
    directoryLinkName: string;
    defaultDestinationId?: Guid;
    errors: JSX.Element[];
  };

class DirectoryLinkMoveForm extends Component<Props> {
  isItemDisabled = (directory: ConnectedDirectory) => {
    const { selectedDirectory } = this.props;

    if (!canWriteInDirectory(directory)) {
      return true;
    }

    if (directory.id == selectedDirectory.parentId) {
      return true; // Already in parent directory
    }

    const parentIds = directoryAncestryIds(directory);
    if (parentIds.includes(selectedDirectory.id)) {
      return true; // Cannot move to itself or a subfolder
    }

    return false;
  };

  render() {
    const { intl, form, selectedDirectory, defaultDestinationId, directoryLinkName } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Form layout="horizontal" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
        <div className={styles.movingDirectoryWrap}>
          <div className={styles.movingDirectoryTitle}>{<Fmt id="DirectoryLinkMoveFormModal.moveDirectoryLink" />}</div>
          <PathDisplay path={selectedDirectory.path} className={styles.movingDirectoryPath} />
        </div>
        <Form.Item
          className={styles.item}
          label={intl.formatMessage({ id: 'DirectoryMoveFormModal.destinationDirectoryId.label' })}
        >
          {getFieldDecorator<DirectoryLinkMoveDto>('destination', {
            initialValue: defaultDestinationId,
            rules: [requiredRule('DirectoryCreateForm.form.items.name.rules.required')],
          })(<DirectoriesTreeSelectFormItem isItemDisabled={this.isItemDisabled} />)}
        </Form.Item>
        <Form.Item className={styles.item} label={<Fmt id="DirectoryMoveFormModal.rename" />}>
          {getFieldDecorator<DirectoryLinkMoveDto>('name', {
            initialValue: directoryLinkName,
            rules: [
              requiredRule('DirectoryEditForm.form.items.name.rules.required', true),
              maxLengthRule('general.maxNameLength'),
              {
                pattern: FILE_AND_FOLDER_NAME_REGEX,
                message: <FilenameErrorMessage />,
              },
            ],
          })(<Input type="text" />)}
        </Form.Item>

        <DocumentMultipleActionError errors={this.props.errors} titleId="DocumentsMoveFormModal.errorsTitle.missed" />
      </Form>
    );
  }
}

export default Form.create<Props>()(DirectoryLinkMoveForm);
