import { Button, Typography } from 'antd';
import { masterApi } from 'api/completeApi';
import {
  EstiConUtvaryStructureDto,
  OrgExtendedPermissionCategoryEnum,
  OrgExtendedPermissionEnum,
  OrgExtendedPermissionValueEnum,
  OrgExtendedPermissionsDto,
  OrgExtendedPermissionsPatchDto,
  OrgUserDto,
} from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { DeleteButton } from 'components/DeleteButton/DeleteButton';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import OrgReportAccessLevel from 'components/OrgReportAccessLevel';
import SpinBoxCenter from 'components/SpinBoxCenter';
import StackPanel from 'components/StackPanel';
import { useApiData, useIntl, useItemsSet, useSameCallback } from 'hooks';
import { Fmt, InjectedIntlProps } from 'locale';
import { uniq } from 'lodash';
import Panel from 'pages/ProjectSettingsPage/Panel';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { messageError } from 'utils';
import ReportUnitSettingsModal from './ReportUnitPermissionSettingsModal/ReportUnitSettingsModal';

type Props = InjectedIntlProps & {
  panelWidth?: number | string;
  organizationUsers?: OrgUserDto[];
  organizationId: Guid;
};

type OrganizationUserPermissionParams = {
  userId: string;
};

type PermissionMap = Record<OrgExtendedPermissionCategoryEnum, OrgExtendedPermissionEnum[]>;

const permissionOptionsMap: Record<OrgExtendedPermissionEnum, Set<OrgExtendedPermissionValueEnum>> = {
  financialOverviewByUnit: new Set<OrgExtendedPermissionValueEnum>([
    OrgExtendedPermissionValueEnum.none,
    OrgExtendedPermissionValueEnum.read,
  ]),
  projectsInRealisationOverview: new Set<OrgExtendedPermissionValueEnum>([
    OrgExtendedPermissionValueEnum.none,
    OrgExtendedPermissionValueEnum.read,
    OrgExtendedPermissionValueEnum.write,
  ]),
  projectsInRealisationOverviewAdmin: new Set<OrgExtendedPermissionValueEnum>([
    OrgExtendedPermissionValueEnum.none,
    OrgExtendedPermissionValueEnum.read,
  ]),
  projectsInRealisationShareWidget: new Set<OrgExtendedPermissionValueEnum>([
    OrgExtendedPermissionValueEnum.none,
    OrgExtendedPermissionValueEnum.read,
  ]),
  assignmentsOverview: new Set<OrgExtendedPermissionValueEnum>([
    OrgExtendedPermissionValueEnum.none,
    OrgExtendedPermissionValueEnum.read,
  ]),
};

const extendedPermissionWithAdvancedSettings = new Set<OrgExtendedPermissionEnum>([
  OrgExtendedPermissionEnum.projectsInRealisationOverview,
]);

const EMPTY_USER_PERMISSIONS = {} as Record<OrgExtendedPermissionEnum, OrgExtendedPermissionValueEnum>;

const getPermissionKey = (permission: OrgExtendedPermissionEnum, property: string): string =>
  `${permission}-${property || ''}`;

const OrganizationUserPermissionPanel: FunctionComponent<Props> = ({ organizationUsers, organizationId }) => {
  const [savingItems, addSavingItem, deleteSavingItem] = useItemsSet<string>();
  const [selectedPermissionDetail, setPermissionDetail] = useState<OrgExtendedPermissionEnum>();
  const [activeUserPermissions, setUserPermissions] = useState<Record<string, OrgExtendedPermissionValueEnum>>(
    EMPTY_USER_PERMISSIONS
  );

  const { url } = useRouteMatch();
  const { userId } = useParams<OrganizationUserPermissionParams>();
  const intl = useIntl();

  const setActivePermissions = useCallback((data: OrgExtendedPermissionsDto) => {
    setUserPermissions(
      data.settings.reduce(
        (acc, settings) => ({
          ...acc,
          [getPermissionKey(settings.permissionType, settings.property)]: settings.permission,
        }),
        EMPTY_USER_PERMISSIONS
      )
    );
  }, []);

  const [userPermissions, permissionError, permissionLoading, loadPermission] = useApiData(
    (ct) => masterApi.projects.admin.id.extendedpermission.get(userId, ct),
    {
      autoload: true,
      fetchCallback: setActivePermissions,
    }
  );

  const hidePermissionDetail = () => setPermissionDetail(undefined);

  useEffect(() => {
    loadPermission();
    return () => {
      deleteSavingItem();
      setUserPermissions(EMPTY_USER_PERMISSIONS);
    };
  }, [userId]);

  const handleSave = useSameCallback(
    async (
      userId: Guid,
      permission: OrgExtendedPermissionEnum,
      value: OrgExtendedPermissionValueEnum,
      property?: Guid
    ) => {
      addSavingItem(getPermissionKey(permission, property));

      const data: OrgExtendedPermissionsPatchDto = {
        settings: [{ permission: value, permissionType: permission, property }],
      };
      const [err] = await masterApi.projects.admin.id.extendedpermission.patch(userId, data);

      if (err) {
        messageError(err, intl);
      } else {
        setUserPermissions((current) => ({ ...current, [getPermissionKey(permission, property)]: value }));
      }

      deleteSavingItem(getPermissionKey(permission, property));
    }
  );

  const getPermissionSettingsDisplay = useCallback(
    (permission: OrgExtendedPermissionEnum, unit?: EstiConUtvaryStructureDto) => {
      const permissionProperty = unit?.id;
      return (
        <SpinBoxCenter
          spinning={savingItems.has(getPermissionKey(permission, permissionProperty))}
          flex
          flexDirection="horizontal"
        >
          <OrgReportAccessLevel
            value={activeUserPermissions[getPermissionKey(permission, permissionProperty)]}
            enabledOptions={permissionOptionsMap[permission]}
            onChange={(val) => handleSave(userId, permission, val, permissionProperty)}
          />
          <DeleteButton
            onDelete={() => handleSave(userId, permission, OrgExtendedPermissionValueEnum.none, permissionProperty)}
            type="link"
          />
        </SpinBoxCenter>
      );
    },
    [activeUserPermissions, handleSave, savingItems, userId]
  );

  const permissionMap = useMemo(
    () =>
      userPermissions?.settings.reduce(
        (permissionMap, setting) => ({
          ...permissionMap,
          [setting.category]: [...(permissionMap[setting.category] || []), setting.permissionType],
        }),
        {} as PermissionMap
      ) || ({} as PermissionMap),
    [userPermissions]
  );

  const selectedUser = useMemo(() => organizationUsers?.find((user) => user.id === userId), [
    organizationUsers,
    userId,
  ]);

  console.log('permissionMap', permissionMap);

  return (
    <MasterComponent
      url={url}
      title={selectedUser?.appUserProfile.username}
      children={() => (
        <>
          <ContentGate error={permissionError} loading={permissionLoading} empty={!userPermissions?.settings.length}>
            <Panel
              noMargin
              panelWidth="auto"
              toolbarTitle={
                <Typography.Title level={3}>
                  <Fmt id="OrganizationUserSettingPermissionPanel.Title" />
                </Typography.Title>
              }
            >
              <StackPanel vertical scrollable>
                <GeneralSettingsContainer itemsLargeGap>
                  {Object.keys(permissionMap).map((permissionCategory: OrgExtendedPermissionCategoryEnum) => (
                    <GeneralSettingsContainer
                      key={permissionCategory}
                      title={
                        <Fmt
                          id={`OrganizationUserSettingPermissionPanel.permissionCategoryType.${permissionCategory}`}
                        />
                      }
                    >
                      {uniq(permissionMap[permissionCategory]).map((permission) => (
                        <GeneralSettingsItem
                          key={permission}
                          title={<Fmt id={`OrganizationUserSettingPermissionPanel.permissionType.${permission}`} />}
                          input={
                            <>
                              {extendedPermissionWithAdvancedSettings.has(permission) ? (
                                <Button onClick={() => setPermissionDetail(permission)} type="primary">
                                  <Fmt id="general.edit" />
                                </Button>
                              ) : (
                                getPermissionSettingsDisplay(permission)
                              )}
                            </>
                          }
                        ></GeneralSettingsItem>
                      ))}
                    </GeneralSettingsContainer>
                  ))}
                </GeneralSettingsContainer>
              </StackPanel>
            </Panel>
          </ContentGate>
          <ReportUnitSettingsModal
            organizationId={organizationId}
            open={!!selectedPermissionDetail}
            onClose={hidePermissionDetail}
            onUnitSettingsDisplay={(unit) => getPermissionSettingsDisplay(selectedPermissionDetail, unit)}
          />
        </>
      )}
    />
  );
};

export default OrganizationUserPermissionPanel;
