export const joinRegexExp = (regexs: RegExp[]): RegExp => {
  const flags = regexs
    .map((r) => r.flags)
    .join('') // this line joins flags of all regexes (one regex can one string with multiple flags)
    .split('') // this line splits flags into array of characters
    .sort()
    .join('')
    .replace(/(.)(?=.*\1)/g, ''); // this line eliminates duplicate flags
  return new RegExp('^' + regexs.map((r) => r.source).join(''), flags);
};
