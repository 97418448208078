import { Button, Form, Modal, ModalProps, Progress, Typography } from 'antd';
import { ServiceError } from 'api/completeApiInterfaces';
import { ApiError } from 'api/errors';
import Axios from 'axios';
import ServiceErrorBox from 'components/ServiceErrorBox';
import StackPanel from 'components/StackPanel';
import { FormModalWrapperContext } from 'components/forms/NewFormModalWrapper/FormModalWrapperContext';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { useIntl, useIsMounted } from 'hooks';
import { useCloseOnEscape } from 'hooks/useCloseOnEscape';
import { IntlMessageId } from 'locale/messages/cs';
import React, { FunctionComponent, ReactElement, ReactNode, useCallback, useRef, useState } from 'react';
import { modalFormConfirmClose, processApiError } from 'utils';

export type FormSubmitHandler<T = any> = (values: T) => Promise<ApiError>;

type Props = ModalProps & {
  onSubmit: FormSubmitHandler;
  children: ReactElement;
  onClose?: () => void;
  title?: ReactNode | string;
  submitTextId?: IntlMessageId;
  submitTextLoadingId?: IntlMessageId;
  cancelTextId?: IntlMessageId;
  confirmClose?: boolean;
  progress?: number;
  showProgress?: boolean;
  forceLoading?: boolean;
  errorMessage?: ReactNode;
  onSubmitDisabled?: boolean;
  closeOnBackdrop?: boolean;
};

const EMPTY_CLOSE = () => {};

const NewFormModalWrapperComponent: FunctionComponent<Props> = (props) => {
  const {
    title,
    submitTextId = 'forms.button.submit',
    submitTextLoadingId = 'forms.button.submit.loading',
    cancelTextId = 'forms.button.cancel',
    confirmClose = true,
    progress,
    showProgress,
    errorMessage,
    forceLoading,
    onSubmit,
    onClose = EMPTY_CLOSE,
    children,
    onSubmitDisabled,
    closeOnBackdrop,
    open,
    ...restModalProps
  } = props;
  const intl = useIntl();
  const isMountedRef = useIsMounted();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ServiceError>(null);

  function handleSubmit() {
    form.validateFields().then((values: any) => {
      if (isMountedRef.current) setLoading(true);
      onSubmit(values)
        .then((apiError) => {
          if (!isMountedRef.current) return;
          if (apiError) {
            if (!Axios.isCancel(apiError)) {
              processApiError(apiError, handleError);
            }
          } else {
            setError(null);
            setLoading(false);
          }
        })
        .catch(() => {
          if (isMountedRef.current) setLoading(false);
        });
    });
  }

  function handleError(error: ServiceError) {
    setError(error);
    setLoading(false);
  }

  const inConfirmationRef = useRef<boolean>(false);

  const handleClose = useCallback(() => {
    setError(null);
    onClose();
  }, [onClose]);

  const handleCancel = useCallback(() => {
    if (inConfirmationRef.current) return;
    if (confirmClose !== undefined && !confirmClose) {
      handleClose();
      return;
    }

    if (form.isFieldsTouched()) {
      inConfirmationRef.current = true;
      modalFormConfirmClose(
        intl,
        () => {
          inConfirmationRef.current = false;
          handleClose();
        },
        () => {
          inConfirmationRef.current = false;
        }
      );
    } else {
      handleClose();
    }
  }, [handleClose, confirmClose]);

  const footer = (
    <FlowLayout alignRight>
      {!errorMessage && ((loading && progress) || showProgress) ? (
        <Progress
          style={{ flex: 1 }}
          percent={progress}
          status={progress === 100 ? 'success' : loading ? 'active' : 'normal'}
        />
      ) : !!errorMessage ? (
        <Typography.Text type="danger" style={{ flex: 1, textAlign: 'left' }}>
          {errorMessage}
        </Typography.Text>
      ) : (
        <div style={{ flex: 1 }} />
      )}

      <Button onClick={handleCancel}>{intl.formatMessage({ id: cancelTextId })}</Button>
      <Button type="primary" loading={loading || forceLoading} onClick={handleSubmit} disabled={onSubmitDisabled}>
        {intl.formatMessage({
          id: (loading || forceLoading) && submitTextLoadingId ? submitTextLoadingId : submitTextId,
        })}
      </Button>
    </FlowLayout>
  );

  useCloseOnEscape(open, handleCancel);

  return (
    <Modal
      open={open}
      title={title}
      onCancel={handleCancel}
      maskClosable={closeOnBackdrop}
      footer={footer}
      {...restModalProps}
    >
      <StackPanel vertical stretch>
        <StackPanel vertical scrollable stretch autoHeight padding>
          {!!error && <ServiceErrorBox error={error} />}
          {open && <FormModalWrapperContext.Provider value={{ form }}>{children}</FormModalWrapperContext.Provider>}
        </StackPanel>
      </StackPanel>
    </Modal>
  );
};

export const NewFormModalWrapper = React.memo(NewFormModalWrapperComponent);
