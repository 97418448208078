import '@ant-design/compatible/assets/index.css';
import { Form, Input, Modal, Select } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { projectApi } from 'api/completeApi';
import { apiConstraints } from 'api/completeApiConstraints';
import {
  ExternalApplicationSettingDto,
  ExternalApplicationSettingSetDto,
  ProjectSettingExternalAppSettingsEnum,
} from 'api/completeApiInterfaces';
import { FULL_WIDTH_FORM_LABEL } from 'config/constants';
import { useIntl, useSameCallback } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';
import { messageError } from 'utils';
import uuid from 'uuid';
import { AddExternAppsProps } from '../externAppsTypes';

enum ProtocolsEnum {
  Http = 'http://',
  Https = 'https://',
}

interface UrlProtocolAddress {
  protocol?: ProtocolsEnum;
  address: string;
}
const eraseProtocol = (url: string) => {
  return url.split('://').pop();
};
const DEFAULT_URL: UrlProtocolAddress = {
  address: '',
  protocol: ProtocolsEnum.Https,
};

function parseUrl(url: string): UrlProtocolAddress {
  if (url.toLowerCase().startsWith(ProtocolsEnum.Http)) {
    return DEFAULT_URL;
  } else if (url.toLowerCase().startsWith(ProtocolsEnum.Https)) {
    return { address: eraseProtocol(url), protocol: ProtocolsEnum.Https };
  } else {
    return { address: url, protocol: undefined };
  }
}

export type ApplicationUrlSetFormProps = AddExternAppsProps & {
  externalAppSetting?: ExternalApplicationSettingDto;
  externalAppType:
    | ProjectSettingExternalAppSettingsEnum.constructionJournal
    | ProjectSettingExternalAppSettingsEnum.pageUrl;
};

const ApplicationUrlSetForm: FunctionComponent<ApplicationUrlSetFormProps> = ({
  open,
  onAppAdded,
  onCancel,
  externalAppSetting,
  externalAppType = ProjectSettingExternalAppSettingsEnum.pageUrl,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const { Option } = Select;

  const [externalUrl, setExternalUrl] = useState<string>(DEFAULT_URL.address);
  const [menuName, setMenuName] = useState<string>('');
  const [protocol, setProtocol] = useState<string>(DEFAULT_URL.protocol);
  const [saving, setSaving] = useState<boolean>(false);

  const isConstructionJournal = externalAppType === ProjectSettingExternalAppSettingsEnum.constructionJournal;

  useEffect(() => {
    if (!!externalAppSetting) {
      const { address, protocol } = parseUrl('url' in externalAppSetting.data ? externalAppSetting.data.url : '');
      if (protocol) {
        setProtocol(protocol);
      }
      setExternalUrl(address);
    } else {
      setProtocol(DEFAULT_URL.protocol);
      setExternalUrl(DEFAULT_URL.address);
    }
    setMenuName(
      externalAppSetting?.name ||
        (externalAppType === ProjectSettingExternalAppSettingsEnum.constructionJournal
          ? intl.formatMessage({ id: 'ProjectSettingsPage.ExternApps.ConstructionJournal.listItemTitle' })
          : '')
    );
  }, [externalAppSetting]);

  const intl = useIntl();

  const options = Object.values(ProtocolsEnum)
    .filter((p) => p === ProtocolsEnum.Https)
    .map((protocolEnum) => (
      <Option key={protocolEnum} value={protocolEnum}>
        {protocolEnum}
      </Option>
    ));

  const selectProtocol = (
    <Select defaultValue={DEFAULT_URL.protocol} value={protocol} style={{ width: 90 }} onChange={setProtocol}>
      {options}
    </Select>
  );

  const handleChangeUrl = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    const parsedUrl = parseUrl(e.currentTarget.value);
    setExternalUrl(parsedUrl.address);
    if (!!parsedUrl.protocol) {
      setProtocol(parsedUrl.protocol);
    }
  }, []);

  const handleSubmit = useSameCallback(async () => {
    if (!!externalUrl && !saving && !!protocol) {
      setSaving(true);
      const newSettings: ExternalApplicationSettingSetDto = externalAppSetting
        ? { ...externalAppSetting, name: menuName, data: { url: protocol.concat(externalUrl) }, textPage: undefined }
        : {
            id: uuid(),
            name: menuName,
            menuOrder: 0,
            type: externalAppType,
            data: { url: protocol.concat(externalUrl) },
            textPage: undefined,
          };
      const [err, res] = await projectApi.projectsetting.externalapplication.patch(newSettings);
      setSaving(false);

      if (!err) {
        dispatch.externalApplicationsSettings.setData(res.data);
        onAppAdded();
        return true;
      } else {
        messageError(err, intl);
        return false;
      }
    }
    return false;
  });

  useDirtyStoreReload(
    (store) => store.externalApplicationsSettings,
    (dispatch) => dispatch.externalApplicationsSettings
  );

  const okButtonProps = useMemo<ButtonProps>(
    () => ({
      disabled: !externalUrl || !menuName,
      loading: saving,
    }),
    [externalUrl, menuName, saving]
  );

  const cancelButtonProps = useMemo<ButtonProps>(
    () => ({
      loading: saving,
    }),
    [saving]
  );

  return (
    <Modal
      title={
        <Fmt
          id={
            isConstructionJournal
              ? 'ProjectSettingsPage.ExternApps.ConstructionJournal.title'
              : 'ProjectSettingsPage.ExternApps.ApplicationUrlForm.title'
          }
        />
      }
      open={open}
      onOk={handleSubmit}
      onCancel={onCancel}
      okButtonProps={okButtonProps}
      cancelButtonProps={cancelButtonProps}
    >
      <Form>
        {!isConstructionJournal && (
          <Form.Item
            required
            labelCol={FULL_WIDTH_FORM_LABEL}
            label={intl.formatMessage({
              id: 'ProjectSettingsPage.ExternApps.ApplicationUrlForm.menuName.placeholder',
            })}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'ProjectSettingsPage.ExternApps.ApplicationUrlForm.menuName.placeholder',
              })}
              onChange={(e) => {
                setMenuName(e.currentTarget.value);
              }}
              defaultValue={externalAppSetting?.name}
              maxLength={apiConstraints.externalApplicationsProjectSettingsTextPagePatchDto.title.maxLength}
              value={menuName}
            />
          </Form.Item>
        )}
        <Form.Item
          required
          labelCol={FULL_WIDTH_FORM_LABEL}
          label={intl.formatMessage({
            id: 'ProjectSettingsPage.ExternApps.ApplicationUrlForm.url.placeholder',
          })}
        >
          <Input
            addonBefore={selectProtocol}
            placeholder={intl.formatMessage({
              id: 'ProjectSettingsPage.ExternApps.ApplicationUrlForm.url.placeholder',
            })}
            onChange={handleChangeUrl}
            defaultValue={externalUrl}
            value={externalUrl}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default ApplicationUrlSetForm;
