import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { WarningOutlined } from '@ant-design/icons';
import { Input, InputRef, Select, Typography } from 'antd';
import { apiConstraints } from 'api/completeApiConstraints';
import { WorkflowStateEnum } from 'api/completeApiInterfaces';
import { Margin } from 'components/Margin/Margin';
import RevisionNumberTag from 'components/RevisionNumberTag/RevisionNumberTag';
import { Fmt, InjectedIntlProps } from 'locale';
import React, { useEffect } from 'react';
import { NEW_DOCUMENT_STATES } from 'utils/documentStateUtils';
import { maxLengthRule, requiredRule } from 'utils/formHelpersCompatibility';
import styles from './DocumentOnlineEditorRevisionCreateForm.module.less';

export type DocumentOnlineEditorRevisionCreateFormData = {
  state: WorkflowStateEnum;
  description: string;
};

type Props = FormComponentProps<DocumentOnlineEditorRevisionCreateFormData> &
  InjectedIntlProps & { isOldRevision: boolean; setRef: (ref: InputRef) => void; allowedStates: WorkflowStateEnum[] };

const DocumentOnlineEditorRevisionCreateForm = React.forwardRef<unknown, Props>(
  ({ form, intl, isOldRevision, allowedStates, setRef }, ref) => {
    // backward compatibility with class components
    useEffect(() => {
      (ref as any).current = { props: { form } };
    }, [form]);

    const { getFieldDecorator } = form;

    return (
      <Form layout="vertical">
        {isOldRevision && (
          <Margin bottom>
            <Typography.Text strong type="danger">
              <WarningOutlined /> <Fmt id="DocumentCreateForm.form.oldRevisionWarning" />
            </Typography.Text>
          </Margin>
        )}
        <Form.Item label={intl.formatMessage({ id: 'DocumentCreateForm.form.items.state.label' })}>
          {getFieldDecorator<DocumentOnlineEditorRevisionCreateFormData>('state', {
            initialValue: null,
            rules: [requiredRule('DocumentCreateForm.form.items.state.rules.required')],
          })(
            <Select>
              {(allowedStates?.length ? allowedStates : NEW_DOCUMENT_STATES).map((state) => (
                <Select.Option key={state}>
                  <div className={styles.stateOption}>
                    <RevisionNumberTag state={state} showTitle />
                  </div>
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'RevisionCreateForm.form.items.description.label' })}>
          {getFieldDecorator<DocumentOnlineEditorRevisionCreateFormData>('description', {
            initialValue: '',
            preserve: true,
            rules: [
              requiredRule('RevisionCreateForm.form.items.description.rules.required', true),
              maxLengthRule(
                'general.maxDescriptionLength',
                apiConstraints.prepareAddRevisionRequestDto.description.maxLength
              ),
            ],
          })(
            <Input.TextArea
              autoFocus
              ref={setRef}
              rows={3}
              autoSize={{ minRows: 3 }}
              placeholder={intl.formatMessage({ id: 'RevisionCreateForm.form.items.description.placeholder' })}
              className={'scrollbar'}
            />
          )}
        </Form.Item>
      </Form>
    );
  }
);

export default Form.create<Props>()(DocumentOnlineEditorRevisionCreateForm);
