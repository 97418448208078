import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { DatePicker, Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { useCurrentAppUser } from 'hooks';
import { useEsticonFirmsWithProjects } from 'hooks/useEsticonFirmsWithProjects';
import { Fmt, InjectedIntlProps } from 'locale';
import moment from 'moment';
import React, { FunctionComponent, useMemo } from 'react';
import { requiredRule } from 'utils/formHelpersCompatibility';
import { ReportWidgetCreateFormData } from '../ReportWidgetCreateForm';
import { ReportWidgetEditFormData } from '../ReportWidgetEditForm';

const REPORT_FROM_START_YEAR = 2022; // TODO: Unify this value and whole form with report settings on report detail page for ProjectsInRealization report

type FormPropsData = ReportWidgetCreateFormData | ReportWidgetEditFormData;
type Props = FormComponentProps<FormPropsData> &
  InjectedIntlProps & {
    organizationId: Guid;
    defaultValues?: FormPropsData;
  };

const ProjectsInRealizationConfigurationForm: FunctionComponent<Props> = ({ form, organizationId, defaultValues }) => {
  const currentAppUser = useCurrentAppUser();

  const { esticonFirms, firmsWithProjectsLoading } = useEsticonFirmsWithProjects(organizationId);

  const availableFirmOptions = useMemo(
    () =>
      Object.values(esticonFirms)
        .filter((firmRow) =>
          currentAppUser.organizationUsers?.some(
            (orgUser) => !!orgUser.organization.esticonFirmIds?.some((firmId) => firmRow.id === firmId)
          )
        )
        ?.map((firmRow): DefaultOptionType => ({ value: firmRow.id, label: firmRow.nazev })) || [],
    [esticonFirms, currentAppUser]
  );

  const availableReportYears = useMemo(() => {
    const endYear = moment().year();
    return Array.from({ length: endYear - REPORT_FROM_START_YEAR + 1 }, (_, i) => REPORT_FROM_START_YEAR + i).map(
      (year): DefaultOptionType => ({ value: year, label: year })
    );
  }, []);

  return (
    <>
      <Form.Item label={<Fmt id="ProjectsInRealizationOverviewReport.reportSelection.unit" />}>
        {form.getFieldDecorator<FormPropsData>('esticonUnit', {
          rules: [requiredRule('ProjectsInRealizationOverviewReport.reportSelection.required.unit', true)],
          initialValue: defaultValues?.esticonUnit,
        })(<Select options={availableFirmOptions} loading={firmsWithProjectsLoading} />)}
      </Form.Item>

      <Form.Item label={<Fmt id="ProjectsInRealizationOverviewReport.reportSelection.reportingPeriod" />}>
        {form.getFieldDecorator<FormPropsData>('startYear', {
          rules: [requiredRule('ProjectsInRealizationOverviewReport.reportSelection.required.forYear', false)],
          initialValue: defaultValues?.startYear,
        })(<Select options={availableReportYears} />)}
      </Form.Item>

      <Form.Item label={<Fmt id="ProjectsInRealizationOverviewReport.reportSelection.date" />}>
        {form.getFieldDecorator<FormPropsData>('reportDate', {
          rules: [requiredRule('ProjectsInRealizationOverviewReport.reportSelection.required.date', false)],
          initialValue: defaultValues?.reportDate && moment(defaultValues?.reportDate),
        })(<DatePicker />)}
      </Form.Item>
    </>
  );
};

export default ProjectsInRealizationConfigurationForm;
