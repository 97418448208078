import { TeamOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { OrganizationAdminReportDto } from 'api/completeApiInterfaces';
import { AuditLogButton } from 'components/ActionButtons/AuditLogButton';
import CommonHubEllipsisText from 'components/CommonHubEllipsisText/CommonHubEllipsisText';
import { DeleteButton } from 'components/DeleteButton/DeleteButton';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import { EditIcon } from 'components/Icons/HubActionsIcons';
import List from 'components/List';
import { ListEmpty } from 'components/ListEmpty/ListEmpty';
import { ModelProcessingExportButton } from 'components/forms/ModelProcessingExportForm/ModelProcessingExportButton';
import { useIntl } from 'hooks';
import React, { FunctionComponent } from 'react';
import { smartFilter } from 'utils';

type Props = {
  data: OrganizationAdminReportDto[];
  search: string;
  selectedId?: Guid;
  onSelect?: (userId: Guid) => void;
  onClearSearch?: () => void;
  onRemove?: (organizationId: Guid) => void;
  onEdit?: (organizationId: Guid) => void;
  editOrganizationUsers?: (organizationId: Guid) => void;
  title?: React.ReactNode;
  onShowAuditLog?: (organization: OrganizationAdminReportDto) => void;
};

const OrganizationList: FunctionComponent<Props> = ({
  data,
  search,
  selectedId,
  onSelect,
  onClearSearch,
  children,
  onRemove,
  onEdit,
  editOrganizationUsers,
  title,
  onShowAuditLog,
}) => {
  const intl = useIntl();

  const handleOrganizationEdit = (event: React.MouseEvent<HTMLElement, MouseEvent>, id: Guid) => {
    event.stopPropagation();
    onEdit(id);
  };

  const handleOrganizationUsersEdit = (event: React.MouseEvent<HTMLElement, MouseEvent>, id: Guid) => {
    event.stopPropagation();
    editOrganizationUsers(id);
  };

  return (
    <GeneralSettingsContainer title={title}>
      <List<OrganizationAdminReportDto>
        data={data}
        search={search}
        filterItem={(item) => (item && smartFilter(item?.name, search)) || smartFilter(item?.description, search)}
        renderItem={(item) => (
          <GeneralSettingsItem
            key={item.id}
            selected={item.id === selectedId}
            selectable={!!onSelect}
            onClick={onSelect ? () => onSelect(item.id) : null}
            title={item.name}
            wrap
            description={
              !!item.description ? (
                <CommonHubEllipsisText placement="topLeft" title={item.description}>
                  {item.description}
                </CommonHubEllipsisText>
              ) : null
            }
            additionalActions={
              <>
                {onRemove && (
                  <DeleteButton
                    disabled={
                      !!item.projects.length ? intl.formatMessage({ id: 'DeleteButton.orgHasProject' }) : undefined
                    }
                    onDelete={() => onRemove(item.id)}
                    type="link"
                  />
                )}
                {onEdit && (
                  <Button onClick={(e) => handleOrganizationEdit(e, item.id)} type="link" icon={<EditIcon />} />
                )}
                {editOrganizationUsers && (
                  <Button
                    onClick={(e) => handleOrganizationUsersEdit(e, item.id)}
                    type="link"
                    icon={<TeamOutlined />}
                  />
                )}
                {!onSelect && <ModelProcessingExportButton organizationId={item.id} />}
                {!!onShowAuditLog && <AuditLogButton onClick={() => onShowAuditLog(item)} />}
              </>
            }
          />
        )}
        renderEmpty={(total, filtered) => <ListEmpty filtered={filtered} total={total} onClearSearch={onClearSearch} />}
      />
      {children}
    </GeneralSettingsContainer>
  );
};

export default React.memo(OrganizationList);
