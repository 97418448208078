import '@ant-design/compatible/assets/index.css';
import { Button, Col, InputNumber, Row, Select, Tooltip } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { DeleteButton } from 'components/ActionButtons';
import { Margin } from 'components/Margin/Margin';
import { Fmt, InjectedIntlProps } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { ignoreRef, smartFilter } from 'utils';
import { KpiRule, KpiRuleCondition, KpiRuleValidation } from './KpiConfigurationForm.utils';

type Props = InjectedIntlProps & {
  value: Record<string, KpiRule>;
  onChange: (value: Record<string, KpiRule>) => void;
  availableColumns: Record<string, IntlMessageId>;
};

const defaultKpiRule: KpiRule = {
  condition: KpiRuleCondition.GreaterThanOrEqual,
  refValue: 0.0,
  validation: KpiRuleValidation.Ok,
};

const KpiRuleConfigurationFormItemComponent: FunctionComponent<Props> = ({
  intl,
  value,
  onChange,
  availableColumns,
}) => {
  const unusedColumnsOptions = useMemo(() => {
    const unusedColumns = Object.entries(availableColumns).filter((column) => !(column[0] in value));
    return (
      unusedColumns.map(
        (column): DefaultOptionType => ({ value: column[0], label: intl.formatMessage({ id: column[1] }) })
      ) || []
    );
  }, [availableColumns, intl, value]);

  const compareFunctionOptions: DefaultOptionType[] = useMemo(
    () => [...Object.entries(KpiRuleCondition).map((condition) => ({ value: condition[1], label: condition[1] }))],
    []
  );

  const validationOptions: DefaultOptionType[] = useMemo(
    () => [
      { value: KpiRuleValidation.Ok, label: intl.formatMessage({ id: 'KpiConfigurationFormItem.evaluation.ok' }) },
      {
        value: KpiRuleValidation.Critical,
        label: intl.formatMessage({ id: 'KpiConfigurationFormItem.evaluation.critical' }),
      },
    ],
    [intl]
  );

  const handleCreateNewConfiguration = useCallback(() => {
    if (unusedColumnsOptions.length > 0) {
      onChange && onChange({ ...value, [unusedColumnsOptions[0].value]: defaultKpiRule });
    }
  }, [value, unusedColumnsOptions, onChange]);

  const rowChangeHandle = useCallback(
    (property: keyof KpiRule | 'newKew', rowKey: string) => (newValue: any) => {
      if (property === 'newKew') {
        const newConfig: Record<string, KpiRule> = { ...value, [newValue]: value[rowKey] || defaultKpiRule };
        delete newConfig[rowKey];
        onChange(newConfig);
      } else {
        onChange({ ...value, [rowKey]: { ...value[rowKey], [property]: newValue } });
      }
    },
    [onChange, value]
  );

  const rowDeleteHandle = useCallback(
    (rowKey: string) => {
      const newConfig: Record<string, KpiRule> = { ...value };
      delete newConfig[rowKey];
      onChange(newConfig);
    },
    [onChange, value]
  );

  const propertyFilter = (input: string, option: DefaultOptionType) => {
    return smartFilter(option.label?.toString() || option.children?.toString(), input);
  };

  const addButtonDisabled = Object.keys(value).length >= Object.keys(availableColumns).length;

  return (
    <>
      <Row gutter={8}>
        <Col span={6}>
          <Fmt id="KpiConfigurationFormItem.column" />
        </Col>
        <Col span={4}>
          <Fmt id="KpiConfigurationFormItem.condition" />
        </Col>
        <Col span={6}>
          <Fmt id="KpiConfigurationFormItem.referentialValue" />
        </Col>
        <Col span={6}>
          <Fmt id="KpiConfigurationFormItem.evaluation" />
        </Col>
        <Col span={2}></Col>
      </Row>
      {Object.entries(value).map(([key, rule]) => (
        <Margin bottom key={key}>
          <Row gutter={8}>
            <Col span={6}>
              <Select showSearch value={key} onChange={rowChangeHandle('newKew', key)} filterOption={propertyFilter}>
                {[
                  ...unusedColumnsOptions,
                  { value: key, label: intl.formatMessage({ id: availableColumns[key] }) },
                ].map((column) => (
                  <Select.Option key={column.value} value={column.value}>
                    {column.label}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col span={4}>
              <Select value={rule.condition} onChange={rowChangeHandle('condition', key)} filterOption={propertyFilter}>
                {compareFunctionOptions.map((condition) => (
                  <Select.Option key={condition.value} value={condition.value}>
                    {condition.label}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col span={6}>
              <InputNumber
                value={rule.refValue}
                onChange={(value) => rowChangeHandle('refValue', key)(parseFloat(`${value}`))}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                precision={2}
                defaultValue={0.0}
                decimalSeparator=","
                controls={false}
                style={{ width: '100%' }}
              />
            </Col>
            <Col span={6}>
              <Select
                value={rule.validation}
                onChange={rowChangeHandle('validation', key)}
                filterOption={propertyFilter}
                options={validationOptions}
              />
            </Col>
            <Col span={2}>
              <DeleteButton onClick={() => rowDeleteHandle(key)} />
            </Col>
          </Row>
        </Margin>
      ))}
      <Margin top>
        <Row gutter={8}>
          <Col span={16}>
            <Fmt id="KpiConfigurationFormItem.addRule.title" />
          </Col>
          <Col span={8}>
            <Tooltip title={addButtonDisabled && <Fmt id="KpiConfigurationFormItem.addRule.disabled.tooltip" />}>
              <Button onClick={handleCreateNewConfiguration} type="primary" block disabled={addButtonDisabled}>
                <Fmt id="KpiConfigurationFormItem.addRule.button" />
              </Button>
            </Tooltip>
          </Col>
        </Row>
      </Margin>
    </>
  );
};

export const KpiRuleConfigurationFormItem = ignoreRef(KpiRuleConfigurationFormItemComponent) as FunctionComponent<
  Omit<Props, 'value' | 'onChange'>
>;
