export const MASK_METADATA = {
  FOLDER_NAME: 'folderName',
};

type ObjectValues<T> = T[keyof T];

export type MaskMetadata = ObjectValues<typeof MASK_METADATA>;

export type MaskItemLettersType = {
  case: 'upper' | 'lower' | 'mixed';
  allowSpecialCharacters?: boolean;
  allowNumbers?: boolean;
  whitespace?: boolean;
};

export type MaskItemCountsType = {
  count: number;
  otherCount?: number;
};

export type MaskItemMetadataType = {
  propName: MaskMetadata;
};

export type MaskItemTextType = {
  value: string;
};

export type MaskItemType = { id: string } & (
  | ({ type: 'text' } & MaskItemTextType)
  | ({ type: 'number' } & MaskItemCountsType)
  | ({ type: 'metadata' } & MaskItemMetadataType)
  | ({ type: 'letters' } & MaskItemLettersType & MaskItemCountsType)
);
// | {
//     type: 'specialCharacters';
//     whitelist: string[]; // check length of one of each item
//     count: number;
//     otherCount?: number;
//   }

export type MaskItemsDto = {
  rules: MaskItemType[];
};

export const MASK_ITEMS_MOCK: MaskItemType[] = [
  {
    id: '1',
    type: 'text',
    value: 'DMS-',
  },
  {
    id: '2',
    type: 'number',
    count: 2,
    otherCount: 4,
  },
  {
    id: '3',
    type: 'text',
    value: '_',
  },
  {
    id: '4',
    type: 'number',
    count: 2,
  },
  {
    id: '5',
    type: 'text',
    value: '-',
  },
  {
    id: '6',
    type: 'metadata',
    propName: MASK_METADATA.FOLDER_NAME,
  },
  {
    id: '7',
    type: 'text',
    value: '-',
  },
  {
    id: '8',
    type: 'letters',
    count: 5,
    otherCount: 40,
    case: 'mixed',
    allowSpecialCharacters: false,
    allowNumbers: true,
    whitespace: true,
  },
];

/// 'DMS-12_32-Mosty-pilýř číslo 2 pro most 235';
