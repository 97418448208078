import { CategoryListItem } from 'components/CategoryListItem/CategoryListItem';
import { Fmt } from 'locale';
import { Dictionary } from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dispatch, RootState } from 'store';
import { legacyMapDispatchToProps } from 'store/models/storeModelinterfaces';
import { categoryMapSelector } from 'store/selectors';

type OwnProps = {
  categories: Dictionary<Guid>;
  isLink?: boolean;
};

const mapStateToProps = (state: RootState) => ({
  categoryMap: categoryMapSelector(state),
  categoryTrees: state.categoryTrees,
  hasDirtyCategories: state.categories.dirty,
});

type PropsFromState = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadCategories: dispatch.categories.loadData,
});

type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

type Props = PropsFromState & PropsFromDispatch & OwnProps;

class CategoriesList extends PureComponent<Props> {
  static defaultProps: Partial<OwnProps> = {
    isLink: true,
  };

  async componentDidUpdate() {
    if (this.props.hasDirtyCategories) {
      await this.props.loadCategories({ reload: false, silent: true });
    }
  }

  render() {
    const { categories, categoryTrees, categoryMap } = this.props;
    const categoryKeys = Object.keys(categories);
    if (!categories || !categoryMap || !categoryTrees) {
      return null;
    }

    return (
      <>
        {categoryKeys.map((categoryId) => {
          const categoryNodeId = categories[categoryId];
          const category = categoryMap[categoryId];
          const categoryNodes = categoryTrees[categoryId];
          if (!categoryNodes || !categoryNodes.map) {
            return null;
          }
          const categoryNode = categoryNodes.map[categoryNodeId];
          return <CategoryListItem categoryName={category.name} nodeName={categoryNode?.name} key={category.id} />;
        })}
        {categoryKeys.length === 0 && <span>{<Fmt id="general.notSet" />}</span>}
      </>
    );
  }
}

export default connect(mapStateToProps, legacyMapDispatchToProps(mapDispatchToProps))(CategoriesList);
