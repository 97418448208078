import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Select, TreeSelect } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { ProjectTemplateCategoryNodeDto, ProjectTemplateCategoryTreeDto } from 'api/completeApiInterfaces';
import { Fmt, InjectedIntlProps } from 'locale';
import React, { useEffect, useMemo } from 'react';
import { requiredRule } from 'utils/formHelpersCompatibility';

export type ProjectTemplateDirectoryCategoryFormData = {
  categoryTreeId: Guid;
  categoryNodeId: Guid;
};

type Props = FormComponentProps<ProjectTemplateDirectoryCategoryFormData> &
  InjectedIntlProps & {
    defaults?: Partial<ProjectTemplateDirectoryCategoryFormData>;
    categoryList: ProjectTemplateCategoryTreeDto[];
    categoryNodes: ProjectTemplateCategoryNodeDto[];
  };

const mapNodeAsDefaultOption = (
  root: ProjectTemplateCategoryNodeDto,
  nodes: ProjectTemplateCategoryNodeDto[]
): DefaultOptionType => {
  return {
    value: root.id,
    label: root.name,
    children: nodes.filter((node) => node.parentId === root.id).map((node) => mapNodeAsDefaultOption(node, nodes)),
  };
};

const ProjectTemplateDirectoryCategoryForm = React.forwardRef<unknown, Props>(
  ({ form, defaults, categoryList, categoryNodes }, ref) => {
    // backward compatibility with class components
    useEffect(() => {
      (ref as any).current = { props: { form } };
    }, [form]);

    const categoryTreeData = useMemo((): DefaultOptionType[] => {
      const selectedTreeId = form.getFieldValue('categoryTreeId') || defaults?.categoryTreeId;
      if (!selectedTreeId) return undefined;

      const filteredNodes = categoryNodes.filter((node) => node.templateCategoryTreeId === selectedTreeId);
      const rootNode = filteredNodes.find((node) => !node.parentId);
      if (!rootNode) return undefined;
      const categoryTree = mapNodeAsDefaultOption(rootNode, filteredNodes);

      return [{ ...categoryTree }];
    }, [categoryNodes, form, defaults]);

    return (
      <Form>
        <Form.Item label={<Fmt id="general.category" />}>
          {form.getFieldDecorator<ProjectTemplateDirectoryCategoryFormData>('categoryTreeId', {
            rules: [requiredRule('ProjectTemplateDirectoryCategoryForm.category.required')],
            initialValue: defaults?.categoryTreeId,
          })(
            <Select>
              {categoryList.map((categoryTree) => (
                <Select.Option key={categoryTree.id} value={categoryTree.id}>
                  {categoryTree.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label={<Fmt id="ProjectTemplateDirectoryCategoryForm.node" />}>
          {form.getFieldDecorator<ProjectTemplateDirectoryCategoryFormData>('categoryNodeId', {
            rules: [requiredRule('ProjectTemplateDirectoryCategoryForm.categoryNode.required')],
            initialValue: defaults?.categoryNodeId,
          })(
            <TreeSelect
              showSearch
              placeholder={<Fmt id="DirectorySettingsForm.requiredCategories.placeholder" />}
              allowClear
              treeDefaultExpandAll
              treeData={categoryTreeData}
            />
          )}
        </Form.Item>
      </Form>
    );
  }
);

export default Form.create<Props>()(ProjectTemplateDirectoryCategoryForm);
