import { InputRef, Modal, Typography } from 'antd';
import { masterApi } from 'api/completeApi';
import {
  LanguageEnum,
  MdMeDto,
  MdProjectCreateDto,
  MdProjectDto,
  MdProjectPhaseEnum,
  MdRoleDto,
  MdRoleEnum,
  ProjectCreateFromTempDto,
  ProjectUserRequestDto,
} from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { Margin } from 'components/Margin/Margin';
import SpinBox from 'components/SpinBox';
import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from 'components/forms/FormModalWrapper';
import { HIDE_BUTTON_PROPS } from 'config/constants';
import { useApiData, useCancelToken, useCurrentAppUser, useIntl, useIsMounted, useSameCallback } from 'hooks';
import { useFocus } from 'hooks/useFocus';
import { useDirtyStoreReload, useStoreSelector } from 'hooks/useSelectorDispatch';
import { Fmt } from 'locale';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { messageError } from 'utils';
import MDProjectCreateForm, { MDProjectCreateFormData } from './MDProjectCreateForm';

type Props = FormModalProps<MdProjectDto> & {
  creator: MdMeDto;
  organizationId: Guid;
  intentionerId: Guid;
  mdRoles: MdRoleDto[];
  initialPhaseValue?: MdProjectPhaseEnum;
};

const MDProjectCreateFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, organizationId, creator, intentionerId, mdRoles, initialPhaseValue, ...restProps } = props;
  const intl = useIntl();
  const ct = useCancelToken('Project create form unloading', []);
  const isMounted = useIsMounted();
  const [creatingProjectData, setCreatingProjectData] = useState<MdProjectCreateDto>();
  const { setInputRef } = useFocus<InputRef>(restProps.visible);
  const currentAppUser = useCurrentAppUser();

  const [projectTemplates, projectTemplatesError, projectTemplatesLoading] = useApiData(
    (ct) => masterApi.projects.tempates.projecttemplatelist.id.get(organizationId, ct),
    { autoload: true }
  );

  const hubProjects = useStoreSelector((state) => state.allProjects);

  useDirtyStoreReload(
    (state) => state.allProjects,
    (dispatch) => dispatch.allProjects
  );

  const currentUserRoles = useMemo(
    () => creator?.mdRoles.filter((role) => role.mdRoleType === MdRoleEnum.proposer) || [],
    [creator]
  );

  const proposerRole = useMemo(
    () =>
      currentUserRoles.reduce((priorityRole, role) => {
        return role.head.id == creator?.mdUser.id ? role : priorityRole;
      }, currentUserRoles[0]),
    [creator, currentUserRoles]
  );

  const handleSubmit: FormSubmitHandler<MDProjectCreateFormData> = useSameCallback(async (values) => {
    const mdManagerRoles = mdRoles?.filter((role) => role.mdRoleType === MdRoleEnum.md_admin);
    const projectCreateData: ProjectCreateFromTempDto = {
      name: values.name,
      description: values.description,
      organizationId,
      projectTemplateId: values.templateId,
      isMdProject: true,
      expandUsers: [
        ...(mdManagerRoles
          ?.flatMap((role) => [role.head, ...(role.mdUsers || [])])
          .map(
            (user): ProjectUserRequestDto => ({
              addAsAdmin: true,
              language: LanguageEnum.cs,
              mail: user.orgUser.appUserProfile.username,
            })
          ) || []),
        { addAsAdmin: false, language: LanguageEnum.cs, mail: currentAppUser.username },
      ],
    };
    const [projectErr, projectRes] = await masterApi.projects.createfromtemplate.post(projectCreateData, ct);
    if (!isMounted.current) return;
    if (projectErr) {
      messageError(projectErr, intl);
      return;
    }

    setCreatingProjectData({
      organizationId,
      intentionerId,
      projectId: projectRes.data.id,
      mdRoleId: proposerRole.id,
      ckIdentificaion: values.ckIdentification,
      ...values,
    });

    return null;
  });

  useEffect(() => {
    const createMdProject = async () => {
      const [err, res] = await masterApi.projects.md.project.create.post(creatingProjectData, ct);
      setCreatingProjectData(undefined);
      if (err) {
        messageError(err, intl);
        return;
      }
      onSubmit(res.data);
    };

    if (!creatingProjectData) return;
    const createdProject = hubProjects.data.projects.find((project) => project.id === creatingProjectData.projectId);
    if (createdProject) {
      void createMdProject();
    }
  }, [hubProjects, creatingProjectData]);

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      titleId={'MD.Projects.ProjectCreateModal.title'}
      submitTextId={'general.create'}
      {...restProps}
    >
      {({ intl, formRef }) => (
        <>
          <ContentGate loading={projectTemplatesLoading} error={projectTemplatesError}>
            <MDProjectCreateForm
              intl={intl}
              wrappedComponentRef={formRef}
              setRef={setInputRef}
              projectTemplates={projectTemplates}
              initialPhaseValue={initialPhaseValue}
            />
          </ContentGate>
          <Modal
            title={<Fmt id="MD.Projects.ProjectCreateModal.creating.title" />}
            open={!!creatingProjectData}
            cancelButtonProps={HIDE_BUTTON_PROPS}
            okButtonProps={HIDE_BUTTON_PROPS}
            closable={false}
          >
            <Typography.Paragraph>
              <Fmt id="MD.Projects.ProjectCreateModal.creating.content" />
              <Margin top>
                <Typography.Text strong type="warning">
                  <Fmt id="MD.Projects.ProjectCreateModal.creating.content.warning" />
                </Typography.Text>
              </Margin>
              <SpinBox spinning />
            </Typography.Paragraph>
          </Modal>
        </>
      )}
    </FormModalWrapper>
  );
};

export default MDProjectCreateFormModal;
