import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert, Input, Modal } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { projectApi } from 'api/completeApi';
import { apiConstraints } from 'api/completeApiConstraints';
import {
  DirectoryContentDto,
  ExternalApplicationSettingDto,
  ExternalApplicationSettingSetDto,
  ProjectSettingExternalAppSettingsEnum,
} from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import DocumentRow from 'components/DocumentRow';
import {
  DocumentSelectToolbar,
  DocumentToolbarSelectedDocuments,
} from 'components/DocumentSelectToolbar/DocumentSelectToolbar';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { FULL_WIDTH_FORM_LABEL } from 'config/constants';
import { useApiData, useIntl, useSameCallback } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';
import { messageError } from 'utils';
import { DisabledWithReason } from 'utils/types';
import uuid from 'uuid';
import { AddExternAppsProps } from '../externAppsTypes';
import styles from './DocumentView.module.less';

export type DocumentViewSetFormProps = AddExternAppsProps & {
  externalAppSetting?: ExternalApplicationSettingDto & { data: { documentId: Guid } };
};

const DocumentViewSetForm: FunctionComponent<DocumentViewSetFormProps> = ({
  open,
  onAppAdded,
  onCancel,
  externalAppSetting,
}) => {
  const dispatch = useDispatch<Dispatch>();

  const [menuName, setMenuName] = useState<string>(externalAppSetting?.name || '');
  const [selectedDocumentId, setSelectedDocumentId] = useState<string | undefined>(externalAppSetting?.data.documentId);
  const [saving, setSaving] = useState<boolean>(false);

  const [documentInfo, documentError, documentLoading, loadDocument] = useApiData(
    (ct) => projectApi.documents.id.get(selectedDocumentId, ct),
    { autoload: false }
  );

  useEffect(() => {
    if (selectedDocumentId) {
      loadDocument();
    }
  }, [selectedDocumentId]);

  const intl = useIntl();
  const handleSubmit = useSameCallback(async () => {
    if (!saving) {
      setSaving(true);
      const newSettings: ExternalApplicationSettingSetDto = externalAppSetting
        ? { ...externalAppSetting, name: menuName, data: { documentId: selectedDocumentId }, textPage: undefined }
        : {
            id: uuid(),
            name: menuName,
            menuOrder: 0,
            type: ProjectSettingExternalAppSettingsEnum.document,
            data: { documentId: selectedDocumentId },
            textPage: undefined,
          };
      const [err, res] = await projectApi.projectsetting.externalapplication.patch(newSettings);
      setSaving(false);

      if (!err) {
        dispatch.externalApplicationsSettings.setData(res.data);
        onAppAdded();
        return true;
      } else {
        messageError(err, intl);
        return false;
      }
    }
    return false;
  });

  useDirtyStoreReload(
    (store) => store.externalApplicationsSettings,
    (dispatch) => dispatch.externalApplicationsSettings
  );

  const okButtonProps = useMemo<ButtonProps>(
    () => ({
      disabled: !selectedDocumentId || !menuName,
      loading: saving,
    }),
    [selectedDocumentId, menuName, saving]
  );

  const cancelButtonProps = useMemo<ButtonProps>(
    () => ({
      loading: saving,
    }),
    [saving]
  );

  const handleDocumentSelection = useCallback(async (documents: DocumentToolbarSelectedDocuments) => {
    setSelectedDocumentId(documents.existing?.[0].id || documents.uploaded?.[0].id);
    return Promise.resolve();
  }, []);

  const disableDocumentForSelection = useCallback(
    (document: DirectoryContentDto): DisabledWithReason => {
      if (selectedDocumentId === document.id) {
        return intl.formatMessage({ id: 'AssignmentDocumentsField.documentIsAlreadySelected' });
      }
      return false;
    },
    [intl, selectedDocumentId]
  );

  return (
    <Modal
      title={<Fmt id={'ProjectSettingsPage.ExternApps.DocumentView.listItemTitle'} />}
      open={open}
      onOk={handleSubmit}
      onCancel={onCancel}
      okButtonProps={okButtonProps}
      cancelButtonProps={cancelButtonProps}
    >
      <Form>
        <Form.Item
          required
          label={intl.formatMessage({
            id: 'ProjectSettingsPage.ExternApps.ApplicationUrlForm.menuName.placeholder',
          })}
          labelCol={FULL_WIDTH_FORM_LABEL}
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'ProjectSettingsPage.ExternApps.ApplicationUrlForm.menuName.placeholder',
            })}
            onChange={(e) => {
              setMenuName(e.currentTarget.value);
            }}
            defaultValue={externalAppSetting?.name}
            maxLength={apiConstraints.externalApplicationsProjectSettingsTextPagePatchDto.title.maxLength}
            value={menuName}
          />
        </Form.Item>
        <Form.Item>
          <DocumentSelectToolbar
            canSelectExisting
            canUpload
            disableDocumentForSelection={disableDocumentForSelection}
            onDocumentSelect={handleDocumentSelection}
            className={styles.toolbar}
            styled={false}
          />
          <ContentGate loading={documentLoading} error={documentError}>
            {!documentInfo?.primaryFile.hasPdfDerivate && (
              <Alert
                message={intl.formatMessage({ id: 'ProjectSettingsPage.ExternApps.DocumentView.missingPdfDerivate' })}
                type="warning"
              />
            )}
            {documentInfo && (
              <FlowLayout className={styles.documentInfo}>
                <DocumentRow
                  id={documentInfo.id}
                  onThumbnailClick={undefined}
                  name={documentInfo.name}
                  revisionNo={documentInfo.currentRevision?.number}
                  revisionDate={documentInfo.currentRevision?.createdDate}
                  state={documentInfo.currentRevision?.revisionState}
                  thumbnailUrl={documentInfo.primaryFile.thumbnail}
                  hideSelect
                  disableReserve
                />
              </FlowLayout>
            )}
          </ContentGate>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default DocumentViewSetForm;
