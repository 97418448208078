import classnames from 'classnames';
import { DropIndicator } from 'components/MaskInput/DropIndicator';
import { MaskTag } from 'components/MaskInput/MaskTag';
import React, { FC } from 'react';
import { mergeProps, useDraggableItem, useDroppableItem, useFocusRing, useOption } from 'react-aria';
import styles from './MaskReorderableList.module.less';

type MaskReorderableOptionProps = {
  item: any;
  state: any;
  dragState: any;
  dropState: any;
};

export const MaskReorderableOption: FC<MaskReorderableOptionProps> = ({ item, state, dragState, dropState }) => {
  const ref = React.useRef(null);
  const { optionProps } = useOption({ key: item.key }, state, ref);
  const { isFocusVisible, focusProps } = useFocusRing();

  // Register the item as a drop target.
  const { dropProps, isDropTarget } = useDroppableItem(
    {
      target: { type: 'item', key: item.key, dropPosition: 'on' },
    },
    dropState,
    ref
  );

  // Register the item as a drag source.
  const { dragProps } = useDraggableItem(
    {
      key: item.key,
    },
    dragState
  );

  return (
    <>
      <DropIndicator target={{ type: 'item', key: item.key, dropPosition: 'before' }} dropState={dropState} />
      <MaskTag
        {...mergeProps(optionProps, dragProps, dropProps, focusProps)}
        innerRef={ref}
        className={classnames(styles.option, isFocusVisible && styles.focusVisible, isDropTarget && styles.dropTarget)}
      >
        {item.rendered}
      </MaskTag>
      {state.collection.getKeyAfter(item.key) == null && (
        <DropIndicator target={{ type: 'item', key: item.key, dropPosition: 'after' }} dropState={dropState} />
      )}
    </>
  );
};
