import { Alert } from 'antd';
import { api } from 'api';
import { GroupDto, ProjectUserProfileDto, ProjectUserProfileStatusEnum, ServiceError } from 'api/completeApiInterfaces';
import { ApiError } from 'api/errors';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import List from 'components/List';
import { ListEmpty } from 'components/ListEmpty/ListEmpty';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import ServiceErrorBox from 'components/ServiceErrorBox';
import StackPanel from 'components/StackPanel';
import SwitchCheck from 'components/SwitchCheck';
import { useItemsSet } from 'hooks';
import { Fmt, InjectedIntlProps } from 'locale';
import PageContent from 'pages/ProjectSettingsPage/PageContent';
import Panel from 'pages/ProjectSettingsPage/Panel';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { messageError, smartFilter } from 'utils';

type Props = InjectedIntlProps & {
  userId: Guid;
  userStatus: ProjectUserProfileStatusEnum;
  groups: GroupDto[];
  userGroupsSet: Set<Guid>;
  currentUser: ProjectUserProfileDto;
  error?: ServiceError;
  onChange?: (userId: Guid, groupId: Guid, checked: boolean) => void;
};

const GroupsTab: FunctionComponent<Props> = ({
  intl,
  userId,
  userStatus,
  groups,
  userGroupsSet,
  currentUser,
  error,
  onChange,
}) => {
  const { url } = useRouteMatch();
  const [savingItems, addSavingItem, deleteSavingItem] = useItemsSet<Guid>();
  const [search, setSearch] = useState<string>();

  useEffect(() => () => deleteSavingItem(), [userId]); // after change clear saving indicators

  const handleSave = async (userId: Guid, groupId: Guid, checked: boolean) => {
    addSavingItem(groupId);

    let err: ApiError;
    if (checked) {
      [err] = await api.project.projectUser.addUserGroup(userId, groupId);
    } else {
      [err] = await api.project.projectUser.removeUserGroup(userId, groupId);
    }

    if (err) {
      messageError(err, intl);
    } else {
      onChange && onChange(userId, groupId, checked);
    }

    deleteSavingItem(groupId);
  };

  const userDisabled = userStatus === ProjectUserProfileStatusEnum.suspended;

  if (error) return <ServiceErrorBox error={error} />;

  return (
    <MasterComponent
      url={url}
      title={intl.formatMessage({ id: 'UserDetailPanel.GroupsTab' })}
      children={() => (
        <StackPanel vertical scrollable>
          <PageContent>
            <Panel onSearch={setSearch} searchValue={search}>
              <GeneralSettingsContainer>
                {userDisabled && (
                  <Alert
                    type="warning"
                    message={intl.formatMessage({ id: 'UserDetailPanel.GroupsTab.UserDisabledWarning' })}
                  />
                )}

                <List<GroupDto>
                  data={groups}
                  search={search}
                  filterItem={(item) => item && smartFilter(item?.name, search)}
                  renderItem={(item) => (
                    <GeneralSettingsItem
                      key={item.id}
                      title={item.name}
                      description={item.description}
                      input={
                        <SwitchCheck
                          checked={userGroupsSet.has(item.id)}
                          loading={savingItems.has(item.id)}
                          disabled={
                            userDisabled || ((currentUser.id === userId || !currentUser.isAdmin) && item.isAdminGroup)
                          }
                          onChange={(checked) => handleSave(userId, item.id, checked)}
                          label={<Fmt id={userGroupsSet.has(item.id) ? 'general.on' : 'general.off'} />}
                          labelOnLeft
                        />
                      }
                    />
                  )}
                  renderEmpty={(total, filtered) => (
                    <ListEmpty filtered={filtered} total={total} onClearSearch={() => setSearch(undefined)} />
                  )}
                />
              </GeneralSettingsContainer>
            </Panel>
          </PageContent>
        </StackPanel>
      )}
    />
  );
};

export default GroupsTab;
