import '@ant-design/compatible/assets/index.css';
import { InputNumber, InputNumberProps } from 'antd';

import { Fmt } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React, { useCallback } from 'react';
import { JSONVariableTypeEnum, MDInput } from '../MDProjectVariableData.types';

type Props = {
  dataType: JSONVariableTypeEnum;
  value?: MDInput;
  onChange?: (value: MDInput) => void;
  intlId?: IntlMessageId;
} & Omit<InputNumberProps, 'value' | 'onChange'>;

const MDVariableTextareaFormItem = React.forwardRef<HTMLInputElement, Props>(
  ({ dataType, value, onChange, intlId, ...restProps }, ref) => {
    const handleChange = useCallback(
      (value: string | number) => {
        onChange && onChange({ type: dataType, value: value });
      },
      [dataType, onChange]
    );
    return (
      <>
        <InputNumber ref={ref} onChange={handleChange} value={value?.value} {...restProps} />
        {!!intlId && <Fmt id={intlId} />}
      </>
    );
  }
);

export default React.memo(MDVariableTextareaFormItem);
