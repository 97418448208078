import { CheckOutlined, CloseOutlined, CopyOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { DeleteButton } from 'components/ActionButtons';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { Margin } from 'components/Margin/Margin';
import { Draggable } from 'components/MaskInput/Draggable';
import { MASK_METADATA, MaskItemType } from 'components/MaskInput/MaskInput.types';
import { MaskItemLabel } from 'components/MaskInput/MaskItemLabel';
import { MaskItemValue } from 'components/MaskInput/MaskItemValue';
import { MaskReorderableListBox } from 'components/MaskInput/MaskReorderableList';
import { useIntl } from 'hooks';
import { Fmt, InjectedIntl } from 'locale';
import React, { FunctionComponent } from 'react';
import { Item, useListData } from 'react-stately';
import uuid from 'uuid';
import styles from './MaskInput.module.less';

function getDefaultMaskItem(type: string, intl: InjectedIntl): MaskItemType | null {
  const id = uuid.v4();
  switch (type) {
    case 'text':
      return {
        id: id,
        type: 'text',
        value: intl.formatMessage({ id: 'folderMasks.MaskInput.text.initialValue' }),
      };
    case 'number':
      return {
        id: id,
        type: 'number',
        count: 2,
      };
    case 'metadata':
      return {
        id: id,
        type: 'metadata',
        propName: MASK_METADATA.FOLDER_NAME,
      };
    case 'letters':
      return {
        id: id,
        type: 'letters',
        count: 3,
        otherCount: 30,
        case: 'mixed',
        allowNumbers: true,
        allowSpecialCharacters: false,
        whitespace: true,
      };
  }

  return null;
}

type Props = {
  values: MaskItemType[];
  onSubmit: (values: MaskItemType[]) => void;
  onCancel: () => void;
};

const MaskInput: FunctionComponent<Props> = ({ values, onSubmit, onCancel }) => {
  const intl = useIntl();

  const list = useListData({
    initialItems: values,
  });

  const onReorder = (e: any) => {
    if (e.target.dropPosition === 'before') {
      list.moveBefore(e.target.key, e.keys);
    } else if (e.target.dropPosition === 'after') {
      list.moveAfter(e.target.key, e.keys);
    }
  };

  const onInsert = async (e: any) => {
    const type = await e.items[0].getText('text/plain');
    const item: MaskItemType = getDefaultMaskItem(type, intl);
    if (!item) return;

    if (!e.target) {
      list.append(item);
    } else if (e.target.dropPosition === 'before') {
      list.insertBefore(e.target.key, item);
    } else if (e.target.dropPosition === 'after') {
      list.insertAfter(e.target.key, item);
    }
  };

  const onAdd = (itemType: MaskItemType['type']) => {
    const item: MaskItemType = getDefaultMaskItem(itemType, intl);
    if (!item) return;
    list.append(item);
  };

  const onItemChange = (item: Partial<Omit<MaskItemType, 'type'>> & { id: string }) => {
    const oldValue = list.items.find((i) => i.id === item.id);
    list.update(item.id, { ...oldValue, ...item });
  };

  return (
    <>
      <FlowLayout wrap className={styles.toolbar}>
        <Draggable
          value={{
            id: null,
            type: 'text',
          }}
          onAdd={onAdd}
        />
        <Draggable
          value={{
            id: null,
            type: 'number',
          }}
          onAdd={onAdd}
        />
        <Draggable
          value={{
            id: null,
            type: 'metadata',
          }}
          onAdd={onAdd}
        />
        <Draggable
          value={{
            id: null,
            type: 'letters',
          }}
          onAdd={onAdd}
        />
      </FlowLayout>
      <MaskReorderableListBox
        selectionMode="single"
        items={list.items}
        acceptedDragTypes={['text/plain']}
        onReorder={onReorder}
        onInsert={onInsert}
        onRootDrop={onInsert}
      >
        {(item: MaskItemType) => {
          return (
            <Item key={item.id} textValue={item.type}>
              <MaskItemLabel item={item} /> <MaskItemValue item={item} onItemChange={onItemChange} />
              <div style={{ marginLeft: 'auto' }}></div>
              <Button
                icon={<CopyOutlined />}
                type="link"
                onClick={() => {
                  const originalId = item.id;
                  list.insertAfter(originalId, { ...item, id: uuid.v4() });
                }}
              />
              <DeleteButton
                onClick={() => {
                  list.remove(item.id);
                }}
              />
            </Item>
          );
        }}
      </MaskReorderableListBox>
      <Margin top>
        <FlowLayout alignRight>
          <Button icon={<CloseOutlined />} onClick={onCancel}>
            <Fmt id={'general.cancel'} />
          </Button>
          <Button
            icon={<CheckOutlined />}
            type="primary"
            onClick={() => {
              onSubmit(list.items);
            }}
          >
            <Fmt id={'general.save'} />
          </Button>
        </FlowLayout>
      </Margin>
    </>
  );
};

MaskInput.displayName = 'MaskInput';

export default MaskInput;
