import { Form } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Input, InputRef } from 'antd';
import { LabelDto, ServiceError } from 'api/completeApiInterfaces';
import classNames from 'classnames';
import ColorPickerInput from 'components/ColorPickerInput';
import DisplayName from 'components/DisplayName';
import Label from 'components/Label';
import ServiceErrorBox from 'components/ServiceErrorBox';
import { InjectedIntlProps } from 'locale';
import randomColor from 'randomcolor';
import React, { Component } from 'react';
import { duplicateNameRuleCallback, requiredRule } from 'utils/formHelpersCompatibility';
import styles from './LabelsListItemForm.module.less';

export type LabelCreateFormData = {
  name: string;
  description: string;
  color: string;
};

type Props = FormComponentProps<LabelCreateFormData> &
  InjectedIntlProps & {
    defaults?: LabelDto;
    validateUniqueName: (name: string) => boolean;
    setRef: (ref: InputRef) => void;
  };

interface State {
  error: ServiceError;
  name: string;
  _color: string;
}

class LabelsListItemForm extends Component<Props, State> {
  constructor(props: Props, state: State) {
    super(props, state);
    this.state = {
      error: null,
      _color: props.defaults ? props.defaults.color : (randomColor() as string),
      name: props.defaults ? props.defaults.name : '',
    };
  }

  componentDidMount() {
    const defaults = this.props.defaults;
    if (defaults) {
      this.props.form.setFieldsValue({ ...defaults });
    }
  }

  setColor = (color: string) => {
    this.setState({ _color: color });
  };

  render() {
    const { intl, form, defaults, validateUniqueName, setRef } = this.props;
    const { error, _color, name } = this.state;

    const { getFieldDecorator } = form;

    return (
      <div className={styles.listItem}>
        {error && <ServiceErrorBox error={error} className={styles.hbox} />}
        <div className={styles.hbox}>
          <Label color={_color}>
            {name ? <DisplayName text={name} /> : intl.formatMessage({ id: 'LabelsForm.preview' })}
          </Label>
        </div>
        <Form layout="vertical" labelAlign="left" className={styles.form}>
          <Form.Item
            label={intl.formatMessage({ id: 'LabelsForm.form.items.color.label' })}
            className={classNames(styles.item)}
          >
            {getFieldDecorator<LabelCreateFormData>('color', { initialValue: _color })(
              <ColorPickerInput onChange={this.setColor} />
            )}
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ id: 'forms.items.name.label' })}
            className={classNames(styles.item, styles.name)}
          >
            {getFieldDecorator<LabelCreateFormData>('name', {
              initialValue: name,
              rules: [
                requiredRule('forms.items.name.rules.required', true),
                duplicateNameRuleCallback(validateUniqueName),
              ],
            })(
              <Input
                placeholder={intl.formatMessage({ id: 'LabelsForm.form.items.name.placeholder' })}
                onChange={(e) => this.setState({ name: e.target.value })}
                maxLength={30}
                autoFocus
                ref={setRef}
              />
            )}
          </Form.Item>
          <Form.Item
            label={intl.formatMessage({ id: 'forms.items.description.label' })}
            className={classNames(styles.item, styles.description)}
          >
            {getFieldDecorator<LabelCreateFormData>('description', {
              initialValue: defaults ? defaults.description : '',
            })(
              <Input
                placeholder={intl.formatMessage({ id: 'LabelsForm.form.items.description.placeholder' })}
                maxLength={1000}
              />
            )}
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default Form.create<Props>()(LabelsListItemForm);
