import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Input, InputRef } from 'antd';
import DocumentMultipleActionError from 'components/DocumentMultipleActionError';
import { Fmt, InjectedIntlProps } from 'locale';
import React, { Component } from 'react';
import { requiredRule } from 'utils/formHelpersCompatibility';
import styles from './DocumentsDownloadForm.module.less';

export type DocumentsDownloadFormData = {
  archiveName: string;
};

type Props = FormComponentProps<DocumentsDownloadFormData> &
  InjectedIntlProps & {
    selectedDocuments: Guid[];
    selectedLinks: Guid[];
    firstDocumentName: string;
    errors: JSX.Element[];
    setRef: (ref: InputRef) => void;
  };

class DocumentsDownloadForm extends Component<Props> {
  render() {
    const { intl, form, firstDocumentName, selectedDocuments, selectedLinks, errors, setRef } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Form layout="horizontal" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
        <div className={styles.downloadDocumentsWrap}>
          <div className={styles.downloadDocumentsTitle}>
            {<Fmt id="DocumentsDownloadForm.downloadDocuments" values={{ count: selectedDocuments.length }} />}
          </div>
          <div className={styles.downloadDocumentsTitle}>
            {<Fmt id="DocumentsDownloadForm.downloadDocumentLinks" values={{ count: selectedLinks.length }} />}
          </div>
        </div>
        <Form.Item label={intl.formatMessage({ id: 'DocumentsDownloadForm.nameLabel' })}>
          {getFieldDecorator<DocumentsDownloadFormData>('archiveName', {
            initialValue: firstDocumentName,
            validateFirst: true,
            rules: [requiredRule('DocumentsDownloadForm.form.items.name.rules.required')],
          })(<Input autoFocus ref={setRef} />)}
        </Form.Item>
        {errors.length > 0 && (
          <DocumentMultipleActionError errors={errors} titleId={'DocumentsDownloadForm.errorsTitle.missed'} />
        )}
      </Form>
    );
  }
}

export default Form.create<Props>()(DocumentsDownloadForm);
