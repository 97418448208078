export const apiConstraints = {
  addBackgroundDocumentDto: {
    name: { maxLength: 255, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  appUserDto: {
    firstname: { maxLength: 50 },
    lastname: { maxLength: 50 },
  },

  appUserRequestDto: {
    mail: { minLength: 1 },
  },

  assignmentAnnotationSaveDto: {
    name: { maxLength: 128, minLength: 0 },
    content: { maxLength: 2000, minLength: 0 },
  },

  assignmentAttachmentAssignDto: {
    name: { maxLength: 255, minLength: 0 },
  },

  assignmentAttachmentCreateDto: {
    name: { maxLength: 255, minLength: 0 },
  },

  assignmentAttachmentDto: {
    name: { maxLength: 255, minLength: 0 },
  },

  assignmentAttachmentsDownloadDto: {
    name: { minLength: 1 },
  },

  assignmentCreateDto: {
    name: { maxLength: 128, minLength: 0 },
    content: { maxLength: 2000, minLength: 0 },
  },

  assignmentDto: {
    name: { minLength: 1 },
  },

  assignmentForExportRequestDto: {
    typ: { minLength: 1 },
    state: { minLength: 1 },
    createdDate: { minLength: 1 },
  },

  assignmentListForExportRequestDto: {
    nameColDesc: { minLength: 1 },
    projectNameColDesc: { minLength: 1 },
    typeColDesc: { minLength: 1 },
    stateColDesc: { minLength: 1 },
    createdDateColDesc: { minLength: 1 },
    taskGiverColDesc: { minLength: 1 },
    associateColDesc: { minLength: 1 },
    deadlineDateColDesc: { minLength: 1 },
    resolveDateColDesc: { minLength: 1 },
    contentColDesc: { minLength: 1 },
    solverColDesc: { minLength: 1 },
  },

  assignmentMessageDto: {
    name: { minLength: 1 },
    data: { minLength: 1 },
  },

  assignmentNoteCreateDto: {
    title: { maxLength: 256, minLength: 0 },
    message: { maxLength: 16000, minLength: 0 },
  },

  assignmentNoteDto: {
    title: { minLength: 1 },
  },

  assignmentNoteEditDto: {
    title: { maxLength: 256, minLength: 0 },
    message: { maxLength: 16000, minLength: 0 },
  },

  assignmentPatchDto: {
    name: { maxLength: 128, minLength: 0 },
    content: { maxLength: 2000, minLength: 0 },
  },

  auditLogUserActivityRequestDto: {
    sessionHash: { minLength: 1 },
  },

  authenticateWopiResponseDto: {
    aspeToken: { minLength: 1 },
    documentName: { minLength: 1 },
    user: { minLength: 1 },
    version: { minLength: 1 },
    fileName: { minLength: 1 },
    fileExt: { minLength: 1 },
    clientAddress: { minLength: 1 },
  },

  backgroundDocumentAddRequestDto: {
    name: { maxLength: 64 },
    description: { maxLength: 1000 },
  },

  backgroundDocumentPatchRequestDto: {
    name: { maxLength: 64 },
    description: { maxLength: 1000 },
  },

  commentProcedureCategoryCreateDto: {
    name: { maxLength: 128, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  commentProcedureCategoryDto: {
    name: { minLength: 1 },
    description: { minLength: 1 },
  },

  commentProcedureCommentAttachmentAssignDto: {
    name: { maxLength: 256, minLength: 0 },
  },

  commentProcedureCommentAttachmentCreateDto: {
    name: { maxLength: 255, minLength: 0 },
  },

  commentProcedureCommentAttachmentDto: {
    name: { maxLength: 256, minLength: 0 },
  },

  commentProcedureCommentAttachmentsDownloadDto: {
    name: { minLength: 1 },
  },

  commentProcedureCommentCreateDto: {
    title: { maxLength: 256, minLength: 0 },
    message: { maxLength: 32000, minLength: 0 },
  },

  commentProcedureCommentDto: {
    title: { minLength: 1 },
  },

  commentProcedureCommentListDto: {
    title: { minLength: 1 },
  },

  commentProcedureCommentNoteCreateDto: {
    title: { maxLength: 256, minLength: 0 },
    message: { maxLength: 16000, minLength: 0 },
  },

  commentProcedureCommentNoteDto: {
    title: { minLength: 1 },
  },

  commentProcedureCommentNoteEditDto: {
    title: { maxLength: 256, minLength: 0 },
    message: { maxLength: 16000, minLength: 0 },
  },

  commentProcedureCommentOverviewDto: {
    title: { minLength: 1 },
  },

  commentProcedureCreateDto: {
    name: { maxLength: 128, minLength: 1 },
    description: { maxLength: 1000 },
  },

  commentProcedureDocumentCreateDto: {
    name: { maxLength: 255, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  commentProcedureDto: {
    name: { minLength: 1 },
  },

  commentProcedureChangeDeadlineDto: {
    reason: { maxLength: 500, minLength: 0 },
  },

  commentProcedureListDto: {
    name: { minLength: 1 },
  },

  commentProcedurePatchDto: {
    name: { maxLength: 128 },
    description: { maxLength: 1000 },
  },

  commentProcedureTeamCreateDto: {
    name: { maxLength: 128, minLength: 0 },
  },

  commentProcedureTeamDto: {
    name: { minLength: 1 },
  },

  conflictedDataDto: {
    path: { minLength: 1 },
  },

  directoryAppDto: {
    name: { minLength: 1 },
  },

  directoryContentDto: {
    name: { minLength: 1 },
    directoryPath: { minLength: 1 },
  },

  directoryCreateDto: {
    name: { maxLength: 255, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  directoryDetailDto: {
    name: { minLength: 1 },
  },

  directoryDownloadDto: {
    name: { minLength: 1 },
  },

  directoryDto: {
    name: { minLength: 1 },
  },

  directoryLinkCreateDto: {
    name: { maxLength: 255, minLength: 0 },
  },

  directoryLinkPatchDto: {
    name: { maxLength: 255, minLength: 0 },
  },

  directoryOverviewDto: {
    name: { minLength: 1 },
  },

  directoryPatchDto: {
    name: { maxLength: 255, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  directoryUncheckDto: {
    name: { minLength: 1 },
    path: { minLength: 1 },
  },

  discardedDirectoryDto: {
    name: { minLength: 1 },
  },

  documentAnnotationSaveDto: {
    content: { maxLength: 2000, minLength: 0 },
  },

  documentCategoryNodeCreateDto: {
    name: { maxLength: 255, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  documentCategoryNodePatchDto: {
    name: { maxLength: 255, minLength: 0 },
    description: { maxLength: 255, minLength: 0 },
  },

  documentCategoryTreeCreateDto: {
    name: { maxLength: 255, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  documentCategoryTreePatchDto: {
    name: { maxLength: 255, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  documentCreateDto: {
    name: { maxLength: 255, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  documentLinkCreateDto: {
    name: { maxLength: 255, minLength: 0 },
  },

  documentLinkPatchDto: {
    name: { maxLength: 255, minLength: 0 },
  },

  documentMultipleDownloadDto: {
    name: { minLength: 1 },
  },

  documentPatchDto: {
    name: { maxLength: 255, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  estiConAdresaDto: {
    sign: { maxLength: 50, minLength: 0 },
    name: { maxLength: 100, minLength: 0 },
    icp: { maxLength: 20, minLength: 0 },
    ulice: { maxLength: 60, minLength: 0 },
    mesto: { maxLength: 60, minLength: 0 },
    psc: { maxLength: 5, minLength: 0 },
    poBox: { maxLength: 5, minLength: 0 },
    poznamka: { maxLength: 200, minLength: 0 },
    dorucovaciAdresa: { maxLength: 500, minLength: 0 },
    prohlaseniPrenosDph: { maxLength: 8000, minLength: 0 },
  },

  estiConAdresaPartnerDto: {
    dic: { maxLength: 14, minLength: 0 },
    nazev: { maxLength: 150, minLength: 0 },
    ico: { maxLength: 15, minLength: 0 },
    ulice: { maxLength: 60, minLength: 0 },
    mesto: { maxLength: 60, minLength: 0 },
    psc: { maxLength: 5, minLength: 0 },
    znacka: { maxLength: 50, minLength: 0 },
    icp: { maxLength: 20, minLength: 0 },
    poBox: { maxLength: 5, minLength: 0 },
    prohlaseniPrenosDph: { maxLength: 8000, minLength: 0 },
    obchodniRejstrik: { maxLength: 8000, minLength: 0 },
    dorucovaciAdresa: { maxLength: 500, minLength: 0 },
    kodBanky: { maxLength: 10, minLength: 0 },
    bicSwift: { maxLength: 20, minLength: 0 },
    adresaBanka: { maxLength: 500, minLength: 0 },
    predcisli: { maxLength: 6, minLength: 0 },
    cisloUctu: { maxLength: 10, minLength: 0 },
    iban: { maxLength: 32, minLength: 0 },
    nazevBanky: { maxLength: 150, minLength: 0 },
  },

  estiConAppProjektyArchivniDbHistorieDataDto: {
    userLogin: { maxLength: 50, minLength: 0 },
    userFirstName: { maxLength: 50, minLength: 0 },
    userLastName: { maxLength: 50, minLength: 0 },
  },

  estiConAppProjektyDataDto: {
    znacka: { maxLength: 50, minLength: 0 },
    nazev: { maxLength: 500, minLength: 0 },
    dodavatelKontaktniOsoba: { maxLength: 150, minLength: 0 },
    investorKontaktniOsoba: { maxLength: 150, minLength: 0 },
    projektantKontaktniOsoba: { maxLength: 150, minLength: 0 },
    sKod: { maxLength: 20, minLength: 0 },
    evidencniCislo: { maxLength: 20, minLength: 0 },
    zatrideniProjektuZnackaUzivatelske: { maxLength: 200, minLength: 0 },
    zatrideniProjektuNazevUzivatelske: { maxLength: 200, minLength: 0 },
  },

  estiConAppProjektyNastaveniDataDto: {
    dphZemeZnacka: { maxLength: 10, minLength: 0 },
    mena: { maxLength: 3, minLength: 0 },
    zaZhotovitele: { maxLength: 60, minLength: 0 },
    zaZhotoviteleFce: { maxLength: 60, minLength: 0 },
    zaObjednatele: { maxLength: 60, minLength: 0 },
    zaObjednateleFce: { maxLength: 60, minLength: 0 },
    mistoPodpisu: { maxLength: 60, minLength: 0 },
    okres: { maxLength: 60, minLength: 0 },
    mistoStavby: { maxLength: 60, minLength: 0 },
    smlouva: { maxLength: 60, minLength: 0 },
    smlouvaObjednatel: { maxLength: 60, minLength: 0 },
    zaZhotovitelePol: { maxLength: 60, minLength: 0 },
    zaZhotoviteleFcePol: { maxLength: 60, minLength: 0 },
    zaObjednatelePol: { maxLength: 60, minLength: 0 },
    zaObjednateleFcePol: { maxLength: 60, minLength: 0 },
    popisFunkce: { maxLength: 60, minLength: 0 },
    jmeno: { maxLength: 60, minLength: 0 },
    funkce: { maxLength: 60, minLength: 0 },
    csTyp: { maxLength: 200, minLength: 0 },
    csVarianta: { maxLength: 20, minLength: 0 },
    mistoPodpisuFa: { maxLength: 60, minLength: 0 },
    zaZhotoviteleFa: { maxLength: 60, minLength: 0 },
    zaObjednateleFa: { maxLength: 60, minLength: 0 },
    spravceFaRsd: { maxLength: 60, minLength: 0 },
    ms2014: { maxLength: 60, minLength: 0 },
    isprofin: { maxLength: 60, minLength: 0 },
    prohlaseniZp: { maxLength: 8000, minLength: 0 },
    prohlaseniFa: { maxLength: 8000, minLength: 0 },
    tmoCsTyp: { maxLength: 200, minLength: 0 },
    tmoCsVarianta: { maxLength: 20, minLength: 0 },
    zaZhotoviteleFaFce: { maxLength: 60, minLength: 0 },
    zaObjednateleFaFce: { maxLength: 60, minLength: 0 },
    zaZhotoviteleFaSdruzeni: { maxLength: 60, minLength: 0 },
    zaZhotoviteleFaSdruzeniFce: { maxLength: 60, minLength: 0 },
    zaObjednateleFaSdruzeni: { maxLength: 60, minLength: 0 },
    zaObjednateleFaSdruzeniFce: { maxLength: 60, minLength: 0 },
    mistoPodpisuFaSdruzeni: { maxLength: 60, minLength: 0 },
    spravceFaRsdSdruzeni: { maxLength: 60, minLength: 0 },
    prefixSdruzeniZp: { maxLength: 30, minLength: 0 },
    prefixSdruzeniFa: { maxLength: 30, minLength: 0 },
    stavbyvedouci: { maxLength: 60, minLength: 0 },
    cenoveProjednani: { maxLength: 60, minLength: 0 },
    autorskyDozor: { maxLength: 60, minLength: 0 },
    stavebniDozor: { maxLength: 60, minLength: 0 },
    spravceStavby: { maxLength: 60, minLength: 0 },
    opravnenaOsoba: { maxLength: 60, minLength: 0 },
    supervize: { maxLength: 60, minLength: 0 },
    zhotovitelPriloha7: { maxLength: 60, minLength: 0 },
    mereniZhotovitel: { maxLength: 60, minLength: 0 },
    mereniAsistent: { maxLength: 60, minLength: 0 },
  },

  estiConAppProjektyPoznamkyDataDto: {
    poznamka: { maxLength: 2000, minLength: 0 },
    autor: { maxLength: 110, minLength: 0 },
    login: { maxLength: 100, minLength: 0 },
  },

  estiConBankaDto: {
    name: { maxLength: 150, minLength: 0 },
    kodBanky: { maxLength: 10, minLength: 0 },
    bicSwift: { maxLength: 20, minLength: 0 },
    adresa: { maxLength: 500, minLength: 0 },
  },

  estiConBankovniUcetDto: {
    name: { maxLength: 60, minLength: 0 },
    cisloUctu: { maxLength: 18, minLength: 0 },
    predcisli: { maxLength: 6, minLength: 0 },
    iban: { maxLength: 32, minLength: 0 },
  },

  estiConBimModelStructureDto: {
    object3DUid: { maxLength: 100, minLength: 0 },
  },

  estiConCerpaniPolozkyDto: {
    odpady: { maxLength: 8000, minLength: 0 },
  },

  estiConCerpaniRozpocetStructureDto: {
    sign: { maxLength: 50, minLength: 0 },
    name: { maxLength: 500, minLength: 0 },
  },

  estiConCerpaniZjistovaciProtokolDto: {
    sign: { maxLength: 50, minLength: 0 },
  },

  estiConCisAdresyDataDto: {
    znacka: { maxLength: 50, minLength: 0 },
    nazev: { maxLength: 100, minLength: 0 },
    icp: { maxLength: 20, minLength: 0 },
    ulice: { maxLength: 60, minLength: 0 },
    mesto: { maxLength: 60, minLength: 0 },
    psc: { maxLength: 5, minLength: 0 },
    poBox: { maxLength: 5, minLength: 0 },
    poznamka: { maxLength: 200, minLength: 0 },
    prohlaseniPrenosDph: { maxLength: 8000, minLength: 0 },
    kategorieSubdodavky: { maxLength: 200, minLength: 0 },
    dorucovaciAdresa: { maxLength: 500, minLength: 0 },
  },

  estiConCisKategorieSubdodavkyDataDto: {
    znacka: { maxLength: 20, minLength: 0 },
    nazev: { maxLength: 200, minLength: 0 },
  },

  estiConCisKvDataDto: {
    znacka: { maxLength: 50, minLength: 0 },
    nazev: { maxLength: 50, minLength: 0 },
  },

  estiConCisKvPolozkyDataDto: {
    vzorec: { maxLength: 300, minLength: 0 },
  },

  estiConCisPartneriDataDto: {
    nazev: { maxLength: 150, minLength: 0 },
    ico: { maxLength: 15, minLength: 0 },
    dic: { maxLength: 14, minLength: 0 },
    obchodniRejstrik: { maxLength: 8000, minLength: 0 },
  },

  estiConCisPkvDataDto: {
    znacka: { maxLength: 50, minLength: 0 },
    nazev: { maxLength: 500, minLength: 0 },
  },

  estiConCisSkupinyZbvDataDto: {
    znacka: { maxLength: 20, minLength: 0 },
    nazev: { maxLength: 200, minLength: 0 },
  },

  estiConDphZemeDto: {
    sign: { maxLength: 10, minLength: 0 },
    name: { maxLength: 50, minLength: 0 },
  },

  estiConFakDetailDto: {
    sign: { maxLength: 50, minLength: 0 },
    danovyDoklad: { maxLength: 50, minLength: 0 },
    zaZhotovitele: { maxLength: 60, minLength: 0 },
    zaZhotoviteleFce: { maxLength: 60, minLength: 0 },
    zaObjednatele: { maxLength: 60, minLength: 0 },
    zaObjednateleFce: { maxLength: 60, minLength: 0 },
    mistoPodpisu: { maxLength: 60, minLength: 0 },
    spravceFaRSD: { maxLength: 60, minLength: 0 },
    smlouvaZhotovitel: { maxLength: 60, minLength: 0 },
    smlouvaObjednatel: { maxLength: 60, minLength: 0 },
    variabilniSymbol: { maxLength: 10, minLength: 0 },
    poznamkaSz: { maxLength: 8000, minLength: 0 },
  },

  estiConFakDetailHubLogDto: {
    userLogin: { maxLength: 50, minLength: 0 },
    userFirstName: { maxLength: 50, minLength: 0 },
    userLastName: { maxLength: 50, minLength: 0 },
    comment: { maxLength: 8000, minLength: 0 },
  },

  estiConFakFakturyDataDto: {
    znacka: { maxLength: 50, minLength: 0 },
    danovyDoklad: { maxLength: 50, minLength: 0 },
    isprofin: { maxLength: 50, minLength: 0 },
    mistoPodpisu: { maxLength: 60, minLength: 0 },
    zaZhotovitele: { maxLength: 60, minLength: 0 },
    zaObjednatele: { maxLength: 60, minLength: 0 },
    spravceFaRsd: { maxLength: 60, minLength: 0 },
    smlouva: { maxLength: 60, minLength: 0 },
    kde: { maxLength: 60, minLength: 0 },
    zaZhotoviteleFce: { maxLength: 60, minLength: 0 },
    zaObjednateleFce: { maxLength: 60, minLength: 0 },
    smlouvaObjednatel: { maxLength: 60, minLength: 0 },
    variabilniSymbol: { maxLength: 10, minLength: 0 },
    poznamkaSz: { maxLength: 8000, minLength: 0 },
    zatrideniCzcpa: { maxLength: 60, minLength: 0 },
  },

  estiConFakFakturyHubLogDataDto: {
    userLogin: { maxLength: 50, minLength: 0 },
    userFirstName: { maxLength: 50, minLength: 0 },
    userLastName: { maxLength: 50, minLength: 0 },
    comment: { maxLength: 8000, minLength: 0 },
  },

  estiConFakFakturyPartneriDataDto: {
    ico: { maxLength: 15, minLength: 0 },
    dic: { maxLength: 14, minLength: 0 },
    znacka: { maxLength: 50, minLength: 0 },
    nazev: { maxLength: 150, minLength: 0 },
    icp: { maxLength: 20, minLength: 0 },
    ulice: { maxLength: 60, minLength: 0 },
    mesto: { maxLength: 60, minLength: 0 },
    psc: { maxLength: 5, minLength: 0 },
    poBox: { maxLength: 5, minLength: 0 },
    prohlaseniPrenosDph: { maxLength: 8000, minLength: 0 },
    obchodniRejstrik: { maxLength: 8000, minLength: 0 },
    kodBanky: { maxLength: 10, minLength: 0 },
    bicSwift: { maxLength: 20, minLength: 0 },
    predcisli: { maxLength: 6, minLength: 0 },
    cisloUctu: { maxLength: 18, minLength: 0 },
    iban: { maxLength: 32, minLength: 0 },
    ks: { maxLength: 10, minLength: 0 },
    vs: { maxLength: 10, minLength: 0 },
    nazevBanky: { maxLength: 150, minLength: 0 },
    dorucovaciAdresa: { maxLength: 500, minLength: 0 },
    adresaBanky: { maxLength: 500, minLength: 0 },
  },

  estiConFakFakturySdruzeniDataDto: {
    znacka: { maxLength: 50, minLength: 0 },
    danovyDoklad: { maxLength: 50, minLength: 0 },
    isprofin: { maxLength: 50, minLength: 0 },
    zaZhotovitele: { maxLength: 60, minLength: 0 },
    zaZhotoviteleFce: { maxLength: 60, minLength: 0 },
    zaObjednatele: { maxLength: 60, minLength: 0 },
    zaObjednateleFce: { maxLength: 60, minLength: 0 },
    mistoPodpisu: { maxLength: 60, minLength: 0 },
    spravceFaRsd: { maxLength: 60, minLength: 0 },
    smlouva: { maxLength: 60, minLength: 0 },
    kde: { maxLength: 60, minLength: 0 },
    smlouvaObjednatel: { maxLength: 60, minLength: 0 },
    variabilniSymbol: { maxLength: 10, minLength: 0 },
  },

  estiConFakFakturySdruzeniHubLogDataDto: {
    userLogin: { maxLength: 50, minLength: 0 },
    userFirstName: { maxLength: 50, minLength: 0 },
    userLastName: { maxLength: 50, minLength: 0 },
    comment: { maxLength: 8000, minLength: 0 },
  },

  estiConFakFakturySdruzeniPartneriDataDto: {
    ico: { maxLength: 15, minLength: 0 },
    dic: { maxLength: 14, minLength: 0 },
    znacka: { maxLength: 50, minLength: 0 },
    nazev: { maxLength: 150, minLength: 0 },
    icp: { maxLength: 20, minLength: 0 },
    ulice: { maxLength: 60, minLength: 0 },
    mesto: { maxLength: 60, minLength: 0 },
    psc: { maxLength: 5, minLength: 0 },
    poBox: { maxLength: 5, minLength: 0 },
    prohlaseniPrenosDph: { maxLength: 8000, minLength: 0 },
    obchodniRejstrik: { maxLength: 8000, minLength: 0 },
    kodBanky: { maxLength: 10, minLength: 0 },
    bicSwift: { maxLength: 20, minLength: 0 },
    predcisli: { maxLength: 6, minLength: 0 },
    cisloUctu: { maxLength: 18, minLength: 0 },
    iban: { maxLength: 32, minLength: 0 },
    ks: { maxLength: 10, minLength: 0 },
    vs: { maxLength: 10, minLength: 0 },
    nazevBanky: { maxLength: 150, minLength: 0 },
    dorucovaciAdresa: { maxLength: 500, minLength: 0 },
    adresaBanky: { maxLength: 500, minLength: 0 },
  },

  estiConFakPartneriDto: {
    ico: { maxLength: 15, minLength: 0 },
    dic: { maxLength: 14, minLength: 0 },
    sign: { maxLength: 50, minLength: 0 },
    nazev: { maxLength: 150, minLength: 0 },
    icp: { maxLength: 20, minLength: 0 },
    ulice: { maxLength: 60, minLength: 0 },
    mesto: { maxLength: 60, minLength: 0 },
    psc: { maxLength: 5, minLength: 0 },
    poBox: { maxLength: 5, minLength: 0 },
    prohlaseniPrenosDph: { maxLength: 8000, minLength: 0 },
    obchodniRejstrik: { maxLength: 8000, minLength: 0 },
    dorucovaciAdresa: { maxLength: 500, minLength: 0 },
    kodBanky: { maxLength: 10, minLength: 0 },
    bicSwift: { maxLength: 20, minLength: 0 },
    adresaBanka: { maxLength: 500, minLength: 0 },
    predcisli: { maxLength: 6, minLength: 0 },
    cisloUctu: { maxLength: 10, minLength: 0 },
    iban: { maxLength: 32, minLength: 0 },
    ks: { maxLength: 10, minLength: 0 },
    vs: { maxLength: 10, minLength: 0 },
    nazevBanky: { maxLength: 150, minLength: 0 },
  },

  estiConFakProjektDto: {
    sign: { maxLength: 50, minLength: 0 },
    name: { maxLength: 500, minLength: 0 },
  },

  estiConFakSpolDetailDto: {
    sign: { maxLength: 50, minLength: 0 },
    danovyDoklad: { maxLength: 50, minLength: 0 },
    zaZhotovitele: { maxLength: 60, minLength: 0 },
    zaZhotoviteleFce: { maxLength: 60, minLength: 0 },
    zaObjednatele: { maxLength: 60, minLength: 0 },
    zaObjednateleFce: { maxLength: 60, minLength: 0 },
    mistoPodpisu: { maxLength: 60, minLength: 0 },
    spravceFaRSD: { maxLength: 60, minLength: 0 },
    smlouvaZhotovitel: { maxLength: 60, minLength: 0 },
    smlouvaObjednatel: { maxLength: 60, minLength: 0 },
    variabilniSymbol: { maxLength: 10, minLength: 0 },
  },

  estiConFakSpolDetailHubLogDto: {
    userLogin: { maxLength: 50, minLength: 0 },
    userFirstName: { maxLength: 50, minLength: 0 },
    userLastName: { maxLength: 50, minLength: 0 },
    comment: { maxLength: 8000, minLength: 0 },
  },

  estiConFakSpolPartneriDto: {
    ico: { maxLength: 15, minLength: 0 },
    dic: { maxLength: 14, minLength: 0 },
    sign: { maxLength: 50, minLength: 0 },
    nazev: { maxLength: 150, minLength: 0 },
    icp: { maxLength: 20, minLength: 0 },
    ulice: { maxLength: 60, minLength: 0 },
    mesto: { maxLength: 60, minLength: 0 },
    psc: { maxLength: 5, minLength: 0 },
    poBox: { maxLength: 5, minLength: 0 },
    prohlaseniPrenosDph: { maxLength: 8000, minLength: 0 },
    obchodniRejstrik: { maxLength: 8000, minLength: 0 },
    dorucovaciAdresa: { maxLength: 500, minLength: 0 },
    kodBanky: { maxLength: 10, minLength: 0 },
    bicSwift: { maxLength: 20, minLength: 0 },
    adresaBanka: { maxLength: 500, minLength: 0 },
    predcisli: { maxLength: 6, minLength: 0 },
    cisloUctu: { maxLength: 10, minLength: 0 },
    iban: { maxLength: 32, minLength: 0 },
    ks: { maxLength: 10, minLength: 0 },
    vs: { maxLength: 10, minLength: 0 },
    nazevBanky: { maxLength: 150, minLength: 0 },
  },

  estiConFakZjistovaciProtokolySimpleDto: {
    sign: { maxLength: 50, minLength: 0 },
  },

  estiConFirmDetailDto: {
    name: { maxLength: 50, minLength: 1 },
    description: { maxLength: 500, minLength: 1 },
    server: { maxLength: 100, minLength: 1 },
    userName: { maxLength: 100, minLength: 0 },
    password: { maxLength: 100, minLength: 0 },
  },

  estiConFirmRowDto: {
    nazev: { maxLength: 50, minLength: 1 },
    popis: { maxLength: 500, minLength: 1 },
    server: { maxLength: 100, minLength: 1 },
  },

  estiConHarmonogramPreruseniDto: {
    description: { maxLength: 250, minLength: 0 },
  },

  estiConHarmonogramProjektCalendarDto: {
    description: { maxLength: 250, minLength: 0 },
  },

  estiConHarmonogramProjektDto: {
    sign: { maxLength: 50, minLength: 0 },
    name: { maxLength: 500, minLength: 0 },
  },

  estiConHarmonogramStructureDto: {
    sign: { maxLength: 50, minLength: 0 },
    name: { maxLength: 500, minLength: 0 },
    wbs: { maxLength: 50, minLength: 0 },
  },

  estiConHarmonogramVersionDto: {
    sign: { maxLength: 50, minLength: 0 },
    name: { maxLength: 500, minLength: 0 },
    approveBy: { maxLength: 150, minLength: 0 },
  },

  estiConHmgDataDataDto: {
    znacka: { maxLength: 50, minLength: 0 },
    nazev: { maxLength: 500, minLength: 0 },
    wbs: { maxLength: 50, minLength: 0 },
  },

  estiConHmgPreruseniDataDto: {
    popis: { maxLength: 250, minLength: 0 },
  },

  estiConHmgProjektyKalendarDataDto: {
    popis: { maxLength: 250, minLength: 0 },
  },

  estiConHmgVerzeDataDto: {
    znacka: { maxLength: 50, minLength: 0 },
    nazev: { maxLength: 500, minLength: 0 },
    schvalil: { maxLength: 150, minLength: 0 },
  },

  estiConKalPotrebyStrukturaDataDto: {
    znacka: { maxLength: 50, minLength: 0 },
    nazev: { maxLength: 500, minLength: 0 },
    popis: { maxLength: 500, minLength: 0 },
    mj: { maxLength: 20, minLength: 0 },
    csTyp: { maxLength: 200, minLength: 0 },
    csVarianta: { maxLength: 20, minLength: 0 },
    znackaCs: { maxLength: 20, minLength: 0 },
    popis2: { maxLength: 500, minLength: 0 },
    kategorieDodavatele: { maxLength: 200, minLength: 0 },
    kod: { maxLength: 50, minLength: 0 },
  },

  estiConKategorieSubdodavekDto: {
    sign: { maxLength: 20, minLength: 0 },
    name: { maxLength: 200, minLength: 0 },
  },

  estiConKontaktniOsobaDto: {
    jmeno: { maxLength: 50, minLength: 0 },
    prijmeni: { maxLength: 50, minLength: 0 },
    telefon: { maxLength: 20, minLength: 0 },
    email: { maxLength: 70, minLength: 0 },
    titulPred: { maxLength: 15, minLength: 0 },
    titulZa: { maxLength: 15, minLength: 0 },
    mobil: { maxLength: 20, minLength: 0 },
  },

  estiConKVDto: {
    nazev: { maxLength: 50, minLength: 0 },
    znacka: { maxLength: 50, minLength: 0 },
  },

  estiConKVPolozkaDto: {
    vzorec: { maxLength: 300, minLength: 0 },
  },

  estiConKVVzorecDto: {
    vzorec: { maxLength: 300, minLength: 0 },
  },

  estiConMdlModelyDataDto: {
    nazev: { maxLength: 500, minLength: 0 },
    popis: { maxLength: 8000, minLength: 0 },
    formatSouboru: { maxLength: 20, minLength: 0 },
  },

  estiConMdlModelyStrukturaDataDto: {
    objekt3dGuid: { maxLength: 100, minLength: 0 },
  },

  estiConMdlModelyVerzeDataDto: {
    popis: { maxLength: 8000, minLength: 0 },
    soubor: { maxLength: 32, minLength: 0 },
  },

  estiConMenyDto: {
    sign: { maxLength: 3, minLength: 0 },
    name: { maxLength: 200, minLength: 0 },
    symbol: { maxLength: 3, minLength: 0 },
  },

  estiConNabNabidkyDataDto: {
    varianta: { maxLength: 50, minLength: 0 },
    importLog: { maxLength: 8000, minLength: 0 },
    popis: { maxLength: 8000, minLength: 0 },
    kriteria: { maxLength: 8000, minLength: 0 },
  },

  estiConNabPolozkyDataDto: {
    znacka: { maxLength: 50, minLength: 0 },
    nazev: { maxLength: 500, minLength: 0 },
    varianta: { maxLength: 3, minLength: 0 },
    popis: { maxLength: 8000, minLength: 0 },
    specifikace: { maxLength: 8000, minLength: 0 },
    mj: { maxLength: 20, minLength: 0 },
    csTyp: { maxLength: 200, minLength: 0 },
    csVarianta: { maxLength: 20, minLength: 0 },
  },

  estiConNabStrukturaDataDto: {
    znacka: { maxLength: 50, minLength: 0 },
    nazev: { maxLength: 500, minLength: 0 },
  },

  estiConPartnerDto: {
    name: { maxLength: 150, minLength: 0 },
    ico: { maxLength: 15, minLength: 0 },
    dic: { maxLength: 14, minLength: 0 },
    ulice: { maxLength: 60, minLength: 0 },
    mesto: { maxLength: 60, minLength: 0 },
    psc: { maxLength: 5, minLength: 0 },
    obchodniRejstrik: { maxLength: 8000, minLength: 0 },
  },

  estiConPKVDto: {
    znacka: { maxLength: 10, minLength: 0 },
    nazev: { maxLength: 50, minLength: 0 },
  },

  estiConPolozkaCerpaniDto: {
    sign: { maxLength: 50, minLength: 0 },
    name: { maxLength: 500, minLength: 0 },
    variant: { maxLength: 3, minLength: 0 },
    unit: { maxLength: 20, minLength: 0 },
    cenovaSoustava: { maxLength: 20, minLength: 0 },
    cenovaSoustavaType: { maxLength: 200, minLength: 0 },
  },

  estiConPolozkaFullDtoBase: {
    sign: { maxLength: 50, minLength: 0 },
    name: { maxLength: 500, minLength: 0 },
    variant: { maxLength: 3, minLength: 0 },
    unit: { maxLength: 20, minLength: 0 },
    cenovaSoustava: { maxLength: 20, minLength: 0 },
    cenovaSoustavaType: { maxLength: 200, minLength: 0 },
  },

  estiConProjectDashboardDto: {
    sign: { maxLength: 50, minLength: 0 },
    name: { maxLength: 500, minLength: 0 },
  },

  estiConProjectDto: {
    sign: { maxLength: 50, minLength: 0 },
    name: { maxLength: 500, minLength: 0 },
    kontaktInvestor: { maxLength: 150, minLength: 0 },
    kontaktProjektant: { maxLength: 150, minLength: 0 },
    kontaktDodavatel: { maxLength: 150, minLength: 0 },
    sKod: { maxLength: 20, minLength: 0 },
    evidencniCislo: { maxLength: 20, minLength: 0 },
  },

  estiConProjectHubLogDto: {
    userLogin: { maxLength: 50, minLength: 0 },
    userFirstName: { maxLength: 50, minLength: 0 },
    userLastName: { maxLength: 50, minLength: 0 },
    comment: { maxLength: 8000, minLength: 0 },
  },

  estiConProjectNoteDto: {
    note: { maxLength: 2000, minLength: 0 },
    autor: { maxLength: 110, minLength: 0 },
    login: { maxLength: 100, minLength: 0 },
  },

  estiConProjectRowDto: {
    skod: { maxLength: 20, minLength: 0 },
    evidencniCislo: { maxLength: 20, minLength: 0 },
  },

  estiConProjectSettingsDto: {
    cenovaSoustava: { maxLength: 20, minLength: 0 },
    cenovaSoustavaType: { maxLength: 200, minLength: 0 },
    zaZhotovitele: { maxLength: 60, minLength: 0 },
    zaZhotoviteleFunkce: { maxLength: 60, minLength: 0 },
    zaObjednatele: { maxLength: 60, minLength: 0 },
    zaObjednateleFunkce: { maxLength: 60, minLength: 0 },
    mistoPodpisu: { maxLength: 60, minLength: 0 },
    mistoPodpisuOkres: { maxLength: 60, minLength: 0 },
    mistoStavby: { maxLength: 60, minLength: 0 },
    smlouva: { maxLength: 60, minLength: 0 },
    smlouvaObjednatel: { maxLength: 60, minLength: 0 },
    zaZhotovitelePolozky: { maxLength: 60, minLength: 0 },
    zaZhotovitelePolozkyFunkce: { maxLength: 60, minLength: 0 },
    zaObjednatelePolozky: { maxLength: 60, minLength: 0 },
    zaObjednatelePolozkyFunkce: { maxLength: 60, minLength: 0 },
    popisFunkce: { maxLength: 60, minLength: 0 },
    jmeno: { maxLength: 60, minLength: 0 },
    funkce: { maxLength: 60, minLength: 0 },
    cenovaSoustavaTM: { maxLength: 20, minLength: 0 },
    cenovaSoustavaTypeTM: { maxLength: 200, minLength: 0 },
    isprofin: { maxLength: 60, minLength: 0 },
    ms2014: { maxLength: 60, minLength: 0 },
    zaZhotoviteleFa: { maxLength: 60, minLength: 0 },
    zaZhotoviteleFaFce: { maxLength: 60, minLength: 0 },
    zaObjednateleFa: { maxLength: 60, minLength: 0 },
    zaObjednateleFaFce: { maxLength: 60, minLength: 0 },
    mistoPodpisuFa: { maxLength: 60, minLength: 0 },
    spravceFaRsd: { maxLength: 60, minLength: 0 },
    zaZhotoviteleFaSdruzeni: { maxLength: 60, minLength: 0 },
    zaZhotoviteleFaSdruzeniFce: { maxLength: 60, minLength: 0 },
    zaObjednateleFaSdruzeni: { maxLength: 60, minLength: 0 },
    zaObjednateleFaSdruzeniFce: { maxLength: 60, minLength: 0 },
    mistoPodpisuFaSdruzeni: { maxLength: 60, minLength: 0 },
    spravceFaRsdSdruzeni: { maxLength: 60, minLength: 0 },
    prefixSdruzeniZp: { maxLength: 60, minLength: 0 },
    prefixSdruzeniFa: { maxLength: 60, minLength: 0 },
  },

  estiConProjectUserSettingsDto: {
    name: { maxLength: 200, minLength: 1 },
    setting: { minLength: 1 },
  },

  estiConReaCerpanePolozkyDataDto: {
    odpady: { maxLength: 8000, minLength: 0 },
  },

  estiConReaZjistovaciProtokolyDataDto: {
    znacka: { maxLength: 50, minLength: 0 },
    mistoStavby: { maxLength: 60, minLength: 0 },
    okres: { maxLength: 60, minLength: 0 },
    smlouva: { maxLength: 60, minLength: 0 },
    smlouvaObjednatel: { maxLength: 60, minLength: 0 },
    zaZhotovitele: { maxLength: 60, minLength: 0 },
    zaZhotoviteleFce: { maxLength: 60, minLength: 0 },
    zaObjednatele: { maxLength: 60, minLength: 0 },
    zaObjednateleFce: { maxLength: 60, minLength: 0 },
    mistoPodpisu: { maxLength: 60, minLength: 0 },
    zaZhotovitelePol: { maxLength: 60, minLength: 0 },
    zaZhotoviteleFcePol: { maxLength: 60, minLength: 0 },
    zaObjednatelePol: { maxLength: 60, minLength: 0 },
    zaObjednateleFcePol: { maxLength: 60, minLength: 0 },
    popisFunkce: { maxLength: 60, minLength: 0 },
    jmeno: { maxLength: 60, minLength: 0 },
    funkce: { maxLength: 60, minLength: 0 },
  },

  estiConReaZjistovaciProtokolyHubLogDataDto: {
    userLogin: { maxLength: 50, minLength: 0 },
    userFirstName: { maxLength: 50, minLength: 0 },
    userLastName: { maxLength: 50, minLength: 0 },
    comment: { maxLength: 8000, minLength: 0 },
  },

  estiConReaZjistovaciProtokolySdruzeniDataDto: {
    znacka: { maxLength: 50, minLength: 0 },
    mistoStavby: { maxLength: 60, minLength: 0 },
    okres: { maxLength: 60, minLength: 0 },
    smlouva: { maxLength: 60, minLength: 0 },
    smlouvaObjednatel: { maxLength: 60, minLength: 0 },
    zaZhotovitele: { maxLength: 60, minLength: 0 },
    zaZhotoviteleFce: { maxLength: 60, minLength: 0 },
    zaObjednatele: { maxLength: 60, minLength: 0 },
    zaObjednateleFce: { maxLength: 60, minLength: 0 },
    mistoPodpisu: { maxLength: 60, minLength: 0 },
    zaZhotovitelePol: { maxLength: 60, minLength: 0 },
    zaZhotoviteleFcePol: { maxLength: 60, minLength: 0 },
    zaObjednatelePol: { maxLength: 60, minLength: 0 },
    zaObjednateleFcePol: { maxLength: 60, minLength: 0 },
    popisFunkce: { maxLength: 60, minLength: 0 },
    jmeno: { maxLength: 60, minLength: 0 },
    funkce: { maxLength: 60, minLength: 0 },
  },

  estiConReaZjistovaciProtokolySdruzeniHubLogDataDto: {
    userLogin: { maxLength: 50, minLength: 0 },
    userFirstName: { maxLength: 50, minLength: 0 },
    userLastName: { maxLength: 50, minLength: 0 },
    comment: { maxLength: 8000, minLength: 0 },
  },

  estiConRozElementAtributDto: {
    name: { maxLength: 100, minLength: 0 },
    unit: { maxLength: 20, minLength: 0 },
  },

  estiConRozElementyAtributyDataDto: {
    nazev: { maxLength: 100, minLength: 0 },
    mj: { maxLength: 20, minLength: 0 },
  },

  estiConRozFakturyDodavateleDataDto: {
    znacka: { maxLength: 50, minLength: 0 },
  },

  estiConRozFiguryDataDto: {
    znacka: { maxLength: 50, minLength: 0 },
    popis: { maxLength: 1000, minLength: 0 },
  },

  estiConRozKpDataDataDto: {
    kp: { maxLength: 100, minLength: 0 },
  },

  estiConRozKPDto: {
    kp: { maxLength: 100, minLength: 0 },
  },

  estiConRozKvVzorceDataDto: {
    vzorec: { maxLength: 300, minLength: 0 },
  },

  estiConRozPolozkaDto: {
    sign: { maxLength: 50, minLength: 0 },
    name: { maxLength: 500, minLength: 0 },
    variant: { maxLength: 3, minLength: 0 },
    unit: { maxLength: 20, minLength: 0 },
    cenovaSoustava: { maxLength: 20, minLength: 0 },
    cenovaSoustavaType: { maxLength: 200, minLength: 0 },
  },

  estiConRozPolozkaHistorieCefDto: {
    vypracoval: { minLength: 1 },
    zduvodneni: { maxLength: 8000, minLength: 0 },
  },

  estiConRozPolozkaPotrebaDto: {
    znacka: { maxLength: 50, minLength: 0 },
    nazev: { maxLength: 500, minLength: 0 },
    popis: { maxLength: 8000, minLength: 0 },
    mj: { maxLength: 20, minLength: 0 },
    csVarianta: { maxLength: 20, minLength: 0 },
    csTyp: { maxLength: 200, minLength: 0 },
  },

  estiConRozPolozkaSpecifikaceDto: {
    description: { maxLength: 8000, minLength: 0 },
    specification: { maxLength: 8000, minLength: 0 },
    poznamka: { maxLength: 8000, minLength: 0 },
    poznamkaKalkulant: { maxLength: 8000, minLength: 0 },
  },

  estiConRozPolozkyDataDto: {
    znacka: { maxLength: 50, minLength: 0 },
    nazev: { maxLength: 500, minLength: 0 },
    varianta: { maxLength: 3, minLength: 0 },
    popis: { maxLength: 8000, minLength: 0 },
    specifikace: { maxLength: 8000, minLength: 0 },
    mj: { maxLength: 20, minLength: 0 },
    csTyp: { maxLength: 200, minLength: 0 },
    csVarianta: { maxLength: 20, minLength: 0 },
    poznamka: { maxLength: 8000, minLength: 0 },
    poznamkaKalkulant: { maxLength: 8000, minLength: 0 },
    skupinaMereni: { maxLength: 10, minLength: 0 },
  },

  estiConRozPolozkyHistorieCefDataDto: {
    vypracoval: { maxLength: 100, minLength: 0 },
    zduvodneni: { minLength: 1 },
  },

  estiConRozPolozkyKalkVerzeDataDto: {
    znacka: { maxLength: 50, minLength: 0 },
  },

  estiConRozPolozkyPotrebyDataDto: {
    znacka: { maxLength: 50, minLength: 0 },
    nazev: { maxLength: 500, minLength: 0 },
    popis: { maxLength: 8000, minLength: 0 },
    mj: { maxLength: 20, minLength: 0 },
    csTyp: { maxLength: 200, minLength: 0 },
    csVarianta: { maxLength: 20, minLength: 0 },
  },

  estiConRozPolozkyZpetneOceneniDataDto: {
    reference: { maxLength: 200, minLength: 0 },
  },

  estiConRozProjektDto: {
    sign: { maxLength: 50, minLength: 0 },
    name: { maxLength: 500, minLength: 0 },
  },

  estiConRozSOCerpaniDto: {
    zaZhotovitele: { maxLength: 60, minLength: 0 },
    zaZhotoviteleFunkce: { maxLength: 60, minLength: 0 },
    zaObjednatele: { maxLength: 60, minLength: 0 },
    zaObjednateleFunkce: { maxLength: 60, minLength: 0 },
    mistoPodpisu: { maxLength: 60, minLength: 0 },
    zaZhotovitelePolozky: { maxLength: 60, minLength: 0 },
    zaZhotovitelePolozkyFunkce: { maxLength: 60, minLength: 0 },
    zaObjednatelePolozky: { maxLength: 60, minLength: 0 },
    zaObjednatelePolozkyFunkce: { maxLength: 60, minLength: 0 },
    popisFunkce: { maxLength: 60, minLength: 0 },
    jmeno: { maxLength: 60, minLength: 0 },
    funkce: { maxLength: 60, minLength: 0 },
    zhotovitelMereniPol: { maxLength: 60, minLength: 0 },
    asistentMereniPol: { maxLength: 60, minLength: 0 },
  },

  estiConRozSoDataDataDto: {
    stavbyvedouci: { maxLength: 60, minLength: 0 },
    cenoveProjednani: { maxLength: 60, minLength: 0 },
    autorskyDozor: { maxLength: 60, minLength: 0 },
    stavebniDozor: { maxLength: 60, minLength: 0 },
    spravceStavby: { maxLength: 60, minLength: 0 },
    opravnenaOsoba: { maxLength: 60, minLength: 0 },
    supervize: { maxLength: 60, minLength: 0 },
    zadaniVypracoval: { maxLength: 60, minLength: 0 },
    nabidkuVypracoval: { maxLength: 60, minLength: 0 },
    zaZhotovitele: { maxLength: 60, minLength: 0 },
    zaZhotoviteleFce: { maxLength: 60, minLength: 0 },
    zaObjednatele: { maxLength: 60, minLength: 0 },
    zaObjednateleFce: { maxLength: 60, minLength: 0 },
    mistoPodpisu: { maxLength: 60, minLength: 0 },
    zaZhotovitelePol: { maxLength: 60, minLength: 0 },
    zaZhotoviteleFcePol: { maxLength: 60, minLength: 0 },
    zaObjednatelePol: { maxLength: 60, minLength: 0 },
    zaObjednateleFcePol: { maxLength: 60, minLength: 0 },
    popisFunkce: { maxLength: 60, minLength: 0 },
    jmeno: { maxLength: 60, minLength: 0 },
    funkce: { maxLength: 60, minLength: 0 },
    csTyp: { maxLength: 200, minLength: 0 },
    csVarianta: { maxLength: 20, minLength: 0 },
    szZhotovitel: { maxLength: 60, minLength: 0 },
    szDozor: { maxLength: 60, minLength: 0 },
    szSpravce: { maxLength: 60, minLength: 0 },
    szZastupce: { maxLength: 60, minLength: 0 },
    szPravnik: { maxLength: 60, minLength: 0 },
    szIniciatorZmeny: { maxLength: 60, minLength: 0 },
    szOdpovednaOsoba: { maxLength: 60, minLength: 0 },
    szVedouci: { maxLength: 60, minLength: 0 },
    szReditel: { maxLength: 60, minLength: 0 },
    szNamestek: { maxLength: 60, minLength: 0 },
    szPredbeznySouhlas: { maxLength: 60, minLength: 0 },
    mereniZhotovitel: { maxLength: 60, minLength: 0 },
    mereniAsistent: { maxLength: 60, minLength: 0 },
    zhotovitelPriloha7: { maxLength: 60, minLength: 0 },
    zatrideniZnackaCzcpa: { maxLength: 20, minLength: 0 },
    zatrideniNazevCzcpa: { maxLength: 200, minLength: 0 },
    zatrideniZnackaCzcc: { maxLength: 20, minLength: 0 },
    zatrideniNazevCzcc: { maxLength: 200, minLength: 0 },
    zatrideniZnackaUzivatelske: { maxLength: 20, minLength: 0 },
    zatrideniNazevUzivatelske: { maxLength: 200, minLength: 0 },
    mj: { maxLength: 20, minLength: 0 },
  },

  estiConRozSODto: {
    cenovaSoustava: { maxLength: 20, minLength: 0 },
    cenovaSoustavaType: { maxLength: 200, minLength: 0 },
    zatrideniZnackaCzcpa: { maxLength: 20, minLength: 0 },
    zatrideniNazevCzcpa: { maxLength: 200, minLength: 0 },
    zatrideniZnackaCzcc: { maxLength: 20, minLength: 0 },
    zatrideniNazevCzcc: { maxLength: 200, minLength: 0 },
    zatrideniZnackaUzivatelske: { maxLength: 20, minLength: 0 },
    zatrideniNazevUzivatelske: { maxLength: 200, minLength: 0 },
    mj: { maxLength: 20, minLength: 0 },
  },

  estiConRozSORozpocetDto: {
    stavbyvedouci: { maxLength: 60, minLength: 0 },
    cenoveProjednani: { maxLength: 60, minLength: 0 },
    autorskyDozor: { maxLength: 60, minLength: 0 },
    stavebniDozor: { maxLength: 60, minLength: 0 },
    spravceStavby: { maxLength: 60, minLength: 0 },
    opravnenaOsoba: { maxLength: 60, minLength: 0 },
    supervize: { maxLength: 60, minLength: 0 },
    zadaniVypracoval: { maxLength: 60, minLength: 0 },
    nabidkuVypracoval: { maxLength: 60, minLength: 0 },
    szZhotovitel: { maxLength: 60, minLength: 0 },
    szDozor: { maxLength: 60, minLength: 0 },
    szSpravce: { maxLength: 60, minLength: 0 },
    szZastupce: { maxLength: 60, minLength: 0 },
    szPravnik: { maxLength: 60, minLength: 0 },
    szIniciatorZmeny: { maxLength: 60, minLength: 0 },
    szOdpovednaOsoba: { maxLength: 60, minLength: 0 },
    szVedouci: { maxLength: 60, minLength: 0 },
    szReditel: { maxLength: 60, minLength: 0 },
    szNamestek: { maxLength: 60, minLength: 0 },
    szPredbeznySouhlas: { maxLength: 60, minLength: 0 },
    zhotovitelPriloha7: { maxLength: 60, minLength: 0 },
  },

  estiConRozStrukturaDataDto: {
    znacka: { maxLength: 50, minLength: 0 },
    nazev: { maxLength: 500, minLength: 0 },
    popis: { maxLength: 8000, minLength: 0 },
  },

  estiConRozStrukturaDto: {
    sign: { maxLength: 50, minLength: 0 },
    name: { maxLength: 500, minLength: 0 },
  },

  esticonSettingsDto: {
    projectDescription: { maxLength: 1000, minLength: 0 },
  },

  estiConSkupinaZbvDto: {
    sign: { maxLength: 20, minLength: 0 },
    name: { maxLength: 200, minLength: 0 },
  },

  estiConTmoDataDataDto: {
    znacka: { maxLength: 50, minLength: 0 },
    nazev: { maxLength: 500, minLength: 0 },
    mj: { maxLength: 20, minLength: 0 },
    popis: { maxLength: 8000, minLength: 0 },
    poznamka: { maxLength: 8000, minLength: 0 },
    identifikatorPropojeni: { maxLength: 50, minLength: 0 },
  },

  estiConTmoPolozkyCenyDataDto: {
    zalozeno: { maxLength: 50, minLength: 0 },
  },

  estiConTmoRozpisZbvEuroviaDataDto: {
    soZnacka: { maxLength: 50, minLength: 0 },
    soNazev: { maxLength: 500, minLength: 0 },
    smluvniDodatekZnacka: { maxLength: 50, minLength: 0 },
  },

  estiConTmoRozpisZbvEuroviaZlDataDto: {
    znacka: { maxLength: 50, minLength: 0 },
    nazev: { maxLength: 200, minLength: 0 },
  },

  estiConTmoSppEuroviaDataDto: {
    znacka: { maxLength: 50, minLength: 0 },
    nazev: { maxLength: 500, minLength: 0 },
  },

  estiConTmoVerzeDataDto: {
    znacka: { maxLength: 50, minLength: 0 },
    nazev: { maxLength: 500, minLength: 0 },
    poznamka: { maxLength: 8000, minLength: 0 },
  },

  estiConUtvaryStructureDto: {
    sign: { maxLength: 50, minLength: 0 },
    name: { maxLength: 200, minLength: 0 },
  },

  estiConVykazVymerFiguraRozpisDto: {
    sign: { maxLength: 50, minLength: 1 },
    description: { maxLength: 1000, minLength: 0 },
  },

  estiConVykazVymerRowDto: {
    kod: { maxLength: 50, minLength: 0 },
    popis: { maxLength: 8000, minLength: 0 },
    vzorec: { maxLength: 1000, minLength: 0 },
  },

  estiConVykRadkyDataDto: {
    kod: { maxLength: 50, minLength: 0 },
    popis: { maxLength: 8000, minLength: 0 },
    vzorec: { maxLength: 4000, minLength: 0 },
  },

  estiConZBVDodatekProjectDto: {
    sign: { maxLength: 50, minLength: 0 },
    name: { maxLength: 500, minLength: 0 },
  },

  estiConZBVDodatekStructureDto: {
    sign: { maxLength: 500, minLength: 0 },
    name: { maxLength: 500, minLength: 0 },
  },

  estiConZBVDodatekZmenovyListDto: {
    sign: { maxLength: 50, minLength: 0 },
    name: { maxLength: 200, minLength: 0 },
  },

  estiConZBVPolozkaDto: {
    sign: { maxLength: 50, minLength: 0 },
    name: { maxLength: 500, minLength: 0 },
    variant: { maxLength: 3, minLength: 0 },
    unit: { maxLength: 20, minLength: 0 },
    cenovaSoustava: { maxLength: 20, minLength: 0 },
    cenovaSoustavaType: { maxLength: 200, minLength: 0 },
  },

  estiConZBVPolozkaRozpisSkupinyDto: {
    displayName: { minLength: 1 },
  },

  estiConZBVPolozkyStructureDto: {
    sign: { maxLength: 50, minLength: 0 },
    name: { maxLength: 500, minLength: 0 },
  },

  estiConZBVPolozkyZmenovyListDto: {
    sign: { maxLength: 50, minLength: 0 },
    name: { maxLength: 200, minLength: 0 },
  },

  estiConZBVProjectDto: {
    sign: { maxLength: 50, minLength: 0 },
    name: { maxLength: 500, minLength: 0 },
  },

  estiConZbvSkupinyDataDto: {
    znacka: { maxLength: 20, minLength: 0 },
  },

  estiConZbvSkupinyDto: {
    sign: { maxLength: 50, minLength: 0 },
    popis: { maxLength: 8000, minLength: 0 },
    zduvodneni: { maxLength: 8000, minLength: 0 },
  },

  estiConZBVSmluvniDodatekDetailDto: {
    sign: { maxLength: 50, minLength: 0 },
    name: { maxLength: 200, minLength: 0 },
    zduvodneni: { maxLength: 8000, minLength: 0 },
  },

  estiConZBVSmluvniDodatekSimpleDto: {
    sign: { maxLength: 50, minLength: 0 },
  },

  estiConZbvSmluvniDodatkyDataDto: {
    znacka: { maxLength: 50, minLength: 0 },
    nazev: { maxLength: 200, minLength: 0 },
  },

  estiConZBVStructureDto: {
    sign: { maxLength: 50, minLength: 0 },
    name: { maxLength: 500, minLength: 0 },
  },

  estiConZbvZmenoveListyDataDto: {
    znacka: { maxLength: 50, minLength: 0 },
    nazev: { maxLength: 200, minLength: 0 },
    zaDodavatele: { maxLength: 60, minLength: 0 },
    zaProjektanta: { maxLength: 60, minLength: 0 },
    dozor: { maxLength: 60, minLength: 0 },
    spravce: { maxLength: 60, minLength: 0 },
    supervize: { maxLength: 60, minLength: 0 },
    cenoveProjednani: { maxLength: 60, minLength: 0 },
    opravnenaOsoba: { maxLength: 60, minLength: 0 },
    szZhotovitel: { maxLength: 60, minLength: 0 },
    szDozor: { maxLength: 60, minLength: 0 },
    szSpravce: { maxLength: 60, minLength: 0 },
    szZastupce: { maxLength: 60, minLength: 0 },
    szPravnik: { maxLength: 60, minLength: 0 },
    szIniciatorZmeny: { maxLength: 60, minLength: 0 },
    szOdpovednaOsoba: { maxLength: 60, minLength: 0 },
    szVedouci: { maxLength: 60, minLength: 0 },
    szReditel: { maxLength: 60, minLength: 0 },
    szNamestek: { maxLength: 60, minLength: 0 },
    szPredbeznySouhlas: { maxLength: 60, minLength: 0 },
    zhotovitelPriloha7: { maxLength: 60, minLength: 0 },
  },

  estiConZbvZmenoveListyHubLogDataDto: {
    userLogin: { maxLength: 50, minLength: 0 },
    userFirstName: { maxLength: 50, minLength: 0 },
    userLastName: { maxLength: 50, minLength: 0 },
    comment: { maxLength: 8000, minLength: 0 },
  },

  estiConZBVZmenovyListDetailDto: {
    sign: { maxLength: 50, minLength: 0 },
    name: { maxLength: 200, minLength: 0 },
    zaDodavatele: { maxLength: 60, minLength: 0 },
    zaProjektanta: { maxLength: 60, minLength: 0 },
    dozor: { maxLength: 60, minLength: 0 },
    spravce: { maxLength: 60, minLength: 0 },
    supervize: { maxLength: 60, minLength: 0 },
    cenoveProjednani: { maxLength: 60, minLength: 0 },
    opravnenaOsoba: { maxLength: 60, minLength: 0 },
    szZhotovitel: { maxLength: 60, minLength: 0 },
    szDozor: { maxLength: 60, minLength: 0 },
    szSpravce: { maxLength: 60, minLength: 0 },
    szZastupce: { maxLength: 60, minLength: 0 },
    szPravnik: { maxLength: 60, minLength: 0 },
    szIniciatorZmeny: { maxLength: 60, minLength: 0 },
    szOdpovednaOsoba: { maxLength: 60, minLength: 0 },
    szVedouci: { maxLength: 60, minLength: 0 },
    szReditel: { maxLength: 60, minLength: 0 },
    szNamestek: { maxLength: 60, minLength: 0 },
    szPredbeznySouhlas: { maxLength: 60, minLength: 0 },
    zhotovitelPriloha7: { maxLength: 60, minLength: 0 },
  },

  estiConZBVZmenovyListDto: {
    sign: { maxLength: 50, minLength: 0 },
    name: { maxLength: 200, minLength: 0 },
    zaDodavatele: { maxLength: 60, minLength: 0 },
    zaProjektanta: { maxLength: 60, minLength: 0 },
    dozor: { maxLength: 60, minLength: 0 },
    spravce: { maxLength: 60, minLength: 0 },
    supervize: { maxLength: 60, minLength: 0 },
    cenoveProjednani: { maxLength: 60, minLength: 0 },
    opravnenaOsoba: { maxLength: 60, minLength: 0 },
    szZhotovitel: { maxLength: 60, minLength: 0 },
    szDozor: { maxLength: 60, minLength: 0 },
    szSpravce: { maxLength: 60, minLength: 0 },
    szZastupce: { maxLength: 60, minLength: 0 },
    szPravnik: { maxLength: 60, minLength: 0 },
    szIniciatorZmeny: { maxLength: 60, minLength: 0 },
    szOdpovednaOsoba: { maxLength: 60, minLength: 0 },
    szVedouci: { maxLength: 60, minLength: 0 },
    szReditel: { maxLength: 60, minLength: 0 },
    szNamestek: { maxLength: 60, minLength: 0 },
    szPredbeznySouhlas: { maxLength: 60, minLength: 0 },
    zhotovitelPriloha7: { maxLength: 60, minLength: 0 },
  },

  estiConZBVZmenovyListHubLogDto: {
    userLogin: { maxLength: 50, minLength: 0 },
    userFirstName: { maxLength: 50, minLength: 0 },
    userLastName: { maxLength: 50, minLength: 0 },
    comment: { maxLength: 8000, minLength: 0 },
  },

  estiConZBVZmenovyListSimpleDto: {
    sign: { maxLength: 50, minLength: 0 },
  },

  estiConZjistovaciProtokolDto: {
    sign: { maxLength: 50, minLength: 0 },
    mistoStavby: { maxLength: 60, minLength: 0 },
    mistoPodpisuOkres: { maxLength: 60, minLength: 0 },
    smlouva: { maxLength: 60, minLength: 0 },
    smlouvaObjednatel: { maxLength: 60, minLength: 0 },
    zaZhotovitele: { maxLength: 60, minLength: 0 },
    zaZhotoviteleFunkce: { maxLength: 60, minLength: 0 },
    zaObjednatele: { maxLength: 60, minLength: 0 },
    zaObjednateleFunkce: { maxLength: 60, minLength: 0 },
    mistoPodpisu: { maxLength: 60, minLength: 0 },
    zaZhotovitelePolozky: { maxLength: 60, minLength: 0 },
    zaZhotovitelePolozkyFunkce: { maxLength: 60, minLength: 0 },
    zaObjednatelePolozky: { maxLength: 60, minLength: 0 },
    zaObjednatelePolozkyFunkce: { maxLength: 60, minLength: 0 },
    popisFunkce: { maxLength: 60, minLength: 0 },
    jmeno: { maxLength: 60, minLength: 0 },
    funkce: { maxLength: 60, minLength: 0 },
  },

  estiConZjistovaciProtokolHubLogDto: {
    userLogin: { maxLength: 50, minLength: 0 },
    userFirstName: { maxLength: 50, minLength: 0 },
    userLastName: { maxLength: 50, minLength: 0 },
    comment: { maxLength: 8000, minLength: 0 },
  },

  estiConZjistovaciProtokolSdruzeniDetailDto: {
    sign: { maxLength: 50, minLength: 0 },
    mistoStavby: { maxLength: 60, minLength: 0 },
    mistoPodpisuOkres: { maxLength: 60, minLength: 0 },
    smlouva: { maxLength: 60, minLength: 0 },
    smlouvaObjednatel: { maxLength: 60, minLength: 0 },
    zaZhotovitele: { maxLength: 60, minLength: 0 },
    zaZhotoviteleFunkce: { maxLength: 60, minLength: 0 },
    zaObjednatele: { maxLength: 60, minLength: 0 },
    zaObjednateleFunkce: { maxLength: 60, minLength: 0 },
    mistoPodpisu: { maxLength: 60, minLength: 0 },
    zaZhotovitelePolozky: { maxLength: 60, minLength: 0 },
    zaZhotovitelePolozkyFunkce: { maxLength: 60, minLength: 0 },
    zaObjednatelePolozky: { maxLength: 60, minLength: 0 },
    zaObjednatelePolozkyFunkce: { maxLength: 60, minLength: 0 },
    popisFunkce: { maxLength: 60, minLength: 0 },
    jmeno: { maxLength: 60, minLength: 0 },
    funkce: { maxLength: 60, minLength: 0 },
  },

  estiConZjistovaciProtokolSdruzeniDto: {
    sign: { maxLength: 50, minLength: 0 },
    mistoStavby: { maxLength: 60, minLength: 0 },
    mistoPodpisuOkres: { maxLength: 60, minLength: 0 },
    smlouva: { maxLength: 60, minLength: 0 },
    smlouvaObjednatel: { maxLength: 60, minLength: 0 },
    zaZhotovitele: { maxLength: 60, minLength: 0 },
    zaZhotoviteleFunkce: { maxLength: 60, minLength: 0 },
    zaObjednatele: { maxLength: 60, minLength: 0 },
    zaObjednateleFunkce: { maxLength: 60, minLength: 0 },
    mistoPodpisu: { maxLength: 60, minLength: 0 },
    zaZhotovitelePolozky: { maxLength: 60, minLength: 0 },
    zaZhotovitelePolozkyFunkce: { maxLength: 60, minLength: 0 },
    zaObjednatelePolozky: { maxLength: 60, minLength: 0 },
    zaObjednatelePolozkyFunkce: { maxLength: 60, minLength: 0 },
    popisFunkce: { maxLength: 60, minLength: 0 },
    jmeno: { maxLength: 60, minLength: 0 },
    funkce: { maxLength: 60, minLength: 0 },
  },

  estiConZjistovaciProtokolSdruzeniHubLogDto: {
    userLogin: { maxLength: 50, minLength: 0 },
    userFirstName: { maxLength: 50, minLength: 0 },
    userLastName: { maxLength: 50, minLength: 0 },
    comment: { maxLength: 8000, minLength: 0 },
  },

  estiConZjistovaciProtokolyListProjectDto: {
    sign: { maxLength: 50, minLength: 0 },
    name: { maxLength: 500, minLength: 0 },
  },

  estiConZjistovaciProtokolyStructureDto: {
    sign: { maxLength: 50, minLength: 0 },
    name: { maxLength: 500, minLength: 0 },
  },

  estiProjectNoteForHubCreateDto: {
    znacka: { maxLength: 50, minLength: 0 },
    nazevAkce: { maxLength: 500, minLength: 0 },
  },

  estiProjectNoteForHubDto: {
    znacka: { maxLength: 50, minLength: 0 },
    nazevAkce: { maxLength: 500, minLength: 0 },
  },

  estiProjectNoteForHubItemDto: {
    note: { maxLength: 2000, minLength: 0 },
  },

  estiProjectNoteForHubItemSetDto: {
    note: { maxLength: 2000, minLength: 0 },
  },

  estiProjectNoteForHubPatchDto: {
    znacka: { maxLength: 50, minLength: 0 },
    nazevAkce: { maxLength: 500, minLength: 0 },
  },

  estiProjectSettingsBaseDto: {
    projectCurrencySign: { maxLength: 3, minLength: 0 },
  },

  excludedDayDto: {
    name: { maxLength: 100, minLength: 0 },
  },

  excludedDaySetDto: {
    name: { maxLength: 100, minLength: 0 },
  },

  excludedDayTemplateDto: {
    name: { maxLength: 100, minLength: 0 },
  },

  extendedPermissionTemplateCreateDto: {
    name: { maxLength: 100, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  extendedPermissionTemplateDto: {
    name: { maxLength: 100, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  extendedPermissionTemplatePatchDto: {
    name: { maxLength: 100, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  externalApplicationSettingDto: {
    name: { minLength: 1 },
  },

  externalApplicationSettingSetDto: {
    name: { minLength: 1 },
  },

  externalApplicationsProjectSettingsTextPageDto: {
    title: { maxLength: 128, minLength: 1 },
  },

  externalApplicationsProjectSettingsTextPagePatchDto: {
    title: { maxLength: 128, minLength: 1 },
  },

  fakturaCreateDto: {
    sign: { minLength: 1 },
  },

  favoriteProjectListDto: {
    name: { maxLength: 100, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  forgeSearchLocationSettingsDto: {
    property: { minLength: 1 },
  },

  generalSavedRecordDeleteRequestDto: {
    iden: { maxLength: 512, minLength: 0 },
  },

  generalSavedRecordDto: {
    iden: { maxLength: 512, minLength: 0 },
  },

  generalSavedRecordRequestDto: {
    iden: { maxLength: 512, minLength: 0 },
  },

  groupCreateDto: {
    name: { maxLength: 255, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  groupPatchDto: {
    name: { maxLength: 255, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  labelCreateDto: {
    name: { maxLength: 30, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
    color: { minLength: 1 },
  },

  labelPatchDto: {
    name: { maxLength: 30, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  mdDivisionCreateDto: {
    name: { maxLength: 100, minLength: 2 },
    description: { maxLength: 1000, minLength: 0 },
    mdOrganizationName: { maxLength: 100, minLength: 0 },
  },

  mdDivisionDto: {
    name: { maxLength: 100, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
    organizationName: { maxLength: 100, minLength: 0 },
  },

  mdDivisionPatchDto: {
    name: { maxLength: 100, minLength: 2 },
    description: { maxLength: 1000, minLength: 0 },
    organizationName: { maxLength: 100, minLength: 0 },
  },

  mdProjectCreateDto: {
    name: { maxLength: 100, minLength: 2 },
    description: { maxLength: 1000, minLength: 0 },
  },

  mdProjectListDto: {
    name: { maxLength: 100, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  mdProjectPatchHeadDto: {
    name: { maxLength: 100, minLength: 2 },
    description: { maxLength: 1000, minLength: 0 },
  },

  modelElementCreateDto: {
    elementId: { maxLength: 64, minLength: 1 },
    name: { maxLength: 128, minLength: 1 },
    attributeName: { maxLength: 128 },
  },

  multiUploadFileDto: {
    name: { minLength: 1 },
  },

  organizationAdminReportDto: {
    name: { minLength: 1 },
    description: { maxLength: 1000, minLength: 0 },
    defaultTimeZoneId: { maxLength: 64, minLength: 0 },
  },

  organizationCreateDto: {
    name: { minLength: 1 },
    description: { maxLength: 1000, minLength: 0 },
    defaultTimeZoneId: { maxLength: 64, minLength: 0 },
  },

  organizationDto: {
    name: { minLength: 1 },
    description: { maxLength: 1000, minLength: 0 },
    defaultTimeZoneId: { maxLength: 64, minLength: 0 },
  },

  organizationPatchDto: {
    description: { maxLength: 1000, minLength: 0 },
    defaultTimeZoneId: { maxLength: 64, minLength: 0 },
  },

  organizationPOHReportSettingDto: {
    completeRealizationColor: { maxLength: 7, minLength: 0 },
  },

  organizationPOHReportUnitSettingDto: {
    color: { maxLength: 7, minLength: 0 },
    sign: { minLength: 1 },
  },

  organizationUserInfoDto: {
    firstname: { minLength: 1 },
    lastname: { minLength: 1 },
  },

  orgExtendedPermissionDto: {
    property: { minLength: 1 },
  },

  orgLabelCreateDto: {
    name: { maxLength: 30, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
    color: { minLength: 1 },
  },

  orgLabelPatchDto: {
    name: { maxLength: 30, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  orgUserClaimDto: {
    claimType: { minLength: 1 },
  },

  orgUserDto: {
    firstname: { maxLength: 50, minLength: 1 },
    lastname: { maxLength: 50, minLength: 1 },
  },

  orgUserRequestDto: {
    mail: { minLength: 1 },
    firstname: { maxLength: 50 },
    lastname: { maxLength: 50 },
  },

  orgWorkingPositionDto: {
    name: { minLength: 1 },
  },

  orgWorkingPositionSetDto: {
    name: { maxLength: 64, minLength: 0 },
  },

  prepareAddRevisionRequestDto: {
    description: { maxLength: 1000 },
  },

  projectBaseListDto: {
    name: { maxLength: 100, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  projectCreateDto: {
    name: { maxLength: 100, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
    timeZoneId: { maxLength: 64, minLength: 0 },
    storageArea: { maxLength: 3, minLength: 0 },
  },

  projectCreateFromTempDto: {
    name: { maxLength: 100, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
    storageArea: { maxLength: 3, minLength: 0 },
  },

  projectDto: {
    name: { maxLength: 100, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  projectForExportRequestDto: {
    name: { maxLength: 100, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
    organizationName: { maxLength: 100, minLength: 0 },
    createdDate: { minLength: 1 },
  },

  projectHubDto: {
    name: { maxLength: 100, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  projectListDto: {
    name: { maxLength: 100, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  projectListForExportRequestDto: {
    nameColDesc: { minLength: 1 },
    descriptionColDesc: { minLength: 1 },
    organizationNameColDesc: { minLength: 1 },
    labelsColDesc: { minLength: 1 },
    createdDateColDesc: { minLength: 1 },
  },

  projectMetadataDefinitionCreateDto: {
    name: { maxLength: 128, minLength: 0 },
  },

  projectMetadataDefinitionDto: {
    name: { minLength: 1 },
  },

  projectMetadataDefinitionPatchDto: {
    name: { maxLength: 128, minLength: 0 },
  },

  projectMetadataDocumentImportDto: {
    documentName: { minLength: 1 },
  },

  projectMetadataValueDto: {
    definitionName: { minLength: 1 },
    value: { maxLength: 256, minLength: 0 },
  },

  projectPatchDto: {
    name: { maxLength: 100, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
    timeZoneId: { maxLength: 64, minLength: 0 },
  },

  projectTemplateCategoryNodeDto: {
    name: { maxLength: 255, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  projectTemplateCategoryTreeDto: {
    name: { maxLength: 255, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  projectTemplateCreateDto: {
    name: { maxLength: 100, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  projectTemplateDirectoryDto: {
    name: { maxLength: 255, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  projectTemplateDto: {
    name: { maxLength: 100, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  projectTemplateGroupDto: {
    name: { maxLength: 255, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  projectTemplateLabelDto: {
    name: { maxLength: 30, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
    color: { minLength: 1 },
  },

  projectTemplateListDto: {
    name: { maxLength: 100, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  projectTemplatePatchDto: {
    name: { maxLength: 100, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  projectTemplateRoleDto: {
    name: { maxLength: 255, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  projectTemplateWfTmpCreateDto: {
    name: { maxLength: 255, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  projectTemplateWfTmpDto: {
    name: { maxLength: 255, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  projectTemplateWfTmpCheckedBackgroundDirDto: {
    origAliasName: { minLength: 1 },
  },

  projectTemplateWfTmpCheckedRoleDto: {
    origName: { minLength: 1 },
  },

  projectTemplateWfTmpCheckedUserDto: {
    origName: { minLength: 1 },
  },

  projectTemplateWfTmpCheckResponseDto: {
    origName: { minLength: 1 },
  },

  projectTemplateWfTmpListDto: {
    name: { maxLength: 255, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  projectUserRequestDto: {
    mail: { minLength: 1 },
  },

  refreshWopiResponseDto: {
    token: { minLength: 1 },
  },

  refuseBackgroundDocumentDto: {
    reason: { maxLength: 1000, minLength: 0 },
  },

  revisionCreateDto: {
    description: { minLength: 1 },
  },

  roleCreateDto: {
    name: { maxLength: 255, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  rolePatchDto: {
    name: { maxLength: 255, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  secondaryDocumentCreateDto: {
    name: { maxLength: 255, minLength: 0 },
  },

  signDocumentAppInfoDto: {
    returnAddress: { maxLength: 512, minLength: 1 },
  },

  signDocumentDownloadInfoDto: {
    documentName: { minLength: 1 },
  },

  signDocumentInfoDto: {
    signObjectKey: { maxLength: 256, minLength: 1 },
    signObjectValue: { maxLength: 256, minLength: 1 },
    fieldName: { maxLength: 256 },
  },

  signDocumentRequestDto: {
    returnAddress: { maxLength: 512, minLength: 1 },
    signObjectKey: { maxLength: 256, minLength: 1 },
    signObjectValue: { maxLength: 256, minLength: 1 },
    fieldName: { maxLength: 256 },
  },

  signDocumentResponseDto: {
    bankIdSignServerUrl: { minLength: 1 },
  },

  startWopiResponseDto: {
    wopiServerUrl: { minLength: 1 },
    wopiClientUrl: { minLength: 1 },
    token: { minLength: 1 },
  },

  subWorkflowDto: {
    name: { maxLength: 100, minLength: 1 },
    description: { maxLength: 1000 },
  },

  subWorkflowOverviewDto: {
    name: { maxLength: 100, minLength: 1 },
    description: { maxLength: 1000 },
  },

  subWorkflowTemplateCreateDto: {
    name: { maxLength: 100, minLength: 1 },
    description: { maxLength: 1000 },
  },

  subWorkflowTemplateDto: {
    name: { maxLength: 100, minLength: 1 },
    description: { maxLength: 1000 },
  },

  subWorkflowTemplatePatchDto: {
    name: { maxLength: 100, minLength: 1 },
    description: { maxLength: 1000 },
  },

  uploadCreateDto: {
    fileName: { maxLength: 255, minLength: 0 },
    clientFileId: { maxLength: 255, minLength: 0 },
  },

  uploadPartCompleteRequestDto: {
    eTag: { maxLength: 255, minLength: 0 },
  },

  uploadPartInitRequestDto: {
    hash: { minLength: 1 },
  },

  workflowBackgroundDocumentDto: {
    name: { maxLength: 64, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  workflowDefaultDirectoryDto: {
    name: { maxLength: 64, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  workflowDefaultDirectoryPatchDto: {
    name: { maxLength: 64, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  workflowDto: {
    name: { minLength: 1 },
  },

  workflowInstantiationDto: {
    instanceName: { maxLength: 100, minLength: 0 },
    instanceDescription: { maxLength: 1000, minLength: 0 },
  },

  workflowListDto: {
    name: { minLength: 1 },
  },

  workflowNodeAddDocumentDto: {
    name: { maxLength: 255, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  workflowNodeAddRevisionDto: {
    description: { minLength: 1 },
  },

  workflowNodeAttachmentAssignDto: {
    name: { maxLength: 255, minLength: 0 },
  },

  workflowNodeAttachmentCreateDto: {
    name: { maxLength: 255, minLength: 0 },
  },

  workflowNodeAttachmentDto: {
    name: { maxLength: 255, minLength: 0 },
  },

  workflowNodeAttachmentsDownloadDto: {
    name: { minLength: 1 },
  },

  workflowNodeDto: {
    name: { maxLength: 100, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  workflowNodeMessageDto: {
    subject: { maxLength: 100, minLength: 0 },
    message: { maxLength: 1000, minLength: 0 },
  },

  workflowNodeNoteCreateDto: {
    title: { maxLength: 256, minLength: 0 },
    message: { maxLength: 16000, minLength: 0 },
  },

  workflowNodeNoteDto: {
    title: { minLength: 1 },
  },

  workflowNodeNoteEditDto: {
    title: { maxLength: 256, minLength: 0 },
    message: { maxLength: 16000, minLength: 0 },
  },

  workflowNodePortDto: {
    name: { maxLength: 64, minLength: 1 },
    description: { maxLength: 150 },
  },

  workflowPatchDto: {
    name: { maxLength: 100 },
    description: { maxLength: 1000 },
  },

  workflowTemplateBackgroundDocumentCreateDto: {
    name: { maxLength: 64, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  workflowTemplateBackgroundDocumentDto: {
    name: { maxLength: 64, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  workflowTemplateBackgroundDocumentPatchDto: {
    name: { maxLength: 64, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  workflowTemplateCreateDto: {
    name: { maxLength: 100, minLength: 1 },
    description: { maxLength: 1000 },
  },

  workflowTemplateDirectoryAliasDto: {
    name: { maxLength: 64, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  workflowTemplateDto: {
    name: { minLength: 1 },
  },

  workflowTemplateDuplicateDto: {
    name: { maxLength: 100, minLength: 1 },
    description: { maxLength: 1000 },
  },

  workFlowTemplateCheckedBackgroundDirDto: {
    origAliasName: { minLength: 1 },
  },

  workFlowTemplateCheckedRoleDto: {
    origName: { minLength: 1 },
  },

  workFlowTemplateCheckedTemplateDto: {
    origName: { minLength: 1 },
  },

  workFlowTemplateCheckedUserDto: {
    origName: { minLength: 1 },
  },

  workFlowTemplateImportTemplateDto: {
    name: { minLength: 1 },
  },

  workflowTemplateListDto: {
    name: { minLength: 1 },
  },

  workflowTemplateNodeCreateDto: {
    name: { maxLength: 100, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  workflowTemplateNodeDto: {
    name: { maxLength: 100, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  workflowTemplateNodeMessageCreateDto: {
    subject: { maxLength: 100, minLength: 0 },
    message: { maxLength: 1000, minLength: 0 },
  },

  workflowTemplateNodeMessagePatchDto: {
    subject: { maxLength: 100, minLength: 0 },
    message: { maxLength: 1000, minLength: 0 },
  },

  workflowTemplateNodePatchDto: {
    name: { maxLength: 100, minLength: 0 },
    description: { maxLength: 1000, minLength: 0 },
  },

  workflowTemplateNodePortDto: {
    name: { maxLength: 64, minLength: 1 },
    description: { maxLength: 150 },
  },

  workflowTemplatePatchDto: {
    name: { maxLength: 100, minLength: 1 },
    description: { maxLength: 1000 },
  },

  zBVCreateDto: {
    sign: { minLength: 1 },
    objectSign: { minLength: 1 },
  },

  zjistovakCreateDto: {
    sign: { minLength: 1 },
    objectSign: { minLength: 1 },
  },
};
