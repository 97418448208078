import classNames from 'classnames';
import React, { FC } from 'react';
import { DropIndicatorProps, useDropIndicator } from 'react-aria';
import styles from './DropIndicator.module.less';

type Props = DropIndicatorProps & {
  dropState: any;
};

export const DropIndicator: FC<Props> = (props) => {
  let ref = React.useRef(null);
  let { dropIndicatorProps, isHidden, isDropTarget } = useDropIndicator(props, props.dropState, ref);
  if (isHidden) {
    return null;
  }

  return (
    <li
      className={classNames(styles.dropIndicator, isDropTarget && styles.dropTarget)}
      {...dropIndicatorProps}
      role="option"
      ref={ref}
    />
  );
};
