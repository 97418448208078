import { Button } from 'antd';
import { api } from 'api';
import { ApiPromise } from 'api/await-to';
import { masterApi } from 'api/completeApi';
import {
  AuditLogDto,
  AuditLogEntityRequestDto,
  AuditLogListResponseDto,
  AuditLogStavbaRequestDto,
  EntityTypesEnum,
  EstiCategoryEnum,
} from 'api/completeApiInterfaces';
import Axios, { CancelToken, CancelTokenSource } from 'axios';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { AddIcon } from 'components/Icons/HubActionsIcons';
import { Margin } from 'components/Margin/Margin';
import { MinHeightSizer } from 'components/MinHeightSizer/MinHeightSizer';
import { useSameCallback } from 'hooks';
import { Fmt, InjectedIntlProps, memoizeWithIntl } from 'locale';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { messageError } from 'utils';
import { AuditLogGrid } from './AuditLogGrid';

export type AuditLogRequestBuilder = (
  size: number,
  from: number,
  cancelToken: CancelToken
) => ApiPromise<AuditLogListResponseDto>;

type Props = InjectedIntlProps & {
  /** This needs to be memoized, since it is used as an dependency. */
  requestBuilder: AuditLogRequestBuilder;
  isAdminOutsideProject?: boolean;
};

const AUDIT_LOG_MIN_HEIGHT = 'max(50vh, 200px)';

export const createEntityAuditLogBuilder = (entityType: EntityTypesEnum, entityId: Guid): AuditLogRequestBuilder => (
  size,
  from,
  cancelToken
) => {
  const request: AuditLogEntityRequestDto = {
    timeFromFilter: new Date('1/1/2000').toISOString(),
    timeToFilter: new Date().toISOString(),
    size,
    from,
    entityId,
    entityType,
  };
  return api.project.auditLog.listAuditLogEntity(request, cancelToken);
};

export const createConstructionAuditLogBuilder = (
  esticonCategories: EstiCategoryEnum[] | null
): AuditLogRequestBuilder => (size, from, cancelToken) => {
  const request: AuditLogStavbaRequestDto = {
    timeFromFilter: new Date('1/1/2000').toISOString(),
    timeToFilter: new Date().toISOString(),
    size,
    from,
    esticonCategoryTypesFilter: esticonCategories?.length ? esticonCategories : null,
  };
  return api.project.auditLog.listAuditLogStavba(request, cancelToken);
};

export const createMasterApiEntityAuditLogBuilder = (
  entityType: EntityTypesEnum,
  entityId: Guid
): AuditLogRequestBuilder => (size, from, cancelToken) => {
  const request: AuditLogEntityRequestDto = {
    timeFromFilter: new Date('1/1/2000').toISOString(),
    timeToFilter: new Date().toISOString(),
    size,
    from,
    entityId,
    entityType,
  };
  return masterApi.auditlog.post(request);
};

const PAGE_SIZE = 50;

const AuditLogGridLoader: FunctionComponent<Props> = ({ intl, requestBuilder, isAdminOutsideProject = false }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [addLoading, setAddLoading] = useState<boolean>(false);
  const [logs, setLogs] = useState<AuditLogDto[]>([]);
  const [total, setTotal] = useState<number>(0);

  const cancelSourceRef = useRef<CancelTokenSource>();
  const loadAuditLogs = useCallback(
    (
      from: number,
      loadingSetter: React.Dispatch<React.SetStateAction<boolean>>,
      onSuccess: (data: AuditLogListResponseDto) => void
    ) => {
      cancelSourceRef.current?.cancel('AuditLogGridLoader: another loadAuditLogs call was made');
      cancelSourceRef.current = Axios.CancelToken.source();
      loadingSetter(true);
      requestBuilder(PAGE_SIZE, from, cancelSourceRef.current.token).then(([err, resp]) => {
        if (Axios.isCancel(err)) {
          return;
        }
        loadingSetter(false);
        if (err) {
          messageError(err, intl);
          return;
        }
        setTotal(resp.data.total);
        onSuccess(resp.data);
      });
    },
    [requestBuilder, intl]
  );

  useEffect(() => {
    // Invoking this effect might cancel "load more" request, which would not clear the loading flag on it's own,
    // so we need to clear it manually to not have a "loading more" spinbar active when nothing is loading.
    setAddLoading(false);
    loadAuditLogs(0, setLoading, (data) => {
      setLogs(data.auditLogs);
    });
  }, [loadAuditLogs]);

  const onNextPage = useSameCallback(() => {
    loadAuditLogs(logs.length, setAddLoading, (data) => {
      setLogs((logs) => [...logs, ...data.auditLogs]);
    });
  });

  useEffect(() => () => cancelSourceRef.current?.cancel('AuditLogGridLoader: unmounting'), []);

  return (
    <ContentGate overlay loading={loading} empty={!logs?.length}>
      <MinHeightSizer minHeight={AUDIT_LOG_MIN_HEIGHT} scrollable>
        <AuditLogGrid logs={logs} isAdminOutsideProject={isAdminOutsideProject} />
        {!loading && logs?.length < total && (
          <Margin top>
            <Button type="primary" size="large" icon={<AddIcon />} loading={addLoading} onClick={onNextPage}>
              <Fmt id="ProjectSearchPage.loadMore" values={{ count: Math.min(total - logs?.length, PAGE_SIZE) }} />
            </Button>
          </Margin>
        )}
      </MinHeightSizer>
    </ContentGate>
  );
};

export default memoizeWithIntl(AuditLogGridLoader);
