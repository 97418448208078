import { Button, Empty, Space, Typography } from 'antd';
import { api } from 'api';
import {
  AccessLevelEnum,
  DirectoryDto,
  DirectoryGroupDto,
  DirectoryGroupPatchDto,
  DirectoryListDto,
  DirectoryPermissionDto,
  DirectoryPermissionPatchDto,
  DirectoryUserDto,
  DirectoryUserPatchDto,
  ProjectUserProfileListDto,
} from 'api/completeApiInterfaces';
import AccessPermissionRequest from 'components/AccessPermissionRequest';
import { ContentGate } from 'components/ContentGate/ContentGate';
import DirectorySettingsPermissionsForm from 'components/DirectorySettingsPermissions/DirectorySettingsPermissionsForm';
import { Margin } from 'components/Margin/Margin';
import { SettingsBox } from 'components/SettingsBox/SettingsBox';
import SpinBoxCenter from 'components/SpinBoxCenter';
import StackPanel from 'components/StackPanel';
import { SIGNAL_R_DIRECTORY_CONNECTION } from 'config';
import { useApiData, useDispatchEffect, useSameCallback } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import produce from 'immer';
import { Fmt, InjectedIntlProps } from 'locale';
import { keyBy } from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'store';
import { DisableWindowStoreStartPayload } from 'store/models/storeModelinterfaces';
import { groupListSelector } from 'store/selectors/groupSelectors';
import { processApiError } from 'utils';
import styles from './DirectorySettingsPermissions.module.less';

type Props = InjectedIntlProps & {
  projectId: Guid;
  directory: DirectoryDto | DirectoryListDto;
  usersList: ProjectUserProfileListDto[];
  usersMap: Record<Guid, ProjectUserProfileListDto>;
};

interface DirectoryPermissionAction {
  type: 'set';
  payload: DirectoryPermissionDto;
}

interface SetGroupAction {
  type: 'setGroup';
  payload: DirectoryGroupPatchDto;
}

interface ResetGroupAction {
  type: 'reset';
}

interface RemoveGroupsAction {
  type: 'removeGroup';
  payload: Guid;
}

interface AddUserAction {
  type: 'addUser';
  payload: DirectoryUserPatchDto;
}

interface SetUserAction {
  type: 'setUser';
  payload: DirectoryUserPatchDto;
}

interface RemoveUserAction {
  type: 'removeUser';
  payload: Guid;
}

interface SetPermissionsInheritance {
  type: 'setPermissionsInheritance';
  payload: boolean;
}

export interface DirectoryPermissionMap {
  permissionInheritance: boolean;
  groups: Record<Guid, DirectoryGroupDto>;
  users: Record<Guid, DirectoryUserDto>;
}

export type DirectorySettingsPermissionsActions =
  | DirectoryPermissionAction
  | ResetGroupAction
  | SetGroupAction
  | RemoveGroupsAction
  | AddUserAction
  | SetUserAction
  | RemoveUserAction
  | SetPermissionsInheritance;

export interface PermissionState {
  permissionPatch: DirectoryPermissionPatchDto;
  isUsersDirty: boolean;
  isGroupsDirty: boolean;
  isInheritanceDirty: boolean;
  defaultPermissions: DirectoryPermissionDto;
  defaultPermissionsMap: DirectoryPermissionMap;
}

function checkUsersIsDirty(state: PermissionState) {
  const permissionsUsersMap = state.defaultPermissionsMap.users;
  const users = state.permissionPatch.users;
  const isDirty =
    users.length !== state.defaultPermissions.users.length ||
    users.some(
      (userPermission) =>
        (permissionsUsersMap[userPermission.userId] &&
          userPermission.accessLevel !== permissionsUsersMap[userPermission.userId].accessLevel) ||
        (!state.permissionPatch.permissionInheritance && !!userPermission.accessLevel)
    );
  return isDirty;
}

function checkGroupsIsDirty(state: PermissionState) {
  const groups: DirectoryGroupPatchDto[] = state.permissionPatch.groups;
  const permissions = state.defaultPermissions;
  return (
    groups.length !== permissions.groups.length ||
    groups.some((defaultPermission) =>
      permissions.groups.some(
        (userPermission) =>
          userPermission.groupId === defaultPermission.groupId &&
          userPermission.accessLevel !== defaultPermission.accessLevel
      )
    )
  );
}

function checkPermissionInheritanceIsDirty(state: PermissionState, permissionInheritance: boolean) {
  return state.defaultPermissions.permissionInheritance !== permissionInheritance;
}

export function permissionStateReducer(state: PermissionState, action: DirectorySettingsPermissionsActions) {
  switch (action.type) {
    case 'set': {
      const payload = {
        ...action.payload,
      };
      const permissionMap: DirectoryPermissionMap = {
        groups: keyBy(payload.groups, 'groupId'),
        users: keyBy(payload.users, 'userId'),
        permissionInheritance: payload.permissionInheritance,
      };
      return {
        ...state,
        permissionPatch: { ...payload },
        defaultPermissions: { ...payload },
        defaultPermissionsMap: permissionMap,
        isUsersDirty: false,
        isGroupsDirty: false,
        isInheritanceDirty: false,
      };
    }
    case 'setGroup':
      return produce(state, (draft) => {
        draft.permissionPatch.groups.find((g) => g.groupId === action.payload.groupId).accessLevel =
          action.payload.accessLevel;
        draft.isGroupsDirty = checkGroupsIsDirty(draft);
      });
    case 'reset':
      return produce(state, (draft) => {
        draft.permissionPatch = {
          groups: state.defaultPermissions.groups.map((g) => ({ accessLevel: g.accessLevel, groupId: g.groupId })),
          users: state.defaultPermissions.users.map((u) => ({ accessLevel: u.accessLevel, userId: u.userId })),
          permissionInheritance: state.defaultPermissions.permissionInheritance,
        };
        draft.isGroupsDirty = false;
        draft.isUsersDirty = false;
        draft.isInheritanceDirty = false;
      });
    case 'addUser':
      return produce(state, (draft) => {
        const existingUser = draft.permissionPatch.users.find((u) => u.userId === action.payload.userId);
        if (existingUser) {
          existingUser.accessLevel = AccessLevelEnum.none;
        } else {
          draft.permissionPatch.users.push(action.payload);
        }
        draft.isUsersDirty = checkUsersIsDirty(draft);
      });
    case 'setUser':
      return produce(state, (draft) => {
        draft.permissionPatch.users.find((u) => u.userId === action.payload.userId).accessLevel =
          action.payload.accessLevel;
        draft.isUsersDirty = checkUsersIsDirty(draft);
      });
    case 'removeUser':
      return produce(state, (draft) => {
        const user = draft.permissionPatch.users.find((u) => u.userId === action.payload);
        if (user) {
          if (!!draft.defaultPermissionsMap.users[action.payload]) {
            user.accessLevel = null;
          } else {
            draft.permissionPatch.users = draft.permissionPatch.users.filter((u) => u.userId !== action.payload);
          }
        }
        draft.isUsersDirty = checkUsersIsDirty(draft);
      });
    case 'setPermissionsInheritance':
      return produce(state, (draft) => {
        draft.permissionPatch.permissionInheritance = action.payload;
        draft.isInheritanceDirty = checkPermissionInheritanceIsDirty(state, action.payload);
      });
    default:
      throw new Error();
  }
}

const defaultPermissionsState: PermissionState = {
  isInheritanceDirty: false,
  isGroupsDirty: false,
  isUsersDirty: false,
  permissionPatch: undefined,
  defaultPermissions: undefined,
  defaultPermissionsMap: undefined,
};

const DirectorySettingsPermissions: FunctionComponent<Props> = ({
  intl,
  projectId,
  directory,
  usersList,
  usersMap,
}) => {
  const directoryId = directory ? directory.id : undefined;

  const [defaultPermissions, permissionsError, permissionsLoading, loadPermissions] = useApiData((ct) =>
    api.project.directories.getDirectoryPermissions(directoryId, ct)
  );

  useEffect(loadPermissions, [directoryId]);

  useEffect(() => {
    const reloadPermissionHandler = () => loadPermissions();
    SIGNAL_R_DIRECTORY_CONNECTION.on('DirectoryCurrentUserRightsChanged', reloadPermissionHandler);

    return () => {
      SIGNAL_R_DIRECTORY_CONNECTION.off('DirectoryCurrentUserRightsChanged', reloadPermissionHandler);
    };
  }, [directoryId]);

  const [permissionsState, dispatchPermissions] = useReducer(permissionStateReducer, defaultPermissionsState);
  const isDirty =
    permissionsState.isInheritanceDirty || permissionsState.isUsersDirty || permissionsState.isGroupsDirty;

  const permissions = permissionsState.permissionPatch;
  const defaultPermissionsMap = permissionsState.defaultPermissionsMap;

  const dispatch = useDispatch<Dispatch>();
  const [loading, setLoading] = useState(false);

  useDispatchEffect((dispatch) => dispatch.groups.loadData({ reload: false }), []);
  useDirtyStoreReload(
    (store) => store.groups,
    (dispatch) => dispatch.groups
  );
  const groupsList = useSelector(groupListSelector);
  const adminGroupIds = useMemo(
    () => groupsList?.filter((group) => group.isAdminGroup).map((group) => group.id) || [],
    [groupsList]
  );

  const handleChangePermissions = useCallback((newPermissions: DirectoryPermissionDto) => {
    if (!newPermissions) {
      return;
    }
    dispatchPermissions({
      type: 'set',
      payload: {
        groups: newPermissions.groups || [],
        users: newPermissions.users || [],
        permissionInheritance: newPermissions.permissionInheritance || false,
      },
    });
  }, []);

  useEffect(() => {
    handleChangePermissions(defaultPermissions);
  }, [defaultPermissions]);

  const disableWindowConfig = useMemo(
    (): DisableWindowStoreStartPayload => ({
      showMask: true,
      message: intl.formatMessage({ id: 'DirectorySettingsForm.permissions.notSaveAlert' }),
    }),
    [intl]
  );

  useEffect(() => {
    if (!isDirty) {
      dispatch.disableWindow.stopPreventing();
    } else {
      dispatch.disableWindow.startPreventing(disableWindowConfig);
    }
  }, [isDirty]);

  const handleSavePermission = useSameCallback(async () => {
    setLoading(true);
    const [err, result] = await api.project.directories.setDirectoryPermissions(directoryId, permissions);
    if (!!err) {
      processApiError(err);
    } else {
      handleChangePermissions(result.data);
    }
    setLoading(false);
  });

  const handleDiscardChanges = useCallback(() => {
    dispatchPermissions({
      type: 'reset',
    });
  }, []);

  if (!directory) {
    return (
      <div className={styles.emptyWrap}>
        <Empty />
      </div>
    );
  }

  return (
    <SpinBoxCenter spinning={loading} delay={100}>
      <StackPanel vertical scrollable className={styles.modalWrap}>
        <div className={styles.modalWindow}>
          <Margin top right bottom left>
            <SettingsBox>
              <Typography.Title level={4}>
                {intl.formatMessage({ id: 'DirectorySettingsForm.permissions.current' })}
              </Typography.Title>
              <div>
                <strong>
                  <Fmt id={`AccessLevel.tooltips.${directory.currentAccessLevel}`} />
                </strong>
                <div>
                  <Fmt id={`AccessLevel.tooltips.${directory.currentAccessLevel}.description`} />
                </div>
              </div>
              {directoryId && directory.currentAccessLevel !== AccessLevelEnum.admin && (
                <Margin top>
                  <AccessPermissionRequest
                    selectedDirectoryId={directoryId}
                    startAccessOption={directory.currentAccessLevel}
                  />
                </Margin>
              )}
            </SettingsBox>
            {directory.currentAccessLevel === AccessLevelEnum.admin && (
              <ContentGate error={permissionsError} loading={permissionsLoading}>
                {permissions && defaultPermissions && (
                  <DirectorySettingsPermissionsForm
                    adminGroupIds={adminGroupIds}
                    projectId={projectId}
                    directoryId={directory.id}
                    usersList={usersList}
                    usersMap={usersMap}
                    defaultPermissionsMap={defaultPermissionsMap}
                    permissions={permissions}
                    isDirty={isDirty}
                    dispatchPermissions={dispatchPermissions}
                  />
                )}
              </ContentGate>
            )}
          </Margin>
        </div>
        {isDirty && (
          <div className={styles.buttonGroup}>
            <Space.Compact>
              <Button type="default" disabled={loading} onClick={handleDiscardChanges}>
                {intl.formatMessage({ id: 'DirectorySettingsForm.permissions.discardChanges' })}
              </Button>
              <Button type="primary" disabled={loading} loading={loading} onClick={handleSavePermission}>
                {intl.formatMessage({ id: 'DirectorySettingsForm.permissions.save' })}
              </Button>
            </Space.Compact>
          </div>
        )}
      </StackPanel>
    </SpinBoxCenter>
  );
};

export default injectIntl(DirectorySettingsPermissions);
