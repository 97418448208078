import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Input } from 'antd';
import { api } from 'api';
import { GroupListDto, ProjectUserProfileStatusEnum } from 'api/completeApiInterfaces';
import { UserTransferFormItem, groupSelectUserFooter } from 'components/UserTransfer/UserTransfer';
import { useApiData } from 'hooks';
import { InjectedIntlProps } from 'locale';
import { Dictionary } from 'lodash';
import { AutoSizeType } from 'rc-textarea/lib/ResizableTextArea';
import React, { useEffect, useMemo } from 'react';
import { messageError } from 'utils';
import { textComparer } from 'utils/comparators';
import { requiredRule } from 'utils/formHelpersCompatibility';

export type AppUserShareFormData = {
  users: Dictionary<Guid>;
  message: string;
};

const AUTOSIZE_OPTIONS: AutoSizeType = { minRows: 3 };

type Props = FormComponentProps<AppUserShareFormData> &
  InjectedIntlProps & {
    directoryId: Guid;
    shareDocument: boolean;
  };

const AppUserShareFormComponent = React.forwardRef<unknown, Props>(
  ({ form, intl, directoryId, shareDocument }, ref) => {
    // backward compatibility with class components
    useEffect(() => {
      (ref as any).current = { props: { form } };
    }, [form]);

    const [accessInfo, accessInfoError, accessInfoLoading, loadAccessInfo] = useApiData((ct) =>
      api.project.sharedDownload.getDirectoryAccessInfo(directoryId, ct)
    );
    useEffect(loadAccessInfo, [directoryId]);

    useEffect(() => {
      if (accessInfoError) {
        messageError(accessInfoError, intl);
      }
    }, [accessInfoError]);

    const users = accessInfo?.users.filter((user) => user.status !== ProjectUserProfileStatusEnum.suspended);

    const groups = useMemo<GroupListDto[]>(() => accessInfo?.groups.sort(textComparer.map((group) => group.name)), [
      accessInfo,
    ]);

    const createFooter = useMemo(() => groupSelectUserFooter(groups, users), [groups, users]);

    return (
      <Form layout="vertical">
        <Form.Item label={intl.formatMessage({ id: 'AppUserShareFormModal.form.users' })}>
          {form.getFieldDecorator('users', {
            initialValue: [],
            rules: [requiredRule('forms.items.rules.noEmpty')],
          })(<UserTransferFormItem users={users} disabled={accessInfoLoading} createFooter={createFooter} />)}
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'AppUserShareFormModal.form.items.message.label' })}>
          {form.getFieldDecorator('message', {
            initialValue: shareDocument
              ? intl.formatMessage({ id: 'AppUserShareFormModal.form.items.message.initialValue.document' })
              : intl.formatMessage({ id: 'AppUserShareFormModal.form.items.message.initialValue.folder' }),
          })(<Input.TextArea rows={3} autoSize={AUTOSIZE_OPTIONS} />)}
        </Form.Item>
      </Form>
    );
  }
);

export default Form.create<Props>()(AppUserShareFormComponent);
