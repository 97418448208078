import { LoadingOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import classNames from 'classnames';
import { FlowLayout } from 'components/layouts/FlowLayout';
import React, { FunctionComponent, ReactNode } from 'react';
import styles from './GeneralSettingsItem.module.less';

export type ListItemProps = {
  children?: ReactNode;
  additionalActions?: ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onFocus?: () => void;
  disabled?: boolean;
  selected?: boolean;
  selectable?: boolean;
  className?: string;
  title: ReactNode;
  description?: ReactNode;
  icon?: ReactNode;
  input?: ReactNode;
  contentBody?: ReactNode;
  wrap?: boolean;
  loading?: boolean;
  hideCaret?: boolean;
  isBold?: boolean;
};

type Props = ListItemProps;

const GeneralSettingsItem: FunctionComponent<Props> = ({
  onClick,
  selected,
  selectable,
  disabled,
  children,
  additionalActions,
  title,
  input,
  description,
  icon,
  className,
  wrap,
  loading,
  hideCaret,
  isBold = true,
}) => {
  return (
    <div
      className={classNames(
        styles.listItem,
        selectable && onClick && styles.selectable,
        selected && styles.selected,
        disabled && styles.disabled,
        wrap && styles.wrap,
        className
      )}
      onClick={onClick}
      aria-disabled={disabled}
    >
      <div className={styles.listItemContent}>
        {!!icon && <div className={styles.listItemIcon}>{icon}</div>}
        <div className={styles.listItemHeader}>
          <div className={isBold ? styles.listItemTitle : undefined}>
            {title}
            {!!loading && (
              <>
                {' '}
                <LoadingOutlined />
              </>
            )}
          </div>

          {!!description && <Typography.Text className={styles.description}>{description}</Typography.Text>}
        </div>
        <div className={styles.listItemInput}>
          <FlowLayout wrap alignRight>
            {input}
            {additionalActions}
            {selectable && !hideCaret && <Button disabled={disabled} type="link" icon={<RightOutlined />} />}
          </FlowLayout>
        </div>
      </div>
      {children}
    </div>
  );
};

export default React.memo(GeneralSettingsItem);
