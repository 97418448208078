import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Input } from 'antd';
import { apiConstraints } from 'api/completeApiConstraints';
import { InjectedIntlProps } from 'locale';
import React, { useEffect } from 'react';
import { maxLengthRule, requiredRule } from 'utils/formHelpersCompatibility';

export type ProjectTemplatePermissionPresetFormData = {
  name: string;
  description: string;
};

type Props = FormComponentProps<ProjectTemplatePermissionPresetFormData> & InjectedIntlProps & {};

const ProjectTemplatePermissionPresetForm = React.forwardRef<unknown, Props>(({ intl, form }, ref) => {
  // backward compatibility with class components
  useEffect(() => {
    (ref as any).current = { props: { form } };
  }, [form]);

  const { getFieldDecorator } = form;
  return (
    <Form layout="vertical">
      <Form.Item label={intl.formatMessage({ id: 'forms.items.name.label' })}>
        {getFieldDecorator<ProjectTemplatePermissionPresetFormData>('name', {
          rules: [
            requiredRule('forms.items.name.rules.required', true),
            maxLengthRule('general.maxNameLength', apiConstraints.extendedPermissionTemplateCreateDto.name.maxLength),
          ],
        })(<Input autoFocus />)}
      </Form.Item>
      <Form.Item label={intl.formatMessage({ id: 'forms.items.description.label' })}>
        {getFieldDecorator<ProjectTemplatePermissionPresetFormData>('description', {
          rules: [
            maxLengthRule(
              'general.maxNameLength',
              apiConstraints.extendedPermissionTemplateCreateDto.description.maxLength
            ),
          ],
        })(<Input />)}
      </Form.Item>
    </Form>
  );
});

export default Form.create<Props>()(ProjectTemplatePermissionPresetForm);
