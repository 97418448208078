import { InjectedIntl } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import { useCallback } from 'react';

export enum Currency {
  CZK = 'CZK',
  EUR = 'EUR',
  USD = 'USD',
  PLN = 'PLN',
}

const INTL_ACCEPTABLE_CURRENCY_LENGHT = 3;

export const DEFAULT_CURRENCY: Currency = Currency.CZK;

export const checkCurrency = (esticonCurrency?: string, hubSetEsticonCurrency?: string) => {
  if (hubSetEsticonCurrency) return hubSetEsticonCurrency as Currency;
  if (!esticonCurrency) return DEFAULT_CURRENCY;
  const regex = /^[a-zA-Z]{3}/;
  return regex.test(esticonCurrency) && esticonCurrency.length === INTL_ACCEPTABLE_CURRENCY_LENGHT
    ? (esticonCurrency as Currency)
    : DEFAULT_CURRENCY;
};

export function formatCurrencyLabel(label: string, currencyKey: Currency, intl: InjectedIntl) {
  const currency =
    currencyKey in Currency ? intl.formatMessage({ id: `general.currency.${currencyKey}` }) : `${currencyKey}`;
  return intl.formatMessage({ id: 'general.currencyLabel' }, { label, currency });
}

export const useCurrencyLabelFormat = (currency: Currency, intl: InjectedIntl) => {
  return useCallback(
    (labelKey: IntlMessageId) => formatCurrencyLabel(intl.formatMessage({ id: labelKey }), currency, intl),
    [currency, intl]
  );
};
