import { Badge, Checkbox, Col, Collapse, Row, Tag, Typography } from 'antd';
import { api } from 'api';
import {
  MsgCategoryEnum,
  MsgCategoryTypeEnum,
  MsgCenterSettingDto,
  MsgCenterSettingPatchDto,
  ProjectListDto,
} from 'api/completeApiInterfaces';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { Margin } from 'components/Margin/Margin';
import { MinHeightSizer } from 'components/MinHeightSizer/MinHeightSizer';
import StackPanel from 'components/StackPanel';
import { WrapButton } from 'components/WrapButton/WrapButton';
import { DROPDOWN_TRIGGER_CLICK } from 'config/constants';
import { useApiData, useIntl } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, MouseEventHandler, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { messageError } from 'utils';
import { KeyOfType } from 'utils/types';
import { NO_SETTABLE_CATEGORIES, categoryMap } from '../Constants';
import { SettingsReducerActions, SettingsState } from './MessageCenterSettings';
import styles from './MessageCenterSettings.module.less';

const { Panel } = Collapse;

type Props = {
  className?: string;
  projectId: Guid;
  settingDispatch: (value: SettingsReducerActions) => void;
  settings: SettingsState;
  projectList: ProjectListDto[];
};

const STOP_PROPAGATION: MouseEventHandler<HTMLDivElement> = (event) => event.stopPropagation();

export const MessageProjectSettingsDetail: FunctionComponent<Props> = (props) => {
  const { className, projectId, settingDispatch, settings, projectList } = props;
  const intl = useIntl();

  const [_projectSettings, _projectSettingsError, loading, loadProjectSettings] = useApiData(
    (ct) => api.master.messageCenter.getProjectSettings(projectId, ct),
    {
      fetchCallback: (data) => settingDispatch({ type: 'setProjectSettings', projectId: projectId, settings: data }),
      errorCallback: (error) => messageError(error, intl),
      autoload: false,
    }
  );

  useEffect(() => {
    if (!settings.currentProjectSettings?.[projectId]) {
      loadProjectSettings();
    }
  }, [projectId]);

  const currentSetting = settings.currentProjectSettings?.[projectId];

  const handleSendEmailToggle = useCallback(
    (value: boolean, categories: MsgCategoryEnum[]) =>
      settingDispatch({
        type: 'setProjectSettingOption',
        categories,
        setting: 'sendEmail',
        value,
        projectId,
      }),
    [settingDispatch, projectId]
  );

  const handleSendReportToggle = useCallback(
    (value: boolean, categories: MsgCategoryEnum[]) =>
      settingDispatch({
        type: 'setProjectSettingOption',
        categories,
        setting: 'sendReport',
        value,
        projectId,
      }),
    [settingDispatch, projectId]
  );

  const handleIgnoreToggle = useCallback(
    (value: boolean, categories: MsgCategoryEnum[]) =>
      settingDispatch({
        type: 'setProjectSettingOption',
        categories,
        setting: 'ignore',
        value,
        projectId,
      }),
    [settingDispatch, projectId]
  );

  const handleResetDefault = useCallback(
    (categories: MsgCategoryEnum[]) =>
      settingDispatch({
        type: 'resetProjectSettingOption',
        categories,
        projectId,
      }),
    [settingDispatch, projectId]
  );

  const renderHeaderOption = useCallback(
    (categories: MsgCategoryEnum[]) => {
      const relevantSettings =
        currentSetting?.settings.filter((setting) => categories.includes(setting.category)) || [];

      const getCheckboxStatus = (
        property: keyof MsgCenterSettingPatchDto,
        defaultProperty: KeyOfType<MsgCenterSettingDto, boolean>
      ) => ({
        isChecked: relevantSettings.every((setting) => setting[property] ?? setting[defaultProperty]),
        isIndeterminate:
          relevantSettings.some((setting) => setting[property] ?? setting[defaultProperty]) &&
          relevantSettings.some((setting) => !(setting[property] ?? setting[defaultProperty])),
      });

      const sendEmailStatus = getCheckboxStatus('sendEmail', 'sendEmailDefault');
      const sendReportStatus = getCheckboxStatus('sendReport', 'sendReportDefault');
      const ignoreStatus = getCheckboxStatus('ignore', 'ignoreDefault');

      const isUsingDefaultSettings = relevantSettings.every(
        (setting) => setting.sendEmail === null && setting.sendReport === null && setting.ignore === null
      );

      return (
        <>
          <Col span={3} onClick={STOP_PROPAGATION}>
            <Checkbox
              checked={sendEmailStatus.isChecked}
              indeterminate={sendEmailStatus.isIndeterminate}
              onChange={(value) => handleSendEmailToggle(value.target.checked, categories)}
            />
          </Col>
          <Col span={3} onClick={STOP_PROPAGATION}>
            <Checkbox
              checked={sendReportStatus.isChecked}
              indeterminate={sendReportStatus.isIndeterminate}
              onChange={(value) => handleSendReportToggle(value.target.checked, categories)}
            />
          </Col>
          <Col span={3} onClick={STOP_PROPAGATION}>
            <Checkbox
              checked={ignoreStatus.isChecked}
              indeterminate={ignoreStatus.isIndeterminate}
              onChange={(value) => handleIgnoreToggle(value.target.checked, categories)}
            />
          </Col>
          <Col span={5} onClick={STOP_PROPAGATION}>
            {isUsingDefaultSettings ? (
              <Fmt id={'MessageCenterPage.settings.usingDefaultSetting.state'} />
            ) : (
              <WrapButton type="primary" size="small" onClick={() => handleResetDefault(categories)}>
                <Fmt id={'MessageCenterPage.settings.useDefaultSetting.button'} />
              </WrapButton>
            )}
          </Col>
        </>
      );
    },
    [currentSetting, handleSendEmailToggle, handleSendReportToggle, handleIgnoreToggle, handleResetDefault]
  );

  const renderDefaultOption = useCallback(
    (defaultOption: MsgCenterSettingDto) => {
      const isDefault =
        defaultOption.sendEmail !== null || defaultOption.sendReport !== null || defaultOption.ignore !== null;
      return (
        <div className={styles.panelContent}>
          <Row key={defaultOption.category}>
            <Col span={10}>
              <Fmt id={`MessageCenterPage.message.category.${defaultOption.category}.filter`} />
            </Col>
            <Col span={3} onClick={STOP_PROPAGATION}>
              <Checkbox
                checked={defaultOption.sendEmail ?? defaultOption.sendEmailDefault}
                onChange={(value) => handleSendEmailToggle(value.target.checked, [defaultOption.category])}
              />
            </Col>
            <Col span={3} onClick={STOP_PROPAGATION}>
              <Checkbox
                checked={defaultOption.sendReport ?? defaultOption.sendReportDefault}
                onChange={(value) => handleSendReportToggle(value.target.checked, [defaultOption.category])}
              />
            </Col>
            <Col span={3} onClick={STOP_PROPAGATION}>
              <Checkbox
                checked={defaultOption.ignore ?? defaultOption.ignoreDefault}
                onChange={(value) => handleIgnoreToggle(value.target.checked, [defaultOption.category])}
              />
            </Col>
            <Col span={5} onClick={STOP_PROPAGATION}>
              {isDefault ? (
                <WrapButton type="primary" size="small" onClick={() => handleResetDefault([defaultOption.category])}>
                  <Fmt id={'MessageCenterPage.settings.useDefaultSetting.button'} />
                </WrapButton>
              ) : (
                <Fmt id={'MessageCenterPage.settings.usingDefaultSetting.state'} />
              )}
            </Col>
          </Row>
        </div>
      );
    },
    [handleSendEmailToggle, handleSendReportToggle, handleIgnoreToggle, handleResetDefault]
  );

  const mappedSettings = useMemo(
    () =>
      currentSetting?.settings.reduce((result, setting) => {
        result[setting.category] = renderDefaultOption(setting);

        return result;
      }, {} as Record<MsgCategoryEnum, ReactNode>) || ({} as Record<MsgCategoryEnum, ReactNode>),
    [currentSetting, renderDefaultOption]
  );

  const unmappedKeys = useMemo(() => {
    return (currentSetting?.settings.map((setting) => setting.category) || [])
      .filter(
        (category) =>
          !Object.values(categoryMap).some((categoryGroup) => categoryGroup.includes(category)) &&
          !NO_SETTABLE_CATEGORIES.includes(category)
      )
      .sort();
  }, [currentSetting]);

  // render
  return (
    <ContentGate loading={loading}>
      <StackPanel vertical className={className}>
        <Row>
          <Col span={10}></Col>
          <Col span={3}>
            <CommonHubTooltip
              trigger={DROPDOWN_TRIGGER_CLICK}
              title={
                <>
                  <strong>
                    <Fmt id={'MessageCenterPage.settings.option.header.email'} />
                  </strong>
                  <br />
                  <Fmt id={'MessageCenterPage.settings.option.header.email.tooltip'} />
                </>
              }
            >
              <Typography.Text ellipsis>
                <Fmt id={'MessageCenterPage.settings.option.header.email'} />
              </Typography.Text>
            </CommonHubTooltip>
          </Col>
          <Col span={3}>
            <CommonHubTooltip
              trigger={DROPDOWN_TRIGGER_CLICK}
              title={
                <>
                  <strong>
                    <Fmt id={'MessageCenterPage.settings.option.header.report'} />
                  </strong>
                  <br />
                  <Fmt id={'MessageCenterPage.settings.option.header.report.tooltip'} />
                </>
              }
            >
              <Typography.Text ellipsis>
                <Fmt id={'MessageCenterPage.settings.option.header.report'} />
              </Typography.Text>
            </CommonHubTooltip>
          </Col>
          <Col span={3}>
            <CommonHubTooltip
              trigger={DROPDOWN_TRIGGER_CLICK}
              title={
                <>
                  <strong>
                    <Fmt id={'MessageCenterPage.settings.option.header.mute'} />
                  </strong>
                  <br />
                  <Fmt id={'MessageCenterPage.settings.option.header.mute.tooltip'} />
                </>
              }
            >
              <Typography.Text ellipsis>
                <Fmt id={'MessageCenterPage.settings.option.header.mute'} />
              </Typography.Text>
            </CommonHubTooltip>
          </Col>
          <Col span={5}>
            <CommonHubTooltip
              trigger={DROPDOWN_TRIGGER_CLICK}
              title={
                <>
                  <strong>
                    <Fmt id={'MessageCenterPage.settings.option.header.restore'} />
                  </strong>
                  <br />
                  <Fmt id={'MessageCenterPage.settings.option.header.restore.tooltip'} />
                </>
              }
            >
              <Typography.Text ellipsis>
                <Fmt id={'MessageCenterPage.settings.option.header.restore'} />
              </Typography.Text>
            </CommonHubTooltip>
          </Col>
        </Row>
        <MinHeightSizer minHeight={200}>
          <StackPanel vertical scrollable>
            {Object.keys(categoryMap).map((categoryType: MsgCategoryTypeEnum) => (
              <Margin top key={categoryType}>
                <Collapse bordered={false}>
                  <Panel
                    key={categoryType}
                    header={
                      <Row>
                        <Col span={10}>
                          <Typography.Text className={styles.badgedLabel}>
                            <Fmt id={`MessageCenterPage.message.categoryType.${categoryType}`} />
                          </Typography.Text>
                          <Tag>{categoryMap[categoryType]?.length}</Tag>
                        </Col>
                        {renderHeaderOption(categoryMap[categoryType])}
                      </Row>
                    }
                  >
                    {categoryMap[categoryType].map((category) => mappedSettings[category])}
                  </Panel>
                </Collapse>
              </Margin>
            ))}

            {unmappedKeys.length > 0 && (
              <Margin top>
                <Collapse bordered={false}>
                  <Panel
                    key={'unmappedKeys'}
                    header={
                      <Row>
                        <Col span={10}>
                          <Typography.Text className={styles.badgedLabel}>
                            <Fmt id={'MessageCenterPage.settings.unmappedCategory.title'} />
                          </Typography.Text>
                          <Tag>{unmappedKeys?.length}</Tag>
                        </Col>
                        {renderHeaderOption(unmappedKeys)}
                      </Row>
                    }
                  >
                    {unmappedKeys.map((category) => mappedSettings[category])}
                  </Panel>
                </Collapse>
              </Margin>
            )}
          </StackPanel>
        </MinHeightSizer>
      </StackPanel>
    </ContentGate>
  );
};
