import '@ant-design/compatible/assets/index.css';
import { Modal, ModalProps } from 'antd';
import { DirectoryContentsExDto, DirectoryListsExDto } from 'api/completeApiInterfaces';
import { ApiError } from 'api/errors';
import { AxiosResponse, CancelToken } from 'axios';
import {
  CommonDocument,
  DOCUMENT_FILTERS,
  DOCUMENT_ORDER_OPTIONS,
} from 'components/DocumentCompleteList/DocumentCompleteList';
import { mapDocumentLinkDtoToCommonLinkedDocument } from 'components/DocumentLinkRow/DocumentLinkRow';
import { DirectoryList } from 'components/DocumentSelect/DirectoryList';
import DocumentsGrid, { DocumentsGridItemProps } from 'components/DocumentsGrid/DocumentsGrid';
import StackPanel from 'components/StackPanel';
import { useApiData, useFrontendFilters, useIntl, useSameCallback } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { List } from 'react-virtualized';
import { directoryListToLinkedTree } from 'store/selectors';
import { messageError } from 'utils';
import { DirectoryNodeKey } from 'utils/typeMappings/directories/directoryTreeIds';
import { DirectoryNodeType, directoryNodeHelpers } from 'utils/typeMappings/directories/directoryTypes';
import styles from './MDProjectDirectorySelectModal.module.less';

type Props = {
  hubProjectId: Guid;
  directories: DirectoryListsExDto;
  onDocumentSelect: (documentId: Guid, documentName: string) => void;
  fetchDocumentsFn: (directoryId: Guid, ct?: CancelToken) => Promise<[ApiError, AxiosResponse<DirectoryContentsExDto>]>;
  getOriginalUrl?: (previewId: Guid) => Promise<string>;
} & ModalProps;

const transformDocumentToGridItem = (document: CommonDocument): DocumentsGridItemProps => ({
  ...document,
  name: document.name,
  revisionNo: undefined, //document.revision?.number,
  revisionDate: undefined, //document.revision?.createdDate,
  revisionUser: undefined, //document.revision?.createdBy,
  state: undefined, //document.revision?.revisionState,
  createdDate: undefined,
  createdBy: undefined,
  primaryFileContentType: document.primaryFile?.contentType,
  thumbnailUrl: document.primaryFile?.thumbnail,
  path: document.directoryPath,
});

const MDProjectDocumentSelectModal: FunctionComponent<Props> = ({
  hubProjectId,
  directories,
  onDocumentSelect,
  fetchDocumentsFn,
  getOriginalUrl,
  ...modalProps
}) => {
  const [selectedDirectoryKeys, setSelectedDirectoryKeys] = useState<DirectoryNodeKey[]>([]);
  const [selectedItemsIds, setSelectItem] = useState<Set<Guid>>(new Set());
  const listRef = useRef<List>();
  const intl = useIntl();

  const directoryTree = useMemo(() => directoryListToLinkedTree(directories.directories, []), [directories]);

  const selecedDirectoryId = useMemo(() => {
    const directoryKey =
      selectedDirectoryKeys[0] && directoryNodeHelpers.directoryNodeKeyToId(selectedDirectoryKeys[0]);

    if (directoryKey?.type === DirectoryNodeType.DirectoryLink) {
      return directoryTree.linksById[directoryKey.directoryLinkId]?.linkedDirectory.id;
    }
    return directoryKey?.directoryId || directoryTree.rootDir.id;
  }, [directoryTree, selectedDirectoryKeys]);

  const [documentAndLinks, documentsError, documentsLoading, loadDocuments] = useApiData(
    (ct) => fetchDocumentsFn(selecedDirectoryId, ct),
    { errorCallback: (err) => messageError(err, intl) }
  );

  useEffect(() => {
    if (selectedDirectoryKeys[0]) {
      loadDocuments();
    }
  }, [selectedDirectoryKeys]);

  const { documents, documentLinks } = documentAndLinks || {};

  const commonDocuments = useMemo(
    () =>
      documents
        ?.map(
          (document): CommonDocument => ({
            ...document,
            revision: document.currentRevision,
          })
        )
        .concat(documentLinks?.map(mapDocumentLinkDtoToCommonLinkedDocument) || []),
    [documents, documentLinks]
  );

  const { orderedItems, ...filterProps } = useFrontendFilters<CommonDocument>(
    DOCUMENT_FILTERS,
    DOCUMENT_ORDER_OPTIONS,
    commonDocuments
  );

  const handleDocumentConfirm = useSameCallback(() => {
    if (selectedItemsIds.size === 0) return;
    const documentId = selectedItemsIds.values().next().value;
    onDocumentSelect(documentId, commonDocuments.find((doc) => doc.id === documentId)?.name);
  });

  // Update grid on selected change
  useEffect(() => {
    listRef?.current?.forceUpdateGrid();
  }, [selectedItemsIds]);

  const handleSelectDocument = useCallback((documentId: Guid) => {
    setSelectItem((current) => (current.has(documentId) ? new Set<Guid>() : new Set([documentId])));
  }, []);

  return (
    <Modal
      {...modalProps}
      title={<Fmt id="MD.ProjectVariableLinkModal.document.title" />}
      okButtonProps={{ disabled: selectedItemsIds.size === 0 }}
      width={1200}
      onOk={handleDocumentConfirm}
    >
      <StackPanel className={styles.documentSelectContainer}>
        <StackPanel vertical scrollable className={styles.directoryListWrapper}>
          <DirectoryList
            selectedKeys={selectedDirectoryKeys}
            setSelectedKeys={setSelectedDirectoryKeys}
            directoryRoot={directoryTree.rootDir}
            selectedDocuments={[]}
            directoriesById={directoryTree.dirsById}
          />
        </StackPanel>
        <div className={styles.directroryContentWrapper}>
          <div className={styles.responsiveTitle}>
            <Fmt id="general.documents" />
          </div>
          <DocumentsGrid
            documents={orderedItems}
            disableReserve
            disableDownload
            selectedFilesIds={selectedItemsIds}
            listRef={listRef}
            selectFile={handleSelectDocument}
            transform={transformDocumentToGridItem}
            selectItemOnClickEnabled
            hasFilteredOutItems={filterProps.hasFilteredOutItems}
            clearFilters={filterProps.clearFilters}
          />
        </div>
      </StackPanel>
    </Modal>
  );
};

export default MDProjectDocumentSelectModal;
