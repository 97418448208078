import { ColProps, Collapse, Form, Modal, ModalProps } from 'antd';
import { createCancelToken } from 'api';
import { masterApi } from 'api/completeApi';
import {
  JToken,
  MdCkIdentificationEnum,
  MdMeDto,
  MdProjectDto,
  MdProjectListDto,
  MdProjectStateEnum,
  MdProjectVariableEnum,
  MdRoleDto,
  MdRoleEnum,
  OrgUserClaimDto,
} from 'api/completeApiInterfaces';
import { CancelTokenSource } from 'axios';
import { ContentGate } from 'components/ContentGate/ContentGate';
import StackPanel from 'components/StackPanel';
import { HIDE_BUTTON_PROPS } from 'config/constants';
import { useApiData, useIntl, useSameCallback } from 'hooks';
import { Fmt } from 'locale';
import { Dictionary, debounce, isEqual } from 'lodash';
import { FieldData } from 'rc-field-form/lib/interface';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { messageError } from 'utils';
import MDProjectCardCommission from './MDProjectCardCommission';
import MDProjectCardHead from './MDProjectCardHead';
import styles from './MDProjectCardModal.module.less';
import MDProjectCardProjectData from './MDProjectCardProjectData';
import MDProjectCardToolbar from './MDProjectCardToolbar';

type Props = {
  organizationId: Guid;
  mdProjectId: Guid;
  mdRoles: MdRoleDto[];
  mdProjects: MdProjectListDto[];
  onClose: () => void;
  onProjectUpdate?: (project: MdProjectDto) => void;
  mdCurrentUser: MdMeDto;
} & Omit<ModalProps, 'onOk' | 'title' | 'onCancel'>;

export const MD_FORM_LABEL_FULL_COL: ColProps = { span: 4 };
export const MD_FORM_LABEL_HALF_COL: ColProps = { span: 8 };
export const MD_FORM_ROW_GUTTER = 10;

export type MDProjectCardFormData = {
  name: string;
  description: string;
  ckIdentification: MdCkIdentificationEnum;
} & Record<MdProjectVariableEnum, JToken>;

const MDProjectCardModal: React.FC<Props> = ({
  mdProjectId,
  organizationId,
  mdRoles,
  mdProjects,
  onClose,
  onProjectUpdate,
  mdCurrentUser,
  ...modalProps
}) => {
  const [form] = Form.useForm();
  const intl = useIntl();

  const [isLoading, setLoading] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [extendedUserClaims, setExtendedUserClaims] = useState<OrgUserClaimDto[]>(null);

  const [projectData, projectDataError, projectDataLoading, loadProjectData, setProjectData] = useApiData(
    (ct) => masterApi.projects.md.project.id.get(mdProjectId, ct),
    { autoload: false }
  );

  useEffect(() => {
    loadProjectData();
  }, [mdProjectId]);

  const validateDirty = useMemo(
    () =>
      debounce((_changedFields: FieldData[], allFields: FieldData[]) => {
        if (!projectData) return;
        const isDirty = allFields.some((fieldData) => {
          const fieldName = Array.isArray(fieldData.name) ? fieldData.name[0] : fieldData.name;
          if (fieldName === 'name') return projectData.name !== fieldData.value;
          if (fieldName === 'description') return projectData.description !== fieldData.value;
          return (
            projectData.projectData.some(
              (data) => data.variable === fieldName && !isEqual(data.data, fieldData.value)
            ) ||
            (!projectData.projectData.some((data) => data.variable === fieldName) && !!fieldData.value)
          );
        });
        setDirty(isDirty);
      }, 200),
    [projectData]
  );

  const handleFormReset = useCallback(() => {
    form.resetFields();
    setDirty(false);
  }, [form]);

  useEffect(() => {
    if (!projectData) return;
    const fetchUserExtras = async (cancelToken: CancelTokenSource) => {
      const userIds = [
        projectData.proposer?.id,
        projectData.guarantor?.head.orgUser.id,
        ...(projectData.guarantor?.mdUsers.map((user) => user.orgUser.id) || []),
      ].filter(Boolean);

      const [err, res] = await masterApi.projects.admin.getorgusers.id.claims.post(
        organizationId,
        { orgUserIds: userIds },
        cancelToken.token
      );
      if (err) {
        messageError(err, intl);
        return;
      }
      setExtendedUserClaims(res.data.userClaims);
    };

    const cancelToken = createCancelToken();
    void fetchUserExtras(cancelToken);
    () => cancelToken.cancel();
  }, [organizationId, projectData]);

  const handleFormSubmit = useSameCallback(async (values: MDProjectCardFormData) => {
    setLoading(true);
    if (
      values.name !== projectData.name ||
      values.description !== projectData.description ||
      values.ckIdentificaion !== projectData.ckIdentificaion
    ) {
      const [err, res] = await masterApi.projects.md.project.patchhead.patch({
        id: projectData.id,
        name: values.name,
        description: values.description,
        ckIdentificaion: values.ckIdentification,
      });

      if (err) {
        messageError(err, intl);
        setLoading(false);
        return;
      }
      setProjectData(res.data);
      onProjectUpdate(res.data);
    }

    const variableItemValues = Object.keys(MdProjectVariableEnum).reduce(
      (dataRecord, variableType: MdProjectVariableEnum) => {
        if (!(variableType in values)) return dataRecord;
        return { ...dataRecord, [variableType]: values[variableType] };
      },
      {} as Record<MdProjectVariableEnum, Dictionary<JToken>>
    );

    const mappedChangedData = Object.entries(variableItemValues).reduce(
      (dataRecord, variableItemValue: [MdProjectVariableEnum, Dictionary<JToken>]) => {
        const [variableType, variableData] = variableItemValue;
        const originalData = projectData.projectData.find((data) => data.variable === variableType)?.data;
        if (!originalData?.data && !variableData) return dataRecord;
        if (!isEqual(variableData, originalData)) {
          return { ...dataRecord, [variableType]: variableData };
        }

        return dataRecord;
      },
      {} as Dictionary<Dictionary<JToken>>
    );

    if (Object.keys(mappedChangedData).length > 0) {
      const [errData, resData] = await masterApi.projects.md.project.setdata.post({
        mdProjectId: projectData.id,
        phase: projectData.phase,
        insertOrUpdateData: mappedChangedData,
      });

      if (errData) {
        messageError(errData, intl);
        setLoading(false);
        return;
      }
      setProjectData(resData.data);
      onProjectUpdate(resData.data);
    }
    validateDirty.cancel();
    setDirty(false);
    setLoading(false);
  });

  const handleDataUpdate = useCallback(
    (project: MdProjectDto) => {
      setProjectData(project);
      onProjectUpdate(project);
    },
    [onProjectUpdate, setProjectData]
  );

  const isProposerRole = useMemo(() => {
    if (!projectData || !mdCurrentUser) return false;
    const isProposer = mdCurrentUser.mdRoles?.some(
      (role) => role.mdRoleType === MdRoleEnum.proposer && projectData.proposer.id === role.head.orgUser.id
    );
    if (!isProposer) return false;
    return isProposer && projectData.state === MdProjectStateEnum.entering;
  }, [projectData, mdCurrentUser]);

  const isO910RoleInDivisionSetting = useMemo(() => {
    if (!projectData || !mdCurrentUser || projectData.state != MdProjectStateEnum.divisionSetting) return false;
    const isOnO910Role = mdCurrentUser.mdRoles?.some((role) => role.mdRoleType === MdRoleEnum.O910);
    return isOnO910Role;
  }, [mdCurrentUser, projectData]);

  const isCKOrganizerRole = useMemo(() => {
    if (!projectData || !mdCurrentUser) return false;
    if (
      projectData.state === MdProjectStateEnum.entering ||
      projectData.state === MdProjectStateEnum.divisionSetting ||
      projectData.state === MdProjectStateEnum.guarantorSetting
    ) {
      return false;
    }
    const isInCkRoles = mdCurrentUser.mdRoles?.some(
      (role) => role.mdRoleType === MdRoleEnum.ck_organizer || role.mdRoleType === MdRoleEnum.ck_head
    );
    return isInCkRoles;
  }, [projectData, mdCurrentUser]);

  const isProjectGuarantorRole = useMemo(() => {
    if (!projectData || !mdCurrentUser) return false;
    const isInDivisionRole = mdCurrentUser.mdRoles?.some(
      (role) => role.mdRoleType === MdRoleEnum.guarantor && role.id === projectData.guarantor?.id
    );
    return (
      isInDivisionRole &&
      (projectData.state == MdProjectStateEnum.projectProcessing ||
        projectData.state == MdProjectStateEnum.projectValidation)
    );
  }, [projectData, mdCurrentUser]);

  const existingProjectNames = useMemo(() => mdProjects?.map((project) => project.name) || [], [mdProjects]);

  return (
    <Modal
      title={
        <StackPanel stretch>
          <Fmt id="MD.Projects.ProjectCardModal.title" />
        </StackPanel>
      }
      okButtonProps={HIDE_BUTTON_PROPS}
      onCancel={onClose}
      className={styles.formModal}
      {...modalProps}
    >
      <ContentGate loading={projectDataLoading} error={projectDataError}>
        <MDProjectCardToolbar
          mdProject={projectData}
          mdRoles={mdRoles}
          isDirty={isDirty}
          saving={isLoading}
          form={form}
          onCancelChanges={handleFormReset}
          onProjectUpdate={handleDataUpdate}
          extendedUserClaims={extendedUserClaims}
          isProposerRole={isProposerRole}
          isO910Role={isO910RoleInDivisionSetting}
          isProjectGuarantorRole={isProjectGuarantorRole}
        />
        <Form form={form} onFinish={handleFormSubmit} onFieldsChange={validateDirty}>
          <MDProjectCardHead
            mdProject={projectData}
            existingProjectNames={existingProjectNames}
            canEditAsGuarantor={isProjectGuarantorRole}
            canEditAsO910={isO910RoleInDivisionSetting}
            canEditAsProposer={isProposerRole}
            extendedUserClaims={extendedUserClaims}
            form={form}
          />
          <Collapse defaultActiveKey={['projectData']} ghost>
            <Collapse.Panel
              header={<Fmt id="MD.Projects.ProjectCardModal.section.projectData.title" />}
              key="projectData"
              className={styles.collapse}
            >
              <MDProjectCardProjectData
                mdProject={projectData}
                canEditAsProposer={isProposerRole}
                canEditAsGuarantor={isProjectGuarantorRole}
                canEditAsCKOrganizer={isCKOrganizerRole}
              />
            </Collapse.Panel>
            <Collapse.Panel
              header={<Fmt id="MD.Projects.ProjectCardModal.section.commission.title" />}
              key="commission"
              className={styles.collapse}
            >
              <MDProjectCardCommission
                mdProject={projectData}
                canEditAsProposer={isProposerRole}
                canEditAsGuarantor={isProjectGuarantorRole}
                canEditAsCKOrganizer={isCKOrganizerRole}
              />
            </Collapse.Panel>
          </Collapse>
        </Form>
      </ContentGate>
    </Modal>
  );
};

export default MDProjectCardModal;
