import { MaskItemType } from 'components/MaskInput/MaskInput.types';

// regex for characters with and without diacritics (utf-8)
const charsWithDiacritics = 'a-zA-Z\\u00C0-\\u017F';

export const convertMaskToRegExp = (mask: MaskItemType, folderProps: Record<string, string>) => {
  switch (mask.type) {
    case 'text':
      return new RegExp(`(${mask.value})`);
    case 'number':
      return new RegExp(
        mask.otherCount ? '(\\d{' + mask.count + ',' + mask.otherCount + '})' : '(\\d{' + mask.count + '})'
      );
    case 'metadata':
      const folderProp = folderProps[mask.propName];
      return new RegExp(`(${folderProp})`);
    case 'letters':
      const numbersMask = mask.allowNumbers ? '0-9' : '';
      // double backslash is needed, because it makes one backslash in the string than then will escape the hyphen for regular expression. So backlash itself is not allowed in the string.
      const specialCharsMask = mask.allowSpecialCharacters ? '@$#&%+\\-_!().,' : '';
      const spacesMask = mask.whitespace ? '\\s' : '';
      const counts = mask.otherCount ? `{${mask.count},${mask.otherCount}}` : '{' + mask.count + '}';
      const maskValue = `([${charsWithDiacritics}${numbersMask}${specialCharsMask}${spacesMask}]${counts})`;
      return new RegExp(maskValue, 'u');
  }
};
