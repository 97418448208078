import { ProjectOutlined } from '@ant-design/icons';
import { Button, Tag, Typography } from 'antd';
import { ProjectUserProfileListDto } from 'api/completeApiInterfaces';
import { AuditLogOpenButton } from 'components/AuditLogsComponents/AuditLogOpenButton';
import { UserAvatarSize, UserIcon } from 'components/avatars/UserIcon/UserIcon';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { DeleteButton } from 'components/DeleteButton/DeleteButton';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import { EditIcon } from 'components/Icons/HubActionsIcons';
import List from 'components/List';
import { ListEmpty } from 'components/ListEmpty/ListEmpty';
import { ReinviteButton } from 'components/ReinviteButton/ReinviteButton';
import StopPropagationWrapper from 'components/StopPropagationWrapper/StopPropagationWrapper';
import styles from 'components/UsersItem/UsersItem.module.less';
import UserStatus from 'components/UserStatus/UserStatus';
import { useCurrentAppUser, useIntl } from 'hooks';
import { Fmt } from 'locale';
import { ProjectUserProfileListExtended } from 'pages/OrganizationsSettingPage/OrganizationsDetailPanel';
import React, { FunctionComponent, ReactNode, useState } from 'react';
import { smartFilter } from 'utils';

type Props = {
  data: ProjectUserProfileListExtended[];
  search: string;
  selectedId?: Guid;
  showIsAdminTag?: boolean;
  onActivity?: (userId: ProjectUserProfileListDto) => void;
  onSelect?: (userId: Guid) => void;
  onClearSearch?: () => void;
  onRemove?: (appUserUserName: string) => void;
  onEdit?: (appUserUserName: string) => void;
  onReinvite?: (appUserUserId: Guid) => void;
  onChangeUserSettingOnProjects?: (appUserUserId: Guid) => void;
  additionalActions?: (user: ProjectUserProfileListExtended) => ReactNode;
};

const GeneralSettingsUsersList: FunctionComponent<Props> = ({
  data,
  search,
  selectedId,
  onActivity,
  onSelect,
  onClearSearch,
  children,
  onRemove,
  onEdit,
  onReinvite,
  onChangeUserSettingOnProjects,
  additionalActions,
  showIsAdminTag = true,
}) => {
  const currentUser = useCurrentAppUser();
  const intl = useIntl();

  const [reinvitedIds, setReinvitedIds] = useState<Guid[]>([]);

  const handleReinviteUser = (userId: Guid) => {
    onReinvite(userId);
    setReinvitedIds((prevState) => [...prevState, userId]);
  };

  return (
    <GeneralSettingsContainer>
      <List<ProjectUserProfileListExtended>
        data={data}
        search={search}
        filterItem={(item) =>
          (item && smartFilter(item?.username, search)) ||
          smartFilter(item?.firstname, search) ||
          smartFilter(item?.lastname, search)
        }
        renderItem={(item) => (
          <GeneralSettingsItem
            icon={<UserIcon size={UserAvatarSize.Large} user={item} />}
            key={item.id}
            selected={item.id === selectedId}
            selectable={!!onSelect}
            onClick={onSelect ? () => onSelect(item.id) : null}
            title={item.username}
            wrap
            description={
              (item.firstname || item.lastname) && (
                <>
                  {item.firstname && <Typography.Text>{`${item.firstname}`}</Typography.Text>}{' '}
                  {item.lastname && <Typography.Text>{`${item.lastname}`}</Typography.Text>}
                </>
              )
            }
            additionalActions={
              <>
                {additionalActions && additionalActions(item)}
                {item.status && <UserStatus status={item.status} />}
                {showIsAdminTag && item.isAdmin && (
                  <CommonHubTooltip title={<Fmt id="general.administrator" />}>
                    <Tag color="green">A</Tag>
                  </CommonHubTooltip>
                )}
                {onReinvite && !item.isConfirmed && (
                  <StopPropagationWrapper className={styles.activity}>
                    <ReinviteButton
                      disabled={
                        reinvitedIds.includes(item.id)
                          ? intl.formatMessage({ id: 'ReinviteButton.reinvited' })
                          : undefined
                      }
                      tooltip={intl.formatMessage({ id: 'ReinviteButton.reinvite.tooltip' })}
                      onReinvite={() => handleReinviteUser(item.appUserId)}
                      type="link"
                    />
                  </StopPropagationWrapper>
                )}
                {onRemove && (
                  <StopPropagationWrapper className={styles.activity}>
                    <DeleteButton
                      disabled={
                        currentUser.username.toLocaleLowerCase() === item.username.toLocaleLowerCase()
                          ? intl.formatMessage({ id: 'DeleteButton.cannotDeleteYourself' })
                          : undefined
                      }
                      onDelete={() => onRemove(item.id)}
                      type="link"
                    />
                  </StopPropagationWrapper>
                )}
                {onActivity && (
                  <StopPropagationWrapper className={styles.activity}>
                    <AuditLogOpenButton onClick={() => onActivity(item)} />
                  </StopPropagationWrapper>
                )}
                {onEdit && (
                  <StopPropagationWrapper className={styles.activity}>
                    <Button onClick={() => onEdit(item.username)} type="link" icon={<EditIcon />} />
                  </StopPropagationWrapper>
                )}
                {onChangeUserSettingOnProjects && (
                  <StopPropagationWrapper className={styles.activity}>
                    <Button
                      onClick={() => onChangeUserSettingOnProjects(item.id)}
                      type="link"
                      icon={<ProjectOutlined />}
                    />
                  </StopPropagationWrapper>
                )}
              </>
            }
          />
        )}
        renderEmpty={(total, filtered) => <ListEmpty filtered={filtered} total={total} onClearSearch={onClearSearch} />}
      />
      {children}
    </GeneralSettingsContainer>
  );
};

export default GeneralSettingsUsersList;
