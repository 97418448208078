import { MoreOutlined } from '@ant-design/icons';
import React, { FC } from 'react';
import { MoveMoveEvent, useMove } from 'react-aria';
import styles from './Resizer.module.less';

type Props = {
  onMove: React.Dispatch<React.SetStateAction<number>>;
  clamp: (width: number) => number;
};

export const Resizer: FC<Props> = ({ onMove, clamp }) => {
  const { moveProps } = useMove({
    onMove: (e: MoveMoveEvent) => {
      onMove((width) => clamp(width + e.deltaX));
    },
  });

  return (
    <div role="separator" className={styles.resizer} {...moveProps} tabIndex={0}>
      <MoreOutlined />
    </div>
  );
};
