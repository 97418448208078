import '@ant-design/compatible/assets/index.css';
import { Input, InputProps } from 'antd';

import React, { FunctionComponent, useCallback } from 'react';
import { JSONVariableTypeEnum, MDInput } from '../MDProjectVariableData.types';

type Props = {
  dataType: JSONVariableTypeEnum;
  value?: MDInput;
  onChange?: (value: MDInput) => void;
} & Omit<InputProps, 'value' | 'onChange'>;

const MDVariableInputFormItem: FunctionComponent<Props> = ({ dataType, value, onChange, ...restProps }) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange({ type: dataType, value: e.target.value });
    },
    [dataType, onChange]
  );
  return <Input onChange={handleChange} value={value?.value} {...restProps} />;
};

export default React.memo(MDVariableInputFormItem);
