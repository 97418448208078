import React, { FunctionComponent, useEffect, useRef } from 'react';

type Props = {
  url: string;
  token: string;
  frameKey: string;
};

const STYLE_DISPLAY = { display: 'none' };

const LoaderForm: FunctionComponent<Props> = ({ url = '', token = '', frameKey }) => {
  const formElem = useRef<HTMLFormElement>();

  useEffect(() => {
    formElem.current && token && formElem.current.submit();
  }, [token]);

  return (
    <div style={STYLE_DISPLAY}>
      <form
        ref={formElem}
        action={url}
        encType="multipart/form-data"
        method="post"
        target={`collabora-online-viewer-${frameKey}`}
        id="collabora-submit-form"
      >
        <input name="access_token" value={token} type="hidden" id="access-token" />
        <input type="submit" value="" />
      </form>
    </div>
  );
};

export default LoaderForm;
