import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { AccessLevelEnum, DirectoryListDto, DocumentsMultipleMoveStrategyEnum } from 'api/completeApiInterfaces';
import { DirectoriesTreeSelectFormItem } from 'components/DirectoriesTreeSelect/DirectoriesTreeSelect';
import DocumentMultipleActionError from 'components/DocumentMultipleActionError';
import PathDisplay from 'components/PathDisplay';
import { Fmt, InjectedIntlProps } from 'locale';
import React, { Component } from 'react';
import { requiredRule } from 'utils/formHelpersCompatibility';
import styles from './DocumentsMoveForm.module.less';

export type DocumentsMoveFormData = {
  destinationDirectory: Guid;
};

type Props = FormComponentProps<DocumentsMoveFormData> &
  InjectedIntlProps & {
    selectedDocuments: Guid[];
    selectedLinks: Guid[];
    selectedDirectory: DirectoryListDto;
    destinationDirectory: Guid;
    errors: JSX.Element[];
    linkErrors: JSX.Element[];
    strategy: DocumentsMultipleMoveStrategyEnum;
  };

class DocumentsMoveForm extends Component<Props> {
  render() {
    const {
      intl,
      form,
      selectedDirectory,
      destinationDirectory,
      selectedDocuments,
      selectedLinks,
      strategy,
      errors,
      linkErrors,
    } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Form layout="horizontal" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
        <div className={styles.movingDocumentsWrap}>
          <div className={styles.movingDocumentsTitle}>
            {<Fmt id="DocumentsMoveFormModal.moveDocuments" values={{ count: selectedDocuments.length }} />}
          </div>
          <div className={styles.movingDocumentsTitle}>
            {<Fmt id="DocumentsMoveFormModal.moveLinks" values={{ count: selectedLinks.length }} />}
          </div>
          <div className={styles.movingDocumentsTitle}>
            <Fmt id="DocumentsMoveFormModal.moveFromFolder" />
          </div>
          {<PathDisplay path={selectedDirectory.path} className={styles.movingDocumentsPath} />}
        </div>
        <Form.Item
          className={styles.item}
          label={intl.formatMessage({ id: 'DocumentsMoveFormModal.destinationDirectoryId.label' })}
        >
          {getFieldDecorator<DocumentsMoveFormData>('destinationDirectory', {
            initialValue: destinationDirectory,
            rules: [requiredRule('DirectoryCreateForm.form.items.name.rules.required')],
          })(
            <DirectoriesTreeSelectFormItem
              isItemDisabled={(item) =>
                (false &&
                  item.currentAccessLevel !== AccessLevelEnum.admin &&
                  item.currentAccessLevel !== AccessLevelEnum.write) ||
                this.props.selectedDirectory.id === item.id
              }
              autoFocus
              dropdownStyle={{ maxHeight: 400, overflow: 'auto', maxWidth: 300 }}
              placeholder={intl.formatMessage({ id: 'DirectoryMoveFormModal.destinationDirectoryId.placeholder' })}
            />
          )}
        </Form.Item>
        <DocumentMultipleActionError
          errors={errors}
          titleId={
            !!strategy && strategy === DocumentsMultipleMoveStrategyEnum.rename
              ? 'DocumentsMoveFormModal.errorsTitle.duplicate'
              : 'DocumentsMoveFormModal.errorsTitle.missed'
          }
        />
        {!!linkErrors.length && (
          <DocumentMultipleActionError errors={errors} titleId={'DocumentsMoveFormModal.errorsTitle.duplicateLinks'} />
        )}
      </Form>
    );
  }
}

export default Form.create<Props>()(DocumentsMoveForm);
