import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import { masterApi } from 'api/completeApi';
import SideMenuActivator, { SideMenuKey } from 'components/SideMenuActivator';
import { useApiData, useCurrentAppUser } from 'hooks';
import { Fmt } from 'locale';
import PageContent from 'pages/ProjectSettingsPage/PageContent';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import MDApprovalProjectsReport from './MDApprovalProjectsReport';
import styles from './MDApprovedProjectsReportPage.module.less';

type Props = {};

export type ApprovalProjectsParams = {
  organizationId: Guid;
  mdProjectId: Guid;
};

const MDApprovalProjectsReportPageComponent: FunctionComponent<Props> = () => {
  const currentAppUser = useCurrentAppUser();
  const { organizationId, mdProjectId } = useParams<ApprovalProjectsParams>() as ApprovalProjectsParams;
  const [initialProjectId, setInitialProjectId] = React.useState<Guid>(mdProjectId);

  useEffect(() => {
    if (mdProjectId) {
      setInitialProjectId(mdProjectId);
    }
  }, [mdProjectId]);

  const userOrgId = useMemo(
    () =>
      organizationId ||
      currentAppUser.organizationUsers?.find((orgUser) => orgUser.organization.hasMdModule)?.organization.id,
    [organizationId, currentAppUser.organizationUsers]
  );

  const [mdCurrentUser, mdCurrentUserError, mdCurrentUserLoading, loadMdCurrentUser] = useApiData(
    (ct) => masterApi.projects.md.user.id.me.get(userOrgId, ct),
    { autoload: true }
  );

  useEffect(() => {
    if (!userOrgId) return;
    loadMdCurrentUser();
  }, [userOrgId]);

  return (
    <ErrorBoundary>
      <SideMenuActivator menuItemKey={SideMenuKey.MDPROJECTS}>
        <div className={styles.pageContent}>
          <PageContent title={<Fmt id="MD.Projects.ProjectReportPage.title" />}>
            <MDApprovalProjectsReport
              organizationId={userOrgId}
              mdCurrentUser={mdCurrentUser}
              initialProjectId={initialProjectId}
            />
          </PageContent>
        </div>
      </SideMenuActivator>
    </ErrorBoundary>
  );
};

export default React.memo(MDApprovalProjectsReportPageComponent);
