import { EyeOutlined } from '@ant-design/icons';
import { Button, Dropdown, Tag, Tooltip, Typography } from 'antd';
import {
  SecondaryBlobTypeEnum,
  WorkflowApprovalDocumentDto,
  WorkflowNodePermittedActionsEnum,
} from 'api/completeApiInterfaces';
import { TOOLTIP_DELAY } from 'components/CommonHubTooltip/CommonHubTooltip';
import DisplayName from 'components/DisplayName';
import {
  AnnotateIcon,
  DeleteIcon,
  DownloadIcon,
  EditIcon,
  SignDocumentIcon,
  UploadIcon,
} from 'components/Icons/HubActionsIcons';
import { LabelLinkType } from 'components/LabelDisplay/LabelDisplay';
import LabelsList from 'components/LabelsList';
import { Margin } from 'components/Margin/Margin';
import PathDisplayMenu from 'components/PathDisplayMenu';
import StackPanel from 'components/StackPanel';
import { WorkflowApprovalDocumentActivityHistoryButton } from 'components/WorkflowApprovalDocumentActivity/WorkflowApprovalDocumentActivityHistoryButton';
import { mapApprovalDocumentActivitySorted } from 'components/WorkflowApprovalDocumentActivity/WorkflowApprovalDocumentActivityList';
import { WorkflowApprovalDocumentActivityRow } from 'components/WorkflowApprovalDocumentActivity/WorkflowApprovalDocumentActivityRow';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { DROPDOWN_TRIGGER_CLICK } from 'config/constants';
import { useActiveProject, useIntl, useSelectorDispatch } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt } from 'locale';
import moment from 'moment';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { projectUsersMapSelector } from 'store/selectors/projectUsersSelectors';
import { numberComparer } from 'utils/comparators';
import { selectMaxValue } from 'utils/selectMaxValue';
import styles from './ApprovalDocumentRevisionInfo.module.less';

type Props = {
  approvalDocument: WorkflowApprovalDocumentDto;
  selectedNodeId: Guid;
  canPerformActions: boolean;
  permittedActions?: WorkflowNodePermittedActionsEnum[];
  onUpload: (document: WorkflowApprovalDocumentDto) => void;
  onRemove: (document: WorkflowApprovalDocumentDto) => void;
  onDownload: (document: WorkflowApprovalDocumentDto) => void;
  onSignOnline: (document: WorkflowApprovalDocumentDto) => void;
  onSignUpload: (document: WorkflowApprovalDocumentDto) => void;
  setPreviewId: (documentId: Guid) => void;
  onEditOnline: (document: WorkflowApprovalDocumentDto) => void;
  onAnnotate: (document: WorkflowApprovalDocumentDto) => void;
  style?: React.CSSProperties;
};

export const ApprovalDocumentRevisionInfo: FunctionComponent<Props> = ({
  approvalDocument,
  selectedNodeId,
  canPerformActions,
  permittedActions,
  onUpload,
  onRemove,
  onDownload,
  onSignOnline,
  onSignUpload,
  setPreviewId,
  onEditOnline,
  onAnnotate,
  style,
}) => {
  const permittedActionsMap = useMemo(() => new Set<WorkflowNodePermittedActionsEnum>(permittedActions), [
    permittedActions,
  ]);

  const activeProject = useActiveProject();
  const intl = useIntl();

  const users = useSelectorDispatch(projectUsersMapSelector, (dispatch) =>
    dispatch.projectUsers.loadData({ reload: false })
  );

  useDirtyStoreReload(
    (store) => store.projectUsers,
    (dispatch) => dispatch.projectUsers
  );

  const lastRevision = useMemo(
    () =>
      selectMaxValue(
        approvalDocument.addedRevisions?.filter((addedRevision) => addedRevision.workflowNodeId === selectedNodeId),
        numberComparer.map((addedRevision) => addedRevision.revision.number)
      )?.revision || approvalDocument.revision,
    [approvalDocument, selectedNodeId]
  );

  const isEsticonDocument = !!approvalDocument.document.esticonObjectLink;

  const onPreview = useCallback(() => {
    setPreviewId(approvalDocument.id);
  }, [setPreviewId, approvalDocument]);

  const onUploadHandler = useCallback(() => {
    onUpload(approvalDocument);
  }, [onUpload, approvalDocument]);

  const onRemoveHandler = useCallback(() => {
    onRemove(approvalDocument);
  }, [onRemove, approvalDocument]);

  const onDownloadHandler = useCallback(() => {
    onDownload(approvalDocument);
  }, [onDownload, approvalDocument]);

  const onSignOnlineHandler = useCallback(() => {
    onSignOnline(approvalDocument);
  }, [onSignOnline, approvalDocument]);

  const onSignUploadHandler = useCallback(() => {
    onSignUpload(approvalDocument);
  }, [onSignUpload, approvalDocument]);

  const onEditOnlineHandler = useCallback(() => {
    onEditOnline(approvalDocument);
  }, [onEditOnline, approvalDocument]);

  const onAnnotateHandler = useCallback(() => {
    onAnnotate(approvalDocument);
  }, [onAnnotate, approvalDocument]);

  const hasPdfDerivate = useMemo(() => {
    return lastRevision.primaryFile.hasPdfDerivate;
  }, [lastRevision]);

  const doc = approvalDocument.document;

  const lastDocumentActivity = useMemo(
    () => mapApprovalDocumentActivitySorted(approvalDocument, users, selectedNodeId)[0],
    [approvalDocument, users, selectedNodeId]
  );

  const isSignable = useMemo(
    () =>
      lastRevision?.secondaryFiles.some(
        (file) => file.blobType === SecondaryBlobTypeEnum.signed && file.contentType === 'application/pdf'
      ) ||
      (lastRevision?.primaryFile.hasPdfDerivate &&
        !lastRevision?.secondaryFiles.some((file) => file.blobType === SecondaryBlobTypeEnum.signed)),
    [lastRevision]
  );

  const activityAdditionaData = useCallback(
    (activity) =>
      moment(activity.actionDate)
        .locale(intl.locale)
        .format('LLL'),
    [intl]
  );

  const signingMenu = {
    items: [
      isSignable && {
        key: 'signOnline',
        label: <Fmt id="general.docMenu.signOnline" />,
        icon: <SignDocumentIcon />,
        onClick: onSignOnlineHandler,
      },
      {
        key: 'signLocally',
        label: <Fmt id="general.docMenu.signLocally" />,
        icon: <UploadIcon />,
        onClick: onSignUploadHandler,
      },
    ].filter(Boolean),
  };

  return (
    <StackPanel vertical className={styles.documentRow} style={style}>
      <FlowLayout>
        <Typography.Text strong ellipsis>
          <PathDisplayMenu path={doc.directoryPath} directoryId={doc.directoryId} />
          <Link to={`/projects/${activeProject.id}/documents/${doc.id}`}>
            <DisplayName>{doc.name}</DisplayName>
          </Link>
        </Typography.Text>
        <div>{doc.labels?.length > 0 && <LabelsList labels={doc.labels} linkType={LabelLinkType.Document} />}</div>
      </FlowLayout>
      <FlowLayout growFirst>
        <div>
          {!!lastDocumentActivity ? (
            <StackPanel autoWidth className={styles.wrap}>
              <Margin left>
                <WorkflowApprovalDocumentActivityRow documentActivity={lastDocumentActivity} />
              </Margin>
              <WorkflowApprovalDocumentActivityHistoryButton
                workflowApprovalDocument={approvalDocument}
                type="link"
                size="small"
                additionalData={activityAdditionaData}
                workflowNodeId={selectedNodeId}
              />
            </StackPanel>
          ) : (
            isEsticonDocument && (
              <div>
                <Tag>
                  <Fmt id="ApprovalDocumentRevisionInfo.cannotChangeEsticonDocument" />
                </Tag>
              </div>
            )
          )}
        </div>
        <FlowLayout wrap>
          {canPerformActions && permittedActionsMap.has(WorkflowNodePermittedActionsEnum.addRevision) && (
            <Tooltip title={<Fmt id="ApprovalDocumentRevisionInfo.edit" />} mouseEnterDelay={TOOLTIP_DELAY}>
              <Button
                type="link"
                icon={<EditIcon />}
                disabled={approvalDocument.document.isModel}
                size="small"
                onClick={onEditOnlineHandler}
              />
            </Tooltip>
          )}
          {canPerformActions &&
            permittedActionsMap.has(WorkflowNodePermittedActionsEnum.addRevision) &&
            !isEsticonDocument && (
              <Tooltip
                title={
                  <Fmt
                    id={
                      hasPdfDerivate || approvalDocument.document.isModel
                        ? 'ApprovalDocumentRevisionInfo.annotate'
                        : 'AddedRevisions.message.noDerivateData'
                    }
                  />
                }
                mouseEnterDelay={TOOLTIP_DELAY}
              >
                <Button
                  type="link"
                  icon={<AnnotateIcon />}
                  size="small"
                  onClick={onAnnotateHandler}
                  disabled={!hasPdfDerivate && !approvalDocument.document.isModel}
                ></Button>
              </Tooltip>
            )}
          <Tooltip title={<Fmt id="ApprovalDocumentRevisionInfo.documentPreview" />} mouseEnterDelay={TOOLTIP_DELAY}>
            <Button type="link" icon={<EyeOutlined />} size="small" onClick={onPreview}></Button>
          </Tooltip>
          {canPerformActions &&
            permittedActionsMap.has(WorkflowNodePermittedActionsEnum.addRevision) &&
            !isEsticonDocument && (
              <Tooltip title={<Fmt id="ApprovalDocumentRevisionInfo.addRevision" />} mouseEnterDelay={TOOLTIP_DELAY}>
                <Button type="link" ghost icon={<UploadIcon />} size="small" onClick={onUploadHandler} />
              </Tooltip>
            )}
          {canPerformActions && permittedActionsMap.has(WorkflowNodePermittedActionsEnum.signDocument) && (
            <Dropdown menu={signingMenu} trigger={DROPDOWN_TRIGGER_CLICK} placement={'bottomRight'}>
              <Tooltip title={<Fmt id="general.docMenu.sign" />} mouseEnterDelay={TOOLTIP_DELAY}>
                <Button type="link" ghost icon={<SignDocumentIcon />} size="small" />
              </Tooltip>
            </Dropdown>
          )}
          {canPerformActions && (
            <Tooltip title={<Fmt id="general.download" />} mouseEnterDelay={TOOLTIP_DELAY}>
              <Button type="link" ghost icon={<DownloadIcon />} size="small" onClick={onDownloadHandler} />
            </Tooltip>
          )}
          {canPerformActions &&
            permittedActionsMap.has(WorkflowNodePermittedActionsEnum.removeDocument) &&
            !isEsticonDocument && (
              <Tooltip
                title={<Fmt id="ApprovalDocumentRevisionInfo.removeDocumentFromWf" />}
                mouseEnterDelay={TOOLTIP_DELAY}
              >
                <Button type="link" danger ghost icon={<DeleteIcon />} size="small" onClick={onRemoveHandler} />
              </Tooltip>
            )}
        </FlowLayout>
      </FlowLayout>
    </StackPanel>
  );
};
