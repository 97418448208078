import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { InboxOutlined } from '@ant-design/icons';
import { Collapse, Input, InputRef, Select, Upload } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { DocumentAnnotationSaveDto, WorkflowStateEnum } from 'api/completeApiInterfaces';
import { FileAddIcon } from 'components/Icons/HubActionsIcons';
import PrimaryFileInput from 'components/PrimaryFileInput';
import SingleFileInput from 'components/PrimaryFileInput/SingleFileInput';
import RevisionNumberTag from 'components/RevisionNumberTag/RevisionNumberTag';
import { MAX_ITEM_DESCRIPTION_LENGTH, SIGNED_DOCUMENT_ACCEPTED_CONTENT_TYPES } from 'config/constants';
import { InjectedIntlProps } from 'locale';
import React, { Component, Fragment } from 'react';
import { maxLengthRule, requiredRule } from 'utils/formHelpersCompatibility';
import { DocumentCreateFormData } from '../DocumentCreateForm';
import styles from './RevisionCreateForm.module.less';

export type RevisionCreateFormData = {
  state: WorkflowStateEnum;
  annotations?: DocumentAnnotationSaveDto[];
  description: string;
  primaryFile: File;
  signedDocumentFile?: File;
  secondaryFiles?: UploadChangeParam;
};

type Props = FormComponentProps<RevisionCreateFormData> &
  InjectedIntlProps & {
    initialPrimaryFile?: File;
    availableStates?: WorkflowStateEnum[];
    setRef: (ref: InputRef) => void;
    usefulNewDocumentStatuses: WorkflowStateEnum[];
    allowSignedDocumentAttachment: boolean;
  };

class RevisionCreateForm extends Component<Props> {
  render() {
    const {
      intl,
      form,
      initialPrimaryFile,
      availableStates,
      setRef,
      usefulNewDocumentStatuses,
      allowSignedDocumentAttachment,
    } = this.props;
    const { getFieldDecorator } = form;

    const primaryFileItem = getFieldDecorator('primaryFile', {
      initialValue: initialPrimaryFile,
      rules: [requiredRule('forms.items.primaryFile.rules.required')],
    })(<PrimaryFileInput />);

    const values = form.getFieldsValue() as RevisionCreateFormData;

    // render primary file input to init form steps
    if (!values.primaryFile) {
      const err = form.getFieldError('primaryFile');
      return (
        <>
          <div>{primaryFileItem}</div>
          {err && <div className={styles.primaryFileError}>{err}</div>}
        </>
      );
    }

    return (
      <Form layout="vertical">
        <Form.Item>{primaryFileItem}</Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'DocumentCreateForm.form.items.state.label' })}>
          {getFieldDecorator<DocumentCreateFormData>('state', {
            initialValue:
              availableStates?.length === 1
                ? availableStates[0]
                : usefulNewDocumentStatuses?.length === 1
                ? usefulNewDocumentStatuses[0]
                : null,
            rules: [requiredRule('DocumentCreateForm.form.items.state.rules.required')],
          })(
            <Select>
              {(availableStates || usefulNewDocumentStatuses).map((state) => (
                <Select.Option key={state}>
                  <div className={styles.stateOption}>
                    <RevisionNumberTag state={state} showTitle />
                  </div>
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'RevisionCreateForm.form.items.description.label' })}>
          {getFieldDecorator<RevisionCreateFormData>('description', {
            initialValue: '',
            preserve: true,
            rules: [
              requiredRule('RevisionCreateForm.form.items.description.rules.required', true),
              maxLengthRule('general.maxDescriptionLength', MAX_ITEM_DESCRIPTION_LENGTH),
            ],
          })(
            <Input.TextArea
              autoFocus
              ref={setRef}
              rows={3}
              autoSize={{ minRows: 3 }}
              placeholder={intl.formatMessage({ id: 'RevisionCreateForm.form.items.description.placeholder' })}
              className={'scrollbar'}
            />
          )}
        </Form.Item>
        {allowSignedDocumentAttachment && (
          <Collapse>
            <Collapse.Panel
              header={
                <Fragment>
                  <FileAddIcon className={styles.secondaryPanelIcon} />
                  <span>{intl.formatMessage({ id: 'SignedDocumentInput.collapse.header' })}</span>
                </Fragment>
              }
              key="signedDocument"
            >
              {getFieldDecorator('signedDocumentFile')(
                <SingleFileInput
                  inputText="SignedDocumentInput.collapse.text"
                  inputHint="SignedDocumentInput.collapse.hint"
                  acceptedContentType={SIGNED_DOCUMENT_ACCEPTED_CONTENT_TYPES}
                />
              )}
            </Collapse.Panel>
          </Collapse>
        )}
        <Collapse>
          <Collapse.Panel
            header={
              <Fragment>
                <FileAddIcon className={styles.secondaryPanelIcon} />
                <span>{intl.formatMessage({ id: 'SecondaryFilesInput.collapse.header' })}</span>
              </Fragment>
            }
            key="secondaryFiles"
          >
            {getFieldDecorator('secondaryFiles')(
              <Upload.Dragger multiple={true} beforeUpload={() => false} customRequest={() => {}}>
                <p className="ant-upload-text">{intl.formatMessage({ id: 'SecondaryFilesInput.collapse.text' })}</p>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
              </Upload.Dragger>
            )}
          </Collapse.Panel>
        </Collapse>
      </Form>
    );
  }
}

export default Form.create<Props>()(RevisionCreateForm);
