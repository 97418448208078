import { OrgUserDto } from 'api/completeApiInterfaces';
import JobPositionsListPanel from 'pages/OrganizationsSettingPage/JobPositions/JobPositionsListPanel';
import React, { FC } from 'react';

type Props = {
  selectedOrganizationId: string;
  organizationUsers: OrgUserDto[];
  reloadOrganizationUsers: () => void;
};

export const JobPanel: FC<Props> = ({ selectedOrganizationId, organizationUsers, reloadOrganizationUsers }) => {
  return (
    <JobPositionsListPanel
      loading={false}
      error={null}
      selectedOrganizationId={selectedOrganizationId}
      organizationUsers={organizationUsers}
      reloadOrganizationUsers={reloadOrganizationUsers}
    />
  );
};
