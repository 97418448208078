import { TextProps } from 'antd/es/typography/Text';
import { MaskItemType } from 'components/MaskInput/MaskInput.types';
import { Fmt } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React, { FC } from 'react';

type Props = TextProps & {
  item: Pick<MaskItemType, 'type' | 'id'>;
  children?: React.ReactNode;
};

const TYPE_TRANSLATION_MAP: Record<MaskItemType['type'], IntlMessageId> = {
  letters: 'maskInput.letters',
  metadata: 'maskInput.metadata',
  text: 'maskInput.text',
  number: 'maskInput.number',
};

export const MaskItemLabel: FC<Props> = ({ item, children, ...typographyProps }) => {
  return (
    <strong {...typographyProps}>
      <Fmt id={TYPE_TRANSLATION_MAP[item.type]} />
      {children}
    </strong>
  );
};
