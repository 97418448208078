import { DirectoryDetailDto } from 'api/completeApiInterfaces';
import { MetadataEditList } from 'components/filters/components/MetadataList/MetadataEditList';
import { FiltersPersistentKey } from 'components/filters/filterTypes';
import { useDirectoryMetadataList } from 'hooks/useDirectoryMetadataList';
import React, { FunctionComponent } from 'react';

type Props = { currentDirectory: DirectoryDetailDto; canEdit: boolean };

export const DirectorySettingsMetadata: FunctionComponent<Props> = React.memo(({ currentDirectory, canEdit }) => {
  const { metadataItems, metadataLoading, metadataError, handleSave, loadDirectoryMetadata } = useDirectoryMetadataList(
    currentDirectory.id
  );

  return (
    <MetadataEditList
      metadataItems={metadataItems}
      metadataLoading={metadataLoading}
      metadataError={metadataError}
      handleSave={handleSave}
      persistentKey={FiltersPersistentKey.DirectoryMetadata}
      canEdit={canEdit}
      currentDirectory={currentDirectory}
      loadDirectoryMetadata={loadDirectoryMetadata}
    />
  );
});
