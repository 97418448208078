import { Alert } from 'antd';
import { ExtendedPermissionEnum, GroupDto } from 'api/completeApiInterfaces';
import { Margin } from 'components/Margin/Margin';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import StackPanel from 'components/StackPanel';
import { useCurrentProjectUser, useIntl, useStoreSelector } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt } from 'locale';
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { Dispatch } from 'store';
import { groupMapSelector, groupOrderedListSelector } from 'store/selectors/groupSelectors';
import { userHasPermission } from 'utils/userHasPermission';
import GroupDetailPanel from './GroupDetailPanel';
import GroupsListPanel from './GroupsListPanel/GroupsListPanel';

const Groups: FunctionComponent = () => {
  const { url } = useRouteMatch();
  const intl = useIntl();
  const currentUser = useCurrentProjectUser();
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    dispatch.groups.loadData({ reload: false });
  }, []);

  useDirtyStoreReload(
    (store) => store.groups,
    (dispatch) => dispatch.groups
  );

  const reloadGroups = useCallback(() => {
    dispatch.groups.loadData({ reload: true });
    dispatch.currentProjectUser.loadData({ reload: true });
  }, []);

  const groupsMap = useStoreSelector(groupMapSelector);
  const groupsList = useStoreSelector(groupOrderedListSelector);
  const groupsState = useStoreSelector((state) => state.groups);

  const handleGroupAdd = (group: GroupDto) => {
    reloadGroups();
  };

  const handleGroupDelete = useCallback((groupId: Guid) => {
    history.replace(`${url}`);
    reloadGroups();
  }, []);

  const noPermission =
    currentUser && !currentUser.isAdmin && !userHasPermission(currentUser, ExtendedPermissionEnum.userEditing);

  return (
    <>
      <MasterComponent
        url={url}
        maxWidth={800}
        title={intl.formatMessage({ id: 'general.groups' })}
        children={(onClick, selectedItemId) =>
          noPermission ? (
            <StackPanel vertical scrollable>
              <Margin top left right>
                <Alert type="error" message={<Fmt id="general.insufficientPermission" />} />
              </Margin>
            </StackPanel>
          ) : (
            <GroupsListPanel
              groupsMap={groupsMap}
              groupsList={groupsList}
              groupsListLoading={groupsState.loading}
              groupsListError={groupsState.error}
              selectedId={selectedItemId}
              onAdd={handleGroupAdd}
              onDelete={handleGroupDelete}
              onSelect={onClick}
            />
          )
        }
      />
      <Switch>
        <Route
          path={`${url}/:groupId`}
          exact
          render={(params) => (
            <GroupDetailPanel groupsList={groupsList} loadGroups={reloadGroups} backUrl={url} {...params} />
          )}
        />
      </Switch>
    </>
  );
};

export default Groups;
