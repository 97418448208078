import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Input, InputRef } from 'antd';
import FilenameErrorMessage from 'components/FilenameErrorMessage';
import { FILE_AND_FOLDER_NAME_REGEX, MAX_ITEM_DESCRIPTION_LENGTH } from 'config/constants';
import { InjectedIntlProps } from 'locale';
import React, { Component } from 'react';
import { duplicateNameRuleCallback, maxLengthRule, requiredRule } from 'utils/formHelpersCompatibility';

export type DirectoryCreateFormData = {
  name: string;
  description: string;
};

type Props = FormComponentProps<DirectoryCreateFormData> &
  InjectedIntlProps & {
    validateUniqueName: (name: string) => boolean;
    setRef?: (ref: InputRef) => void;
  };

class DirectoryCreateForm extends Component<Props> {
  render() {
    const { intl, form, validateUniqueName, setRef } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Form layout="vertical">
        <Form.Item label={intl.formatMessage({ id: 'forms.items.name.label' })}>
          {getFieldDecorator<DirectoryCreateFormData>('name', {
            rules: [
              requiredRule('DirectoryCreateForm.form.items.name.rules.required', true),
              maxLengthRule('general.maxNameLength'),
              {
                pattern: FILE_AND_FOLDER_NAME_REGEX,
                message: <FilenameErrorMessage />,
              },
              duplicateNameRuleCallback(validateUniqueName, 'DirectoryCreateForm.form.items.name.rules.nameExists'),
            ],
          })(
            <Input
              placeholder={intl.formatMessage({ id: 'DirectoryCreateForm.form.items.name.placeholder' })}
              autoFocus
              ref={setRef}
            />
          )}
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'forms.items.description.label' })}>
          {getFieldDecorator<DirectoryCreateFormData>('description', {
            rules: [
              {
                whitespace: true,
                message: intl.formatMessage({ id: 'forms.items.name.rules.empty' }),
              },
              {
                max: MAX_ITEM_DESCRIPTION_LENGTH,
                message: intl.formatMessage(
                  { id: 'general.maxDescriptionLength' },
                  { max: MAX_ITEM_DESCRIPTION_LENGTH }
                ),
              },
            ],
          })(
            <Input.TextArea
              rows={2}
              autoSize={{ minRows: 2 }}
              placeholder={intl.formatMessage({ id: 'DirectoryForm.form.items.description.placeholder' })}
            />
          )}
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create<Props>()(DirectoryCreateForm);
