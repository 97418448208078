import { EditOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Select } from 'antd';
import { MASK_METADATA, MaskItemMetadataType, MaskMetadata } from 'components/MaskInput/MaskInput.types';
import { useBoolean } from 'hooks';
import { Fmt } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import React, { FC } from 'react';

type ItemProps = MaskItemMetadataType & {
  id: string;
};

type Props = {
  item: ItemProps;
  onChange?: (item: ItemProps) => void;
};

export const METADATA_TRANSLATIONS_MAP: Record<MaskMetadata, IntlMessageId> = {
  [MASK_METADATA.FOLDER_NAME]: 'mask.metadata.folderName',
};

export const MaskItemMetadataInput: FC<Props> = ({ item, onChange }: Props) => {
  const [form] = Form.useForm<MaskItemMetadataType>();

  const [open, show, hide] = useBoolean(false);

  return (
    <>
      <Button size="small" onClick={show} icon={<EditOutlined />}>
        <Fmt id={METADATA_TRANSLATIONS_MAP[item.propName]} />
      </Button>
      <Modal
        open={open}
        onCancel={hide}
        onOk={() => {
          form.validateFields().then((values) => {
            onChange?.({
              id: item.id,
              propName: item.propName,
            });
            hide();
          });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          <Form.Item
            name="propName"
            label={'Vybraná vlastnost'}
            initialValue={item.propName}
            rules={[
              {
                required: true,
                message: 'Vyberte vlastnost',
              },
            ]}
          >
            <Select
              options={Object.values(MASK_METADATA).map((metadata) => ({
                label: <Fmt id={METADATA_TRANSLATIONS_MAP[metadata]} />,
                value: metadata,
                key: metadata,
              }))}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
