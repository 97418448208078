import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Checkbox, Input, InputRef, Select } from 'antd';
import { ProjectUserProfileStatusEnum, RoleDto } from 'api/completeApiInterfaces';
import { MAX_ITEM_DESCRIPTION_LENGTH } from 'config/constants';
import { Fmt, InjectedIntlProps } from 'locale';
import { Dictionary } from 'lodash';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Dispatch, RootState } from 'store';
import { legacyMapDispatchToProps } from 'store/models/storeModelinterfaces';
import { projectUsersListSelector } from 'store/selectors/projectUsersSelectors';
import {
  duplicateNameRuleCallback,
  maxLengthRule,
  requiredRule,
  simpleSelectFilter,
  whitespaceRule,
} from 'utils/formHelpersCompatibility';

export type RoleFormData = {
  name: string;
  description: string;
  userId: string;
  forceRemove: boolean;
};

const mapStateToProps = (state: RootState) => ({
  usersList: projectUsersListSelector(state),
  usersMapError: state.projectUsers.error,
  usersLoading: state.projectUsers.loading,
  hasDirtyUsers: state.projectUsers.dirty,
});

type PropsFromState = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadUsers: dispatch.projectUsers.loadData,
});

type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>;

type Props = FormComponentProps<RoleFormData> &
  InjectedIntlProps &
  PropsFromState &
  PropsFromDispatch & {
    validateUniqueName: (name: string) => boolean;
    defaults?: RoleDto;
    error: ReactNode;
    setRef: (ref: InputRef) => void;
  };

type State = {
  initialized: boolean;
  selectPlaceholder: string;
};

class RoleForm extends Component<Props, State> {
  users: Dictionary<Guid> = null;

  constructor(props: Props) {
    super(props);
    this.state = { initialized: false, selectPlaceholder: undefined };
  }

  async componentDidMount() {
    await this.props.loadUsers({ reload: false });
    this.users = {};
    this.props.usersList
      .filter((user) => user.status !== ProjectUserProfileStatusEnum.suspended)
      .forEach((user) => {
        this.users[user.id] = user.username;
      });
    if (Object.entries(this.users).length > 0) this.setState({ initialized: true });

    const defaults = this.props.defaults;
    const { intl } = this.props;
    if (defaults?.user?.status === ProjectUserProfileStatusEnum.suspended) {
      this.setState({ selectPlaceholder: intl.formatMessage({ id: 'RoleForm.items.userId.suspended.placeholder' }) });
    }
    if (defaults) {
      this.props.form.setFieldsValue({
        name: defaults.name,
        description: defaults.description,
        userId:
          defaults.user && defaults.user.status !== ProjectUserProfileStatusEnum.suspended
            ? defaults.user.id
            : undefined,
      });
    }
  }

  async componentDidUpdate() {
    if (this.props.hasDirtyUsers) {
      await this.props.loadUsers({ reload: false, silent: true });
    }
  }

  getUsers = (): ReactNode[] => {
    if (!this.state.initialized) return null;
    return Object.entries(this.users).map(([key, user]) => <Select.Option key={key}>{user}</Select.Option>);
  };

  render() {
    const { intl, form, validateUniqueName } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Form layout="vertical">
        <Form.Item label={intl.formatMessage({ id: 'forms.items.name.label' })}>
          {getFieldDecorator<RoleFormData>('name', {
            rules: [
              requiredRule('forms.items.name.rules.required', true),
              maxLengthRule('general.maxNameLength'),
              duplicateNameRuleCallback(validateUniqueName),
            ],
          })(
            <Input
              placeholder={intl.formatMessage({ id: 'RoleForm.items.name.placeholder' })}
              autoFocus
              ref={this.props.setRef}
            />
          )}
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'forms.items.description.label' })}>
          {getFieldDecorator<RoleFormData>('description', {
            rules: [
              whitespaceRule('forms.items.name.rules.empty'),
              {
                max: MAX_ITEM_DESCRIPTION_LENGTH,
                message: intl.formatMessage(
                  { id: 'general.maxDescriptionLength' },
                  { max: MAX_ITEM_DESCRIPTION_LENGTH }
                ),
              },
            ],
          })(<Input.TextArea rows={2} autoSize={{ minRows: 2 }} />)}
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'forms.items.userId.label' })}>
          {getFieldDecorator<RoleFormData>('userId')(
            <Select
              allowClear
              showSearch
              filterOption={simpleSelectFilter}
              placeholder={this.state.selectPlaceholder ? this.state.selectPlaceholder : ''}
            >
              {this.getUsers()}
            </Select>
          )}
        </Form.Item>
        {this.props.error && (
          <div className="ant-typography ant-typography-danger">
            <strong>{this.props.error}</strong>
          </div>
        )}
        {this.props.error && (
          <Form.Item label={intl.formatMessage({ id: 'forms.items.forceRemove.label' })}>
            {getFieldDecorator<RoleFormData>('forceRemove')(
              <Checkbox>
                <Fmt id="forms.items.forceRemove.description" />
              </Checkbox>
            )}
          </Form.Item>
        )}
      </Form>
    );
  }
}

export default connect(mapStateToProps, legacyMapDispatchToProps(mapDispatchToProps))(Form.create<Props>()(RoleForm));
