import { OrgExtendedPermissionEnum } from 'api/completeApiInterfaces';
import DashboardReportsContextProvider from 'components/Reports/contexts/ReportWidgetsContextProvider';
import { useCurrentAppUser } from 'hooks';
import React, { FunctionComponent, useMemo } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { ReportDetailPanelParams, ReportTitlePart } from './ReportDetailPage';
import styles from './ReportDetailPage.module.less';
import FinancialOverviewByUnitReportPage from './ReportDetails/FinancialOverviewByUnitReport/FinancialOverviewByUnitReportPage';
import ProjectsInRealisationOverviewReportRouter from './ReportDetails/ProjectsInRealisationOverviewReport/ProjectsInRealizationOverviewReportRouter';

type Props = {
  addBreadcrumb: (data: ReportTitlePart) => void;
  removeBreadcrumb: (key: string) => void;
};

export type ReportDetailProps = Props & {
  organizationId: Guid;
  basePath: string;
};

export type ReportDetailComponent = FunctionComponent<ReportDetailProps>;

const ReportDetailComponents: Partial<Record<OrgExtendedPermissionEnum, ReportDetailComponent>> = {
  financialOverviewByUnit: FinancialOverviewByUnitReportPage,
  projectsInRealisationOverview: ProjectsInRealisationOverviewReportRouter,
  projectsInRealisationOverviewAdmin: ProjectsInRealisationOverviewReportRouter,
  projectsInRealisationShareWidget: ProjectsInRealisationOverviewReportRouter,
};

const ReportDetail: FunctionComponent<Props> = ({ addBreadcrumb, removeBreadcrumb }) => {
  const { organizationId, report } = useParams<ReportDetailPanelParams>();
  const { url } = useRouteMatch();
  const currentAppUser = useCurrentAppUser();

  const ReportComponent = ReportDetailComponents[report as OrgExtendedPermissionEnum] || null;

  const userOrganizations = useMemo(
    () =>
      currentAppUser.organizationUsers
        ?.filter((userOrg) => userOrg.organization.id === organizationId)
        .map((userOrg) => userOrg.organization) || [],
    [currentAppUser, organizationId]
  );

  return (
    <div className={styles.content}>
      <DashboardReportsContextProvider organizations={userOrganizations}>
        {ReportComponent && (
          <ReportComponent
            organizationId={organizationId}
            addBreadcrumb={addBreadcrumb}
            removeBreadcrumb={removeBreadcrumb}
            basePath={url}
          />
        )}
      </DashboardReportsContextProvider>
    </div>
  );
};

export default React.memo(ReportDetail);
