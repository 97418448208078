import '@ant-design/compatible/assets/index.css';
import { DatePicker, DatePickerProps } from 'antd';

import moment, { Moment } from 'moment';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { JSONVariableTypeEnum, MDDate } from '../MDProjectVariableData.types';

type Props = {
  value?: MDDate;
  onChange?: (value: MDDate) => void;
} & Omit<DatePickerProps, 'value' | 'onChange'>;

const MDVariableDatePickerFormItem: FunctionComponent<Props> = ({ value, onChange, ...restProps }) => {
  const handleChange = useCallback(
    (value: Moment) => {
      onChange && onChange({ type: JSONVariableTypeEnum.date, value: value?.endOf('day').toISOString() });
    },
    [onChange]
  );
  const momentValue = useMemo(() => (value?.value ? moment(value.value) : undefined), [value]);
  return <DatePicker onChange={handleChange} value={momentValue} {...restProps} picker="date" />;
};

export default React.memo(MDVariableDatePickerFormItem);
