import { notification } from 'antd';
import { GroupListDto } from 'api/completeApiInterfaces';
import { useIntl, useSelectorDispatch } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import GroupDetailPanelComponent from 'pages/ProjectSettingsPage/Groups/GroupDetailPanel/GroupDetailPanelComponent';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { projectUsersListSelector } from 'store/selectors/projectUsersSelectors';

type MatchParams = {
  groupId: string;
};

type Props = {
  loadGroups: () => void;
  width?: number;
  groupsList: GroupListDto[];
  backUrl: string;
};

const GroupDetailPanel: FunctionComponent<Props> = ({ loadGroups, groupsList, backUrl }) => {
  const { url } = useRouteMatch();
  const intl = useIntl();
  const selectedGroupId = useParams<MatchParams>()?.groupId;
  const history = useHistory();

  const [lastSelectedGroup, setLastSelectedGroup] = useState<GroupListDto>(undefined);

  const usersList = useSelectorDispatch(projectUsersListSelector, (dispatch) =>
    dispatch.projectUsers.loadData({ reload: true })
  );

  useDirtyStoreReload(
    (store) => store.projectUsers,
    (dispatch) => dispatch.projectUsers
  );

  const selectedGroup = useMemo(() => groupsList?.find((group) => group?.id === selectedGroupId), [
    groupsList,
    selectedGroupId,
  ]);

  useEffect(() => {
    if (selectedGroup?.id) setLastSelectedGroup(selectedGroup);
  }, [selectedGroup]);

  useEffect(() => {
    if (!selectedGroup && !!selectedGroupId && !!groupsList) {
      history.replace(backUrl);
      notification.warning({
        message: intl.formatMessage({ id: 'general.warning' }),
        description: (
          <p
            dangerouslySetInnerHTML={{
              __html: intl.formatHTMLMessage(
                { id: 'ProjectSettingsPage.Groups.erasedGroupMessage' },
                { name: lastSelectedGroup?.name }
              ),
            }}
          />
        ),
        placement: 'top',
        duration: null,
      });
    }
  }, [backUrl, groupsList, history, intl, lastSelectedGroup?.name, selectedGroup, selectedGroupId]);

  const groupUsers = useMemo(
    () => usersList?.filter((u) => selectedGroup?.userIds.some((groupUserId) => groupUserId === u.id)) || [],
    [usersList, selectedGroup]
  );

  return (
    <GroupDetailPanelComponent
      selectedGroup={selectedGroup}
      loadGroups={loadGroups}
      groupUsers={groupUsers}
      usersList={usersList}
      url={url}
      intl={intl}
    />
  );
};

export default React.memo(GroupDetailPanel);
