import { MaskItemType } from 'components/MaskInput/MaskInput.types';
import { MaskItemLengthsInput } from 'components/MaskInput/MaskItemLengthsInput';
import { MaskItemLetterConfigInput } from 'components/MaskInput/MaskItemLetterConfigInput';
import { MaskItemMetadataInput } from 'components/MaskInput/MaskItemMetadataInput';
import { MaskItemTextInput } from 'components/MaskInput/MaskItemTextInput';
import { Fmt } from 'locale';
import React, { FC } from 'react';

type Props = {
  item: MaskItemType;
  onItemChange?: (item: Partial<Omit<MaskItemType, 'type'>>) => void;
};
export const MaskItemValue: FC<Props> = ({ item, onItemChange }: Props) => {
  switch (item.type) {
    case 'text':
      return <MaskItemTextInput item={item} onChange={onItemChange} />;
    case 'number':
      return <MaskItemLengthsInput item={item} onChange={onItemChange} />;
    case 'metadata':
      return <MaskItemMetadataInput item={item} onChange={onItemChange} />;
    case 'letters':
      return (
        <>
          <MaskItemLengthsInput item={item} onChange={onItemChange} />
          <MaskItemLetterConfigInput item={item} onChange={onItemChange} />
        </>
      );
    default:
      return (
        <>
          <Fmt id="folderMasks.MaskItem.general.unknownType" />
        </>
      );
  }
};
