import { HistoryOutlined } from '@ant-design/icons';
import { Button, ButtonProps } from 'antd';
import { WorkflowApprovalDocumentDto } from 'api/completeApiInterfaces';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { useBoolean } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, ReactNode } from 'react';
import { WorkflowApprovalDocumentActivityModal } from './WorkflowApprovalDocumentActivityModal';
import { ApprovalDocumentActivity } from './WorkflowApprovalDocumentActivityRow';

export type Props = Omit<ButtonProps, 'onClick'> & {
  workflowApprovalDocument: WorkflowApprovalDocumentDto;
  additionalData?: (documentActivity: ApprovalDocumentActivity) => ReactNode;
  workflowNodeId?: Guid;
};

export const WorkflowApprovalDocumentActivityHistoryButton: FunctionComponent<Props> = React.memo(
  ({ workflowApprovalDocument, additionalData, workflowNodeId, ...buttonProps }: Props) => {
    const [activityVisible, showActivity, hideActivity] = useBoolean(false);
    return (
      <>
        <CommonHubTooltip title={<Fmt id="WorkflowApprovalDocumentActivityHistoryButton.tooltip" />}>
          <Button onClick={showActivity} {...buttonProps}>
            <HistoryOutlined />
          </Button>
        </CommonHubTooltip>
        {activityVisible && (
          <WorkflowApprovalDocumentActivityModal
            open={activityVisible}
            onCancel={hideActivity}
            approvalDocument={workflowApprovalDocument}
            additionalData={additionalData}
            workflowNodeId={workflowNodeId}
          />
        )}
      </>
    );
  }
);
