import { Alert } from 'antd';
import { ExtendedPermissionEnum, RoleDto } from 'api/completeApiInterfaces';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import StackPanel from 'components/StackPanel';
import { useCurrentProjectUser, useStoreSelector } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt, InjectedIntlProps } from 'locale';
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Dispatch } from 'store';
import { rolesMapSelector, rolesOrderedListSelector } from 'store/selectors';
import { userHasPermission } from 'utils/userHasPermission';
import RolesListPanel from './RolesListPanel/RolesListPanel';

type Props = InjectedIntlProps;

const Roles: FunctionComponent<Props> = ({ intl }) => {
  const rolesList = useStoreSelector(rolesOrderedListSelector);
  const rolesMap = useStoreSelector(rolesMapSelector);
  const rolesState = useStoreSelector((state) => state.roles);
  const currentUser = useCurrentProjectUser();
  const dispatch = useDispatch<Dispatch>();
  const loadRoles = dispatch.roles.loadData;

  useDirtyStoreReload(
    (store) => store.roles,
    (dispatch) => dispatch.roles
  );

  const { url } = useRouteMatch();

  useEffect(() => {
    loadRoles({ reload: true });
  }, []);

  const handleRoleAdd = (role: RoleDto) => {
    loadRoles({ reload: true });
    dispatch.currentProjectUser.loadData({ reload: true });
  };

  const handleRoleDelete = useCallback((roleId: Guid) => {
    loadRoles({ reload: true });
    dispatch.currentProjectUser.loadData({ reload: true });
  }, []);

  const noPermission = !currentUser.isAdmin && !userHasPermission(currentUser, ExtendedPermissionEnum.userEditing);

  return (
    <MasterComponent
      url={url}
      title={intl.formatMessage({ id: 'general.roles' })}
      children={() =>
        noPermission ? (
          <StackPanel vertical scrollable>
            <Alert type="error" message={<Fmt id="general.insufficientPermission" />} />
          </StackPanel>
        ) : (
          <RolesListPanel
            rolesMap={rolesMap}
            rolesList={rolesList}
            rolesListLoading={rolesState.loading}
            rolesListError={rolesState.error}
            onAdd={handleRoleAdd}
            onDelete={handleRoleDelete}
            intl={intl}
          />
        )
      }
    />
  );
};

export default injectIntl(Roles);
