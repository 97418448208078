import { EditOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal } from 'antd';
import { useBoolean, useIntl } from 'hooks';
import React, { FC } from 'react';

type ItemCountsProps = {
  id: string;
  count: number;
  otherCount?: number;
};

type Props = {
  item: ItemCountsProps;
  onChange?: (item: ItemCountsProps) => void;
};

type FormType = {
  count: string;
};

// regex for validating requirements for text length, two options, exact text length (some positive number - not starting on zero) or range of text length (two positive numbers separated by -)
const regex = new RegExp(`^([1-9]\\d*|([1-9]\\d*)-([1-9]\\d*))$`);

export const parseCount = (
  count: string
): {
  count: number;
  otherCount?: number;
} => {
  const [from, to] = count.split('-').map((x) => parseInt(x));
  return to ? { count: from, otherCount: to } : { count: from, otherCount: undefined };
};

export const formatMaskItemCounts = (item: ItemCountsProps): string => {
  return item.count + (item.otherCount ? '-' + item.otherCount : '');
};

export const MaskItemLengthsInput: FC<Props> = ({ item, onChange }: Props) => {
  const intl = useIntl();
  const value = formatMaskItemCounts(item);
  const [form] = Form.useForm<FormType>();
  const [open, show, hide] = useBoolean(false);

  return (
    <>
      <Button size="small" onClick={show} icon={<EditOutlined />}>
        {intl.formatMessage({ id: 'folderMasks.MaskItem.length.label' })}: {value}
      </Button>
      <Modal
        title={intl.formatMessage({ id: 'folderMasks.MaskItem.length.title' })}
        open={open}
        onCancel={hide}
        onOk={() => {
          form.validateFields().then((values) => {
            onChange?.({
              id: item.id,
              ...parseCount(values.count),
            });
            hide();
          });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          <Form.Item
            name="count"
            label={intl.formatMessage({ id: 'folderMasks.MaskItem.length.charsCount' })}
            initialValue={value}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'folderMasks.MaskItem.length.rules.required' }),
              },
              {
                pattern: regex,
                message: intl.formatMessage({ id: 'folderMasks.MaskItem.length.rules.format' }),
              },
              // text if second number is larger than first
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const counts = parseCount(value);
                  if (counts.otherCount && counts.count >= counts.otherCount) {
                    return Promise.reject(
                      new Error(intl.formatMessage({ id: 'folderMasks.MaskItem.length.rules.invalidCountOrder' }))
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
