import { masterApi } from 'api/completeApi';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import StackPanel from 'components/StackPanel';
import { useApiData, useIntl } from 'hooks';
import { InjectedIntlProps } from 'locale';
import Panel from 'pages/ProjectSettingsPage/Panel/Panel';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { messageError } from 'utils';
import WorkflowTemplatesList from './WorkflowTemplateList';

type Props = InjectedIntlProps & {
  organizationId: Guid;
};

const WorkflowTemplateListPanel: FunctionComponent<Props> = ({ organizationId }) => {
  const [search, setSearch] = useState<string>('');

  const { url } = useRouteMatch();
  const intl = useIntl();

  const [workflowTemplates, workflowError, workflowTemplatesLoading, loadWorkflowTemplates] = useApiData(
    (ct) => masterApi.projects.tempates.wftempate.id.get(organizationId, ct),
    { autoload: false }
  );

  useEffect(() => {
    loadWorkflowTemplates();
  }, [organizationId]);

  const clearSearch = useCallback(() => {
    setSearch('');
  }, []);

  const handleTemplateDelete = useCallback(
    async (templateId: Guid) => {
      const [err, res] = await masterApi.projects.tempates.wftempate.id.template.id.delete(organizationId, templateId);
      if (err) {
        messageError(err, intl);
      } else {
        loadWorkflowTemplates();
      }
    },
    [intl, loadWorkflowTemplates]
  );

  return (
    <>
      <MasterComponent
        url={url}
        title={intl.formatMessage({ id: 'ProjectTemplates.title' })}
        children={(onSelect, selectedItemId) => (
          <StackPanel>
            <Panel noMargin onSearch={setSearch} searchValue={search}>
              <ContentGate empty={!workflowTemplates?.length} loading={workflowTemplatesLoading} error={workflowError}>
                <WorkflowTemplatesList
                  workflowTemplates={workflowTemplates}
                  search={search}
                  onClearSearch={clearSearch}
                  onDelete={handleTemplateDelete}
                />
              </ContentGate>
            </Panel>
          </StackPanel>
        )}
      />
    </>
  );
};

export default React.memo(WorkflowTemplateListPanel);
