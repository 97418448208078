import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Input, Typography } from 'antd';
import { SharedReportWidgetConfiguration } from 'components/Reports/contexts/ReportWidgetsContextProvider';
import { MAX_ITEM_NAME_LENGTH } from 'config/constants';
import { Fmt, InjectedIntlProps } from 'locale';
import React, { useEffect } from 'react';
import { maxLengthRule } from 'utils/formHelpersCompatibility';

export type SharedReportWidgetEditFormData = {
  name: string;
};

type Props = FormComponentProps<SharedReportWidgetEditFormData> &
  InjectedIntlProps & {
    configuration: SharedReportWidgetConfiguration;
  };

const SharedReportWidgetEditForm = React.forwardRef<unknown, Props>(({ intl, form, configuration }, ref) => {
  // backward compatibility with class components
  useEffect(() => {
    (ref as any).current = { props: { form } };
  }, [form]);
  const { getFieldDecorator } = form;

  return (
    <Form layout="vertical">
      <Form.Item label={intl.formatMessage({ id: 'forms.items.name.label' })}>
        {getFieldDecorator<SharedReportWidgetEditFormData>('name', {
          rules: [maxLengthRule('general.maxNameLength', MAX_ITEM_NAME_LENGTH)],
          initialValue: configuration.title,
        })(<Input placeholder={intl.formatMessage({ id: 'ReportWidgetCreateFormModalForm.name.placeholder' })} />)}
        <Typography.Paragraph type="secondary">
          <Fmt id="ReportWidgetCreateFormModalForm.name.info" />
        </Typography.Paragraph>
      </Form.Item>
    </Form>
  );
});

export default Form.create<Props>()(SharedReportWidgetEditForm);
