import { InfoCircleOutlined, MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import { createCancelToken } from 'api';
import { projectApi } from 'api/completeApi';
import { DirectoryContentDto, DirectorySelectionDto, WorkflowStateEnum } from 'api/completeApiInterfaces';
import Axios, { CancelTokenSource } from 'axios';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { Margin } from 'components/Margin/Margin';
import SpinBoxCenter from 'components/SpinBoxCenter';
import StackPanel from 'components/StackPanel';
import { VerticalSplitPanel } from 'components/VerticalSplitPane/VerticalSplitPane';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { useSameCallback, useSelectorDispatch } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt, InjectedIntlProps, memoizeWithIntl } from 'locale';
import { uniqBy } from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { List as VirtualList } from 'react-virtualized';
import { directoryConnectedLinksSelector, directoryConnectedMapSelector, directoryRootSelector } from 'store/selectors';
import { messageError } from 'utils';
import { DirectoryNodeKey } from 'utils/typeMappings/directories/directoryTreeIds';
import { ConnectedDirectory, directoryNodeHelpers } from 'utils/typeMappings/directories/directoryTypes';
import { DisabledWithReason } from 'utils/types';
import { DirectoryList } from './DirectoryList';
import { DocumentList, SelectedDocumentsChange } from './DocumentList';
import styles from './DocumentSelect.module.less';

const setSingleDocument = (documents: SelectedDocumentsChange): React.SetStateAction<DocumentSelectDocumentState[]> => {
  const document = documents.addedDocuments[0];
  return document ? [document] : [];
};

const setMultipleDocuments = (
  documents: SelectedDocumentsChange
): React.SetStateAction<DocumentSelectDocumentState[]> => (oldValue) => {
  const filtered = (oldValue || []).filter((doc) => documents.removedDocumentIds.every((id) => id !== doc.id));
  const added = filtered.concat(documents.addedDocuments);
  return uniqBy(added, (doc) => doc.id);
};

export type DocumentSelectDocumentState = {
  id: Guid;
  name?: string;
  directoryId: Guid;
  directoryPath?: string;
  state?: WorkflowStateEnum;
};

export type DocumentsSelectProps = {
  startDirectoryId?: Guid;
  multiple?: boolean;
  disabledDocuments?: (document: DirectoryContentDto, directory: ConnectedDirectory) => DisabledWithReason;
  chaningDocuments: boolean;
  setChaningDocuments: React.Dispatch<React.SetStateAction<boolean>>;
  selectDocumentsTokenRef: React.MutableRefObject<CancelTokenSource>;
  selectedDocuments: DocumentSelectDocumentState[];
  setSelectedDocuments: React.Dispatch<React.SetStateAction<DocumentSelectDocumentState[]>>;
  visible?: boolean;
};

const DocumentSelectComponent: FunctionComponent<InjectedIntlProps & DocumentsSelectProps> = ({
  intl,
  startDirectoryId,
  multiple,
  disabledDocuments,
  chaningDocuments,
  setChaningDocuments,
  selectDocumentsTokenRef,
  selectedDocuments,
  setSelectedDocuments,
  visible,
}) => {
  // directory browsing elevated state
  const [selectedKeys, setSelectedKeys] = useState<DirectoryNodeKey[]>(
    startDirectoryId ? [directoryNodeHelpers.directoryKey(startDirectoryId)] : []
  );

  // load directory tree
  const directoryRoot = useSelectorDispatch(directoryRootSelector, (dispatch) => {
    dispatch.directoriesWithLinks.loadData({ reload: false });
  });

  useDirtyStoreReload(
    (store) => store.directoriesWithLinks,
    (dispatch) => dispatch.directoriesWithLinks
  );

  // set expanded keys to starting directory
  const directoriesById = useSelectorDispatch(directoryConnectedMapSelector, (dispatch) =>
    dispatch.directories.loadData({ reload: false })
  );

  useDirtyStoreReload(
    (store) => store.directories,
    (dispatch) => dispatch.directories
  );

  const directoryTree = useSelector(directoryConnectedMapSelector);
  const directoryLinkMap = useSelector(directoryConnectedLinksSelector);

  const selectedTargetDirectory = useMemo(() => {
    const selectedNode = directoryNodeHelpers.directoryNodeFromKey(selectedKeys[0], directoryTree, directoryLinkMap);
    return selectedNode && directoryNodeHelpers.getTargetDirectory(selectedNode);
  }, [selectedKeys, directoryTree, directoryLinkMap]);

  const [selectedDocumentsCountVisible, setSelectedDocumentsCountVisible] = useState(false);
  const selectedDocumentsLengthRef = useRef(selectedDocuments?.length);
  const selectedDocumentsCountDiff = useMemo(() => {
    setSelectedDocumentsCountVisible(true);
    const result = selectedDocuments.length - selectedDocumentsLengthRef.current;
    selectedDocumentsLengthRef.current = selectedDocuments?.length || 0;
    return result;
  }, [selectedDocuments]);

  useEffect(() => {
    setSelectedDocumentsCountVisible(false);
  }, [selectedTargetDirectory]);

  // select root directory on startup
  useEffect(() => {
    if (!startDirectoryId && directoryRoot?.id) {
      setSelectedKeys((selected) =>
        selected.length === 0 ? [directoryNodeHelpers.directoryKey(directoryRoot.id)] : selected
      );
    }
  }, [startDirectoryId, directoryRoot]);

  const listRef = useRef<VirtualList>();

  // callbacks for components
  const changeSelectedDocuments = useCallback(
    (documentChange: SelectedDocumentsChange) => {
      setSelectedDocuments(multiple ? setMultipleDocuments(documentChange) : setSingleDocument(documentChange));
    },
    [multiple, setSelectedDocuments]
  );

  // Resolves with fetched documents, or doesn't resolve at all
  const fetchDocumentsInDirectoryDeep = (directoryId: Guid) =>
    new Promise<DirectoryContentDto[]>((resolve) => {
      selectDocumentsTokenRef.current?.cancel('DocumentSelect: cancelling previous select all documents request');
      selectDocumentsTokenRef.current = createCancelToken();

      const data: DirectorySelectionDto = {
        directoryId: directoryId,
        withSubfolders: true,
      };

      setChaningDocuments(true);
      const fetch = projectApi.directories.documentselection.post(data, selectDocumentsTokenRef.current.token);
      fetch.then(([err, resp]) => {
        if (Axios.isCancel(err)) {
          return;
        }
        setChaningDocuments(false);

        if (err) {
          messageError(err, intl);
        } else {
          let documents = resp.data.documents.concat(
            resp.data.documentLinks.map((link) => link.linkedDocument).filter(Boolean)
          );
          if (disabledDocuments) {
            documents = documents.filter((doc) => !disabledDocuments(doc, directoryTree[doc.directoryId]));
          }
          resolve(documents);
        }
      });
    });

  // mass select / unselect documents
  const handleSelectAll = useSameCallback(() => {
    const directory = selectedTargetDirectory;
    if (!directory || chaningDocuments) return;

    fetchDocumentsInDirectoryDeep(directory.id).then((documents) =>
      setSelectedDocuments(setMultipleDocuments({ addedDocuments: documents, removedDocumentIds: [] }))
    );
  });

  const handleDeselectAll = useSameCallback(() => {
    const directory = selectedTargetDirectory;
    if (!directory || chaningDocuments) return;

    fetchDocumentsInDirectoryDeep(directory.id).then((documents) =>
      setSelectedDocuments(
        setMultipleDocuments({ addedDocuments: [], removedDocumentIds: documents.map((doc) => doc.id) })
      )
    );
  });

  const handleManualCancel = useCallback(() => {
    selectDocumentsTokenRef.current?.cancel('DocumentSelect: manual cancel');
    setChaningDocuments(false);
  }, [selectDocumentsTokenRef, setChaningDocuments]);

  // weird bug fix
  useEffect(() => {
    const timeout = setTimeout(() => listRef.current?.forceUpdateGrid(), 1);
    return () => clearTimeout(timeout);
  }, [selectedDocuments]);

  const [documentListKey, setDocumentListKey] = useState(0);

  useEffect(() => {
    if (visible) setDocumentListKey((key) => key + 1);
  }, [visible]);

  const disabledDocumentsWithDirectory = useCallback(
    (document: DirectoryContentDto) =>
      disabledDocuments && selectedTargetDirectory && disabledDocuments(document, selectedTargetDirectory),
    [disabledDocuments, selectedTargetDirectory]
  );

  const selectFazeInfoAlert = useMemo(() => {
    if (!chaningDocuments && selectedDocumentsCountVisible && !!selectedDocuments?.length) {
      return (
        <FlowLayout>
          <InfoCircleOutlined />
          <FlowLayout wrap gapSize="extraLarge">
            <div>
              {intl.formatMessage({
                id:
                  selectedDocumentsCountDiff > 0
                    ? `DocumentSelectInfo.actualSelected`
                    : `DocumentSelectInfo.actualDeselected`,
              })}{' '}
              <strong>{Math.abs(selectedDocumentsCountDiff)}</strong>
            </div>
            <div>
              {intl.formatMessage({ id: `DocumentSelectInfo.selectedTotal` })}{' '}
              <strong>{selectedDocuments.length}</strong>
            </div>
          </FlowLayout>
        </FlowLayout>
      );
    } else {
      return null;
    }
  }, [chaningDocuments, selectedDocuments?.length, selectedDocumentsCountDiff, selectedDocumentsCountVisible]);

  return (
    <ContentGate loading={!directoryRoot}>
      <div className={styles.wrapperDiv}>
        <SpinBoxCenter
          spinning={chaningDocuments}
          indicator={
            <div className={styles.spinBoxIndicator}>
              <Spin />
              <br />
              <Fmt id="general.loading" />
              <br />
              <Button onClick={handleManualCancel}>
                <Fmt id="general.cancel" />
              </Button>
            </div>
          }
        >
          <VerticalSplitPanel minSize={200} maxSize={-400} size={300}>
            <div className={styles.dirsSection}>
              <DirectoryList
                startDirectoryId={startDirectoryId}
                selectedKeys={selectedKeys}
                setSelectedKeys={setSelectedKeys}
                directoryRoot={directoryRoot}
                selectedDocuments={selectedDocuments}
                directoriesById={directoriesById}
              />
            </div>
            <StackPanel vertical stretch>
              {multiple && (
                <Margin left top>
                  <FlowLayout wrap gapSize="extraLarge">
                    <FlowLayout wrap>
                      <Button onClick={handleSelectAll} icon={<PlusSquareOutlined />}>
                        <Fmt id="DocumentSelectAddAll.selectAll" />
                      </Button>
                      <Button onClick={handleDeselectAll} icon={<MinusSquareOutlined />}>
                        <Fmt id="DocumentSelectAddAll.deselectAll" />
                      </Button>
                    </FlowLayout>
                    {!!selectFazeInfoAlert && selectFazeInfoAlert}
                  </FlowLayout>
                </Margin>
              )}
              {selectedTargetDirectory && (
                <DocumentList
                  key={documentListKey}
                  directory={selectedTargetDirectory}
                  selectedDocuments={selectedDocuments}
                  changeSelectedDocuments={changeSelectedDocuments}
                  disabledDocuments={disabledDocumentsWithDirectory}
                  listRef={listRef}
                  multiple={multiple}
                />
              )}
            </StackPanel>
          </VerticalSplitPanel>
        </SpinBoxCenter>
      </div>
    </ContentGate>
  );
};

export const DocumentSelect = memoizeWithIntl(DocumentSelectComponent);
