import React, { FC, useCallback, useEffect, useState } from 'react';
import { DEFAULT_ROW_HEIGHT } from 'components/DocumentsGrid/CommonDocumentsGrid';
import StackPanel from 'components/StackPanel';

export const DEFAULT_MIN_ROWS_COUNT = 5;
export const DEFAULT_MAX_ROWS_COUNT = 5;

type Props = {
  minRowsCount?: number;
  maxRowsCount?: number;
  itemsCount?: number;
  header?: React.ReactNode;
  offsetPx?: number;
  rowHeight?: number;
};

export const MinMaxContainer: FC<Props> = ({
  children,
  itemsCount = 0,
  maxRowsCount = DEFAULT_MAX_ROWS_COUNT,
  minRowsCount = DEFAULT_MIN_ROWS_COUNT,
  rowHeight = DEFAULT_ROW_HEIGHT,
  header,
  offsetPx = 0,
}) => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const rowsCount = Math.max(minRowsCount, Math.min(maxRowsCount, itemsCount));
  const minHeight = rowsCount * rowHeight + height + offsetPx;

  const resizeHeader = useCallback(() => {
    setHeight(headerRef.current?.offsetHeight || 0);
  }, []);

  useEffect(() => {
    const header = headerRef.current;
    let observer: ResizeObserver;
    if (header) {
      observer = new ResizeObserver(resizeHeader);
      observer.observe(header);
    }
    return () => {
      if (observer && header) {
        observer.unobserve(header);
      }
    };
  }, []);

  return (
    <StackPanel vertical stretch scrollable>
      <StackPanel vertical stretch style={{ minHeight: minHeight + 'px' }}>
        {header && <div ref={headerRef}>{header}</div>}
        {children}
      </StackPanel>
    </StackPanel>
  );
};
