import {
  MdMeDto,
  MdProjectDataDto,
  MdProjectListDto,
  MdProjectPhaseEnum,
  MdProjectStateEnum,
  MdProjectVariableEnum,
  MdRoleEnum,
} from 'api/completeApiInterfaces';
import { MAP_CK_IDENTIFICATIONS_ENUM_TO_INTL_MESSAGE_ID } from 'components/Reports/MDProjectCard/MDProjectCardCreateForm/MDProjectCreateForm';
import { MDCkApprovalCondition } from 'components/Reports/MDProjectCard/MDProjectDataItem/MDProjectVariableData.types';
import { InjectedIntl } from 'locale';
import { IntlMessageId } from 'locale/messages/cs';
import moment from 'moment';

export const MAP_MD_PROJECT_PHASES_TO_MESSAGE_IDS: Record<MdProjectPhaseEnum, IntlMessageId> = {
  [MdProjectPhaseEnum.init]: 'MD.ProjectPhase.init',
  [MdProjectPhaseEnum.intention]: 'MD.ProjectPhase.intention',
  [MdProjectPhaseEnum.other]: 'MD.ProjectPhase.other',
  [MdProjectPhaseEnum.study]: 'MD.ProjectPhase.study',
  [MdProjectPhaseEnum.update]: 'MD.ProjectPhase.update',
};

export type MDGridData = {
  id: Guid;
  key: Guid;
  intentionerId?: Guid;
  actionName: string;
  isprofond: string;
  actionCharacter: string;
  ckIdentificaion: string;
  constructionRegion: string;
  stretch: string;
  totalCost: number;
  expectedRealisationTimeFrom: string;
  expectedRealisationTimeTo: string;
  ckApprovalDate: string;
  ckApprovalConditionsText: string;
  ckApprovalConditions: MDCkApprovalCondition[];
  isUpToDate: boolean;
  projectPhase: string;
  projectState: string;
  documentationLink: string;
  createdDate: IsoDateTime;
  lastActualizationDate: IsoDateTime;
  isAfterDeadline: boolean;
  preparedForCkApproval: boolean;
  children?: MDGridData[];
};

export const getCkConditionsMetStateIntlId = (conditions: MDCkApprovalCondition[]): IntlMessageId => {
  if (!conditions || !conditions?.length) return null;
  if (!conditions.some((condition) => !condition.ApprovalDone)) return 'MD.MDCkConditionsEnum.conditionsMet';
  if (
    conditions.some(
      (condition) => !condition.ApprovalDone && moment(condition.Deadline).endOf('day') < moment().endOf('day')
    )
  )
    return 'MD.MDCkConditionsEnum.conditionsNotMet';
  return 'MD.MDCkConditionsEnum.withConditions';
};

export const parseListValueToGridValue = (
  projectData: MdProjectDataDto[],
  variable: MdProjectVariableEnum,
  isFrom?: boolean
) => {
  if (!projectData) return undefined;
  const data = projectData?.find((project) => project.variable === variable)?.data || undefined;
  if (!data) return undefined;
  if (variable === MdProjectVariableEnum.expectedRealisationTime)
    return isFrom ? (data.valueFrom as string) : (data.valueTo as string);
  if (variable == MdProjectVariableEnum.preparedForCkApproval) {
    return data.value == 'true' ? true : data.value == 'false' ? false : undefined;
  }
  return data.value;
};

export const mapFlatDataToGridData = (partialList: MDGridData[], fullList: MDGridData[]) => {
  const gridData: MDGridData[] = [];
  partialList?.forEach((row) => {
    const childsky = fullList.filter((project) => project.intentionerId === row.id);
    gridData.push({ ...row, children: !!childsky.length ? mapFlatDataToGridData(childsky, fullList) : [] });
  });
  return gridData;
};

export const mapListObjectsToFlatData = (partialList: MdProjectListDto[], intl: InjectedIntl) => {
  const gridFlatData: MDGridData[] = [];

  partialList.forEach((mdProject) => {
    const approvalConditions = parseListValueToGridValue(
      mdProject.projectData,
      MdProjectVariableEnum.ckApprovalConditions
    ) as MDCkApprovalCondition[];

    const isAfterDeadline = approvalConditions?.some(
      (condition) => !condition.ApprovalDone && moment(condition.Deadline) < moment()
    );

    gridFlatData.push({
      id: mdProject.id,
      key: mdProject.id,
      intentionerId: mdProject.intentionerId,
      actionName: mdProject.name,
      createdDate: mdProject.createdDate,
      lastActualizationDate: mdProject.lastUpdateDate,
      projectPhase: intl.formatMessage({ id: MAP_MD_PROJECT_PHASES_TO_MESSAGE_IDS[mdProject.phase] }),
      projectState: mdProject.state,
      isAfterDeadline: isAfterDeadline,
      isprofond: parseListValueToGridValue(mdProject.projectData, MdProjectVariableEnum.isProFond) as string,
      actionCharacter: parseListValueToGridValue(
        mdProject.projectData,
        MdProjectVariableEnum.actionCharacter
      ) as string,
      preparedForCkApproval: parseListValueToGridValue(
        mdProject.projectData,
        MdProjectVariableEnum.preparedForCkApproval
      ) as boolean,
      ckIdentificaion: !!mdProject.ckIdentificaion
        ? intl.formatMessage({
            id: MAP_CK_IDENTIFICATIONS_ENUM_TO_INTL_MESSAGE_ID[mdProject.ckIdentificaion],
          })
        : null,
      constructionRegion: parseListValueToGridValue(
        mdProject.projectData,
        MdProjectVariableEnum.constructionRegion
      ) as string,
      stretch: parseListValueToGridValue(mdProject.projectData, MdProjectVariableEnum.stretch) as string,
      totalCost: +parseListValueToGridValue(mdProject.projectData, MdProjectVariableEnum.totalCost),
      expectedRealisationTimeFrom: parseListValueToGridValue(
        mdProject.projectData,
        MdProjectVariableEnum.expectedRealisationTime,
        true
      ) as string,
      expectedRealisationTimeTo: parseListValueToGridValue(
        mdProject.projectData,
        MdProjectVariableEnum.expectedRealisationTime,
        false
      ) as string,
      ckApprovalDate: parseListValueToGridValue(mdProject.projectData, MdProjectVariableEnum.ckApprovalDate) as string,
      ckApprovalConditionsText: !approvalConditions?.length
        ? null
        : intl.formatMessage({
            id: getCkConditionsMetStateIntlId(approvalConditions),
          }),
      ckApprovalConditions: approvalConditions,
      isUpToDate: mdProject.isUpToDate,
      documentationLink: parseListValueToGridValue(
        mdProject.projectData,
        MdProjectVariableEnum.documentationLink
      ) as string,
    });
  });
  return gridFlatData;
};

export const filterVisibleData = (projectList: MdProjectListDto[], mdCurrentUser: MdMeDto) => {
  return projectList.filter(
    (project) =>
      (project.state === MdProjectStateEnum.divisionSetting &&
        mdCurrentUser?.mdRoles.some((role) => role.mdRoleType === MdRoleEnum.O910)) ||
      mdCurrentUser?.division?.id === project?.processorDivision?.id ||
      mdCurrentUser?.division?.id === project?.proposerDivision?.id
  );
};
