import { ProjectTemplateListDto } from 'api/completeApiInterfaces';
import { AuditLogButton, EditButton } from 'components/ActionButtons';
import { DeleteButtonConfirm } from 'components/ActionButtons/DeleteButtonConfirm';
import CommonHubEllipsisText from 'components/CommonHubEllipsisText/CommonHubEllipsisText';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import List from 'components/List';
import { ListEmpty } from 'components/ListEmpty/ListEmpty';
import { Fmt } from 'locale';
import React, { FunctionComponent } from 'react';
import { smartFilter } from 'utils';

type Props = {
  projectTemplates: ProjectTemplateListDto[];
  search: string;
  onClearSearch?: () => void;
  onEdit?: (id: Guid) => void;
  onDelete?: (id: Guid) => void;
  onShowAuditLog?: (template: ProjectTemplateListDto) => void;
};

const ProjectTemplatesList: FunctionComponent<Props> = ({
  projectTemplates,
  search,
  onEdit,
  onDelete,
  onClearSearch,
  onShowAuditLog,
  children,
}) => {
  return (
    <GeneralSettingsContainer>
      <List<ProjectTemplateListDto>
        data={projectTemplates}
        search={search}
        filterItem={(item) => (item && smartFilter(item?.name, search)) || smartFilter(item?.description, search)}
        renderItem={(item) => (
          <GeneralSettingsItem
            key={item.id}
            title={item.name}
            wrap
            description={
              !!item.description ? (
                <CommonHubEllipsisText placement="topLeft" title={item.description}>
                  {item.description}
                </CommonHubEllipsisText>
              ) : null
            }
            additionalActions={
              <>
                {onEdit && <EditButton onClick={() => onEdit(item.id)} />}
                {onDelete && (
                  <DeleteButtonConfirm
                    onConfirm={() => onDelete(item.id)}
                    confirmTooltip={<Fmt id="ProjectTemplates.list.deleteTemplate.confirmation" />}
                  />
                )}
                {!!onShowAuditLog && <AuditLogButton onClick={() => onShowAuditLog(item)} />}
              </>
            }
          />
        )}
        renderEmpty={(total, filtered) => <ListEmpty filtered={filtered} total={total} onClearSearch={onClearSearch} />}
      />
      {children}
    </GeneralSettingsContainer>
  );
};

export default ProjectTemplatesList;
