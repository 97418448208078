import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import {
  MasterComponent,
  MasterDetailsContainer,
  MasterDetailsViewContextProvider,
} from 'components/MasterDetailsView/MasterDetailsView';
import PageLevelDropzone from 'components/PageLevelDropzone';
import { FileSystemTreeNode } from 'components/PrimaryFileInput/CommonFilesInputTypes';
import { Resizer } from 'components/Resizer/Resizer';
import SideMenuActivator, { SideMenuKey } from 'components/SideMenuActivator';
import StackPanel from 'components/StackPanel';
import { HeaderContentLayout } from 'components/layouts/HeaderContentLayout/HeaderContentLayout';
import { useIntl } from 'hooks';
import React, { FunctionComponent, ReactElement } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDebounce, useLocalStorage } from 'react-use';
import styles from './AllDocumentsPage.module.less';

type Props = {
  directoryContent: ReactElement;
  directoryTree: ReactElement;
  documentsHeader: ReactElement;
  onDropFiles: (data: FileSystemTreeNode[]) => void;
  disableDropzone?: boolean;
};

const SIZE_CONSTRAINTS = { minWidth: 300, maxWidth: 600 };
const DEFAULT_WIDTH = 300;

const CLAMP = (width: number) =>
  width == null ? DEFAULT_WIDTH : Math.min(Math.max(width, SIZE_CONSTRAINTS.minWidth), SIZE_CONSTRAINTS.maxWidth);

const AllDocumentsPageLayout: FunctionComponent<Props> = ({
  directoryContent,
  directoryTree,
  documentsHeader,
  onDropFiles,
  disableDropzone,
}) => {
  const { url } = useRouteMatch();
  const intl = useIntl();

  const [storedWidth, setStoredWidth] = useLocalStorage('directory-content-layout-width', DEFAULT_WIDTH);
  const [width, setWidth] = React.useState(() => CLAMP(storedWidth));

  useDebounce(
    () => {
      setStoredWidth(width);
    },
    500,
    [width]
  );

  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <MasterDetailsViewContextProvider>
      <SideMenuActivator menuItemKey={SideMenuKey.DOCUMENTS_ALL}>
        <PageLevelDropzone onDrop={onDropFiles} multiple disabled={disableDropzone}>
          <MasterDetailsContainer hideTitle>
            <MasterComponent
              url={url}
              title={intl.formatMessage({ id: 'menu.side.documents' })}
              minWidth={width}
              noFlex
              children={() => (
                <ErrorBoundary>
                  <Resizer clamp={CLAMP} onMove={setWidth} />
                  <StackPanel divRef={ref} vertical stretch scrollable className={styles.directoryTree}>
                    {directoryTree}
                  </StackPanel>
                </ErrorBoundary>
              )}
            />
            <MasterComponent
              url={url + '/content'}
              maxWidth={null}
              children={() => (
                <StackPanel vertical stretch scrollable>
                  <HeaderContentLayout header={documentsHeader}>
                    <ErrorBoundary>{directoryContent}</ErrorBoundary>
                  </HeaderContentLayout>
                </StackPanel>
              )}
            />
          </MasterDetailsContainer>
        </PageLevelDropzone>
      </SideMenuActivator>
    </MasterDetailsViewContextProvider>
  );
};

export default React.memo(AllDocumentsPageLayout);
