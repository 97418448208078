import { Modal, Tag, message } from 'antd';
import { masterApi } from 'api/completeApi';
import { EntityTypesEnum, MdDivisionDto, MdUserDto, MdUserPatchDto, OrgUserDto } from 'api/completeApiInterfaces';
import { MdDivisionUserObstacleError, ServiceErrorEnum } from 'api/errors';
import { AuditLogButton } from 'components/ActionButtons';
import { DeleteButtonConfirm } from 'components/ActionButtons/DeleteButtonConfirm';
import AuditLogMasterApiEntityModal from 'components/AuditLogsComponents/AuditLogMasterApiEntityModal';
import { ContentGate } from 'components/ContentGate/ContentGate';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import List from 'components/List';
import { ListEmpty } from 'components/ListEmpty/ListEmpty';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import StackPanel from 'components/StackPanel';
import { useBoolean, useIntl } from 'hooks';
import { Fmt, InjectedIntlProps } from 'locale';
import Panel from 'pages/ProjectSettingsPage/Panel/Panel';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { messageError, processApiError, smartFilter } from 'utils';
import MDDivisionUserAddFormModal from './MDDivisionUserAddFormModal';

type Props = InjectedIntlProps & {
  divisions: MdDivisionDto[];
  organizationUsers: OrgUserDto[];
  assignedUsersIds: Guid[];
  setDivisions: (divisions: MdDivisionDto[]) => void;
};

type MDDivisionParams = {
  divisionId: string;
};

const MDDivisionDetailPanel: FunctionComponent<Props> = ({
  organizationUsers,
  divisions,
  assignedUsersIds,
  setDivisions,
}) => {
  const [search, setSearch] = useState<string>('');
  const [permissionProfileModalVisible, showPermissionProfileModal, hidePermissionProfileModal] = useBoolean(false);
  const [toShowActivityMdUser, setToShowActivityMdUser] = useState<MdUserDto>();

  const { url } = useRouteMatch();
  const intl = useIntl();
  const { divisionId } = useParams<MDDivisionParams>();

  const selectedDivision = useMemo(() => divisions?.find((division) => division.id === divisionId), [
    divisions,
    divisionId,
  ]);

  const addUsersButtonDisabled = useMemo(() => {
    if (!selectedDivision?.mdUsers.length) return true;
    return !selectedDivision?.mdUsers.find((user) => user.isHead);
  }, [selectedDivision]);

  const handleDivisionUserDelete = useCallback(
    async (deletedUserId: Guid) => {
      const filteredUsers = selectedDivision.mdUsers
        .filter((user) => user.id !== deletedUserId && !user.isHead)
        .map((user) => user.orgUser.id);
      const patchedUsers: MdUserPatchDto = {
        headsOrganizationUserIds: selectedDivision.mdUsers.filter((user) => user.isHead).map((user) => user.orgUser.id),
        membersOrganizationUserIds: filteredUsers,
      };

      const [err, res] = await masterApi.projects.md.division.patch.patch({
        ...selectedDivision,
        users: patchedUsers,
        deleteMembersFromRoles: false,
      });
      if (err) {
        processApiError(err, (error) => {
          if (error.referenceErrorCode === ServiceErrorEnum.MdDivisionObstacleError) {
            const errData = error.errorData as MdDivisionUserObstacleError;

            Modal.confirm({
              title: intl.formatMessage({ id: 'MD.Organization.divisionsUsers.delete.fromRole.title' }),
              content: intl.formatMessage(
                { id: 'MD.Organization.divisionsUsers.delete.fromRole.description' },
                {
                  roles: errData.usersInRoleHead
                    .map((role) => intl.formatMessage({ id: `MD.Roles.name.${role.mdRoleType}` }))
                    .join(', '),
                  membersCount: errData.usersInRoleMembers.length,
                }
              ),
              onOk: async () => {
                const [err, res] = await masterApi.projects.md.division.patch.patch({
                  ...selectedDivision,
                  users: patchedUsers,
                  deleteMembersFromRoles: true,
                });

                if (err) {
                  messageError(err, intl);
                } else {
                  void message.success(intl.formatMessage({ id: 'MD.Organization.divisionsUsers.delete.success' }));
                  setDivisions(res.data);
                }
              },
              onCancel: () => {
                Promise.resolve([err, res]);
              },
              width: 600,
            });
            return null;
          }
        });
        messageError(err, intl);
      } else {
        void message.success(intl.formatMessage({ id: 'MD.Organization.divisionsUsers.delete.success' }));
        setDivisions(res.data);
      }
    },
    [intl, selectedDivision, setDivisions]
  );

  const handleDivisionUserAdd = useCallback(
    (updatedDivisions: MdDivisionDto[]) => {
      setDivisions(updatedDivisions);
      hidePermissionProfileModal();
    },
    [hidePermissionProfileModal, setDivisions]
  );

  const clearSearch = useCallback(() => {
    setSearch('');
  }, []);

  const hideAuditLog = useCallback(() => {
    setToShowActivityMdUser(undefined);
  }, []);

  return (
    <>
      <MasterComponent
        url={url}
        title={intl.formatMessage({ id: 'MD.Organization.divisionsUsers.panel.title' })}
        children={() => (
          <StackPanel>
            <Panel
              noMargin
              addButtonOnClick={showPermissionProfileModal}
              addButtonText={<Fmt id="MD.Organization.divisionsUsers.panel.addUser" />}
              addButtonTooltipText={
                addUsersButtonDisabled ? (
                  <Fmt id="MD.Organization.divisionsUsers.panel.addUser.disabledTooltip" />
                ) : null
              }
              addButtonDisabled={addUsersButtonDisabled}
              onSearch={setSearch}
              searchValue={search}
            >
              <ContentGate loading={!selectedDivision}>
                <GeneralSettingsContainer>
                  <List<MdUserDto>
                    data={selectedDivision?.mdUsers || []}
                    search={search}
                    filterItem={(item) => item && smartFilter(item?.orgUser.appUserProfile.username, search)}
                    renderItem={(item) => (
                      <GeneralSettingsItem
                        key={item.id}
                        title={item.orgUser.appUserProfile.username}
                        wrap
                        description={
                          !!item.isHead ? (
                            <Tag>
                              <Fmt id="MD.Organization.divisionsUsers.headUserTag" />
                            </Tag>
                          ) : null
                        }
                        additionalActions={
                          <>
                            {!item.isHead && (
                              <DeleteButtonConfirm
                                onConfirm={() => handleDivisionUserDelete(item.id)}
                                confirmTooltip={<Fmt id="MD.Organization.divisions.panel.delete.confirm.tooltip" />}
                              />
                            )}
                            <AuditLogButton key="auditLogButton" onClick={() => setToShowActivityMdUser(item)} />
                          </>
                        }
                      />
                    )}
                    renderEmpty={(total, filtered) => (
                      <ListEmpty filtered={filtered} total={total} onClearSearch={clearSearch} />
                    )}
                  />
                </GeneralSettingsContainer>
              </ContentGate>
            </Panel>
          </StackPanel>
        )}
      />
      <MDDivisionUserAddFormModal
        onSubmit={handleDivisionUserAdd}
        onClose={hidePermissionProfileModal}
        visible={permissionProfileModalVisible}
        selectedDivision={selectedDivision}
        organizationUsers={organizationUsers}
        assignedUsersIds={assignedUsersIds}
      />
      {!!toShowActivityMdUser && (
        <AuditLogMasterApiEntityModal
          visible={!!toShowActivityMdUser}
          label={
            <Fmt
              id="AuditLog.mdUser.label"
              values={{ mdUserName: toShowActivityMdUser.orgUser.appUserProfile.username }}
            />
          }
          entityId={toShowActivityMdUser.id}
          entityType={EntityTypesEnum.mdUser}
          onOk={hideAuditLog}
          deps={[toShowActivityMdUser]}
        />
      )}
    </>
  );
};

export default React.memo(MDDivisionDetailPanel);
