import { MaskItemType } from 'components/MaskInput/MaskInput.types';
import { formatMaskItemCounts } from 'components/MaskInput/MaskItemLengthsInput';
import { CASE_MAP } from 'components/MaskInput/MaskItemLetterConfigInput';
import { METADATA_TRANSLATIONS_MAP } from 'components/MaskInput/MaskItemMetadataInput';
import { Fmt } from 'locale';
import React, { FC } from 'react';

type Props = {
  item: MaskItemType;
};

export const MaskItemSimpleDisplay: FC<Props> = ({ item }: Props) => {
  switch (item.type) {
    case 'text':
      return <>{`"${item.value}"`}</>;
    case 'number':
      return (
        <>
          <strong>
            <Fmt id="folderMasks.MaskItem.simpleDisplay.digits" />
          </strong>{' '}
          ({formatMaskItemCounts(item)})
        </>
      );
    case 'metadata':
      return (
        <strong>
          <Fmt id={METADATA_TRANSLATIONS_MAP[item.propName]} />
        </strong>
      );
    case 'letters':
      return (
        <>
          <strong>
            <Fmt id="folderMasks.MaskItem.simpleDisplay.letters" />
          </strong>{' '}
          ({formatMaskItemCounts(item)}, {CASE_MAP[item.case]}, {item.allowNumbers ? '#' : ''}
          {item.allowSpecialCharacters ? '@' : ''}
          {item.whitespace ? '_' : ''})
        </>
      );
    default:
      return null;
  }
};
