import { createCancelToken } from 'api';
import { masterApi } from 'api/completeApi';
import Axios, { CancelTokenSource } from 'axios';
import { produce } from 'immer';
import { processApiError } from 'utils';
import { ActiveCalendarStoreModel, ActiveCalendarStoreModelState } from './storeModelinterfaces';

const CANCEL_TOKEN_REF = { current: undefined as CancelTokenSource };

const activeCalendarStoreDefaults: ActiveCalendarStoreModelState = {
  activeCalendar: null,
  activeCalendarError: null,
  activeCalendarLoading: false,
  dirty: false,
};

export const activeCalendarStoreModel: ActiveCalendarStoreModel = {
  state: activeCalendarStoreDefaults,
  reducers: {
    setActiveCalendar: (state, calendar) =>
      produce(state, (draft) => {
        draft.activeCalendar = calendar;
        draft.activeCalendarError = null;
        draft.activeCalendarLoading = false;
        draft.dirty = false;
      }),
    setActiveCalendarError: (state, error) =>
      produce(state, (draft) => {
        draft.activeCalendar = null;
        draft.activeCalendarError = error;
        draft.activeCalendarLoading = false;
        draft.dirty = false;
      }),
    setActiveCalendarLoading: (state, loading) =>
      produce(state, (draft) => {
        draft.activeCalendarLoading = loading;
      }),
    setDefaults: (state) =>
      produce(state, (draft) => {
        draft = activeCalendarStoreDefaults;
      }),
    setDirty: (state, dirty) =>
      produce(state, (draft) => {
        draft.dirty = dirty;
      }),
  },
  effects: (dispatch) => ({
    async loadCalendar(source) {
      if (!source.organizationId) {
        CANCEL_TOKEN_REF.current?.cancel('activeCalendarStore: leaving project');
        dispatch.activeCalendar.setActiveCalendar(null);
        return;
      }

      CANCEL_TOKEN_REF.current?.cancel('activeCalendarStore: new request was made');
      CANCEL_TOKEN_REF.current = createCancelToken();

      dispatch.activeCalendar.setActiveCalendarLoading(true);
      const [err, response] = await masterApi.projects.admin.calendar.get.post(
        { organizationId: source.organizationId, projectId: source.projectId },
        CANCEL_TOKEN_REF.current.token
      );

      if (err) {
        dispatch.activeCalendar.setActiveCalendarLoading(false);
        if (!Axios.isCancel(err)) {
          processApiError(err, dispatch.activeCalendar.setActiveCalendarError);
        }
        return;
      }

      dispatch.activeCalendar.setActiveCalendar(response.data);
    },
    clearData() {
      CANCEL_TOKEN_REF.current?.cancel('apiStoreModel: data was cleared');
      dispatch.activeCalendar.setDefaults();
    },
    markDirty() {
      dispatch.activeCalendar.setDirty(true);
    },
  }),
};
