import { LoadingOutlined } from '@ant-design/icons';
import { Button, Tag } from 'antd';
import { ProjectBaseListDto, ProjectStatusEnum } from 'api/completeApiInterfaces';
import { AuditLogButton } from 'components/ActionButtons';
import CommonHubEllipsisText from 'components/CommonHubEllipsisText/CommonHubEllipsisText';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import { EditIcon } from 'components/Icons/HubActionsIcons';
import List from 'components/List';
import { ListEmpty } from 'components/ListEmpty/ListEmpty';
import { Fmt } from 'locale';
import React, { FunctionComponent } from 'react';
import { smartFilter } from 'utils';

type Props = {
  data: ProjectBaseListDto[];
  search: string;
  selectedId?: Guid;
  onSelect?: (userId: Guid) => void;
  onClearSearch?: () => void;
  onEdit?: (project: Guid) => void;
  onShowAuditLog?: (projectId: ProjectBaseListDto) => void;
  title?: React.ReactNode;
};

const ProjectsList: FunctionComponent<Props> = ({
  data,
  search,
  selectedId,
  onSelect,
  onClearSearch,
  children,
  onEdit,
  onShowAuditLog,
  title,
}) => {
  return (
    <GeneralSettingsContainer title={title}>
      <List<ProjectBaseListDto>
        data={data}
        search={search}
        filterItem={(item) => (item && smartFilter(item?.name, search)) || smartFilter(item?.description, search)}
        renderItem={(item) => (
          <GeneralSettingsItem
            key={item.id}
            selected={item.id === selectedId}
            selectable={item.status !== ProjectStatusEnum.creating ? !!onSelect : false}
            onClick={onSelect && item.status !== ProjectStatusEnum.creating ? () => onSelect(item.id) : null}
            title={item.name}
            wrap
            description={
              <>
                {item.status === ProjectStatusEnum.creating && (
                  <Tag>
                    <LoadingOutlined /> <Fmt id="ProjectStatusPage.projectCreating.projectCreating" />
                  </Tag>
                )}
                {!!item.description ? (
                  <CommonHubEllipsisText placement="topLeft" title={item.description}>
                    {item.description}
                  </CommonHubEllipsisText>
                ) : null}
              </>
            }
            additionalActions={
              <>
                {onEdit && (
                  <Button
                    disabled={item.status === ProjectStatusEnum.creating}
                    onClick={() => onEdit(item.id)}
                    type="link"
                    icon={<EditIcon />}
                  />
                )}
                {!!onShowAuditLog && <AuditLogButton onClick={() => onShowAuditLog(item)} />}
              </>
            }
          />
        )}
        renderEmpty={(total, filtered) => <ListEmpty filtered={filtered} total={total} onClearSearch={onClearSearch} />}
      />
      {children}
    </GeneralSettingsContainer>
  );
};

export default ProjectsList;
