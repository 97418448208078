import { api } from 'api';
import { masterApi, projectApi } from 'api/completeApi';
import { activeCalendarStoreModel } from './activeCalendarStoreModel';
import { activeProjectStoreModel } from './activeProjectStoreModel';
import { appConfigStoreModel } from './appConfigStoreModel';
import { categoryTreesStoreModel } from './categoryTreesStoreModel';
import { disableWindowStoreModel } from './disableWindowStoreModel';
import { filterStateStoreModel } from './filterStateStoreModel';
import { persistentUiStoreModel } from './peristentUiStoreModel';
import { StoreModelsSafe } from './storeModelinterfaces';
import { createApiStoreModel, createNewApiStoreModel } from './storeModels';
import { allDocumentsPageStoreModel } from './ui/AllDocumentsPageStoreModel';

export const models: StoreModelsSafe = {
  appConfig: appConfigStoreModel,
  currentAppUser: createApiStoreModel(
    (state) => state.currentAppUser,
    (dispatch) => dispatch.currentAppUser,
    api.master.appUsers.getCurrentUser
  ),
  appSettings: createApiStoreModel(
    (state) => state.appSettings,
    (dispatch) => dispatch.appSettings,
    api.master.projects.getAppSettings
  ),
  favoriteProjects: createApiStoreModel(
    (state) => state.favoriteProjects,
    (dispatch) => dispatch.favoriteProjects,
    masterApi.projects.favorite.get
  ),
  allProjects: createApiStoreModel(
    (state) => state.allProjects,
    (dispatch) => dispatch.allProjects,
    masterApi.projects.get
  ),
  activeProject: activeProjectStoreModel,
  activeCalendar: activeCalendarStoreModel,
  externalApplicationsSettings: createApiStoreModel(
    (state) => state.externalApplicationsSettings,
    (dispatch) => dispatch.externalApplicationsSettings,
    projectApi.projectsetting.externalapplications.get
  ),
  directories: createApiStoreModel(
    (state) => state.directories,
    (dispatch) => dispatch.directories,
    api.project.directories.listDirectories
  ),
  directoriesWithLinks: createNewApiStoreModel(
    (state) => state.directoriesWithLinks,
    (dispatch) => dispatch.directoriesWithLinks,
    api.project.directories.listDirectoriesWithLinks
  ),
  currentProjectUser: createApiStoreModel(
    (state) => state.currentProjectUser,
    (dispatch) => dispatch.currentProjectUser,
    api.project.projectUser.getCurrentUser
  ),
  projectUsers: createApiStoreModel(
    (state) => state.projectUsers,
    (dispatch) => dispatch.projectUsers,
    api.project.projectUser.listUsers
  ),
  groups: createApiStoreModel(
    (state) => state.groups,
    (dispatch) => dispatch.groups,
    api.project.groups.listGroups
  ),
  roles: createApiStoreModel(
    (state) => state.roles,
    (dispatch) => dispatch.roles,
    api.project.roles.listRoles
  ),
  labels: createApiStoreModel(
    (state) => state.labels,
    (dispatch) => dispatch.labels,
    api.project.labels.listLabels
  ),
  organizationLabels: createApiStoreModel(
    (state) => state.organizationLabels,
    (dispatch) => dispatch.organizationLabels,
    (ct, data) => masterApi.projects.organization.labels.post(data, ct)
  ),
  projectDirectoryMasks: createApiStoreModel(
    (state) => state.projectDirectoryMasks,
    (dispatch) => dispatch.projectDirectoryMasks,
    (ct) => projectApi.directories.primary.validationMasks.post({ directoryIds: [] }, ct)
  ),
  esticonFirms: createApiStoreModel(
    (state) => state.esticonFirms,
    (dispatch) => dispatch.esticonFirms,
    api.project.budget.getEsticonFirms
  ),
  esticonProjects: createApiStoreModel(
    (state) => state.esticonProjects,
    (dispatch) => dispatch.esticonProjects,
    api.project.budget.getEsticonProjects
  ),
  esticonVat: createApiStoreModel(
    (state) => state.esticonVat,
    (dispatch) => dispatch.esticonVat,
    api.project.budget.getVat
  ),
  esticonDetail: createApiStoreModel(
    (state) => state.esticonDetail,
    (dispatch) => dispatch.esticonDetail,
    api.project.budget.getEsticonProject
  ),
  workflows: createApiStoreModel(
    (state) => state.workflows,
    (dispatch) => dispatch.workflows,
    api.project.workflow.getWorkflowList
  ),
  categories: createApiStoreModel(
    (state) => state.categories,
    (dispatch) => dispatch.categories,
    api.project.categoryTrees.listCategoryTrees
  ),
  userSummary: createApiStoreModel(
    (state) => state.userSummary,
    (dispatch) => dispatch.userSummary,
    masterApi.projects.msgcenter.getsummary.get
  ),
  categoryTrees: categoryTreesStoreModel,
  filterState: filterStateStoreModel,
  // UI
  allDocumentsPage: allDocumentsPageStoreModel,
  disableWindow: disableWindowStoreModel,
  persistentUi: persistentUiStoreModel,
};
