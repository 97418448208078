import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Checkbox, Input, Radio, Select, Space, Typography } from 'antd';
import { apiConstraints } from 'api/completeApiConstraints';
import { LanguageEnum, OrgUserDto } from 'api/completeApiInterfaces';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { Fmt, InjectedIntlProps } from 'locale';
import { useJobPositions } from 'pages/OrganizationsSettingPage/JobPositions/useJobPositions';
import React, { useEffect, useMemo } from 'react';
import { smartFilter } from 'utils';
import { maxLengthRule } from 'utils/formHelpersCompatibility';

export type OrgUserEditFormData = {
  language: LanguageEnum;
  isAdmin: boolean;
  namesFromOrganization: boolean;
  firstname: string;
  lastname: string;
  workingPositionId: Guid;
};

type Props = FormComponentProps<OrgUserEditFormData> &
  InjectedIntlProps & {
    toEditUser: OrgUserDto;
    usefulFirstName: string;
    usefulLastName: string;
    setUsefulFirstName: React.Dispatch<React.SetStateAction<string>>;
    setUsefulLastName: React.Dispatch<React.SetStateAction<string>>;
    isAdminSettingDisabled: boolean;
  };

const OrganizationUserEditFormComponent = React.forwardRef<unknown, Props>(
  (
    {
      form,
      intl,
      toEditUser,
      usefulFirstName,
      usefulLastName,
      setUsefulFirstName,
      setUsefulLastName,
      isAdminSettingDisabled,
    },
    ref
  ) => {
    // backward compatibility with class components
    useEffect(() => {
      (ref as any).current = { props: { form } };
    }, [form]);

    const [jobPositions] = useJobPositions(toEditUser.organizationId);

    useEffect(() => {
      if (form.getFieldValue('namesFromOrganization')) {
        form.setFieldsValue({
          firstname: usefulFirstName,
          lastname: usefulLastName,
        });
      } else {
        form.setFieldsValue({
          firstname: toEditUser.appUserProfile.firstname,
          lastname: toEditUser.appUserProfile.lastname,
        });
      }
    }, [form.getFieldValue('namesFromOrganization')]);

    useEffect(() => {
      if (form.getFieldValue('namesFromOrganization')) setUsefulFirstName(form.getFieldValue('firstname'));
    }, [form.getFieldValue('firstname')]);

    useEffect(() => {
      if (form.getFieldValue('namesFromOrganization')) {
        setUsefulLastName(form.getFieldValue('lastname'));
      }
    }, [form.getFieldValue('lastname')]);

    const displayValue = useMemo(() => {
      if (form.getFieldValue('namesFromOrganization') && !usefulFirstName && !usefulLastName) {
        return (
          <Typography.Text>{intl.formatMessage({ id: 'OrganizationUserEditForm.namesNotSetValue' })}</Typography.Text>
        );
      }

      if (
        !form.getFieldValue('namesFromOrganization') &&
        !toEditUser.appUserProfile.firstname &&
        !toEditUser.appUserProfile.lastname
      ) {
        return (
          <Typography.Text>{intl.formatMessage({ id: 'OrganizationUserEditForm.namesNotSetValue' })}</Typography.Text>
        );
      }
      return form.getFieldValue('namesFromOrganization') ? (
        <>
          <Typography.Text strong ellipsis>
            {` ${usefulFirstName || ''} ${usefulLastName || ''}`}
          </Typography.Text>
        </>
      ) : (
        <>
          <Typography.Text strong ellipsis>
            {` ${toEditUser.appUserProfile.firstname || ''} ${toEditUser.appUserProfile.lastname || ''}`}
          </Typography.Text>
        </>
      );
    }, [
      form,
      intl,
      toEditUser.appUserProfile.firstname,
      toEditUser.appUserProfile.lastname,
      usefulFirstName,
      usefulLastName,
    ]);

    return (
      <>
        {
          <Form layout="vertical" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} autoComplete="off">
            <Form.Item label={intl.formatMessage({ id: 'general.userName' })} style={{ marginBottom: 0 }}>
              <Typography.Text strong ellipsis>
                {toEditUser.appUserProfile.username}
              </Typography.Text>
            </Form.Item>
            <Form.Item label={intl.formatMessage({ id: 'OrganizationUserEditForm.nameSource' })}>
              {form.getFieldDecorator('namesFromOrganization', {
                initialValue: toEditUser.namesFromOrganization,
              })(
                <Radio.Group>
                  <Space direction="vertical">
                    <Radio value={true}>
                      {intl.formatMessage({ id: 'OrganizationUserEditForm.setByOrganization' })}
                    </Radio>
                    <Radio value={false}>
                      {intl.formatMessage({ id: 'OrganizationUserEditForm.takeByUserSetting' })}
                    </Radio>
                  </Space>
                </Radio.Group>
              )}
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({ id: 'OrganizationUserEditForm.firstNameLastName' })}
              style={{ marginBottom: 0 }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: '0',
                }}
              >
                <Form.Item wrapperCol={{ sm: 24 }} style={{ width: '100%' }}>
                  {form.getFieldDecorator('firstname', {
                    initialValue: usefulFirstName,
                    rules: [
                      maxLengthRule('general.maxNameLength', apiConstraints.orgUserRequestDto.firstname.maxLength),
                    ],
                  })(
                    <Input
                      disabled={!form.getFieldValue('namesFromOrganization')}
                      placeholder={intl.formatMessage({ id: 'general.firstName' })}
                    />
                  )}
                </Form.Item>
                <Form.Item wrapperCol={{ sm: 24 }} style={{ width: '100%' }}>
                  {form.getFieldDecorator('lastname', {
                    initialValue: usefulLastName,
                    rules: [
                      maxLengthRule('general.maxNameLength', apiConstraints.orgUserRequestDto.lastname.maxLength),
                    ],
                  })(
                    <Input
                      disabled={!form.getFieldValue('namesFromOrganization')}
                      placeholder={intl.formatMessage({ id: 'general.lastName' })}
                    />
                  )}
                </Form.Item>
              </div>
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({ id: 'OrganizationUserEditForm.namesDisplayValue' })}
              style={{ margin: 0 }}
            >
              <Typography.Text ellipsis>{displayValue}</Typography.Text>
            </Form.Item>
            <Form.Item label={intl.formatMessage({ id: 'general.administrator' })} style={{ marginBottom: 0 }}>
              {form.getFieldDecorator('isAdmin', { valuePropName: 'checked', initialValue: toEditUser.isAdmin })(
                <Checkbox disabled={isAdminSettingDisabled} />
              )}
            </Form.Item>
            <Form.Item
              label={
                <span>
                  <Fmt id="general.language" />
                  <CommonHubTooltip
                    title={intl.formatMessage({ id: 'OrganizationAddForm.language.tooltip' })}
                    placement="right"
                  >
                    <QuestionCircleOutlined style={{ paddingLeft: '8px' }} />
                  </CommonHubTooltip>
                </span>
              }
            >
              {form.getFieldDecorator('language', { initialValue: toEditUser.appUserProfile.language })(
                <Radio.Group>
                  <Radio.Button value={LanguageEnum.cs}>
                    {intl.formatMessage({ id: 'ProjectCreateForm.form.items.language.cs' })}
                  </Radio.Button>
                  <Radio.Button value={LanguageEnum.en}>
                    {intl.formatMessage({ id: 'ProjectCreateForm.form.items.language.en' })}
                  </Radio.Button>
                </Radio.Group>
              )}
            </Form.Item>
            <Form.Item label={intl.formatMessage({ id: 'OrganizationUserEditForm.jobPosition' })} style={{ margin: 0 }}>
              {form.getFieldDecorator('workingPositionId', { initialValue: toEditUser.workingPosition?.id })(
                <Select
                  showSearch
                  allowClear
                  filterOption={(inputValue, option) => smartFilter(inputValue, option?.label)}
                  options={jobPositions.map((position) => ({
                    label: position.name,
                    value: position.id,
                    key: position.id,
                  }))}
                />
              )}
            </Form.Item>
          </Form>
        }
      </>
    );
  }
);

export default Form.create<Props>()(OrganizationUserEditFormComponent);
