import { Alert } from 'antd';
import { projectApi } from 'api/completeApi';
import {
  ProjectMetadataDefinitionCreateDto,
  ProjectMetadataDefinitionMetadataEntityType,
  ProjectMetadataDefinitionPatchDto,
} from 'api/completeApiInterfaces';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import StackPanel from 'components/StackPanel';
import { useApiData, useCurrentProjectUser } from 'hooks';
import { Fmt, InjectedIntlProps } from 'locale';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import { messageError } from 'utils';
import MetadataList from './MetadataList';

type Props = InjectedIntlProps & { entityType: ProjectMetadataDefinitionMetadataEntityType };

const CommonMetadata: FunctionComponent<Props> = ({ intl, entityType }) => {
  const [projectMetadata, metadataError, metadataLoading, loadProjectMetadata, setProjectMetadata] = useApiData(
    (ct) => projectApi.metadata.definitions.get(ct),
    { autoload: true }
  );

  const { url } = useRouteMatch();

  const currentUser = useCurrentProjectUser();

  const metadataDefinitions = useMemo(() => {
    return projectMetadata?.definitions.filter((definition) => definition.type === entityType) || [];
  }, [projectMetadata, entityType]);

  const handleMetaDefinitionDelete = useCallback(
    async (definitionId: Guid) => {
      const [err] = await projectApi.metadata.definitions.id.delete(definitionId);
      if (!!err) {
        messageError(err, intl);
      } else {
        loadProjectMetadata();
      }
    },
    [loadProjectMetadata]
  );

  const handleMetaDefinitionPatch = useCallback(
    async (definitionId: Guid, data: ProjectMetadataDefinitionPatchDto) => {
      const [err, resp] = await projectApi.metadata.definitions.id.patch(definitionId, data);
      if (!!err) {
        messageError(err, intl);
      } else {
        setProjectMetadata(resp.data);
      }
    },
    [intl, setProjectMetadata]
  );

  const handleMetaDefinitionAdd = useCallback(
    async (data: ProjectMetadataDefinitionCreateDto) => {
      const [err, resp] = await projectApi.metadata.definitions.post(data);

      if (!!err) {
        messageError(err, intl);
      } else {
        setProjectMetadata(resp.data);
      }
    },
    [intl, setProjectMetadata]
  );

  return (
    <MasterComponent
      url={url}
      title={intl.formatMessage({
        id:
          entityType === ProjectMetadataDefinitionMetadataEntityType.directory
            ? 'general.folders'
            : 'general.documents',
      })}
      children={() =>
        !currentUser.isAdmin ? (
          <StackPanel vertical scrollable>
            <Alert type="error" message={<Fmt id="general.insufficientPermission" />} />
          </StackPanel>
        ) : (
          <MetadataList
            entityType={entityType}
            metadataDefinitions={metadataDefinitions}
            metadataLoading={metadataLoading}
            metadataError={metadataError}
            onAdd={handleMetaDefinitionAdd}
            onDelete={handleMetaDefinitionDelete}
            onPatch={handleMetaDefinitionPatch}
          />
        )
      }
    />
  );
};

export default injectIntl(CommonMetadata);
