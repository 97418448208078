import { MdProjectDto, MdProjectPhaseEnum, MdProjectVariableEnum } from 'api/completeApiInterfaces';
import React from 'react';
import MDProjectCardVariableFields from './MDProjectCardVariableFields';

type Props = {
  mdProject: MdProjectDto;
  canEditAsProposer: boolean;
  canEditAsGuarantor: boolean;
  canEditAsCKOrganizer: boolean;
};

const mappedPhaseCommissionItems: Record<MdProjectPhaseEnum, MdProjectVariableEnum[]> = {
  [MdProjectPhaseEnum.other]: [MdProjectVariableEnum.ckApproved, MdProjectVariableEnum.ckApprovalConditions],
  [MdProjectPhaseEnum.init]: [
    MdProjectVariableEnum.ckMeetingBackground,
    MdProjectVariableEnum.ckMeetingMinutes,
    MdProjectVariableEnum.ckApprovalDate,
    MdProjectVariableEnum.ckApproved,
    MdProjectVariableEnum.ckApprovalConditions,
  ],
  [MdProjectPhaseEnum.study]: [
    MdProjectVariableEnum.ckMeetingBackground,
    MdProjectVariableEnum.ckMeetingMinutes,
    MdProjectVariableEnum.ckApprovalDate,
    MdProjectVariableEnum.ckApproved,
    MdProjectVariableEnum.ckApprovalConditions,
    MdProjectVariableEnum.isUPDChangeRequest,
  ],
  [MdProjectPhaseEnum.intention]: [
    MdProjectVariableEnum.ckMeetingBackground,
    MdProjectVariableEnum.ckMeetingMinutes,
    MdProjectVariableEnum.ckApprovalDate,
    MdProjectVariableEnum.ckApproved,
    MdProjectVariableEnum.ckApprovalConditions,
  ],
  [MdProjectPhaseEnum.update]: [
    MdProjectVariableEnum.ckMeetingBackground,
    MdProjectVariableEnum.ckMeetingMinutes,
    MdProjectVariableEnum.ckApprovalDate,
    MdProjectVariableEnum.ckApproved,
    MdProjectVariableEnum.ckApprovalConditions,
  ],
};

const MDProjectCardCommission: React.FC<Props> = ({
  mdProject,
  canEditAsProposer,
  canEditAsCKOrganizer,
  canEditAsGuarantor,
}) => {
  if (!mdProject) return null;

  return (
    <MDProjectCardVariableFields
      fields={mappedPhaseCommissionItems}
      mdProject={mdProject}
      canEditAsProposer={canEditAsProposer}
      canEditAsGuarantor={canEditAsGuarantor}
      canEditAsCKOrganizer={canEditAsCKOrganizer}
    />
  );
};

export default MDProjectCardCommission;
