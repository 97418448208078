import { MsgCenterSummaryDto } from 'api/completeApiInterfaces';
import { SIGNAL_R_REDUX_STORE_CONNECTION } from 'config/signalRConnection';
import { useActiveProject } from 'hooks';
import { useAggregateEvent } from 'hooks/useAggregateEvent';
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';

type Props = {};

const STORE_SIGNAL_DEBOUNCE_MS = 300;
const DIRECTORY_LIST_STORE_SIGNAL_DEBOUNCE_MS = 3000;
const DIRECTORY_LIST_STORE_SIGNAL_MAX_DELAY = 90000; // 1,5 minuty

enum ProjectSettingStoreProperties {
  ExternalApplications = 'external_app',
}

const SignalRStoreMonitor: FunctionComponent<Props> = () => {
  const dispatch = useDispatch<Dispatch>();
  const currentProject = useActiveProject();

  const markLabelsDirty = useCallback(() => dispatch.labels.markDirty(), [dispatch.labels]);
  const LabelsChanged = useAggregateEvent(markLabelsDirty, STORE_SIGNAL_DEBOUNCE_MS, (labelId: Guid) => labelId);

  const markWorkflowsDirty = useCallback(() => dispatch.workflows.markDirty(), [dispatch.workflows]);
  const WorkflowListChanged = useAggregateEvent(
    markWorkflowsDirty,
    STORE_SIGNAL_DEBOUNCE_MS,
    (workflowId: Guid) => workflowId
  );

  const markGroupsDirty = useCallback(() => dispatch.groups.markDirty(), [dispatch.groups]);
  const GroupsChanged = useAggregateEvent(markGroupsDirty, STORE_SIGNAL_DEBOUNCE_MS, (groupId: Guid) => groupId);

  const markRolesDirty = useCallback(() => dispatch.roles.markDirty(), [dispatch.roles]);
  const RolesChanged = useAggregateEvent(markRolesDirty, STORE_SIGNAL_DEBOUNCE_MS, (roleId: Guid) => roleId);

  const markCategoriesDirty = useCallback(() => dispatch.categories.markDirty(), [dispatch.categories]);
  const CategoryTreesChanged = useAggregateEvent(
    markCategoriesDirty,
    STORE_SIGNAL_DEBOUNCE_MS,
    (categoryId: Guid, changedById: Guid, categoryNodeId: Guid) => categoryNodeId
  );

  const markCategoryNodesDirty = useCallback(
    (categoryTreeIds: Guid[]) => {
      categoryTreeIds.forEach((categoryTreeId) => {
        void dispatch.categoryTrees.loadCategoryTree({ reload: true, categoryId: categoryTreeId });
      });
    },
    [dispatch.categoryTrees]
  );
  const CategoryNodesChanged = useAggregateEvent(
    markCategoryNodesDirty,
    STORE_SIGNAL_DEBOUNCE_MS,
    (categoryNodeId: Guid, categoryTreeId: Guid) => categoryTreeId
  );

  const markDirectoriesChange = useCallback(() => dispatch.directories.markDirty(), [dispatch.directories]);
  const DirectoriesChanged = useAggregateEvent(
    markDirectoriesChange,
    DIRECTORY_LIST_STORE_SIGNAL_DEBOUNCE_MS,
    (directoryId: Guid) => directoryId,
    undefined,
    DIRECTORY_LIST_STORE_SIGNAL_MAX_DELAY
  );

  const markDirectoriesWithLinksDirty = useCallback(() => dispatch.directoriesWithLinks.markDirty(), [
    dispatch.directoriesWithLinks,
  ]);

  const DirectoriesWithLinksChanged = useAggregateEvent(
    markDirectoriesWithLinksDirty,
    DIRECTORY_LIST_STORE_SIGNAL_DEBOUNCE_MS,
    (directoryId: Guid, createdById: Guid, messageType: number) => directoryId,
    undefined,
    DIRECTORY_LIST_STORE_SIGNAL_MAX_DELAY
  );

  const markProjectUsersDirty = useCallback(() => dispatch.projectUsers.markDirty(), [dispatch.projectUsers]);
  const ProjectUsersChanged = useAggregateEvent(
    markProjectUsersDirty,
    STORE_SIGNAL_DEBOUNCE_MS,
    (userId: Guid) => userId
  );

  const markCurrentProjectUserDirty = useCallback(
    (projectId: Guid[]) => (projectId.includes(currentProject?.id) ? dispatch.currentProjectUser.markDirty() : null),
    [currentProject?.id, dispatch.currentProjectUser]
  );
  const CurrentProjectUserChanged = useAggregateEvent(
    markCurrentProjectUserDirty,
    STORE_SIGNAL_DEBOUNCE_MS,
    (userId: Guid, projectId: Guid) => projectId
  );

  const markProjectListDirty = useCallback(() => dispatch.allProjects.markDirty(), [dispatch.allProjects]);
  const ProjectsChanged = useAggregateEvent(
    markProjectListDirty,
    STORE_SIGNAL_DEBOUNCE_MS,
    (projectId: Guid) => projectId
  );

  const markCurrentAppUserDirty = useCallback(() => dispatch.currentAppUser.loadData({ reload: true, silent: true }), [
    dispatch.currentAppUser,
  ]);
  const AppUserChanged = useAggregateEvent(
    markCurrentAppUserDirty,
    STORE_SIGNAL_DEBOUNCE_MS,
    (userId: Guid, entityType: number, entityId: Guid) => entityId
  );

  const markUserSummaryDirty = useCallback(
    (summaries: MsgCenterSummaryDto[]) => dispatch.userSummary.setData(summaries[summaries.length - 1]),
    [dispatch.userSummary]
  );
  const MessageCentrumStatisticsChanged = useAggregateEvent(
    markUserSummaryDirty,
    STORE_SIGNAL_DEBOUNCE_MS,
    (userId: Guid, summary: MsgCenterSummaryDto) => summary
  );

  const markProjectDirty = useCallback((projectIds: Guid[]) => dispatch.activeProject.reloadProjects(projectIds), [
    dispatch.activeProject,
  ]);
  const ProjectChanged = useAggregateEvent(markProjectDirty, STORE_SIGNAL_DEBOUNCE_MS, (userId: Guid) => userId);

  const markExternalApplicationsDirty = useCallback(
    (settingName: string[]) =>
      settingName.includes(ProjectSettingStoreProperties.ExternalApplications)
        ? dispatch.externalApplicationsSettings.markDirty()
        : null,
    [dispatch.externalApplicationsSettings]
  );

  const ProjectSettingsChanged = useAggregateEvent(
    markExternalApplicationsDirty,
    STORE_SIGNAL_DEBOUNCE_MS,
    (settingName: string) => settingName
  );

  const markCalendarDirty = useCallback(() => dispatch.activeCalendar.markDirty(), [dispatch.activeCalendar]);
  const CalendarChanged = useAggregateEvent(
    markCalendarDirty,
    STORE_SIGNAL_DEBOUNCE_MS,
    (calendarId: Guid) => calendarId
  );

  const markProjectDirectoryMasksDirty = useCallback(() => dispatch.projectDirectoryMasks.markDirty(), [
    dispatch.projectDirectoryMasks,
  ]);
  const ProjectDirectoryMasksChanged = useAggregateEvent(
    markProjectDirectoryMasksDirty,
    STORE_SIGNAL_DEBOUNCE_MS,
    (directoryId: Guid) => directoryId
  );

  useEffect(() => {
    const events = Object.entries({
      LabelsChanged,
      WorkflowListChanged,
      GroupsChanged,
      RolesChanged,
      CategoryNodesChanged,
      DirectoriesChanged,
      DirectoriesWithLinksChanged,
      ProjectUsersChanged,
      CurrentProjectUserChanged,
      ProjectsChanged,
      AppUserChanged,
      MessageCentrumStatisticsChanged,
      ProjectChanged,
      ProjectSettingsChanged,
      CalendarChanged,
      CategoryTreesChanged,
      ProjectDirectoryMasksChanged,
    });

    events.forEach(([key, value]) => {
      SIGNAL_R_REDUX_STORE_CONNECTION.on(key, value);
    });

    return () => {
      events.forEach(([key, value]) => {
        SIGNAL_R_REDUX_STORE_CONNECTION.off(key, value);
      });
    };
  }, [
    CategoryNodesChanged,
    CurrentProjectUserChanged,
    DirectoriesChanged,
    DirectoriesWithLinksChanged,
    GroupsChanged,
    LabelsChanged,
    ProjectUsersChanged,
    RolesChanged,
    WorkflowListChanged,
    ProjectsChanged,
    AppUserChanged,
    MessageCentrumStatisticsChanged,
    ProjectChanged,
    ProjectSettingsChanged,
    CalendarChanged,
    CategoryTreesChanged,
    ProjectDirectoryMasksChanged,
  ]);

  return <></>;
};

export default SignalRStoreMonitor;
