import { Typography } from 'antd';
import React from 'react';
import styles from './CategoryListItem.module.less';

type CategoryFilterUsedItemProps = {
  button?: React.ReactNode;
  categoryName?: string;
  nodeName?: string;
};

export const CategoryListItem = ({ button, categoryName, nodeName }: CategoryFilterUsedItemProps) => {
  return (
    <div className={styles.categoryFilterUsedItem}>
      <div className={styles.categoryItem}>
        <Typography.Text ellipsis={{ tooltip: categoryName }}>{categoryName}</Typography.Text>
        {button}
      </div>
      <div className={styles.nodeItem}>
        <Typography.Text ellipsis={{ tooltip: nodeName }}>{nodeName}</Typography.Text>
      </div>
    </div>
  );
};
