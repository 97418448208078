import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Input, InputRef, Select } from 'antd';
import { OrgExtendedPermissionEnum, OrgExtendedPermissionValueEnum, OrganizationDto } from 'api/completeApiInterfaces';
import StackPanel from 'components/StackPanel';
import { Serializable } from 'components/filters/filterTypes';
import { MAX_ITEM_NAME_LENGTH } from 'config/constants';
import { useCurrentAppUser } from 'hooks';
import { RequestCacheApiCallback } from 'hooks/useCachedApiRequest';
import { Fmt, InjectedIntlProps } from 'locale';
import { isEqual, uniqBy } from 'lodash';
import { ProjectsInRealisationOverviewReportData } from 'pages/ReportDetailPage/ReportDetails/ProjectsInRealisationOverviewReport/ProjectsInRealizationOverviewReportUtils';
import React, { useEffect, useMemo, useState } from 'react';
import { maxLengthRule, requiredRule, simpleSelectFilter } from 'utils/formHelpersCompatibility';
import ProjectsInRealizationConfigurationForm from './ReportWidgetConfigurations/ProjectsInRealizationConfigurationForm';
import { ProjectsInRealizationReportConfigurationColumnFormItem } from './ReportWidgetConfigurations/ProjectsInRealizationReportConfigurationColumnFormItem';
import { ProjectsInRealizationFiltersFormItem } from './ReportWidgetFilterInputs/ProjectsInRealizationFilterFormItem';
import styles from './ReportWidgetForm.module.less';
import {
  ReportWidgetColumnConfiguration,
  ReportWidgetDecimalUnitEnum,
  ReportWidgetLabelAlignEnum,
  getDecimalUnitOptions,
  getLabelAlignOptions,
  supportedReportWidgets,
} from './ReportWidgetForm.utils';

export type ReportWidgetCreateFormData = {
  organizationId: Guid;
  name: string;
  viewDecimalUnit: ReportWidgetDecimalUnitEnum;
  filters: Record<string, Serializable>;
  labelAlign: ReportWidgetLabelAlignEnum;
} & {
  reportType: OrgExtendedPermissionEnum.projectsInRealisationOverview;
  esticonUnit: string;
  reportDate: string;
  startYear: number;
  columnConfigurations: ReportWidgetColumnConfiguration<ProjectsInRealisationOverviewReportData>[];
};

type Props = FormComponentProps<ReportWidgetCreateFormData> &
  InjectedIntlProps & {
    organizations: OrganizationDto[];
    defaults: ReportWidgetCreateFormData;
    setRef: (ref: InputRef) => void;
    requestCacheApiData: RequestCacheApiCallback;
  };

const ReportWidgetCreateForm = React.forwardRef<unknown, Props>(
  ({ intl, form, organizations, defaults, requestCacheApiData, setRef }, ref) => {
    // backward compatibility with class components
    useEffect(() => {
      (ref as any).current = { props: { form } };
    }, [form]);
    const currentAppUser = useCurrentAppUser();
    const [formValues, setFormValues] = useState<Record<string, any>>();

    const { getFieldDecorator } = form;

    useEffect(() => {
      const currentFormValues = form.getFieldsValue();
      if (isEqual(formValues, currentFormValues)) {
        return;
      }
      setFormValues(currentFormValues);
    }, [form]);

    const decimalUnitOptions = getDecimalUnitOptions(intl);
    const labelAlignOptions = getLabelAlignOptions(intl);

    const selectedOrganizationId = useMemo(
      (): Guid => form.getFieldValue('organizationId') || (organizations?.length && organizations[0].id),
      [organizations, form]
    );
    const selectedReportType = useMemo((): OrgExtendedPermissionEnum => form.getFieldValue('reportType'), [form]);
    const orgUserPermissions = useMemo(
      () =>
        currentAppUser.organizationUsers.find((user) => user.organization.id === selectedOrganizationId)
          ?.extendedPermissions || [],
      [currentAppUser, selectedOrganizationId]
    );

    useEffect(() => {
      if (
        !currentAppUser.organizationUsers
          .find((user) => user.organization.id === selectedOrganizationId)
          ?.extendedPermissions.some((permission) => permission.permissionType === selectedReportType)
      ) {
        form.setFieldsValue({ reportType: defaults?.reportType });
      }
    }, [selectedOrganizationId]);

    const reportTypeOptions = useMemo(
      () =>
        uniqBy(
          orgUserPermissions.filter(
            (permission) =>
              permission.permission !== OrgExtendedPermissionValueEnum.none &&
              supportedReportWidgets.some((supportedType) => supportedType === permission.permissionType)
          ),
          (permission) => permission.permissionType
        ).map((permission) => (
          <Select.Option key={permission.permissionType} value={permission.permissionType}>
            <Fmt id={`Reporting.reportType.name.${permission.permissionType}`} />
          </Select.Option>
        )),
      [orgUserPermissions]
    );

    return (
      <Form layout="vertical" className={styles.formWrapper}>
        <StackPanel vertical className={styles.generalConfig}>
          <Form.Item label={intl.formatMessage({ id: 'forms.items.name.label' })}>
            {getFieldDecorator<ReportWidgetCreateFormData>('name', {
              rules: [
                requiredRule('ProjectCreateForm.form.items.name.rules.required', true),
                maxLengthRule('general.maxNameLength', MAX_ITEM_NAME_LENGTH),
              ],
              initialValue: defaults?.name,
            })(
              <Input
                placeholder={intl.formatMessage({ id: 'ProjectCreateForm.form.items.name.placeholder' })}
                autoFocus
                ref={setRef}
              />
            )}
          </Form.Item>
          <Form.Item label={intl.formatMessage({ id: 'general.organizations' })}>
            {form.getFieldDecorator<ReportWidgetCreateFormData>('organizationId', {
              initialValue: defaults?.organizationId || (organizations?.length && organizations[0].id),
              rules: [requiredRule('ReportWidgetCreateFormModalForm.organization.required', true)],
            })(
              <Select showSearch allowClear={false} filterOption={simpleSelectFilter}>
                {organizations?.map((organization) => (
                  <Select.Option key={organization.id} value={organization.id}>
                    {organization.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label={intl.formatMessage({ id: 'ReportWidgetCreateFormModalForm.unit' })}>
            {form.getFieldDecorator<ReportWidgetCreateFormData>('viewDecimalUnit', {
              initialValue: defaults?.viewDecimalUnit,
              rules: [requiredRule('ReportWidgetCreateFormModalForm.unit.required', true)],
            })(<Select options={decimalUnitOptions} />)}
          </Form.Item>
          <Form.Item label={intl.formatMessage({ id: 'ReportWidgetCreateFormModalForm.alignLabel' })}>
            {form.getFieldDecorator<ReportWidgetCreateFormData>('labelAlign', {
              initialValue: defaults?.labelAlign,
              rules: [requiredRule('ReportWidgetCreateFormModalForm.alignLabel.required', true)],
            })(<Select options={labelAlignOptions} />)}
          </Form.Item>

          {selectedOrganizationId && (
            <Form.Item label={intl.formatMessage({ id: 'ReportWidgetCreateFormModalForm.columnConfigurations' })}>
              {form.getFieldDecorator<ReportWidgetCreateFormData>('reportType', {
                rules: [requiredRule('ReportWidgetCreateFormModalForm.reportType.required', true)],
                initialValue: defaults?.reportType,
              })(
                <Select showSearch allowClear={false} filterOption={simpleSelectFilter}>
                  {reportTypeOptions}
                </Select>
              )}
            </Form.Item>
          )}
          {selectedReportType === OrgExtendedPermissionEnum.projectsInRealisationOverview && (
            <>
              <ProjectsInRealizationConfigurationForm
                intl={intl}
                organizationId={selectedOrganizationId}
                form={form}
                defaultValues={defaults}
              />
            </>
          )}
        </StackPanel>
        <StackPanel vertical>
          {selectedReportType === OrgExtendedPermissionEnum.projectsInRealisationOverview && (
            <>
              <Form.Item label={<Fmt id="ReportWidgetCreateFormModalForm.filters" />}>
                {form.getFieldDecorator<ReportWidgetCreateFormData>('filters', {
                  initialValue: defaults?.filters || {},
                })(
                  <ProjectsInRealizationFiltersFormItem
                    requestCacheApiData={requestCacheApiData}
                    esticonUnit={form.getFieldValue('esticonUnit')}
                    reportDate={form.getFieldValue('reportDate')}
                    startYear={form.getFieldValue('startYear')}
                    organizationId={form.getFieldValue('organizationId')}
                  />
                )}
              </Form.Item>
              <Form.Item label={<Fmt id="ReportWidgetCreateFormModalForm.columnConfigurations" />}>
                {form.getFieldDecorator<ReportWidgetCreateFormData>('columnConfigurations', {
                  rules: [requiredRule('ReportWidgetCreateFormModalForm.columnConfigurations.required', false)],
                  initialValue: defaults?.columnConfigurations || [],
                })(<ProjectsInRealizationReportConfigurationColumnFormItem intl={intl} columnCountLimit={6} />)}
              </Form.Item>
            </>
          )}
        </StackPanel>
      </Form>
    );
  }
);

export default Form.create<Props>()(ReportWidgetCreateForm);
