import { masterApi } from 'api/completeApi';
import { MdRoleEnum } from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import List from 'components/List';
import { ListEmpty } from 'components/ListEmpty/ListEmpty';
import { MasterComponent } from 'components/MasterDetailsView/MasterDetailsView';
import StackPanel from 'components/StackPanel';
import { useApiData, useIntl } from 'hooks';
import { InjectedIntlProps } from 'locale';
import Panel from 'pages/ProjectSettingsPage/Panel/Panel';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { smartFilter } from 'utils';
import MDRoleDetailPanel from './MDRoleDetailPanel';

type Props = InjectedIntlProps & {
  organizationId: Guid;
};

const MDRolesListPanel: FunctionComponent<Props> = ({ organizationId }) => {
  const [search, setSearch] = useState<string>('');
  const { url } = useRouteMatch();
  const intl = useIntl();

  const [divisions, divisionsError, divisionsLoading] = useApiData(
    (ct) => masterApi.projects.md.divisions.id.get(organizationId, ct),
    { autoload: true }
  );

  const [roles, rolesError, rolesLoading, loadRoles, setRoles] = useApiData(
    (ct) => masterApi.projects.md.roles.id.get(organizationId, ct),
    { autoload: true }
  );

  const roleList = useMemo(() => Object.values(MdRoleEnum), []);

  const clearSearch = useCallback(() => {
    setSearch('');
  }, []);

  return (
    <>
      <MasterComponent
        url={url}
        title={intl.formatMessage({ id: 'MD.Organization.roles.title' })}
        children={(onSelect, selectedRoleName) => (
          <StackPanel>
            <Panel noMargin onSearch={setSearch} searchValue={search}>
              <ContentGate loading={divisionsLoading || rolesLoading} empty={!divisions?.length} error={divisionsError}>
                <GeneralSettingsContainer>
                  <List<MdRoleEnum>
                    data={roleList}
                    search={search}
                    filterItem={(item) => item && smartFilter(item, search)}
                    renderItem={(item) => (
                      <GeneralSettingsItem
                        key={item}
                        title={intl.formatMessage({ id: `MD.Roles.name.${item}` })}
                        wrap
                        selected={item === selectedRoleName}
                        selectable
                        onClick={onSelect ? () => onSelect(item) : null}
                      />
                    )}
                    renderEmpty={(total, filtered) => (
                      <ListEmpty filtered={filtered} total={total} onClearSearch={clearSearch} />
                    )}
                  />
                </GeneralSettingsContainer>
              </ContentGate>
            </Panel>
          </StackPanel>
        )}
      />
      <Switch>
        <Route
          path={`${url}/:roleName`}
          render={() => <MDRoleDetailPanel roles={roles} divisions={divisions} setRoles={setRoles} intl={intl} />}
        />
      </Switch>
    </>
  );
};

export default React.memo(MDRolesListPanel);
