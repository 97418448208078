import React, { ReactNode } from 'react';
import { CheckFormat } from 'utils';

export type Serializable = boolean | number | string | Serializable[] | { [key: string]: Serializable };

export type CommonFilter<S extends Serializable = Serializable> = {
  key: string; // must be unique among all filters that are used together
  render: (value: S, setValue: React.Dispatch<React.SetStateAction<S>>) => ReactNode;
  isEmpty: (value: S) => boolean;
  defaultValue: S;
  clearedValue: React.SetStateAction<S>;
  checkFormat: CheckFormat<S>; // if format changes, persistently saved value might have the old format and should be discarded
  icon?: ReactNode;
  label?: string;
};

export type FrontendFilter<T, S extends Serializable = Serializable> = CommonFilter<S> & {
  filter: (value: S) => (item: T) => boolean;
};

export type BackendFilter<T, S extends Serializable = Serializable> = CommonFilter<S> & {
  serialize: (accumulator: T, value: S) => T;
};

export type FilterWithValue<S extends Serializable = Serializable, F extends CommonFilter<S> = CommonFilter<S>> = F & {
  value: S | null;
};

export type CommonFilterWithValue<S extends Serializable = Serializable> = FilterWithValue<S, CommonFilter<S>>;

export enum FiltersPersistentKey {
  Assignments = 'Assignments',
  CommentProcedures = 'CommentProcedures',
  CommentProceduresComments = 'CommentProceduresComments',
  CommentProceduresCommentsDocuments = 'CommentProceduresCommentsDocuments',
  CommentProceduresCommentsDocumentsAdd = 'CommentProceduresCommentsDocumentsAdd',
  CommentProceduresDocuments = 'CommentProceduresDocuments',
  CommentProceduresNewDocuments = 'CommentProceduresNewDocuments',
  DocumentsAll = 'DocumentsAll',
  DocumentsComments = 'DocumentsComments',
  DocumentsDiscarded = 'DocumentsDiscarded',
  DocumentsFavorite = 'DocumentsFavorite',
  DocumentsFilter = 'DocumentsFilter',
  DocumentsLinks = 'DocumentsLinks',
  DocumentsModel = 'DocumentsModel',
  DocumentsWorkflows = 'DocumentsWorkflows',
  DocumentDetailBindings = 'DocumentDetailBindings',
  MessageCenterMessages = 'MessageCenterMessages',
  MessageCenterSettings = 'MessageCenterSettings',
  ProjectsAll = 'ProjectsAll',
  WorkflowApprovalDocuments = 'WorkflowApprovalDocuments',
  WorkflowBackgroundDocuments = 'WorkflowBackgroundDocuments',
  WorkflowInstances = 'WorkflowInstances',
  WorkflowTemplates = 'WorkflowTemplates',
  UserActivitySessionLog = 'UserActivitySessionLog',
  ModelDocuments = 'ModelDocuments',
  ModelBindings = 'ModelBindings',
  References = 'References',
  DocumentMetadata = 'DocumentMetadata',
  DirectoryMetadata = 'DirectoryMetadata',
}
