import { Form, Modal, ModalProps, Select, TreeSelect } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { DefaultOptionType } from 'antd/lib/select';
import { createCancelToken } from 'api';
import { masterApi } from 'api/completeApi';
import {
  ProjectTemplateWfTmpAddRequestDto,
  ProjectTemplateWfTmpCheckRequestDto,
  ProjectTemplateWfTmpCheckResponseDto,
  ProjectTemplateWfTmpListDto,
} from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { useIntl, useSameCallback } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { messageError } from 'utils';
import { textComparer } from 'utils/comparators';
import { ProjectTemplateData } from '../../ProjectTemplateBuilder';
import { TemplateDirectoryNode } from '../Directories/ProjectTemplateDirectoriesTab';

type Props = {
  projectTemplate: ProjectTemplateData;
  workflowTemplateList: ProjectTemplateWfTmpListDto[];
  onSubmit: () => void;
} & Omit<ModalProps, 'title' | 'onOk'>;

type ProjectTemplateWorkflowFormData = {
  workflowTemplateId: string;
  role: Record<Guid, Guid>;
  user: Record<Guid, Guid>;
  directory: Record<Guid, Guid>;
};

const getTreeNodeData = (directory: TemplateDirectoryNode): DefaultOptionType => {
  return {
    label: directory.name,
    value: directory.id,
    children: [...directory.children]
      .sort(textComparer.map((child) => child.name))
      .map((child) => getTreeNodeData(child)),
  };
};

const ProjectTemplateWorkflowTemplateAddFormModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, projectTemplate, workflowTemplateList, ...restProps } = props;
  const [form] = useForm<ProjectTemplateWorkflowFormData>();
  const [selectedWfTemplate, setSelectedWfTemplate] = useState<Guid>(null);
  const [checkLoading, setCheckLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [checkResult, setCheckResult] = useState<ProjectTemplateWfTmpCheckResponseDto>();
  const intl = useIntl();

  const handleSubmit = useSameCallback(async (values: ProjectTemplateWorkflowFormData) => {
    setSaving(true);
    const wfAddDto: ProjectTemplateWfTmpAddRequestDto = {
      projectTemplateId: projectTemplate.id,
      wfTmpId: values.workflowTemplateId,
      wfTmpNewName: checkResult.origName,
      projectTemplateWfTmpRoles: Object.entries(values.role || {}).map(([origRoleId, roleId]) => ({
        origRoleId,
        roleId,
      })),
      projectTemplateWfTmpUsers: Object.entries(values.user || {}).map(([origUserId, userId]) => ({
        origUserId,
        userId,
      })),
      workFlowTemplateImportBackgroundDir: Object.entries(values.directory || {}).map(([origAliasId, dirId]) => ({
        origAliasId,
        dirId,
      })),
    };

    const [err, res] = await masterApi.projects.tempates.projecttemplate.wftempate.add.post(wfAddDto);
    if (err) {
      void messageError(err, intl);
      setSaving(false);
      return null;
    }

    setSaving(false);
    form.resetFields();
    onSubmit();
    return null;
  });

  const handleOkButton = () => {
    form.submit();
  };

  const handleValuesChange = async (changedValues: Partial<ProjectTemplateWorkflowFormData>) => {
    if (changedValues.workflowTemplateId) {
      setSelectedWfTemplate(changedValues.workflowTemplateId);
    }
  };

  useEffect(() => {
    if (!projectTemplate || !selectedWfTemplate) {
      return () => {};
    }

    const cancelToken = createCancelToken();
    const checkSelectedWorkflowTemplate = async () => {
      setCheckLoading(true);
      const checkDto: ProjectTemplateWfTmpCheckRequestDto = {
        projectTemplateId: projectTemplate.id,
        wfTmpId: selectedWfTemplate,
      };
      const [err, res] = await masterApi.projects.tempates.projecttemplate.wftempate.check.post(
        checkDto,
        cancelToken.token
      );
      if (err) {
        void messageError(err, intl);
      } else {
        setCheckResult(res.data);
      }
      setCheckLoading(false);
    };

    void checkSelectedWorkflowTemplate();

    return () => {
      cancelToken.cancel();
    };
  }, [selectedWfTemplate]);

  const directoryMap = useMemo(() => {
    const directoriesById: Record<Guid, TemplateDirectoryNode> = {};
    projectTemplate.projectTemplateDirectories.forEach((directory) => {
      directoriesById[directory.id] = { ...directory, children: [] };
    });

    Object.values(directoriesById).forEach((directory) => {
      if (!!directory.parentId && !!directoriesById[directory.parentId]) {
        directoriesById[directory.parentId].children.push(directory);
      }
    });

    return directoriesById;
  }, [projectTemplate.projectTemplateDirectories]);

  const directoryTreeRoot = useMemo(
    () => !!directoryMap && Object.values(directoryMap).find((directory) => !directory.parentId),
    [directoryMap]
  );

  const directoryTreeNodes = useMemo((): DefaultOptionType[] => {
    return [getTreeNodeData(directoryTreeRoot)];
  }, [directoryTreeRoot]);

  return (
    <Modal
      title={<Fmt id="ProjectTemplateWorkflowTemplateAddFormModal.title" />}
      okText={<Fmt id="general.add" />}
      onOk={handleOkButton}
      okButtonProps={{ loading: checkLoading || saving }}
      width={700}
      {...restProps}
    >
      <Form form={form} onFinish={handleSubmit} onValuesChange={handleValuesChange}>
        <Form.Item
          name="workflowTemplateId"
          label={<Fmt id="ProjectTemplateWorkflowTemplateAddFormModal.workflowTemplate" />}
          rules={[{ required: true }]}
        >
          <Select>
            {workflowTemplateList.map((wf) => (
              <Select.Option key={wf.id} value={wf.id}>
                {wf.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <ContentGate loading={checkLoading || (!checkResult && !!selectedWfTemplate)}>
          {checkResult?.roles.length > 0 &&
            checkResult.roles.map((role) => (
              <Form.Item
                label={<Fmt id="ProjectTemplateWorkflowTemplateAddFormModal.roles" values={{ name: role.origName }} />}
                key={role.origId}
                name={['role', role.origId]}
                initialValue={role.foundRole?.id}
              >
                <Select>
                  {projectTemplate.projectTemplateRoles.map((role) => (
                    <Select.Option key={role.id} value={role.id}>
                      {role.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ))}
          {checkResult?.users.length > 0 &&
            checkResult.users.map((user) => (
              <Form.Item
                label={<Fmt id="ProjectTemplateWorkflowTemplateAddFormModal.users" values={{ name: user.origName }} />}
                key={user.origId}
                name={['user', user.origId]}
                initialValue={user.foundUser?.id}
              >
                <Select>
                  {projectTemplate.projectTemplateUsers.map((user) => (
                    <Select.Option key={user.id} value={user.id}>
                      {user.appUserOrganization.appUserProfile.username}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ))}
          {checkResult?.dirs.length > 0 &&
            checkResult.dirs.map((directory) => (
              <Form.Item
                label={
                  <Fmt
                    id="ProjectTemplateWorkflowTemplateAddFormModal.directory"
                    values={{
                      name: directory.origAliasName,
                      empty: directory.isDirEmpty
                        ? intl.formatMessage({
                            id: 'ProjectTemplateWorkflowTemplateAddFormModal.directory.empty',
                          })
                        : '',
                    }}
                  />
                }
                name={['directory', directory.origAliasId]}
                key={directory.origAliasId}
              >
                <TreeSelect treeData={directoryTreeNodes} treeDefaultExpandAll>
                  {projectTemplate.projectTemplateUsers.map((user) => (
                    <Select.Option key={user.id} value={user.id}>
                      {user.appUserOrganization.appUserProfile.username}
                    </Select.Option>
                  ))}
                </TreeSelect>
              </Form.Item>
            ))}
        </ContentGate>
      </Form>
    </Modal>
  );
};

export default ProjectTemplateWorkflowTemplateAddFormModal;
