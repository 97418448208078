import { ButtonsSelectOption } from 'components/ButtonsSelect/ButtonsSelect';
import { InjectedIntl } from 'locale';
import { useMemo } from 'react';

export type MdDisplayMode = 'all' | 'justMine';

export const useMDProjectListDisplayMode = (intl: InjectedIntl) => {
  const options: ButtonsSelectOption<MdDisplayMode>[] = useMemo(() => {
    return [
      {
        label: intl.formatMessage({ id: 'MDApprovedProjectsReport.displayMode.all' }),
        key: 'all',
      },
      {
        label: intl.formatMessage({ id: 'MDApprovedProjectsReport.displayMode.justMine' }),
        key: 'justMine',
      },
    ];
  }, [intl]);

  return options;
};
