import { Alert } from 'antd';
import { FavoriteFilterButton } from 'components/FavoriteButton';
import { FilterInput } from 'components/FilterInput/FilterInput';
import { FlowLayout } from 'components/layouts/FlowLayout';
import StackPanel from 'components/StackPanel';
import { useHeight } from 'hooks/useHeight';
import { Fmt } from 'locale';
import { deburr } from 'lodash';
import React, { FunctionComponent, ReactNode, useCallback, useMemo } from 'react';
import { searchRgx } from 'utils';
import { DirectoryNode, directoryNodeHelpers } from 'utils/typeMappings/directories/directoryTypes';
import { DirectoriesTree, DirectoriesTreeProps } from './DirectoriesTree';

type Props = Omit<DirectoriesTreeProps, 'isDirectoryVisible'> & {
  showFavorites: boolean;
  setShowFavorites: (value: boolean) => void;
  searchPhrase: string;
  setSearchPhrase: (value: string) => void;
};

const WRAPPER_STYLE = {
  flex: '1',
  minWidth: 0,
  overflow: 'hidden',
};

export const DirectoriesFilteringTree: FunctionComponent<Props> = ({
  showFavorites,
  setShowFavorites,
  searchPhrase,
  setSearchPhrase,
  nameRenderer,
  contextMenuItems,
  ...restProps
}) => {
  const searchRegex = useMemo(() => searchPhrase && searchRgx(searchPhrase), [searchPhrase]);

  // TODO: type is tested 3 times. Make more efficient?
  const isDirectoryVisible = useCallback(
    (directoryNode: DirectoryNode) => {
      const targetDirectory = directoryNodeHelpers.getTargetDirectory(directoryNode);
      if (showFavorites && !targetDirectory.isFavorite) {
        return false;
      }
      const nodeName = directoryNodeHelpers.getDirectoryNodeName(directoryNode);
      return !(searchRegex && deburr(nodeName).search(searchRegex) < 0);
    },
    [showFavorites, searchRegex]
  );

  const renderName = useCallback(
    (directory: DirectoryNode, name: ReactNode) => {
      const parentRenderer = nameRenderer || ((_, name) => name);
      const displayedName = directoryNodeHelpers.getDirectoryNodeName(directory);

      const match = searchRegex && deburr(displayedName).match(searchRegex);
      if (match) {
        const beforeMatch = displayedName.slice(0, match.index);
        const matchedText = match[0];
        const afterMatch = displayedName.slice(match.index + match[0].length);

        name = (
          <>
            {beforeMatch}
            <span style={{ color: '#f50' }}>{matchedText}</span>
            {afterMatch}
          </>
        );
      }

      return parentRenderer(directory, name);
    },
    [searchRegex, nameRenderer]
  );

  const favoriteInfo = showFavorites && (
    <Alert message={<Fmt id="AllDocumentsPage.favoriteFilterActive" />} banner type="info" />
  );
  const searchInfo = searchPhrase && (
    <Alert
      message={<Fmt id={'AllDocumentsPage.searchFilterActive'} values={{ text: searchPhrase }} />}
      banner
      type="warning"
    />
  );

  const { tableWrapRef, wrapHeight } = useHeight();

  return (
    <StackPanel vertical>
      <FlowLayout growLast gapSize="small" style={{ padding: '0.25rem' }}>
        <FavoriteFilterButton selected={showFavorites} onChange={() => setShowFavorites(!showFavorites)} />
        <FilterInput value={searchPhrase} onSearch={setSearchPhrase} />
      </FlowLayout>
      {favoriteInfo}
      {searchInfo}
      <div style={WRAPPER_STYLE} ref={tableWrapRef}>
        <DirectoriesTree
          isDirectoryVisible={isDirectoryVisible}
          nameRenderer={renderName}
          searchPhrase={searchPhrase}
          height={wrapHeight}
          contextMenuItems={contextMenuItems}
          {...restProps}
        />
      </div>
    </StackPanel>
  );
};
