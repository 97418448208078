import React, { ReactNode } from 'react';
import { Button } from 'antd';
import { Fmt } from 'locale';
import StackPanel from 'components/StackPanel';
import { IntlMessageId } from 'locale/messages/cs';

type Props<T> = {
  total: number;
  filtered: number;
  onClearSearch?: () => void;
  noItemsMessageId?: IntlMessageId;
  noItemsChildren?: ReactNode;
};

export function ListEmpty<T>({
  total,
  filtered,
  onClearSearch,
  noItemsMessageId = 'ListEmpty.noData',
  noItemsChildren,
}: Props<T>) {
  return (
    <StackPanel vertical centerItems>
      {total !== filtered && total !== 0 ? (
        <>
          <Fmt id={'ListEmpty.noItemsFound'} />
          {onClearSearch && (
            <Button type="link" onClick={onClearSearch}>
              <Fmt id={'general.clearFilters'} />
            </Button>
          )}
        </>
      ) : !!noItemsChildren ? (
        noItemsChildren
      ) : (
        <Fmt id={noItemsMessageId} />
      )}
    </StackPanel>
  );
}
