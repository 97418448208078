import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Input, InputRef, Radio, Select } from 'antd';
import { ProjectBaseListDto, ProjectTimezoneListDto } from 'api/completeApiInterfaces';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { OrganizationLabelsInput } from 'components/LabelsInput/OrganizationLabelsInput';
import { MAX_PROJECT_DESCRIPTION_LENGTH, MAX_PROJECT_NAME_LENGTH } from 'config/constants';
import { useSelectorDispatch } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt, InjectedIntlProps } from 'locale';
import { LanguageEnum } from 'locale/messages/interfaces';
import React, { useEffect, useMemo } from 'react';
import { duplicateNameRule, maxLengthRule, simpleSelectFilter } from 'utils/formHelpersCompatibility';

export type ProjectEditFormData = {
  name: string;
  description: string;
  language: LanguageEnum;
  timeZoneId: string;
  labels: Guid[];
};

type Props = FormComponentProps<ProjectEditFormData> &
  InjectedIntlProps & {
    defaults?: {
      language?: LanguageEnum;
    };
    timeZones: ProjectTimezoneListDto;
    projectToEdit: ProjectBaseListDto;
    organizationId: Guid;
    setRef: (ref: InputRef) => void;
  };

const ProjectEditForm = React.forwardRef<unknown, Props>(
  ({ intl, form, timeZones, projectToEdit, organizationId, setRef }, ref) => {
    // backward compatibility with class components
    useEffect(() => {
      (ref as any).current = { props: { form } };
    }, [form]);

    const { getFieldDecorator } = form;

    const projects = useSelectorDispatch(
      (state) => state.allProjects.data,
      (dispatch) => dispatch.allProjects.loadData({ reload: false }),
      []
    );

    useDirtyStoreReload(
      (state) => state.allProjects,
      (dispatch) => dispatch.allProjects
    );

    const usedNames = useMemo(
      () =>
        projects?.projects
          ?.filter((project) => project.organization.id === organizationId)
          .filter((project) => project.name !== projectToEdit.name)
          .map((project) => project.name) || [],
      [organizationId, projectToEdit.name, projects?.projects]
    );

    return (
      <Form layout="vertical">
        <Form.Item label={intl.formatMessage({ id: 'forms.items.name.label' })}>
          {getFieldDecorator<ProjectEditFormData>('name', {
            rules: [
              maxLengthRule('general.maxNameLength', MAX_PROJECT_NAME_LENGTH),
              duplicateNameRule('forms.items.name.rules.nameExists', usedNames, true),
            ],
            initialValue: projectToEdit.name || '',
          })(
            <Input
              placeholder={intl.formatMessage({ id: 'ProjectCreateForm.form.items.name.placeholder' })}
              autoFocus
              ref={setRef}
            />
          )}
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'forms.items.description.label' })}>
          {getFieldDecorator<ProjectEditFormData>('description', {
            rules: [
              {
                max: MAX_PROJECT_DESCRIPTION_LENGTH,
                message: intl.formatMessage(
                  { id: 'general.maxDescriptionLength' },
                  { max: MAX_PROJECT_DESCRIPTION_LENGTH }
                ),
              },
            ],
            initialValue: projectToEdit.description || '',
          })(
            <Input.TextArea
              rows={3}
              autoSize={{ minRows: 3 }}
              placeholder={intl.formatMessage({ id: 'ProjectCreateForm.form.items.description.placeholder' })}
            />
          )}
        </Form.Item>

        {!!projectToEdit?.storageArea && (
          <Form.Item label={intl.formatMessage({ id: 'general.storagearea' })}>
            <Input disabled value={projectToEdit?.storageArea} />
          </Form.Item>
        )}

        <Form.Item
          label={
            <span>
              <Fmt id="ProjectCreateForm.form.items.language.label" />
              <CommonHubTooltip
                title={intl.formatMessage({ id: 'ProjectCreateForm.form.items.language.tooltip' })}
                placement="right"
              >
                <QuestionCircleOutlined style={{ paddingLeft: '8px' }} />
              </CommonHubTooltip>
            </span>
          }
        >
          {getFieldDecorator<ProjectEditFormData>('language', {
            initialValue: projectToEdit.language || LanguageEnum.cs,
          })(
            <Radio.Group>
              <Radio.Button value={LanguageEnum.cs}>
                {intl.formatMessage({ id: 'ProjectCreateForm.form.items.language.cs' })}
              </Radio.Button>
              <Radio.Button value={LanguageEnum.en}>
                {intl.formatMessage({ id: 'ProjectCreateForm.form.items.language.en' })}
              </Radio.Button>
            </Radio.Group>
          )}
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'ProjectCreateForm.form.timeZone' })}>
          {form.getFieldDecorator<ProjectEditFormData>('timeZoneId', {
            initialValue: projectToEdit.timeZone.id || timeZones.defaultTimeZoneId,
          })(
            <Select showSearch allowClear filterOption={simpleSelectFilter}>
              {timeZones?.timeZones.map((tz) => (
                <Select.Option key={tz.id} value={tz.id}>
                  {tz.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'general.labels' })}>
          {form.getFieldDecorator<ProjectEditFormData>('labels', {
            initialValue: projectToEdit.labels,
          })(<OrganizationLabelsInput organizationId={organizationId} />)}
        </Form.Item>
      </Form>
    );
  }
);

export default Form.create<Props>()(ProjectEditForm);
