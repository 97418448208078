import { apiConstraints } from 'api/completeApiConstraints';
import { DirectoryDetailDto, ProjectMetadataValueDto, ServiceError } from 'api/completeApiInterfaces';
import { ContentGate } from 'components/ContentGate/ContentGate';
import DocumentsGridHeader from 'components/DocumentsGridHeader/DocumentsGridHeader';
import GeneralSettingsContainer from 'components/GeneralSettingsContainer/GeneralSettingsContainer';
import GeneralTextSettingsItem from 'components/GeneralTextSettingsItem/GeneralTextSettingsItem';
import { MinMaxContainer } from 'components/MinMaxContainer/MinMaxContainer';
import StackPanel from 'components/StackPanel';
import { FiltersPersistentKey, FrontendFilter } from 'components/filters/filterTypes';
import { FrontendOrderOption } from 'components/filters/orderTypes';
import { FilterToolbar } from 'components/filters/render/FilterToolbar/FilterToolbar';
import { OrderSelect } from 'components/filters/render/OrderSelect/OrderSelect';
import { useFrontendFilters } from 'hooks';
import { Fmt } from 'locale';
import React, { FunctionComponent } from 'react';
import { textComparer } from 'utils/comparators';
import { BooleanOption, createFrontendBooleanFilter } from '../RadioFilter/variants/BooleanFilter/BooleanFilter';
import { createFrontendMultiTextFilter } from '../TextFilter/TextFilter';
import { MetadataExportImportButton } from './MetadataExportImportButton';

export const METADATA_ORDER_OPTIONS: FrontendOrderOption<ProjectMetadataValueDto>[] = [
  {
    key: 'name',
    label: <Fmt id="SearchSortTypeItem.name" />,
    compare: textComparer.map((metadataItem) => metadataItem.definitionName),
  },
];

export const METADATA_FILTERS: FrontendFilter<ProjectMetadataValueDto>[] = [
  createFrontendMultiTextFilter('name', (metadataItem) => [metadataItem.definitionName], {
    label: <Fmt id="general.name" />,
  }),
  createFrontendBooleanFilter(
    'hasValue',
    {
      label: <Fmt id="BooleanFilter.hasValue" />,
      title: <Fmt id="BooleanFilter.hasValue.description" />,
      trueLabel: <Fmt id="BooleanFilter.hasValue.yes" />,
      falseLabel: <Fmt id="BooleanFilter.hasValue.no" />,
    },
    (metadataItem) => (!!metadataItem.value ? BooleanOption.True : BooleanOption.False)
  ),
];

type Props = {
  metadataItems: ProjectMetadataValueDto[];
  metadataLoading: boolean;
  metadataError: ServiceError;
  handleSave: (value: string, id: Guid) => Promise<React.ReactNode>;
  persistentKey: FiltersPersistentKey;
  canEdit?: boolean;
  currentDirectory?: DirectoryDetailDto;
  loadDirectoryMetadata?: () => void;
};

export const MetadataEditList: FunctionComponent<Props> = React.memo(
  ({
    metadataItems,
    metadataLoading,
    metadataError,
    handleSave,
    loadDirectoryMetadata,
    persistentKey,
    canEdit,
    currentDirectory,
  }) => {
    const { orderedItems, ...filterProps } = useFrontendFilters(
      METADATA_FILTERS,
      METADATA_ORDER_OPTIONS,
      metadataItems,
      persistentKey
    );

    return (
      <>
        <MinMaxContainer
          offsetPx={10}
          itemsCount={orderedItems?.length}
          header={
            <div style={{ padding: '0.5rem 0 0.25rem 0' }}>
              <DocumentsGridHeader
                disableSelect
                order={<OrderSelect {...filterProps} />}
                filters={<FilterToolbar {...filterProps} />}
                additionalContent={
                  !!currentDirectory && !!loadDirectoryMetadata ? (
                    <MetadataExportImportButton
                      currentDirectory={currentDirectory}
                      orderedItems={orderedItems}
                      metadataLoading={metadataLoading}
                      canEdit={canEdit}
                      loadDirectoryMetadata={loadDirectoryMetadata}
                    />
                  ) : null
                }
              />
            </div>
          }
        >
          <ContentGate loading={metadataLoading} empty={!orderedItems.length} error={metadataError}>
            <StackPanel vertical scrollable>
              <GeneralSettingsContainer>
                {orderedItems.map((item) => (
                  <GeneralTextSettingsItem
                    itemId={item.metadataDefinitionId}
                    value={item.value}
                    onSave={handleSave}
                    disableEdit={!canEdit}
                    headline={item.definitionName}
                    maxLength={apiConstraints.projectMetadataValueDto.value.maxLength}
                    key={item.metadataDefinitionId}
                  />
                ))}
              </GeneralSettingsContainer>
            </StackPanel>
          </ContentGate>
        </MinMaxContainer>
      </>
    );
  }
);
