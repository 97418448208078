import { ModelConfig, ModelReducers, Models, RematchDispatch, RematchRootState, RematchStore } from '@rematch/core';
import {
  AppSettingsDto,
  AppUserProfileDto,
  CalendarResponseDto,
  DirectoryListDto,
  DirectoryValidationMasksDto,
  DocumentCategoryNodeDto,
  DocumentCategoryTreeDto,
  EstiConDphZemeDto,
  EstiConFirmRowDto,
  EstiConProjectDto,
  EstiConProjectRowDto,
  ExternalApplicationsProjectSettingsDto,
  FavoriteProjectListDto,
  GroupListDto,
  LabelDto,
  MsgCenterSummaryDto,
  OrgLabelListDto,
  ProjectHubDto,
  ProjectListResultDto,
  ProjectUserProfileDto,
  ProjectUserProfileListDto,
  RoleDto,
  ServiceError,
  WorkflowListDto,
} from 'api/completeApiInterfaces';
import { ErrorVariant } from 'api/errors';
import { DirectoryLink } from 'api/project/directories/directoriesApi';
import { Serializable } from 'components/filters/filterTypes';
import { OrderValue } from 'components/filters/orderTypes';
import { SideMenuKey } from 'components/SideMenuActivator';
import { LanguageEnum } from 'locale/messages/interfaces';
import { Dictionary } from 'lodash';
import { SearchSettings } from 'pages/ProjectSearchPage/SearchPageSettings/SearchPageSettings';
import React from 'react';
import { DirectoryNodeKey } from 'utils/typeMappings/directories/directoryTreeIds';

// old store state

export type ApiStoreState<T> = { data: T | null; error: ServiceError | null; loading: boolean; dirty: boolean };

export const apiStoreDefaults: ApiStoreState<any> = {
  data: null,
  error: null,
  loading: false,
  dirty: false,
};

export type ApiStoreReducers<T> = {
  setData: (state: ApiStoreState<T>, data: T) => ApiStoreState<T>;
  setError: (state: ApiStoreState<T>, error: ServiceError) => ApiStoreState<T>;
  setLoading: (state: ApiStoreState<T>, loading: boolean) => ApiStoreState<T>;
  setDefaults: () => ApiStoreState<T>;
  setDirty: (state: ApiStoreState<T>, dirty: boolean) => ApiStoreState<T>;
};

type PayloadData<T> = T extends undefined ? { data?: T } : { data: T };

export type ApiStoreEffects<T, U = undefined> = {
  loadData: (payload: { reload: boolean; silent?: boolean } & PayloadData<U>, state: RootState) => Promise<void>;
  updateData: (updater: (data: T) => T, state: RootState) => void;
  clearData: () => void;
  markDirty: () => void;
};

export type ApiStoreModel<T, U = undefined> = {
  state: ApiStoreState<T>;
  reducers: ApiStoreReducers<T>;
  effects: (dispatch: SafeDispatch) => ApiStoreEffects<T, U>;
};

// new store state

export type ApiNewStoreState<T> = { data: T | null; error: ErrorVariant | null; loading: boolean; dirty: boolean };

export const apiNewStoreDefaults: ApiNewStoreState<any> = {
  data: null,
  error: null,
  loading: false,
  dirty: false,
};

export type ApiNewStoreReducers<T> = {
  setData: (state: ApiNewStoreState<T>, data: T) => ApiNewStoreState<T>;
  setError: (state: ApiNewStoreState<T>, error: ErrorVariant) => ApiNewStoreState<T>;
  setLoading: (state: ApiNewStoreState<T>, loading: boolean) => ApiNewStoreState<T>;
  setDefaults: () => ApiNewStoreState<T>;
  setDirty: (state: ApiNewStoreState<T>, dirty: boolean) => ApiNewStoreState<T>;
};

export type ApiNewStoreModel<T, U = undefined> = {
  state: ApiNewStoreState<T>;
  reducers: ApiNewStoreReducers<T>;
  effects: (dispatch: SafeDispatch) => ApiStoreEffects<T, U>;
};

/**
 * Config
 */

export interface AppConfigStoreModelState {
  readonly locale: LanguageEnum;
  readonly sideMenuCollapsed: boolean;
  readonly sideMenuActiveKey: SideMenuKey | Guid;
  readonly serachSettings: SearchSettings;
  readonly authenticated: boolean;
}

export interface AppConfigStoreModelReducers {
  setLocale: (state: AppConfigStoreModelState, locale: LanguageEnum) => AppConfigStoreModelState;
  setSideMenuCollapsed: (state: AppConfigStoreModelState, collapsed: boolean) => AppConfigStoreModelState;
  setSideMenuActiveKey: (state: AppConfigStoreModelState, key: SideMenuKey | Guid) => AppConfigStoreModelState;
  setSearchSettings: (
    state: AppConfigStoreModelState,
    settings: React.SetStateAction<SearchSettings>
  ) => AppConfigStoreModelState;
  'redux-oidc/USER_FOUND': (state: AppConfigStoreModelState) => AppConfigStoreModelState;
}

export interface AppConfigStoreModel {
  state: AppConfigStoreModelState;
  reducers: AppConfigStoreModelReducers;
}

/**
 * Active project
 */

export interface ActiveProjectStoreModelState {
  readonly activeProject: ProjectHubDto;
  readonly activeProjectError: ServiceError;
  readonly activeProjectLoading: boolean;
}

export interface ActiveProjectStoreModelReducers {
  setActiveProject: (state: ActiveProjectStoreModelState, project: ProjectHubDto) => ActiveProjectStoreModelState;
  setActiveProjectError: (state: ActiveProjectStoreModelState, error: ServiceError) => ActiveProjectStoreModelState;
  setActiveProjectLoading: (state: ActiveProjectStoreModelState, loading: boolean) => ActiveProjectStoreModelState;
}

export interface ActiveProjectStoreModelEffects {
  selectProject: (projectId: Guid, rootState: RootState) => Promise<void>;
  reloadProjects: (projectIds: Guid[], rootState: RootState) => Promise<void>;
}

export interface ActiveProjectStoreModel {
  state: ActiveProjectStoreModelState;
  reducers: ActiveProjectStoreModelReducers;
  effects: (dispatch: SafeDispatch) => ActiveProjectStoreModelEffects;
}

/**
 * Active calendar
 */

export interface ActiveCalendarStoreModelState {
  readonly activeCalendar: CalendarResponseDto;
  readonly activeCalendarError: ServiceError;
  readonly activeCalendarLoading: boolean;
  readonly dirty: boolean;
}

export interface ActiveCalendarStoreModelReducers {
  setActiveCalendar: (
    state: ActiveCalendarStoreModelState,
    calendarResponse: CalendarResponseDto
  ) => ActiveCalendarStoreModelState;
  setActiveCalendarError: (state: ActiveCalendarStoreModelState, error: ServiceError) => ActiveCalendarStoreModelState;
  setActiveCalendarLoading: (state: ActiveCalendarStoreModelState, loading: boolean) => ActiveCalendarStoreModelState;
  setDefaults: (state: ActiveCalendarStoreModelState) => ActiveCalendarStoreModelState;
  setDirty: (state: ActiveCalendarStoreModelState, dirty: boolean) => ActiveCalendarStoreModelState;
}

export interface ActiveCalendarStoreModelEffects {
  loadCalendar: (source: { organizationId: Guid; projectId?: Guid }) => Promise<void>;
  clearData: () => void;
  markDirty: () => void;
}

export interface ActiveCalendarStoreModel {
  state: ActiveCalendarStoreModelState;
  reducers: ActiveCalendarStoreModelReducers;
  effects: (dispatch: SafeDispatch) => ActiveCalendarStoreModelEffects;
}

/**
 * CategoryTrees
 */

export type CategoryTreesStoreModelState = Record<
  Guid,
  {
    map: Record<Guid, DocumentCategoryNodeDto>;
    error: ServiceError;
    loading: boolean;
  }
>;

export type CategoryTrees = CategoryTreesStoreModelState;

export interface CategoryTreesStoreModelReducers {
  setCategoryTree: (
    state: CategoryTreesStoreModelState,
    data: { categoryId: Guid; map?: Dictionary<DocumentCategoryNodeDto>; loading?: boolean; error?: ServiceError }
  ) => CategoryTreesStoreModelState;
  setCategoryTreeNode: (
    state: CategoryTreesStoreModelState,
    data: { categoryId: Guid; node: DocumentCategoryNodeDto }
  ) => CategoryTreesStoreModelState;
  removeCategoryTreeNode: (
    state: CategoryTreesStoreModelState,
    arg: { categoryId: Guid; nodeId: Guid }
  ) => CategoryTreesStoreModelState;
  categoryChangeNodeName: (
    state: CategoryTreesStoreModelState,
    data: { name: string; categoryId: Guid }
  ) => CategoryTreesStoreModelState;
  setDefaults: () => CategoryTreesStoreModelState;
}

export interface CategoryTreesStoreModelEffects {
  loadCategoryTree: (payload: { reload: boolean; categoryId: Guid }, rootState: RootState) => Promise<void>;
  loadCategoriesTrees: (payload: { reload: boolean }, rootState: RootState) => Promise<void>;
}

export interface CategoryTreesStoreModel {
  state: CategoryTreesStoreModelState;
  reducers: CategoryTreesStoreModelReducers;
  effects: (dispatch: SafeDispatch) => CategoryTreesStoreModelEffects;
}

/**
 * AllDocumentsPage
 */

export interface AllDocumentsPageStoreModelState {
  readonly lastSelectedDirectoryKey: DirectoryNodeKey | undefined;
  readonly directoryTreeExpandedKeys: DirectoryNodeKey[];
  readonly backupExpandedKeys: DirectoryNodeKey[];
  readonly showFavorite: boolean;
  readonly directoryTreeSearch: string;
  readonly dirsSectionWidth: number;
}

export interface AllDocumentsPageStoreModelReducers {
  clearData: (state: AllDocumentsPageStoreModelState) => AllDocumentsPageStoreModelState;
  setLastDirectoryNodeKey: (
    state: AllDocumentsPageStoreModelState,
    payload: { directoryNodeKey: DirectoryNodeKey }
  ) => AllDocumentsPageStoreModelState;
  directoryTreeExpand: (
    state: AllDocumentsPageStoreModelState,
    payload: { keys: DirectoryNodeKey[] }
  ) => AllDocumentsPageStoreModelState;
  directoryTreeExpandWithSearched: (
    state: AllDocumentsPageStoreModelState,
    payload: { keys: DirectoryNodeKey[] }
  ) => AllDocumentsPageStoreModelState;
  directoryTreeCollapse: (
    state: AllDocumentsPageStoreModelState,
    payload: { keys: DirectoryNodeKey[] }
  ) => AllDocumentsPageStoreModelState;
  setDirectoryTreeExpandedKeys: (
    state: AllDocumentsPageStoreModelState,
    payload: { keys: DirectoryNodeKey[] }
  ) => AllDocumentsPageStoreModelState;
  setDirectoryTreeSearch: (
    state: AllDocumentsPageStoreModelState,
    payload: { term: string }
  ) => AllDocumentsPageStoreModelState;
  setDirsSectionWidth: (
    state: AllDocumentsPageStoreModelState,
    payload: { width: number }
  ) => AllDocumentsPageStoreModelState;
  setShowFavorite: (
    state: AllDocumentsPageStoreModelState,
    payload: { isFavorite: boolean }
  ) => AllDocumentsPageStoreModelState;
}

export interface AllDocumentsPageStoreModel {
  state: AllDocumentsPageStoreModelState;
  reducers: AllDocumentsPageStoreModelReducers;
}

/**
 * FilterState
 */

export type FilterItem = {
  filters: Record<string, Serializable>;
  order: OrderValue;
};

export interface FilterStateStoreModelState {
  toolbarFiltersSets: Partial<Record<string, FilterItem>>;
}

export interface FilterStateStoreModelReducers extends ModelReducers<FilterStateStoreModelState> {
  readonly setFilter: (
    state: FilterStateStoreModelState,
    payload: { itemKey: string; filterItem: FilterItem }
  ) => FilterStateStoreModelState;
}

export interface FilterStateStoreModel extends ModelConfig<FilterStateStoreModelState> {
  reducers: FilterStateStoreModelReducers;
}

/**
 * Disable Window
 */

export interface DisableWindowStoreStartPayload {
  readonly showMask: boolean;
  readonly message: string;
}

export interface DisableWindowStoreModelState extends DisableWindowStoreStartPayload {
  readonly isActive: boolean;
}

export interface DisableWindowStoreModelReducers extends ModelReducers<DisableWindowStoreModelState> {
  stopPreventing: (state: DisableWindowStoreModelState) => DisableWindowStoreModelState;
  startPreventing: (
    state: DisableWindowStoreModelState,
    payload: DisableWindowStoreStartPayload
  ) => DisableWindowStoreModelState;
}

export interface DisableWindowStoreModel extends ModelConfig<DisableWindowStoreModelState> {
  reducers: DisableWindowStoreModelReducers;
}

/**
 * Persistent UI
 */

export interface PersistentUiStoreModelState {
  readonly openCreateAssignmentModal: boolean;
  readonly setFilterToSearchByLabelId: Guid | null;
}

export interface PersistentUiStoreModelReducers {
  setOpenCreateAssignmentModal: (
    state: PersistentUiStoreModelState,
    openCreateAssignmentModal: boolean
  ) => PersistentUiStoreModelState;
  setFilterToSearchByLabelId: (
    state: PersistentUiStoreModelState,
    setFilterToSearchByLabelId: Guid | null
  ) => PersistentUiStoreModelState;
}

export interface PersistentUiStoreModel {
  state: PersistentUiStoreModelState;
  reducers: PersistentUiStoreModelReducers;
}

/**
 * Store Models
 */

export interface StoreModelsSafe {
  readonly appConfig: AppConfigStoreModel;
  readonly currentAppUser: ApiStoreModel<AppUserProfileDto>;
  readonly appSettings: ApiStoreModel<AppSettingsDto>;
  readonly favoriteProjects: ApiStoreModel<FavoriteProjectListDto[]>;
  readonly allProjects: ApiStoreModel<ProjectListResultDto>;
  readonly activeProject: ActiveProjectStoreModel;
  readonly activeCalendar: ActiveCalendarStoreModel;
  readonly externalApplicationsSettings: ApiStoreModel<ExternalApplicationsProjectSettingsDto>;
  readonly directories: ApiStoreModel<DirectoryListDto[]>;
  readonly directoriesWithLinks: ApiNewStoreModel<{ directories: DirectoryListDto[]; directoryLinks: DirectoryLink[] }>;
  readonly currentProjectUser: ApiStoreModel<ProjectUserProfileDto>;
  readonly projectUsers: ApiStoreModel<ProjectUserProfileListDto[]>;
  readonly groups: ApiStoreModel<GroupListDto[]>;
  readonly roles: ApiStoreModel<RoleDto[]>;
  readonly labels: ApiStoreModel<LabelDto[]>;
  readonly organizationLabels: ApiStoreModel<OrgLabelListDto[], Guid[]>;
  readonly esticonFirms: ApiStoreModel<EstiConFirmRowDto[]>;
  readonly esticonProjects: ApiStoreModel<EstiConProjectRowDto[]>;
  readonly esticonVat: ApiStoreModel<EstiConDphZemeDto[]>;
  readonly esticonDetail: ApiStoreModel<EstiConProjectDto, Guid>;
  readonly workflows: ApiStoreModel<WorkflowListDto[]>;
  readonly categories: ApiStoreModel<DocumentCategoryTreeDto[]>;
  readonly userSummary: ApiStoreModel<MsgCenterSummaryDto>;
  readonly categoryTrees: CategoryTreesStoreModel;
  readonly filterState: FilterStateStoreModel;
  readonly projectDirectoryMasks: ApiStoreModel<DirectoryValidationMasksDto>;
  // UI
  readonly allDocumentsPage: AllDocumentsPageStoreModel;
  readonly disableWindow: DisableWindowStoreModel;
  readonly persistentUi: PersistentUiStoreModel;
}

export type StoreModelsUnsafe = StoreModelsSafe & Models;

// Reducers

type ReducerToDispatch<T> = T extends (state: unknown, ...args: infer U) => unknown ? (...args: U) => void : never;

type ReducersToDispatch<T> = { [key in keyof T]: ReducerToDispatch<T[key]> };

// Effects

type EffectToDispatch<T> = T extends () => unknown
  ? T // No arguments - don't transform
  : T extends (payload: infer U, store: unknown) => infer V
  ? (payload: U) => V // payload and store: remove store
  : never;

type EffectsToDispatch<T> = { [key in keyof T]: EffectToDispatch<T[key]> };

// Dispatch (Effects + Reducers)

export type StoreModelToDispatch<T> = T extends { reducers: infer R; effects: (dispatch: unknown) => infer E }
  ? ReducersToDispatch<R> & EffectsToDispatch<E> // both reducers and effect
  : T extends { reducers: infer R }
  ? ReducersToDispatch<R> // only reducers
  : T extends { effects: (dispatch: unknown) => infer A }
  ? EffectsToDispatch<A> // only effects
  : never;
type StoreModelsToDispatch<T> = { [key in keyof T]: StoreModelToDispatch<T[key]> };

// State

type ModelToState<T> = T extends { state: infer U } ? U : never;
type ModelsToRootState<T> = { [key in keyof T]: ModelToState<T[key]> };

export type RootStateUnsafe = RematchRootState<StoreModelsUnsafe>;

export type RootState = ModelsToRootState<StoreModelsSafe>;

/**
 * Store Dispatch with default (unsafe) types
 */
export type UnsafeDispatch = RematchDispatch<StoreModelsUnsafe>;

/**
 * Properly typed Dispatch
 */

export type SafeDispatch = StoreModelsToDispatch<StoreModelsSafe>;

/**
 * Store (unsafe?)
 */
export type Store = RematchStore<StoreModelsUnsafe>;

type LegacyMapDispatchToProps<T> = T extends (dispatch: SafeDispatch) => infer U
  ? (dispatch: UnsafeDispatch) => U
  : never;

export const legacyMapDispatchToProps = <T>(mapper: T): LegacyMapDispatchToProps<T> => mapper as any;

export const safeEffectsAsUnsafe = <T>(effects: (dispatch: SafeDispatch) => T): ((dispatch: UnsafeDispatch) => T) =>
  effects as any;
