import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Alert, Button, Select } from 'antd';
import { RefSelectProps, SelectProps } from 'antd/lib/select';
import GeneralSettingsItem from 'components/GeneralSettingsItem/GeneralSettingsItem';
import { EditIcon } from 'components/Icons/HubActionsIcons';
import { SelectSettingsItemOption } from 'components/SettingsItem/SelectSettingsItem';
import { ConfirmOptions } from 'components/SettingsItem/SettingsItem';
import { FlowLayout } from 'components/layouts/FlowLayout';
import { useIsMounted, useSameCallback } from 'hooks';
import { isArray } from 'lodash';
import React, { FunctionComponent, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { simpleSelectFilter } from 'utils/formHelpersCompatibility';

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> &
  Pick<SelectProps, 'mode' | 'showArrow'> & {
    value: string | string[];
    headline: ReactNode;
    editTooltip?: ReactNode;
    onSave?: (value: string | string[]) => Promise<boolean | ReactNode>;
    disableEdit?: boolean;
    easyMode?: boolean;
    loading?: boolean;
    itemId?: Guid;
    options: SelectSettingsItemOption[];
    placeholder?: ReactNode;
    confirmOptions?: ConfirmOptions;
    showSearch?: boolean;
    allowClear?: boolean;
  };

const GeneralSelectSettingsItem: FunctionComponent<Props> = ({
  disableEdit,
  value,
  onSave,
  headline,
  loading,
  itemId,
  showSearch,
  options,
  mode,
  showArrow,
  allowClear = false,
}) => {
  const [saving, setSaving] = useState(false);
  const [editing, setEditing] = useState(false);
  const [error, setError] = useState<ReactNode>(null);
  const [liveValue, setLiveValue] = useState<string | string[]>(value);

  const ref = useRef<RefSelectProps>();

  const isMounted = useIsMounted();

  useEffect(() => {
    setLiveValue(value);
  }, [value]);

  useEffect(() => {
    if (!!itemId) handleCancel();
  }, [itemId]);

  const handleCancel = useCallback(() => {
    setLiveValue(value);
    setError(null);
  }, [value]);

  const handleSave = useSameCallback(async () => {
    if (onSave) {
      setSaving(true);
      const result = await onSave(liveValue);
      if (!isMounted) {
        return;
      }
      if (result !== false && result !== true) {
        setError(result);
      } else if (result) {
        setError(null);
        setEditing(false);
      }
      setSaving(false);
    } else {
      setEditing(false);
    }
  });

  const handleInputChange = (value: string) => {
    setLiveValue(value);
  };

  const isDirty = liveValue !== value;

  const handleEdit = () => {
    setEditing(true);
  };

  const handleCancelEditing = () => {
    setLiveValue(value);
    setError(null);
    setEditing(false);
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      switch (event.key) {
        case 'Escape':
          handleCancelEditing();
          break;
        case 'Enter':
          void handleSave();
          break;
      }
    },
    [handleSave, handleCancelEditing]
  );

  useEffect(() => {
    if (!disableEdit) {
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
    return () => {};
  }, [handleKeyDown, disableEdit]);

  const option = options.find((o) => o.value === liveValue);
  const displayedOption = isArray(liveValue)
    ? options.filter((o) => liveValue.some((value) => o.value === value))
    : options.find((o) => o.value === liveValue);

  return (
    <GeneralSettingsItem
      disabled={disableEdit}
      title={headline}
      description={
        !editing ? (
          displayedOption ? (
            isArray(displayedOption) ? (
              displayedOption.map((option) => option.text)
            ) : (
              option.text
            )
          ) : (
            liveValue || ''
          )
        ) : (
          <>
            <FlowLayout growFirst>
              <Select
                onChange={handleInputChange}
                value={liveValue}
                disabled={disableEdit || saving || loading}
                autoFocus
                showSearch={showSearch}
                filterOption={simpleSelectFilter}
                mode={mode}
                ref={ref}
                showArrow
                allowClear={allowClear}
              >
                {options.map((option) => (
                  <Select.Option key={option.value}>{option.text}</Select.Option>
                ))}
              </Select>
            </FlowLayout>
            {!!error && <Alert type="error" banner message={error} />}
          </>
        )
      }
      additionalActions={
        <>
          {!editing ? (
            <Button type="link" icon={<EditIcon />} onClick={handleEdit} disabled={disableEdit} />
          ) : (
            <>
              <Button type="link" icon={<SaveOutlined />} onClick={handleSave} disabled={!isDirty} loading={loading} />
              <Button type="link" icon={<CloseOutlined />} onClick={handleCancelEditing} />
            </>
          )}
        </>
      }
    />
  );
};

export default GeneralSelectSettingsItem;
