import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { OrgUserDto, ProjectUserProfileListDto } from 'api/completeApiInterfaces';
import { UserTransferFormItem } from 'components/UserTransfer/UserTransfer';
import { InjectedIntlProps } from 'locale';
import React, { useEffect, useMemo } from 'react';

export type MDDivisionAddUsersFormData = {
  users: Guid[];
};

type Props = FormComponentProps<MDDivisionAddUsersFormData> &
  InjectedIntlProps & {
    organizationUsers: OrgUserDto[];
    assignedUsersIds: Guid[];
  };

const MDDivisionAddUserFormComponent = React.forwardRef<unknown, Props>(
  ({ intl, form, organizationUsers, assignedUsersIds }, ref) => {
    // backward compatibility with class components
    useEffect(() => {
      (ref as any).current = { props: { form } };
    }, [form]);

    const availableUserList = useMemo(
      () =>
        organizationUsers
          .filter((user) => !assignedUsersIds.includes(user.id))
          .map(
            (orgUser): ProjectUserProfileListDto => ({
              id: orgUser.id,
              username: orgUser.appUserProfile.username,
              firstname: orgUser.firstname,
              lastname: orgUser.lastname,
              isAdmin: orgUser.isAdmin,
              status: undefined,
            })
          ) || [],
      [assignedUsersIds, organizationUsers]
    );

    return (
      <Form layout="vertical">
        <Form.Item label={intl.formatMessage({ id: 'general.users' })}>
          {form.getFieldDecorator<MDDivisionAddUsersFormData>('users', {
            initialValue: [],
          })(<UserTransferFormItem users={availableUserList} />)}
        </Form.Item>
      </Form>
    );
  }
);

export const MDDivisionAddUserForm = React.memo(Form.create<Props>()(MDDivisionAddUserFormComponent));
