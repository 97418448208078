import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Input, InputRef, Radio, Select, Tabs, TabsProps } from 'antd';
import { masterApi } from 'api/completeApi';
import { OrganizationAdminReportDto, ProjectTimezoneListDto } from 'api/completeApiInterfaces';
import CommonHubTooltip from 'components/CommonHubTooltip/CommonHubTooltip';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { OrganizationLabelsInput } from 'components/LabelsInput/OrganizationLabelsInput';
import {
  DEFAULT_LOCALE,
  DEFAULT_PROJECT_TIMEZONE_LINUX,
  DEFAULT_PROJECT_TIMEZONE_WINDOWS,
  MAX_PROJECT_DESCRIPTION_LENGTH,
  MAX_PROJECT_NAME_LENGTH,
} from 'config/constants';
import { useApiData, useSelectorDispatch } from 'hooks';
import { useDirtyStoreReload } from 'hooks/useSelectorDispatch';
import { Fmt, InjectedIntlProps } from 'locale';
import { LanguageEnum } from 'locale/messages/interfaces';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { duplicateNameRule, maxLengthRule, requiredRule, simpleSelectFilter } from 'utils/formHelpersCompatibility';
import { AppUsersFormItem, SelectableUsersSourceEnum, UserAttributes } from '../AppUsersAddFormModal/AppUsersFormItem';
import { noAdminInAppUsersItem } from '../OrganizationAddFormModal';

export enum ProjectSource {
  NewProject = 'NewProject',
  FromTemplate = 'FromTemplate',
}

export type ProjectCreateFormData = {
  name: string;
  description: string;
  organizationId: Guid;
  storageArea: string;
  labels: Guid[];
  usersToAddWithAttributes: Record<string, UserAttributes>;
} & (
  | {
      type: ProjectSource.NewProject;
      language: LanguageEnum;
      timeZoneId: string;
    }
  | {
      type: ProjectSource.FromTemplate;
      templateId: Guid;
    }
);

type Props = FormComponentProps<ProjectCreateFormData> &
  InjectedIntlProps & {
    defaults?: {
      language?: LanguageEnum;
    };
    timeZones: ProjectTimezoneListDto;
    organizations: OrganizationAdminReportDto[];
    setRef: (ref: InputRef) => void;
  };

const ProjectCreateForm = React.forwardRef<unknown, Props>(
  ({ intl, form, defaults, timeZones, organizations, setRef }, ref) => {
    // backward compatibility with class components
    useEffect(() => {
      (ref as any).current = { props: { form } };
    }, [form]);
    const { getFieldDecorator } = form;

    const [orgStorageAreas, setOrgStorageAreas] = useState<string[]>();

    const projects = useSelectorDispatch(
      (state) => state.allProjects.data,
      (dispatch) => dispatch.allProjects.loadData({ reload: false }),
      []
    );

    useDirtyStoreReload(
      (state) => state.allProjects,
      (dispatch) => dispatch.allProjects
    );

    const selectedOrganizationId = useMemo((): Guid => form.getFieldValue('organizationId'), [form]);
    const activeSourceType = useMemo((): ProjectSource => form.getFieldValue('type') || ProjectSource.NewProject, [
      form,
    ]);
    const selectedTemplateId = useMemo((): Guid => form.getFieldValue('templateId'), [form]);

    const [orgTemplates, orgTemplatesError, orgTemplatesLoading, loadOrgTemplates] = useApiData(
      (ct) => masterApi.projects.tempates.projecttemplatelist.id.get(selectedOrganizationId, ct),
      { autoload: false }
    );

    useEffect(() => {
      if (!!selectedOrganizationId) {
        loadOrgTemplates();
        form.setFieldsValue({ templateId: undefined });
        const selectedOrgStorageAreas = organizations.find((org) => org.id === selectedOrganizationId)
          .defaultStorageArea;
        setOrgStorageAreas(selectedOrgStorageAreas);
      }
    }, [selectedOrganizationId]);

    const organizationUsers = useMemo(() => {
      if (!selectedOrganizationId)
        return (!!organizations && organizations[0].organizationUsers.map((orgUser) => orgUser.appUserProfile)) || [];
      return organizations
        .find((org) => org.id === selectedOrganizationId)
        ?.organizationUsers.map((orgUser) => orgUser.appUserProfile);
    }, [selectedOrganizationId, organizations]);

    const usedNames = projects?.projects?.map((project) => project.name) || [];

    const usersValidation = useCallback(
      (rule, value, callback, source, options) => {
        const errors: string[] = [];
        if (form.getFieldValue('type') === ProjectSource.FromTemplate) callback([]);
        if (noAdminInAppUsersItem(value)) {
          errors.push(intl.formatMessage({ id: 'OrganizationAddForm.required' }));
        }
        callback(errors);
      },
      [intl, form]
    );

    useEffect(() => {
      form.validateFields(['templateId']);
    }, [selectedTemplateId]);

    useEffect(() => {
      form.validateFields(['usersToAddWithAttributes']);
    }, [activeSourceType]);

    const items: TabsProps['items'] = [
      {
        key: ProjectSource.NewProject,
        label: <Fmt id="ProjectCreateForm.form.tabs.newProject" />,
        children: activeSourceType === ProjectSource.NewProject && (
          <>
            <Form.Item
              label={
                <span>
                  <Fmt id="ProjectCreateForm.form.items.language.label" />
                  <CommonHubTooltip
                    title={intl.formatMessage({ id: 'ProjectCreateForm.form.items.language.tooltip' })}
                    placement="right"
                  >
                    <QuestionCircleOutlined style={{ paddingLeft: '8px' }} />
                  </CommonHubTooltip>
                </span>
              }
            >
              {getFieldDecorator<ProjectCreateFormData & { type: ProjectSource.NewProject }>('language', {
                initialValue: defaults?.language || LanguageEnum[DEFAULT_LOCALE] || LanguageEnum.cs,
              })(
                <Radio.Group>
                  <Radio.Button value={LanguageEnum.cs}>
                    {intl.formatMessage({ id: 'ProjectCreateForm.form.items.language.cs' })}
                  </Radio.Button>
                  <Radio.Button value={LanguageEnum.en}>
                    {intl.formatMessage({ id: 'ProjectCreateForm.form.items.language.en' })}
                  </Radio.Button>
                </Radio.Group>
              )}
            </Form.Item>
            <Form.Item label={intl.formatMessage({ id: 'ProjectCreateForm.form.timeZone' })}>
              {form.getFieldDecorator<ProjectCreateFormData & { type: ProjectSource.NewProject }>('timeZoneId', {
                rules: [requiredRule('ProjectCreateForm.form.timeZone.rules.required')],
                initialValue: timeZones.timeZones.some((tz) => tz.id === DEFAULT_PROJECT_TIMEZONE_WINDOWS)
                  ? DEFAULT_PROJECT_TIMEZONE_WINDOWS
                  : DEFAULT_PROJECT_TIMEZONE_LINUX,
              })(
                <Select showSearch allowClear filterOption={simpleSelectFilter}>
                  {timeZones?.timeZones.map((tz) => (
                    <Select.Option key={tz.id} value={tz.id}>
                      {tz.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </>
        ),
      },
      {
        key: ProjectSource.FromTemplate,
        label: <Fmt id="ProjectCreateForm.form.tabs.fromTemplate" />,
        disabled: !orgTemplates?.length || orgTemplatesLoading,
        children: activeSourceType === ProjectSource.FromTemplate && (
          <ContentGate error={orgTemplatesError}>
            <Form.Item label={intl.formatMessage({ id: 'ProjectCreateForm.form.template' })}>
              {form.getFieldDecorator<ProjectCreateFormData & { type: ProjectSource.FromTemplate }>('templateId', {
                rules: [requiredRule('ProjectCreateForm.form.template.rules.required')],
              })(
                <Select showSearch allowClear filterOption={simpleSelectFilter}>
                  {orgTemplates?.map((template) => (
                    <Select.Option key={template.id} value={template.id}>
                      {template.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </ContentGate>
        ),
      },
    ];

    return (
      <Form layout="vertical">
        <Form.Item label={intl.formatMessage({ id: 'forms.items.name.label' })}>
          {getFieldDecorator<ProjectCreateFormData>('name', {
            rules: [
              requiredRule('ProjectCreateForm.form.items.name.rules.required', true),
              maxLengthRule('general.maxNameLength', MAX_PROJECT_NAME_LENGTH),
              duplicateNameRule('forms.items.name.rules.nameExists', usedNames, true),
            ],
          })(
            <Input
              placeholder={intl.formatMessage({ id: 'ProjectCreateForm.form.items.name.placeholder' })}
              autoFocus
              ref={setRef}
            />
          )}
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'forms.items.description.label' })}>
          {getFieldDecorator<ProjectCreateFormData>('description', {
            rules: [
              {
                max: MAX_PROJECT_DESCRIPTION_LENGTH,
                message: intl.formatMessage(
                  { id: 'general.maxDescriptionLength' },
                  { max: MAX_PROJECT_DESCRIPTION_LENGTH }
                ),
              },
            ],
          })(
            <Input.TextArea
              rows={3}
              autoSize={{ minRows: 3 }}
              placeholder={intl.formatMessage({ id: 'ProjectCreateForm.form.items.description.placeholder' })}
            />
          )}
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'general.organizations' })}>
          {form.getFieldDecorator<ProjectCreateFormData>('organizationId', {
            initialValue: organizations?.length && organizations[0].id,
          })(
            <Select showSearch allowClear={false} filterOption={simpleSelectFilter}>
              {organizations?.map((organization) => (
                <Select.Option key={organization.id} value={organization.id}>
                  {organization.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        {!!orgStorageAreas?.length && (
          <Form.Item label={intl.formatMessage({ id: 'general.storagearea' })}>
            {getFieldDecorator<ProjectCreateFormData & { type: ProjectSource.NewProject }>('storageArea', {
              initialValue: orgStorageAreas.length === 1 ? orgStorageAreas[0] : undefined,
              rules: [requiredRule('forms.items.rules.required', true)],
            })(
              <Select>
                {orgStorageAreas.map((area) => (
                  <Select.Option key={area}>{area}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        )}
        <Form.Item label={intl.formatMessage({ id: 'general.labels' })}>
          {form.getFieldDecorator<ProjectCreateFormData>('labels')(
            <OrganizationLabelsInput organizationId={form.getFieldValue('organizationId')} />
          )}
        </Form.Item>
        <Form.Item>
          {form.getFieldDecorator<ProjectCreateFormData>('type', {
            initialValue: ProjectSource.NewProject,
            valuePropName: 'activeKey',
          })(<Tabs items={items} />)}
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'general.users' })}>
          {form.getFieldDecorator('usersToAddWithAttributes', {
            rules: [
              {
                validator: usersValidation,
              },
            ],
          })(
            <AppUsersFormItem
              selectableAppUsers={organizationUsers}
              intl={intl}
              selectableUsersToAddSource={SelectableUsersSourceEnum.organization}
              isProjectCreating
            />
          )}
        </Form.Item>
      </Form>
    );
  }
);

export default Form.create<Props>()(ProjectCreateForm);
