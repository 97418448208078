import { masterApi } from 'api/completeApi';
import { OrganizationAdminReportDto } from 'api/completeApiInterfaces';
import { ServiceErrorEnum } from 'api/errors';
import { ContentGate } from 'components/ContentGate/ContentGate';
import { FormModalProps } from 'components/forms/FormModalProps';
import { FormModalWrapper, FormSubmitHandler } from 'components/forms/FormModalWrapper';
import { useApiData, useIntl, useIsMounted, useSameCallback } from 'hooks';
import React, { FunctionComponent, useState } from 'react';
import { messageError } from 'utils';
import { MdOverheadProjectSettingsForm, MdOverheadProjectSettingsFormData } from './MdOverheadProjectSettingsForm';

type Props = FormModalProps<MdOverheadProjectSettingsFormData> & {
  organization: OrganizationAdminReportDto;
};

const MdOverheadProjectSettingsModal: FunctionComponent<Props> = (props) => {
  const { onSubmit, organization, ...restProps } = props;
  const [saving, setSaving] = useState<boolean>();
  const intl = useIntl();
  const isMounted = useIsMounted();

  const [overheadProjectSettings, overheadProjectSettingsError, overheadProjectSettingsLoading] = useApiData(
    (ct) => masterApi.projects.md.settings.id.getoverhead.get(organization.id, ct),
    { autoload: true }
  );

  const handleSubmit: FormSubmitHandler<MdOverheadProjectSettingsFormData> = useSameCallback(async (values) => {
    setSaving(true);

    const [err, res] = await masterApi.projects.md.settings.id.setoverhead.post(organization.id, {
      id: values.projectId,
    });
    if (!isMounted) return null;
    if (err) {
      messageError(err, intl);
    } else {
      onSubmit(values);
    }

    setSaving(false);
    return null;
  });

  const overheadProjectError =
    overheadProjectSettingsError &&
    overheadProjectSettingsError.referenceErrorCode !== ServiceErrorEnum.OverheadMdProjectNotSetError
      ? overheadProjectSettingsError
      : undefined;

  return (
    <FormModalWrapper
      onSubmit={handleSubmit}
      titleId={'OrganizationSettingsListPanel.report.mdOverheadProject.name'}
      submitTextId={'general.save'}
      forceLoading={saving}
      width={500}
      {...restProps}
    >
      {({ intl, formRef }) => (
        <ContentGate error={overheadProjectError} loading={overheadProjectSettingsLoading}>
          <MdOverheadProjectSettingsForm
            intl={intl}
            wrappedComponentRef={formRef}
            defaultProjectId={overheadProjectSettings?.id}
            organizationId={organization.id}
            projects={organization.projects}
          />
        </ContentGate>
      )}
    </FormModalWrapper>
  );
};

export default MdOverheadProjectSettingsModal;
